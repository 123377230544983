import React, { useState } from "react";
import { format } from "date-fns";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import VivialConnectAPI from "api/vivial-connect";
import { useAppContext } from "components/Context";
import {
  Card as DashboardCard,
  CardHeader as DashboardHeader,
  CardContent as DashboardCardContent,
} from "components/Layouts/Dashboard/Card";

const {
  convertNumberToCurrency,
  withCommaSeparator,
} = VivialConnectAPI.helpers.numbers;

export const AccountStatus = () => {
  const { appState } = useAppContext();
  const {
    id: accountId,
    plan_description: planDescription,
    cash_balance: cashBalance,
    plan_type: planType,
    usage_credits: usageCredits,
    next_renewal_date: nextRenewalDate,
  } = appState;
  const [autorecharge, setAutorecharge] = useState({
    autorecharge_amount: 10000,
    autorecharge_enabled: false,
    autorecharge_threshold: 10000,
  });

  React.useEffect(() => {
    const { accounts } = VivialConnectAPI.services;

    if (planType === "ondemand") {
      accounts
        .getAccountAutoRecharge()
        .then((autorecharge) => setAutorecharge(autorecharge))
        .catch(() => {
          /* mute error */
        });
    }
  }, [planType]);

  if (!accountId) return null;

  return (
    <DashboardCard>
      <DashboardHeader icon={InfoOutlinedIcon} title={"Account Status"} />
      <DashboardCardContent alignContent="flex-start">
        <Grid item xs={6}>
          <Typography variant="body2">Your Plan:</Typography>
        </Grid>
        <Grid item xs={6}>
          <p>
            {planType === "ondemand"
              ? "On-Demand"
              : planType === "subscription"
              ? "90-Day Subscription"
              : planDescription}
          </p>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">Account ID:</Typography>
        </Grid>
        <Grid item xs={6}>
          {accountId}
        </Grid>
        {planType === "ondemand" ? (
          <>
            <Grid item xs={6}>
              <Typography variant="body2">Account Balance:</Typography>
            </Grid>
            <Grid item xs={6}>
              <p>{`${
                cashBalance ? convertNumberToCurrency(cashBalance) : "$0.00"
              }`}</p>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Automatic Billing:</Typography>
            </Grid>
            <Grid item xs={6}>
              <p>{autorecharge.autorecharge_enabled ? "ON" : "OFF"}</p>
            </Grid>
          </>
        ) : null}
        {planType === "subscription" ? (
          <>
            <Grid item xs={6}>
              <Typography variant="body2">Credits available:</Typography>
            </Grid>
            <Grid item xs={6}>
              {usageCredits ? withCommaSeparator(usageCredits) : "0"}
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body2">Credits Renew:</Typography>
            </Grid>
            <Grid item xs={6}>
              {nextRenewalDate
                ? format(Date.parse(nextRenewalDate), "MMMM do, yyyy")
                : ""}
            </Grid>
          </>
        ) : null}
      </DashboardCardContent>
    </DashboardCard>
  );
};

export default {
  AccountStatus,
};
