import { makeStyles } from "@material-ui/core";
import { colorPrimaryBlack, colorPrimaryWhite } from "themes/palette";

export const useStyles = makeStyles((theme) => ({
  logoImg: {
    margin: "0 20px",
    width: "45vw",
    [theme.breakpoints.up("sm")]: {
      width: "270px",
    },
  },
  menuItems: {
    display: "flex",
    flexDirection: "row",
    padding: 0,
    justifyContent: "flex-end",
  },
  menuItem: {
    alignItems: "center",
    width: "100%",
    padding: "0 10px",
    "& a": {
      padding: "10px 0",
      ...theme.typography.body1,
      fontSize: "0.9rem",
      fontWeight: 300,
      color: colorPrimaryWhite,
      transition: "color .2s linear",
    },
    "& a:hover": {
      color: theme.palette.primary.main,
    },
  },
  EOLItem: {
    "& a": {
      color: "#ff3333",
      fontWeight: 800,
    },
    "& a:hover": {
      color: colorPrimaryWhite,
    },
  },
  menuButton: {
    padding: "10px 0",
    ...theme.typography.body1,
    fontSize: "0.9rem",
    fontWeight: 300,
    color: colorPrimaryWhite,
    transition: "color .2s linear",
    display: "inline-block",
    backgroundColor: "transparent",
    border: 0,
    outline: "none",
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.primary.main,
    },
  },
  popMenuItem: {
    "& a": {
      ...theme.typography.body1,
      fontSize: "0.9rem",
      color: colorPrimaryBlack,
    },
  },
  appBar: {
    backgroundColor: "transparent",
    boxShadow: 0,
  },
  appBarScrollClass: {
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  drawer: {
    width: "90vw",
    "& .logo": {
      width: "150px",
      margin: "8px 16px 0",
    },
    "& drawerHeader": theme.mixins.toolbar,
    [theme.breakpoints.up("sm")]: {
      width: "540px",
    },
  },
  drawerOpenIcon: {
    color: colorPrimaryWhite,
    marginRight: theme.spacing(1),
  },
}));
