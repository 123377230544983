export const DEFAULT_CAMPAIGN = {
  account_id: 0,
  active: true,
  brand_id: "",
  renewal: true,
  data: {
    affiliateMarketing: false,
    ageGated: false,
    autoRenewal: true,
    billedDate: "Tue, 10 Aug 2021 00:00:00 GMT",
    brandId: "",
    campaignId: "",
    createDate: "2000-01-01T00:00:00.000Z",
    cspId: "",
    description: "Test",
    directLending: false,
    embeddedLink: false,
    embeddedPhone: false,
    helpMessage: null,
    messageFlow: null,
    mock: false,
    numberPool: false,
    referenceId: null,
    resellerId: null,
    sample1: "",
    sample2: "",
    sample3: "",
    sample4: "",
    sample5: "",
    status: "ACTIVE",
    subUsecases: [],
    subscriberHelp: true,
    subscriberOptin: true,
    subscriberOptout: true,
    usecase: "",
    vertical: "",
  },
  tcr_data: {
    affiliateMarketing: false,
    ageGated: false,
    autoRenewal: true,
    billedDate: "Tue, 10 Aug 2021 00:00:00 GMT",
    brandId: "",
    campaignId: "",
    createDate: "2000-01-01T00:00:00.000Z",
    cspId: "",
    description: "Test",
    directLending: false,
    embeddedLink: false,
    embeddedPhone: false,
    helpMessage: null,
    messageFlow: null,
    mock: false,
    numberPool: false,
    referenceId: null,
    resellerId: null,
    sample1: "",
    sample2: "",
    sample3: "",
    sample4: "",
    sample5: "",
    status: "ACTIVE",
    subUsecases: [],
    subscriberHelp: true,
    subscriberOptin: true,
    subscriberOptout: true,
    usecase: "",
    vertical: "",
  },
  date_created: "2021-09-21T17:58:15",
  date_modified: "2021-09-21T17:58:15",
  id: "C8LV4QZ",
  name: "test",
  origin: "INTERNAL",
  status: "ACTIVE",
};
