import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  paper: {
    height: "100%",
    width: "100%",
    minHeight: "900px",
    borderRadius: 15,
    padding: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(6, 10),
    },
  },
  cardContent: {
    padding: theme.spacing(2),
  },
  connectLogo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(4),
  },
  narrow: {
    width: "35%",
    minWidth: "200px",
  },
  wide: {
    width: "267px",
  },
}));

export default useStyles;
