/* eslint-disable react/prop-types */
import React, { useState, createRef, useEffect } from "react";
import { toast } from "react-toastify";

import { ThemeProvider } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import SearchField from "components/SearchField";
import TablePagination from "@material-ui/core/TablePagination";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import VivialConnectAPI from "api/vivial-connect";
import { useAppContext } from "components/Context";
import withPageContext from "components/Context/WithPageContext";

import { darkTheme } from "themes";
import {
  Card as DashboardCard,
  CardContent as DashboardCardContent,
} from "components/Layouts/Dashboard/Card";
import ListView from "components/ListView";
import { handleNumbersPurchaseItems } from "./helper";
import { useStyles } from "./styles";
import { BannerText } from "./helper";

const { validateIfPostalCode, validateAreaCode, validateCity, validateState } =
  VivialConnectAPI.helpers.validation;

export default withPageContext(NumberPurchase, {
  title: "Numbers",
  subtitle: "buy numbers",
});

function NumberPurchase() {
  const { appState } = useAppContext();
  const classes = useStyles();
  const [numbers, setNumbers] = useState([]);
  const [connectors, setConnectors] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [connector, setConnector] = useState("");
  const [campaign, setCampaign] = useState("");
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(25);
  const [numbersInfo, setNumbersInfo] = useState({
    accountNumber: 0,
    numbersOwned: 0,
  });
  const [selectedNumberType, setSelectedNumberType] = useState("local");
  const [confirmPurchase, setConfirmPurchase] = useState(false);
  const [selectedPhone, setSelectedPhone] = useState("");
  const [purchasedPhone, setPurchasedPhone] = useState({});
  const [error, setError] = useState("");
  const defaultNoNumMessage = "Enter search terms above to get started.";
  const [noNumMessage, setNoNumMessage] = useState(defaultNoNumMessage);
  const [loading, setLoading] = useState(false);
  const [purchaseLoading, setPurchaseLoading] = useState(false);
  const [openConfigureModal, setOpenConfigureModal] = useState(false);
  let searchNumberRef = createRef();
  const numberTypeLabels = {
    local: "Local",
    tollfree: "Toll-Free",
  };

  useEffect(() => {
    const {
      accounts,
      connectors,
      campaigns: campaignsServices,
    } = VivialConnectAPI.services;

    accounts
      .getAccountStatus()
      .then((status) => {
        setNumbersInfo({
          accountNumber: status.plan_max_numbers,
          numbersOwned: status.numbers_owned,
        });
      })
      .catch((error) => toast.error(error));

    connectors
      .getConnectors()
      .then((accountConnectors) => {
        setConnectors(accountConnectors);
      })
      .catch((error) => toast.error(error));

    campaignsServices
      .getCampaigns({
        active: 1,
      })
      .then((campaigns) => setCampaigns(campaigns))
      .catch((error) => toast.error(error));
  }, []);

  const handleSearchNumbers = (searchNumberType) => {
    searchNumberType = searchNumberType || selectedNumberType;
    const { numbers } = VivialConnectAPI.services;
    const search = searchNumberRef.current.value;
    const noMessage = (() => {
      const searchSuffix =
        searchNumberType !== "tollfree" && search ? ` for "${search}"` : "";
      return `No ${numberTypeLabels[searchNumberType]} numbers found${searchSuffix}.`;
    })();

    let params = {
      limit: limit,
      number_type: searchNumberType,
    };

    //Clear page state
    setNumbers([]);
    setSelectedPhone("");
    setPurchasedPhone({});
    setError("");

    //If tollfree then skip validation & search params
    if (searchNumberType === "tollfree") {
      searchNumberRef.current.value = "";
    } else {
      //If search is empty, revert to initial state.
      if (search === "") {
        setNoNumMessage(defaultNoNumMessage);
        return;
      }

      //If search is invalid then display error and abort.
      const [error, addedParams] = validateSearch();
      if (error) {
        setError(
          'Please provide city and state, 2-letter state code, 3-digit area code, or 5-digit postal code. Valid searches include "347", "11205", "NY", "Brooklyn, NY", "347 555 1234", etc.'
        );
        return;
      }
      params = { ...params, ...addedParams };
    }

    setLoading(true);
    numbers
      .getNumberAvailableCountryCode(params)
      .then((phoneNumbers) => {
        setNumbers(phoneNumbers);

        //Handle No Results
        if (!phoneNumbers || !phoneNumbers.length) {
          setNoNumMessage(noMessage);
        }
      })
      .catch(() => {
        setNoNumMessage(noMessage);
        setNumbers([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const validateSearch = () => {
    const search = searchNumberRef.current.value;
    let errorFound = false;
    let match;
    let searchParams = {};

    if (validateIfPostalCode(search)) {
      searchParams.postal_code = search;
    } else if ((match = validateAreaCode(search))) {
      searchParams.area_code = match[1] + match[2] + match[3];
      const contain = match[4].replace(/[^0-9a-z?*]/gi, "");
      if (contain !== "") {
        searchParams.contains = contain;
      }
    } else if ((match = validateCity(search))) {
      searchParams.city = match[1];
      searchParams.state = match[2];
    } else if (validateState(search)) {
      searchParams.state = search;
    } else {
      errorFound = true;
    }

    return [errorFound, searchParams];
  };

  const handleChangeConnector = (event) => {
    setConnector(event.target.value);
  };

  const handleChangeCampaign = (event) => {
    setCampaign(event.target.value);
  };

  const handleChangeNumberType = (event) => {
    const searchNumberType = event.target.value;
    setSelectedNumberType(searchNumberType);
    handleSearchNumbers(searchNumberType);
  };

  const handlePageChange = async (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const renderItem = (phone, index) => {
    if (purchasedPhone.phone_number !== phone.phone_number) {
      return handleNumbersPurchaseItems(
        phone,
        index,
        purchaseNumber,
        confirmPurchase,
        handleConfirmToPurchase,
        selectedPhone,
        selectedNumberType,
        purchaseLoading
      );
    }
  };

  //Step 1
  const handleConfirmToPurchase = (phone) => {
    setConfirmPurchase(true);
    setSelectedPhone(phone.phone_number);
  };

  //Step 2
  const purchaseNumber = (payload, index) => {
    if (purchaseLoading) return; //no extra clicks on the confirm purchase button
    setPurchaseLoading(true);

    const { numbers } = VivialConnectAPI.services;
    numbers
      .postNumbers(payload)
      .then((phoneNumber) => {
        setPurchasedPhone(phoneNumber);
        setSelectedPhone("");
        removeNumber(index);
        toast.success(`${phoneNumber.phone_number} purchased`);
        if (
          (Array.isArray(connectors) && connectors.length !== 0) ||
          (Array.isArray(campaigns) && campaigns.length !== 0)
        ) {
          setOpenConfigureModal(true);
        }
      })
      .catch((error) => {
        if (
          appState.plan_type === "freetrial" &&
          selectedNumberType === "tollfree"
        ) {
          toast.error(FreeTrialErrorNotification({ text: error }));
        } else {
          toast.error(error);
        }
      })
      .finally(() => {
        setPurchaseLoading(false);
      });
  };

  // Add a link to free trial-related errors prompting user to upgrade
  const FreeTrialErrorNotification = ({ text }) => (
    <ThemeProvider theme={darkTheme}>
      <Typography variant="body1">
        {text}
        <br />
        <br />
        Please{" "}
        <Link href="/dashboard/upgrade" className={classes.errorLink}>
          upgrade
        </Link>{" "}
        to purchase toll free numbers.
      </Typography>
    </ThemeProvider>
  );

  //Step 3
  const handleUpdateNumber = () => {
    if (connector) {
      const { connectors: connectorServices } = VivialConnectAPI.services;

      let params = {
        connector: {
          phone_numbers: [{ phone_number: purchasedPhone.phone_number }],
        },
      };

      setPurchaseLoading(true);
      connectorServices
        .putConnectorPhoneNumbers(connector, params)
        .then(() => {
          setConnector("");
          toast.success(
            "Your new phone " +
              purchasedPhone.phone_number +
              " was added to connector ID" +
              connector
          );
        })
        .catch((error) => {
          toast.error(error);
        })
        .finally(() => {
          setPurchaseLoading(false);
        });
    }

    if (campaign) {
      const { numbers } = VivialConnectAPI.services;

      const payload = {
        phone_number: {
          campaign_id: campaign,
        },
      };

      numbers.putNumber(purchasedPhone.id, payload).then(() => {
        toast.success(
          "Your new phone " +
            purchasedPhone.phone_number +
            " was assigned to campaign ID" +
            campaign
        );
      });
    }

    setOpenConfigureModal(false);
    setSelectedPhone("");
    setPurchasedPhone("");
    setCampaign("");
    setConnector("");
  };

  //Step 4
  const removeNumber = (index) => {
    const newNumbers = [...numbers];
    newNumbers.splice(index, 1);
    setNumbers(newNumbers);
  };

  return (
    <div className={classes.root} spacing={2}>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <DashboardCard>
            <DashboardCardContent>
              <div className={classes.bannerWrapper}>
                <Grid item xs={9}>
                  <BannerText
                    plan_type={appState.plan_type}
                    included={numbersInfo.accountNumber}
                    owned={numbersInfo.numbersOwned}
                  />
                  <SearchField
                    label="Search Numbers"
                    placeholder="Search by area code, city and state, zip or pattern."
                    searchValue={searchNumberRef}
                    onSearch={() => handleSearchNumbers()}
                    fullWidth={true}
                    error={error}
                  />
                  <RadioGroup
                    row
                    value={selectedNumberType}
                    onChange={handleChangeNumberType}
                  >
                    <FormControlLabel
                      control={<Radio color="primary" />}
                      label={numberTypeLabels.local}
                      value="local"
                    />
                    <FormControlLabel
                      control={<Radio color="primary" />}
                      label={numberTypeLabels.tollfree}
                      value="tollfree"
                    />
                  </RadioGroup>
                </Grid>
              </div>
            </DashboardCardContent>
          </DashboardCard>
        </Grid>
        <Grid item xs={12}>
          <DashboardCard>
            <DashboardCardContent>
              <ListView
                list={numbers.slice(page * limit, page * limit + limit)}
                notFoundMessage={
                  <div>
                    {loading && <CircularProgress size={24} />}
                    {!loading && <span>{noNumMessage}</span>}
                  </div>
                }
                columns={2}
                renderItem={(row, index) => renderItem(row, index)}
              />
              {numbers.length > 0 && (
                <TablePagination
                  rowsPerPageOptions={[25, 50]}
                  component="div"
                  count={numbers.length}
                  rowsPerPage={limit}
                  page={page}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage={"Rows Per Page"}
                />
              )}
            </DashboardCardContent>
          </DashboardCard>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Dialog
            fullWidth
            maxWidth="sm"
            open={openConfigureModal}
            onClose={() => setOpenConfigureModal(false)}
            aria-labelledby="campaign-dialog"
            className={classes.dialog}
          >
            <DialogTitle id="campaign-dialog" className={classes.dialogTitle}>
              {`Configure Phone Number ${purchasedPhone.phone_number}`}
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  Assign Campaign:
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      select
                      variant="outlined"
                      label="Assign Campaign"
                      name="campaign"
                      value={campaign}
                      onChange={handleChangeCampaign}
                      helperText="Select campaign"
                    >
                      <MenuItem key={`campaign-none`} value={""}>
                        None
                      </MenuItem>
                      {campaigns.map((item, key) => (
                        <MenuItem key={`campaign-${key}`} value={item.id}>
                          {item.id}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  Assign Connector:
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    select
                    label="Connector"
                    variant="outlined"
                    value={connector}
                    onChange={handleChangeConnector}
                    helperText="Select connector"
                    fullWidth
                  >
                    <MenuItem key={`connector-none`} value={""}>
                      None
                    </MenuItem>
                    {connectors.map((item, key) => (
                      <MenuItem key={`connector-${key}`} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleUpdateNumber}
                variant="contained"
                color="primary"
              >
                Ok
              </Button>
              <Button
                onClick={() => setOpenConfigureModal(false)}
                variant="contained"
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    </div>
  );
}
