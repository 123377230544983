import endpoints from "./endpoints.js";
import { factory } from "vivialconnect-js-core";

//NOTE: do not use spread syntax here.
//serviceHelpers == {init, http, appSessionStore}
const serviceHelpers = factory.initService(endpoints);

const getBrands = async (params, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.get(
      endpoints.brands(id ? id : appSessionStore.getAccountId(), {
        params,
      })
    );
    return data.brands;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const addBrand = async (payload, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.post(
      endpoints.brands(id ? id : appSessionStore.getAccountId()),
      payload
    );
    return data;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const addCampaign = async (payload, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.post(
      endpoints.campaignImport(id ? id : appSessionStore.getAccountId()),
      payload
    );
    return data;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const createCampaign = async (payload, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.post(
      endpoints.campaigns(id ? id : appSessionStore.getAccountId()),
      payload
    );
    return data;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getCampaigns = async (params, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.get(
      endpoints.campaigns(id ? id : appSessionStore.getAccountId()),
      { params }
    );
    return data.campaigns;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getCampaign = async (campaignId, params, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.get(
      endpoints.campaign(id ? id : appSessionStore.getAccountId(), campaignId),
      params
    );
    return data.campaign;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const editCampaign = async (campaignId, payload, params, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.put(
      endpoints.campaign(id ? id : appSessionStore.getAccountId(), campaignId),
      payload,
      { params }
    );
    return data.campaign;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getCampaignRenewal = async (campaignId, params, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.get(
      endpoints.renewal(id ? id : appSessionStore.getAccountId(), campaignId),
      params
    );
    return data.renewal;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const editCampaignRenewal = async (campaignId, payload, params, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.put(
      endpoints.renewal(id ? id : appSessionStore.getAccountId(), campaignId),
      payload,
      { params }
    );
    return data.renewal;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getVerticals = async (params) => {
  const { http } = serviceHelpers;
  try {
    const { data } = await http.get(endpoints.verticals(), params);
    return data.verticals;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getUseCases = async (params) => {
  const { http } = serviceHelpers;
  try {
    const { data } = await http.get(endpoints.useCases(), params);
    return data.usecases;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getBrandUseCases = async (brandId, params, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.get(
      endpoints.brandUseCases(
        id ? id : appSessionStore.getAccountId(),
        brandId
      ),
      { params }
    );
    return data.usecases;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getQualifyCampaign = async (brandId, useCase, params, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.get(
      endpoints.qualifyCampaign(
        id ? id : appSessionStore.getAccountId(),
        brandId,
        useCase
      ),
      params
    );
    return data.usecase;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getPrices = async (payload, id) => {
  const { http } = serviceHelpers;
  try {
    const { data } = await http.post(endpoints.prices(), payload);
    return data;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

export default {
  init: serviceHelpers.init,
  getBrands,
  addBrand,
  addCampaign,
  createCampaign,
  getCampaigns,
  getCampaign,
  editCampaign,
  getCampaignRenewal,
  editCampaignRenewal,
  getVerticals,
  getUseCases,
  getBrandUseCases,
  getQualifyCampaign,
  getPrices,
};
