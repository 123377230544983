/* eslint-disable react/prop-types */
import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { format, addDays } from "date-fns";

import {
  Card as DashboardCard,
  CardHeader as DashboardCardHeader,
  CardContent as DashboardCardContent,
} from "components/Layouts/Dashboard/Card";

import VivialConnectAPI from "api/vivial-connect";
const { withCommaSeparator } = VivialConnectAPI.helpers.numbers;

const AccountStatusFreeTrial = ({ status }) => {
  const EXPIRES_IN_DAYS = 90;
  const { usage_credits: usageCredits, plan_dates: planDates } = status;

  return (
    <DashboardCard>
      <DashboardCardHeader title={"Account Status"} />
      <DashboardCardContent alignContent="flex-start">
        <Grid item xs={6}>
          <Typography variant="body2">Credit Balance:</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">
            {usageCredits ? withCommaSeparator(usageCredits) : "0"}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">Free Trial Expires:</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">
            {planDates.start_date
              ? format(
                  addDays(Date.parse(planDates.start_date), EXPIRES_IN_DAYS),
                  "MMMM do, yyyy"
                )
              : ""}
          </Typography>
        </Grid>
      </DashboardCardContent>
    </DashboardCard>
  );
};

export default AccountStatusFreeTrial;
