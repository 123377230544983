import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  button: {
    display: "flex",
    width: "100%",
    flexFlow: "row-reverse",
  },
  typography: {
    display: "flex",
    height: "100%",
    alignItems: "center",
  },
  addCreditCardLabel: {
    marginBottom: 20,
  },
  spinner: {
    position: "absolute",
  },
}));

export default useStyles;
