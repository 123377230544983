// import http from "../../core/http";
import endpoints from "./endpoints";
import { factory } from "vivialconnect-js-core";

//NOTE: do not use spread syntax here.
//serviceHelpers == {init, http, appSessionStore}
const serviceHelpers = factory.initService(endpoints);

const postNewsletterSignup = async (payload, params) => {
  const { http } = serviceHelpers;
  try {
    await http.post(endpoints.newsletterSignup(), payload, { params });
    return true;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const postContactSignup = async (payload, params) => {
  const { http } = serviceHelpers;
  try {
    await http.post(endpoints.contactSignup(), payload, { params });
    return true;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const postPartnerSignup = async (payload, params) => {
  const { http } = serviceHelpers;
  try {
    await http.post(endpoints.partnerSignup(), payload, { params });
    return true;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

export default {
  init: serviceHelpers.init,
  postNewsletterSignup,
  postContactSignup,
  postPartnerSignup,
};
