import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export const AccountTreeIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <g id="Account_Tree_Setting" data-name="Account Tree Setting">
        <path d="M22.51,11.26h-7a.5.5,0,0,1-.5-.51V3.8a.5.5,0,0,1,.5-.5h7a.5.5,0,0,1,.51.5v7A.51.51,0,0,1,22.51,11.26Zm-.57-6.88h-5.8v5.8h5.8Z" />
        <path d="M22.51,20.7h-7a.5.5,0,0,1-.5-.5V13.25a.5.5,0,0,1,.5-.51h7a.51.51,0,0,1,.51.51v7A.5.5,0,0,1,22.51,20.7Zm-.57-6.88h-5.8v5.8h5.8Z" />
        <path d="M8.44,11.26H1.49A.51.51,0,0,1,1,10.75V3.8a.5.5,0,0,1,.51-.5h7a.5.5,0,0,1,.5.5v7A.5.5,0,0,1,8.44,11.26ZM7.86,4.38H2.06v5.8h5.8Z" />
        <rect x="8.9" y="6.78" width="1.55" height="1.08" />
        <rect x="11.75" y="16.25" width="3.52" height="1.08" />
        <rect x="11.45" y="8.89" width="1.08" height="8.42" />
        <circle cx="11.98" cy="7.37" r="0.76" />
        <rect x="13.54" y="6.89" width="1.55" height="1.08" />
      </g>
    </SvgIcon>
  );
};

export const BuildIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <g id="Build_Icon" data-name="Build Icon">
        <path d="M18.88,21a2.29,2.29,0,0,1-1.57-.63c-.33-.31-.9-.88-1.4-1.39l-.77-.77-3.53-3.48L9.2,12.41l-.72-.71a1.68,1.68,0,0,0-1.33-.48A4.16,4.16,0,0,1,3.81,9.93,3.87,3.87,0,0,1,3,6.6a1,1,0,0,1,1.75-.48c.4.48.67.79,1,1.15l.21.25a.43.43,0,0,0,.52,0A2.62,2.62,0,0,0,7.57,6.25s0,0,0-.06l0,0,0,0c-.37-.41-.88-1-1.36-1.48A1,1,0,0,1,6.81,3,3.86,3.86,0,0,1,9.79,4a4.44,4.44,0,0,1,1.37,3.15,2,2,0,0,0,.66,1.55l.51.5c.76.75,1.58,1.55,2.4,2.37l1,.94-.76.77-1-.94L11.57,10l-.51-.49a3,3,0,0,1-1-2.34A3.29,3.29,0,0,0,9,4.82,2.78,2.78,0,0,0,7.1,4h0L8.31,5.38a1.09,1.09,0,0,1,.27,1.27A3.68,3.68,0,0,1,7,8.46,1.48,1.48,0,0,1,5.2,8.34l-.12-.12L4.86,8,4,7a2.77,2.77,0,0,0,.59,2.24,3.12,3.12,0,0,0,2.48.93,2.75,2.75,0,0,1,2.14.79l.72.7L12.37,14l3.53,3.47.78.79,1.37,1.36a1.18,1.18,0,0,0,1.33.22A1.1,1.1,0,0,0,20,18.7a1.36,1.36,0,0,0-.51-.89c-.59-.57-1.21-1.19-1.85-1.82l.77-.77L20.25,17a2.38,2.38,0,0,1,.84,1.62,2.18,2.18,0,0,1-1.24,2.16A2.28,2.28,0,0,1,18.88,21ZM7.59,6.18Z" />
        <circle cx="16.69" cy="14.2" r="0.93" />
      </g>
    </SvgIcon>
  );
};

export const ClockIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <g id="Clock_Icon" data-name="Clock Icon">
        <circle cx="12.01" cy="11.99" r="0.97" />
        <path d="M12,3.06a8.94,8.94,0,0,1,6.32,15.26A8.94,8.94,0,1,1,5.68,5.68,8.87,8.87,0,0,1,12,3.06m0-1A9.94,9.94,0,1,0,21.94,12,9.94,9.94,0,0,0,12,2.06Z" />
        <rect
          x="14.64"
          y="12.46"
          width="1"
          height="3.08"
          transform="translate(-4.69 19.66) rotate(-58.67)"
        />
        <polygon points="17.32 15.33 16.84 13.8 15.75 15.6 17.32 15.33" />
        <rect x="11.5" y="5.24" width="1" height="4.99" />
        <polygon points="12 4.23 10.95 5.44 13.05 5.44 12 4.23" />
      </g>
    </SvgIcon>
  );
};

export const DocumentsIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <g id="Documents_Icon" data-name="Documents Icon">
        <polygon points="17.2 2.05 17.2 3.88 18.93 3.88 17.2 2.05" />
        <path d="M6.7,20.34V1.58h9.43V4.12a.82.82,0,0,0,.82.82h2.36V18.68h1.07V3.88h0L17.2.51H6.66a1,1,0,0,0-1,1v19a.89.89,0,0,0,.88.88H17.68V20.34ZM17.2,2.05l1.73,1.83H17.2Z" />
        <circle cx="19.76" cy="20.7" r="1.02" />
        <polygon points="17.97 23.49 3.22 23.49 3.22 2.73 5.3 2.73 5.3 3.79 4.29 3.79 4.29 22.43 16.91 22.43 16.91 21.8 17.97 21.8 17.97 23.49" />
        <rect x="8.1" y="6.28" width="9.49" height="1.06" />
        <rect x="8.1" y="15.85" width="9.49" height="1.06" />
        <rect x="8.1" y="9.47" width="9.49" height="1.06" />
        <rect x="8.1" y="12.66" width="9.49" height="1.06" />
      </g>
    </SvgIcon>
  );
};

export const DownArrowIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <g id="Down_Arrow_Icon" data-name="Down Arrow Icon">
        <rect
          x="7.22"
          y="10.51"
          width="1.13"
          height="7.64"
          transform="translate(-7.92 9.94) rotate(-45.89)"
        />
        <rect x="11.41" y="4.73" width="1.13" height="11.52" />
        <circle cx="11.96" cy="18.26" r="1.01" />
        <rect
          x="12.4"
          y="13.76"
          width="7.64"
          height="1.13"
          transform="translate(-5.4 15.33) rotate(-44.11)"
        />
      </g>
    </SvgIcon>
  );
};

export const ErrorOutlineIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <g id="Error_Icon" data-name="Error Icon">
        <circle cx="12.11" cy="16.13" r="0.9" />
        <path d="M12,3.73A8.27,8.27,0,1,1,3.73,12,8.28,8.28,0,0,1,12,3.73m0-1A9.28,9.28,0,1,0,21.28,12,9.29,9.29,0,0,0,12,2.72Z" />
        <path d="M11.56,13.93l-.74-7.67h2.53l-.71,7.67Z" />
      </g>
    </SvgIcon>
  );
};

export const GroupAddIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <g id="Group_Add_Icon" data-name="Group Add Icon">
        <circle cx="19.88" cy="19.27" r="0.86" />
        <path d="M13,12.44c2.41.42,4.56,2,6,5.11H20.2a10.25,10.25,0,0,0-6.15-5.9A4.16,4.16,0,0,0,12,3.88h0a4.16,4.16,0,0,0-2.08,7.77,8.05,8.05,0,0,0-1.5.62A10.05,10.05,0,0,0,4,17c-.09.17-.17.34-.25.51h0c-.1.25-.2.5-.28.75a1.12,1.12,0,0,0,.14,1,1.1,1.1,0,0,0,.89.47H18.17v-1H4.49a.1.1,0,0,1-.08-.05.1.1,0,0,1,0-.1,9.3,9.3,0,0,1,5.1-5.81h0a6.73,6.73,0,0,1,1.38-.38A5.48,5.48,0,0,1,13,12.44ZM8.81,8.05A3.15,3.15,0,1,1,12,11.21,3.17,3.17,0,0,1,8.81,8.05Z" />
        <path d="M17.72,11.42a2.31,2.31,0,0,1-2-.78.3.3,0,0,1,0-.3L16,9.7a.14.14,0,0,1,.23,0,2,2,0,1,0,1.56-3.35,2,2,0,0,0-1.34.51.13.13,0,0,1-.21-.06L16,6.06a.13.13,0,0,1,0-.16,3,3,0,0,1,1.81-.59A3.07,3.07,0,0,1,20.87,8.5,3.14,3.14,0,0,1,17.72,11.42Z" />
        <path d="M23,16.9H20.2a.07.07,0,0,1-.07-.07L19.61,16a.07.07,0,0,1,.07-.07h3.05a.07.07,0,0,0,.07-.09,6.13,6.13,0,0,0-3-3.69h0a4.29,4.29,0,0,0-4-.09h-.07l-.85-.4a.07.07,0,0,1,0-.12,5.21,5.21,0,0,1,5.38-.3,7.28,7.28,0,0,1,3.64,4.64v.09a.92.92,0,0,1-.22.67.86.86,0,0,1-.66.32Z" />
        <path d="M4.46,6.07V8.74H7V9.88H4.46V12.6H3.26V9.88H.7V8.74H3.26V6.07Z" />
      </g>
    </SvgIcon>
  );
};

export const HomeIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <g id="Home_Icon" data-name="Home Icon">
        <path
          d="M17.72,20.81H14.13a.4.4,0,0,1-.4-.39V16.15H10.29v4.27a.4.4,0,0,1-.4.39H4.1a.4.4,0,0,1-.39-.39V12.3a.4.4,0,0,0-.4-.4H.58a.39.39,0,0,1-.26-.69L11.75.93a.4.4,0,0,1,.52,0L23.7,11.21a.4.4,0,0,1-.27.69H20.71a.4.4,0,0,0-.4.4v5.91h-1V11.29a.4.4,0,0,1,.39-.4h2.14L12,2.05,2.19,10.89H4.33a.4.4,0,0,1,.39.4V19.8H9.28V15.54a.39.39,0,0,1,.39-.4h4.68a.39.39,0,0,1,.39.4v4.18a.08.08,0,0,0,.08.08h2.9Z"
          transform="translate(-0.19 -0.83)"
        />
        <circle cx="19.54" cy="19.47" r="1.02" />
      </g>
    </SvgIcon>
  );
};

export const MembershipIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <g id="Membership_Icon" data-name="Membership Icon">
        <path d="M23.08,5.93v1H21.41V6.36H2.19V17.64H21.41V12h1.67v6a1.24,1.24,0,0,1-1.24,1.24H1.76A1.25,1.25,0,0,1,.52,18.07V5.93A1.25,1.25,0,0,1,1.76,4.69H21.84A1.24,1.24,0,0,1,23.08,5.93Z" />
        <rect x="2.09" y="13.51" width="20.97" height="1.63" />
        <path d="M23.46,9.27a1.26,1.26,0,1,1-1-1A1.26,1.26,0,0,1,23.46,9.27Z" />
        <path d="M7.55,11.11,5.72,9.28,3.88,11.12a.26.26,0,0,1-.45-.19V6H5v2l.77-.77L6.54,8V6.08H8.06V10.9A.3.3,0,0,1,7.55,11.11Z" />
      </g>
    </SvgIcon>
  );
};

export const PaymentIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <g id="Payment_Icon" data-name="Payment Icon">
        <path d="M23.08,18.07v-1H21.41v.57H2.19V6.36H21.41V12h1.67v-6a1.24,1.24,0,0,0-1.24-1.24H1.76A1.25,1.25,0,0,0,.52,5.93V18.07a1.25,1.25,0,0,0,1.24,1.24H21.84A1.24,1.24,0,0,0,23.08,18.07Z" />
        <rect x="2.09" y="8.86" width="20.97" height="1.63" />
        <path d="M23.46,14.73a1.26,1.26,0,1,0-1,1A1.26,1.26,0,0,0,23.46,14.73Z" />
      </g>
    </SvgIcon>
  );
};

export const PhoneForwardedIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <g id="Phone_Forward" data-name="Phone Forward">
        <circle cx="21.94" cy="19.04" r="0.76" />
        <path d="M22.52,7.55,18.78,4.37l-.84.78L20.12,7h-5v1h5L17.89,9.89l.65.88Z" />
        <path d="M20.41,22.59a20.57,20.57,0,0,1-8.25-2c-6-2.66-8.83-8.83-9-9.16A22.27,22.27,0,0,1,2.05,8.31a15.05,15.05,0,0,1-.62-6.45h0A.54.54,0,0,1,2,1.41H8.22a.3.3,0,0,1,.29.24l.92,5.92a.69.69,0,0,1-.17.57L6.74,10.82a14.65,14.65,0,0,0,6.71,6.8L16,15a.49.49,0,0,1,.41-.14l5.73.93a.39.39,0,0,1,.31.37v1.42H21.41v-.82L16.59,16l-2.65,2.69a.55.55,0,0,1-.62.1h0A15.2,15.2,0,0,1,5.6,10.87h0a.54.54,0,0,1,.12-.54L8.34,7.54,7.55,2.48H2.43C2,6.38,4.14,11,4.16,11h0c0,.06,2.79,6.12,8.43,8.62a18,18,0,0,0,8.81,1.82v-.93h1.08v1.4a.55.55,0,0,1-.43.53h0A8.71,8.71,0,0,1,20.41,22.59Z" />
      </g>
    </SvgIcon>
  );
};

export const PhoneIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <g id="Phone_Icon" data-name="Phone Icon">
        <circle cx="21.94" cy="19.04" r="0.76" />
        <path d="M20.41,22.59a20.57,20.57,0,0,1-8.25-2c-6-2.66-8.83-8.83-9-9.16A22.27,22.27,0,0,1,2.05,8.31a15.05,15.05,0,0,1-.62-6.45h0A.54.54,0,0,1,2,1.41H8.22a.3.3,0,0,1,.29.24l.92,5.92a.69.69,0,0,1-.17.57L6.74,10.82a14.65,14.65,0,0,0,6.71,6.8L16,15a.49.49,0,0,1,.41-.14l5.73.93a.39.39,0,0,1,.31.37v1.42H21.41v-.82L16.59,16l-2.65,2.69a.55.55,0,0,1-.62.1h0A15.2,15.2,0,0,1,5.6,10.87h0a.54.54,0,0,1,.12-.54L8.34,7.54,7.55,2.48H2.43C2,6.38,4.14,11,4.16,11h0c0,.06,2.79,6.12,8.43,8.62a18,18,0,0,0,8.81,1.82v-.93h1.08v1.4a.55.55,0,0,1-.43.53h0A8.71,8.71,0,0,1,20.41,22.59Z" />
      </g>
    </SvgIcon>
  );
};

export const PhoneLinkEraseIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <g id="Phone_Link_Erase_Icon" data-name="Phone Link Erase Icon">
        <circle cx="11.99" cy="21.83" r="1.17" />
        <path d="M6.34,21.34H9.29v1.12H6.13a.91.91,0,0,1-.91-.91V15.66H6.34Z" />
        <path d="M5.22,2.2A1.19,1.19,0,0,1,6.41,1h11.3a1.23,1.23,0,0,1,1.23,1.23V21.68a.78.78,0,0,1-.78.78H14.75V21.34h3.07V2.24a.11.11,0,0,0-.11-.11H6.41a.07.07,0,0,0-.07.07V6.46H5.22Z" />
        <path d="M7.43,15.1l-1.71-3h0L4,15.1H2.5l2.44-4L2.58,7.18h1.6L5.79,10h0L7.45,7.18H8.93L6.57,11,9,15.1Z" />
      </g>
    </SvgIcon>
  );
};

export const PhoneSettingsIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <g id="Phone_Setting_Icon" data-name="Phone Setting Icon">
        <circle cx="21.94" cy="19.04" r="0.76" />
        <circle cx="12.89" cy="9.51" r="1.19" />
        <circle cx="16.58" cy="9.51" r="1.19" />
        <circle cx="20.28" cy="9.51" r="1.19" />
        <path d="M20.41,22.59a20.57,20.57,0,0,1-8.25-2c-6-2.66-8.83-8.83-9-9.16A22.27,22.27,0,0,1,2.05,8.31a15.05,15.05,0,0,1-.62-6.45h0A.54.54,0,0,1,2,1.41H8.22a.3.3,0,0,1,.29.24l.92,5.92a.69.69,0,0,1-.17.57L6.74,10.82a14.65,14.65,0,0,0,6.71,6.8L16,15a.49.49,0,0,1,.41-.14l5.73.93a.39.39,0,0,1,.31.37v1.42H21.41v-.82L16.59,16l-2.65,2.69a.55.55,0,0,1-.62.1h0A15.2,15.2,0,0,1,5.6,10.87h0a.54.54,0,0,1,.12-.54L8.34,7.54,7.55,2.48H2.43C2,6.38,4.14,11,4.16,11h0c0,.06,2.79,6.12,8.43,8.62a18,18,0,0,0,8.81,1.82v-.93h1.08v1.4a.55.55,0,0,1-.43.53h0A8.71,8.71,0,0,1,20.41,22.59Z" />
      </g>
    </SvgIcon>
  );
};

export const PowerSettingsIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <g id="Power_Settings_Icon" data-name="Power Settings Icon">
        <path d="M11.88,20.1A8.15,8.15,0,0,1,6.81,5.56l.76,1A6.93,6.93,0,1,0,18.15,14.9l1.11.53A8.22,8.22,0,0,1,11.88,20.1Z" />
        <path d="M18.81,11.63a7,7,0,0,0-2.38-4.92l.8-.92A8.19,8.19,0,0,1,20,11.57Z" />
        <rect x="11.42" y="4.23" width="1.22" height="8.1" />
        <path d="M20.28,13.38a1,1,0,1,1-1-1A1,1,0,0,1,20.28,13.38Z" />
      </g>
    </SvgIcon>
  );
};

export const ShowChartIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <g id="Show_Chart" data-name="Show Chart">
        <polygon points="4.08 18.47 3.15 17.68 8.32 11.66 11.46 14.59 13.24 12.69 14.13 13.52 11.52 16.3 8.42 13.41 4.08 18.47" />
        <circle cx="15.15" cy="11.47" r="1.09" />
        <rect
          x="15.86"
          y="7.31"
          width="5.39"
          height="1.2"
          transform="translate(0.09 16.02) rotate(-46.78)"
        />
      </g>
    </SvgIcon>
  );
};

export const SupportIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <g id="Support_Icon" data-name="Support Icon">
        <path d="M16.65,9.33h-.4a6.53,6.53,0,0,0-12.15,0H3.91a1.17,1.17,0,0,0-.49.11,1,1,0,0,0-.71,1v1.76a1,1,0,0,0,.83,1,1,1,0,0,0,.3,0A6.52,6.52,0,0,0,8.66,18c0-.33,0-.74,0-1.09a5.45,5.45,0,0,1-3.75-3.82A1.21,1.21,0,0,0,5.56,12V10.54a1.22,1.22,0,0,0-.44-.94,5.48,5.48,0,0,1,10.17.16,1.19,1.19,0,0,0-.29.78V12a1.21,1.21,0,0,0,1.2,1.21h.45A1.22,1.22,0,0,0,17.86,12V10.54A1.21,1.21,0,0,0,16.65,9.33Z" />
        <circle cx="10.74" cy="17.73" r="1.07" />
        <path d="M16.19,5.84c-2.82,0-5.11,2-5.11,4.52a4.15,4.15,0,0,0,.2,1.26c.07.2.16.4.25.6l.16.28h0c.06.1.08.13.08.17a.59.59,0,0,1,0,.23l-.43,1.51a.43.43,0,0,0,.11.42.41.41,0,0,0,.29.13l.12,0,1.82-.49a.69.69,0,0,1,.22,0,1.3,1.3,0,0,1,.27.08l.11,0a4.4,4.4,0,0,0,.62.18,5.64,5.64,0,0,0,1.3.15c2.81,0,5.1-2,5.1-4.51S19,5.84,16.19,5.84Z" />
      </g>
    </SvgIcon>
  );
};

export const TypeBuyNumbersIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <g id="Type-BuyNuymbers_Icon" data-name="Type-BuyNuymbers Icon">
        <polygon points="19.75 8.13 7.87 8.16 7.87 6.94 19.75 6.9 19.75 8.13" />
        <circle cx="5.46" cy="7.55" r="1.19" />
        <polygon points="19.75 12.54 7.87 12.58 7.87 11.35 19.75 11.31 19.75 12.54" />
        <circle cx="5.46" cy="11.96" r="1.19" />
        <polygon points="19.75 16.95 7.87 16.99 7.87 15.76 19.75 15.73 19.75 16.95" />
        <circle cx="5.46" cy="16.38" r="1.19" />
        <path d="M23.12,20.25H.88a.56.56,0,0,1-.56-.56V4.31a.56.56,0,0,1,.56-.56H23.12a.56.56,0,0,1,.56.56V19.69A.56.56,0,0,1,23.12,20.25Zm-21.78-1H22.66V4.77H1.34Z" />
      </g>
    </SvgIcon>
  );
};

export const UpArrow = (props) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <g id="Up_Arrow_Icon" data-name="Up Arrow Icon">
        <rect
          x="15.46"
          y="6.18"
          width="1.07"
          height="7.24"
          transform="translate(-2.17 14.46) rotate(-45.89)"
        />
        <rect x="11.49" y="7.97" width="1.07" height="10.91" />
        <circle cx="12.04" cy="6.07" r="0.95" />
        <rect
          x="4.39"
          y="9.26"
          width="7.24"
          height="1.07"
          transform="translate(-4.56 8.34) rotate(-44.11)"
        />
      </g>
    </SvgIcon>
  );
};

export const UpgradeIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <g id="Upgrade_Icon" data-name="Upgrade Icon">
        <path d="M16.08,9.7c-.17-.12,0-.58.28-1s.55-.84.5-.88S16,7.53,15,7.3h0a0,0,0,0,1,0-.08l4.74-2.56s0,0,0,0L18.92,10s0,0-.05,0h0a13.47,13.47,0,0,0-1.25-1.79c-.11-.06-.43.29-.71.78S16.26,9.81,16.08,9.7Z" />
        <polygon points="5.17 19.34 4.29 18.74 8.23 13.01 11.24 15.27 12.61 13.45 13.46 14.09 11.45 16.76 8.48 14.53 5.17 19.34" />
        <circle cx="14.15" cy="12.2" r="0.96" />
        <rect
          x="14.43"
          y="8.25"
          width="4.73"
          height="1.05"
          transform="translate(-0.33 16.89) rotate(-52.95)"
        />
      </g>
    </SvgIcon>
  );
};
