/* eslint-disable react/prop-types */
import React from "react";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { useStyles } from "./styles";

export default function NumbersList({ numbers, handleChange }) {
  const classes = useStyles();

  return (
    <FormControl component="fieldset" className={classes.formControl}>
      <FormGroup>
        {numbers.map((number, index) => {
          return (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={number.selected || false}
                  onChange={() => handleChange(number)}
                  name={number.id}
                />
              }
              label={number.name}
            />
          );
        })}
      </FormGroup>
    </FormControl>
  );
}
