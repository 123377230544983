// connectors/endpoints.js
import endpoints from "./endpoints.js";
import { factory } from "vivialconnect-js-core";

const helper = factory.initEndpoints();

export default {
  init: helper.init,
  connectors: (id) => `${helper.BASE_URL}/accounts/${id}/connectors.json`,
  connector: (id, connectorId) =>
    `${helper.BASE_URL}/accounts/${id}/connectors/${connectorId}.json`,
  connectorsCount: (id) =>
    `${helper.BASE_URL}/accounts/${id}/connectors/count.json`,
  connectorsCallbacks: (id, connectorId) =>
    `${helper.BASE_URL}/accounts/${id}/connectors/${connectorId}/callbacks.json`,
  connectorsPhoneNumbers: (id, connectorId) =>
    `${helper.BASE_URL}/accounts/${id}/connectors/${connectorId}/phone_numbers.json`,
  connectorsPhoneNumbersCount: (id, connectorId) =>
    `${helper.BASE_URL}/accounts/${id}/connectors/${connectorId}/phone_numbers/count.json`,
};
