import React from "react";

import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";

import { GetContentfulPage, GetAdditionalContent } from "api/contentful/helper";

import HTMLHead from "components/HTMLHead";

import RichText from "components/Layouts/Brochure/RichText";
import Hero from "components/Layouts/Brochure/Hero";
import Section from "components/Layouts/Brochure/Section";
import {
  PreFooter,
  usePreFooterStyles,
} from "components/Layouts/Brochure/Footer/PreFooter";
import Subtitle from "components/Layouts/Brochure/Subtitle";
import BrochureButton from "components/Layouts/Brochure/BrochureButton";
import WideImage from "components/Layouts/Brochure/WideImage";
import BenefitsList from "components/Layouts/Brochure/Lists/BenefitsList";
import OrderedList from "components/Layouts/Brochure/Lists/OrderedList";

const useStyles = makeStyles(() => ({
  logo: {
    maxWidth: "300px",
  },
}));

export default function SpyderLynk() {
  const [page, setPage] = React.useState(null);
  const [heroContent, setHeroContent] = React.useState({});
  const [benefitsList, setBenefitsList] = React.useState({});
  const [secureList, setSecureList] = React.useState({});

  const classes = useStyles();
  const preFooterClasses = usePreFooterStyles();

  //Get page from contentful on load
  React.useEffect(() => {
    GetContentfulPage("spyderlynk-security", setPage);
  }, []);

  //Extract content from contentful content model
  React.useEffect(() => {
    if (!page) return;
    const { fields } = page;

    setHeroContent({
      imageURL: fields.heroImg.fields.file.url,
      header1: fields.heroHdr1,
      header2: fields.heroHdr2,
    });

    GetAdditionalContent(
      page,
      "itemList",
      "spyderlynk_benefits",
      setBenefitsList
    );
    GetAdditionalContent(page, "itemList", "spyderlynk_secure", setSecureList);
  }, [page]);

  if (!page) return null;

  return (
    <>
      <HTMLHead page={page} />
      <Hero {...heroContent} />

      <Section className="narrow center">
        <Subtitle>{page.fields.header1}</Subtitle>
        <RichText>{page.fields.content1}</RichText>

        <img
          alt="Spyderlynk"
          src={page.fields.image1.fields.file.url}
          className={classes.logo}
        />
      </Section>

      <WideImage
        imageURL={page.fields.image2.fields.file.url}
        altText="Spyderlynk"
      />

      <Section>
        <BenefitsList {...benefitsList} />
      </Section>

      <Section bg="dark">
        <OrderedList {...secureList} />
      </Section>

      <PreFooter>
        <div className={preFooterClasses.root}>
          <Typography variant="h3">
            Need help deciding if SpyderLynk is right for you?
          </Typography>

          <BrochureButton variant="contained" color="primary" href="/contact">
            Talk To An Expert
          </BrochureButton>
        </div>
      </PreFooter>
    </>
  );
}
