import endpoints from "./endpoints.js";
import { factory } from "vivialconnect-js-core";

//NOTE: do not use spread syntax here.
//serviceHelpers == {init, http, appSessionStore}
const serviceHelpers = factory.initService(endpoints);

const getNumbers = async (params, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.get(
      endpoints.numbers(id ? id : appSessionStore.getAccountId()),
      { params }
    );
    return data.phone_numbers;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getNumbersCount = async (params, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.get(
      endpoints.numbersCount(id ? id : appSessionStore.getAccountId()),
      { params }
    );
    return data.count;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const postNumbers = async (payload, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.post(
      endpoints.numbers(id ? id : appSessionStore.getAccountId()),
      payload
    );
    return data.phone_number;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getNumber = async (numberId, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.get(
      endpoints.number(id ? id : appSessionStore.getAccountId(), numberId)
    );
    return data.phone_number;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const putNumber = async (numberId, payload, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.put(
      endpoints.number(id ? id : appSessionStore.getAccountId(), numberId),
      payload
    );
    return data.phone_number;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const deleteNumber = async (numberId, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.delete(
      endpoints.number(id ? id : appSessionStore.getAccountId(), numberId)
    );
    return data;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getNumberAvailableCountryCode = async (params, countryCode, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.get(
      endpoints.numbersAvailableCountryCode(
        id ? id : appSessionStore.getAccountId(),
        countryCode ? countryCode : "US"
      ),
      { params }
    );
    return data.phone_numbers;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getNumberAvailableCountryCodeNumberType = async (
  params,
  countryCode,
  numberType,
  id
) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const {
      data,
    } = await http.get(
      endpoints.numbersAvailableCountryCodeNumberType(
        id ? id : appSessionStore.getAccountId(),
        countryCode ? countryCode : "US",
        numberType ? numberType : "local"
      ),
      { params }
    );
    return data.phone_numbers;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

export default {
  init: serviceHelpers.init,
  getNumbers,
  getNumbersCount,
  postNumbers,
  getNumber,
  putNumber,
  deleteNumber,
  getNumberAvailableCountryCode,
  getNumberAvailableCountryCodeNumberType,
};
