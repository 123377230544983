import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  mainHeader: {
    color: theme.palette.common.black,
    marginBottom: 15,
    fontWeight: 500,
  },
  slugCardWidth: {
    [theme.breakpoints.down("xl")]: {
      maxWidth: 800,
    },
    [theme.breakpoints.down("lg")]: {
      maxWidth: 700,
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: 700,
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: 700,
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: 500,
    },
    margin: "0 auto",
  },
  content: {
    padding: theme.spacing(2),
  },
}));
