/* eslint-disable react/prop-types */
import React from "react";

import makeStyles from "@material-ui/core/styles/makeStyles";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";

import Subtitle from "components/Layouts/Brochure/Subtitle";
import MarkDownText from "components/Layouts/Brochure/MarkDownText";

const useStyles = makeStyles(() => ({
  root: {},
  list: {
    padding: 0,
  },
  item: {},
  itemText: {
    marginTop: "1em",
  },
  avatar: {
    width: "60px",
    marginRight: "15px",
    "& img": {
      width: "100%",
    },
  },
}));

export default function OrderedList(props) {
  const classes = useStyles();
  const { header, items } = props;
  //prevent component rendering before the item list is acquired
  if (!items || !items.length) return null;

  return (
    <div className={classes.root}>
      {header && (
        <div className="center">
          <Subtitle>{header}</Subtitle>
        </div>
      )}

      <List className={classes.list}>
        {items.map((item, index) => {
          return (
            <ListItem
              key={index}
              alignItems="flex-start"
              className={classes.item}
            >
              {item.fields.image && (
                <ListItemAvatar className={classes.avatar}>
                  <img
                    alt="{item.header}"
                    src={item.fields.image.fields.file.url}
                  />
                </ListItemAvatar>
              )}

              <ListItemText
                className={classes.itemText}
                primary={
                  <MarkDownText>{item.fields.shortDescription}</MarkDownText>
                }
              />
            </ListItem>
          );
        })}
      </List>
    </div>
  );
}
