import endpoints from "./endpoints";
import { factory } from "vivialconnect-js-core";

//NOTE: do not use spread syntax here.
//serviceHelpers == {init, http, appSessionStore}
const serviceHelpers = factory.initService(endpoints);

const postPhoneSend = async (payload, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.post(
      `${endpoints.phoneSend(id ? id : appSessionStore.getAccountId())}`,
      payload
    );

    return data.success;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const postPhoneVerify = async (payload, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.post(
      `${endpoints.phoneVerify(id ? id : appSessionStore.getAccountId())}`,
      payload
    );

    return data;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

export default {
  init: serviceHelpers.init,
  postPhoneSend,
  postPhoneVerify,
};
