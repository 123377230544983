import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GetGlossaryTerm } from "api/contentful/helper";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import RichText from "components/Layouts/Brochure/RichText";
import Section from "components/Layouts/Brochure/Section";
import PreFooter from "components/Layouts/Brochure/Footer/PreFooter";
import AlphaList from "./AlphaList";
import { useStyles } from "./styles";
import { fetchGlossary } from "./helper";

import { GlossaryShared } from "./shared";

export default function Term() {
  const { termSlug } = useParams();
  const classes = useStyles();
  const [termPage, setTermPage] = useState(null);
  const { page, glossary, heroContent } = fetchGlossary();

  useEffect(() => {
    GetGlossaryTerm(termSlug, setTermPage);
  }, []);

  useEffect(() => {
    if (!termPage) return;
  }, [termPage]);

  if (!termPage || !page) return null;

  return (
    <GlossaryShared heroContent={heroContent} page={page}>
      <Section>
        <AlphaList glossary={glossary} showButton={true} />
        <Divider className={classes.contentDivider} />
        <Typography variant="h1" className={classes.listItemsTitle}>
          {termPage.fields.term}
        </Typography>
        <Grid item className={classes.sidebar}>
          <RichText>{termPage.fields.content}</RichText>
        </Grid>
      </Section>
      <PreFooter />
    </GlossaryShared>
  );
}
