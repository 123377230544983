// https://gist.github.com/xheuz/f97f92d0dec09fdd543554a0a8c484ae

const HUNDRED_CENTS = 10000;

export const convertFromHundredCents = (number) => {
  return number / HUNDRED_CENTS;
};

export const convertToHundredCents = (number) => {
  return number * HUNDRED_CENTS;
};

export const roundDownFromHundredCents = (number) => {
  const newNumber = convertFromHundredCents(number);
  return Math.floor(newNumber * 100) / 100;
};

export const roundUpFromHundredCents = (number) => {
  const newNumber = convertFromHundredCents(number);
  return Math.round((newNumber + Number.EPSILON) * 100) / 100;
};

export const numberWithThousandsSeparator = (
  number,
  decimalDigits = 0,
  separator = ",",
  decimalSeparator = "."
) => {
  let isNegative = false;

  if (number === undefined || number === null) number = 0;
  if (isNaN(number)) return number.toString();
  if (number >= 0 && number < 1000) return number.toFixed(decimalDigits);
  if (number < 0) isNegative = true;

  number = Math.abs(number);

  const [integer, decimals] = number
    .toFixed(decimalDigits)
    .toString()
    .split(".");

  const numberWithCommas = integer
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, separator);

  return `${isNegative ? "-" : ""}${numberWithCommas}${
    decimals ? `${decimalSeparator}${decimals}` : ""
  }`;
};

export const convertNumberToCurrency = (
  number,
  decimalPlaces = 2,
  fromHundredCents = true
) => {
  let convertedNumber = numberWithThousandsSeparator(
    fromHundredCents ? convertFromHundredCents(number) : number,
    decimalPlaces
  );

  if (number < 0)
    return `-$${convertedNumber.substring(1, convertedNumber.length)}`;

  return `$${convertedNumber}`;
};

export default {
  roundUpFromHundredCents,
  roundDownFromHundredCents,
  withCommaSeparator: numberWithThousandsSeparator,
  convertFromHundredCents,
  convertToHundredCents,
  convertNumberToCurrency,
};
