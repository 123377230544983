/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from "react";
import { NavLink, useLocation, matchPath } from "react-router-dom";

import Collapse from "@material-ui/core/Collapse";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import ArrowRight from "@material-ui/icons/ArrowRight";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";

import { GetDocNav } from "api/contentful/helper";
import VivialConnectAPI from "api/vivial-connect";

import logo from "assets/img/vivial_connect_logo.png";
import { useStyles } from "./styles";
import { menuIcons } from "./constants";

const MenuItem = React.memo((props) => {
  const classes = useStyles();
  let {
    item,
    index,
    handleClickItem,
    handleDrawerToggle,
    isActive,
    openItem,
  } = props;

  if (item.children) {
    return (
      <List>
        <ListItem
          key={index}
          button
          onClick={handleClickItem(item.id)}
          className={`${classes.listItem}
            ${isActive(item) ? classes.listItemSelected : ""}`}
        >
          <ListItemText className={classes.listItemText} primary={item.name} />

          {openItem[item.id] ? (
            <ArrowDropDown className={classes.arrowIcon} />
          ) : (
            <ArrowRight className={classes.arrowIcon} />
          )}
        </ListItem>
        <Collapse
          in={openItem[item.id]}
          timeout="auto"
          unmountOnExit
          className={isActive(item) ? classes.listItemCollapseSelected : ""}
        >
          <List component="div" disablePadding>
            {item.children.map((child, index2) => (
              <ListItem
                button
                component={NavLink}
                to={child.route}
                key={index2}
                onClick={handleDrawerToggle}
                className={`${classes.nested}
                  ${
                    isActive(child)
                      ? classes.listItemChildSelected
                      : classes.listItemChild
                  }`}
              >
                <ListItemText
                  primary={child.optionName}
                  style={{ wordWrap: "break-word" }}
                />
              </ListItem>
            ))}
          </List>
        </Collapse>
      </List>
    );
  } else {
    return (
      <ListItem
        button
        component={NavLink}
        to={item.route}
        key={index}
        onClick={handleDrawerToggle}
        className={classes.listItem}
        activeClassName={isActive(item) ? classes.listItemSelected : ""}
      >
        <ListItemText className={classes.listItemText} primary={item.name} />
      </ListItem>
    );
  }
});

export default function SideBar(props) {
  const classes = useStyles();
  const location = useLocation();
  const { pathname } = location;
  const isLogged = VivialConnectAPI.helpers.token.get();

  const { handleDrawerToggle, drawerOpen } = props;
  const [contentfulItems, setContentfulItems] = React.useState(null);
  const [items, setItems] = React.useState([]);
  const [openItem, setOpenItem] = React.useState({
    company: false,
    billing: false,
    numbers: false,
    connectors: false,
  });

  const handleClickItem = (prop) => () => {
    setOpenItem({ ...openItem, [prop]: !openItem[prop] });
  };

  const matchRoutes = (path, route) => {
    const match = matchPath(path, {
      path: route,
      exact: true,
      strict: false,
    });
    if (match && match.isExact) {
      return true;
    }
  };

  const isActive = React.useCallback(
    (item) => {
      if (matchRoutes(pathname, item.route)) {
        return true;
      } else if (item.alternativeRoute) {
        return matchRoutes(pathname, item.alternativeRoute);
      } else if (item.children) {
        for (const i in item.children) {
          if (isActive(item.children[i])) return true;
        }
      }
      return false;
    },
    [pathname]
  );

  React.useEffect(() => {
    GetDocNav(setContentfulItems, 3);
  }, []);

  React.useEffect(() => {
    if (!contentfulItems) return;

    let menuItems = [];
    contentfulItems.fields.children.forEach((section) => {
      let item = section.fields;
      let menuItemChildren = [];
      if (item.children) {
        item.children.forEach((children) => {
          let itemChildren = children.fields;
          menuItemChildren.push({
            optionName: itemChildren.title,
            route: `/docs/${itemChildren.slug}`,
          });
        });
      }

      menuItems.push({
        name: item.title,
        route: `/docs/${item.slug}`,
        children: menuItemChildren.length ? menuItemChildren : null,
        id: item.slug,
        icon: menuIcons[item.iconName],
      });
    });
    setItems(menuItems);
  }, [contentfulItems]);

  React.useEffect(() => {
    if (items.length) {
      let openItems = {};
      items.forEach((item) => {
        openItems[item.id] = isActive(item);
      });
      setOpenItem(openItems);
    }
  }, [isActive, items]);

  const SidebarContent = () => {
    return (
      <List className={classes.list}>
        {items.map((item, index) => {
          return (
            <MenuItem
              key={index}
              item={item}
              index={index}
              handleClickItem={handleClickItem}
              handleDrawerToggle={handleDrawerToggle}
              isActive={isActive}
              openItem={openItem}
            />
          );
        })}
      </List>
    );
  };

  return (
    <>
      <Drawer
        variant="permanent"
        classes={{
          root: classes.permanentDrawerRoot,
          paper: classes.drawerPaper,
        }}
        open={props.open}
      >
        <div
          className={`${classes.drawerHeader} ${classes.permanentDrawerHeader}`}
        >
          <NavLink to={isLogged ? "/dashboard" : "/"}>
            <img
              src={logo}
              className={classes.permanentDrawerLogo}
              alt="Vivial Connect"
            />
          </NavLink>
        </div>
        <SidebarContent />
      </Drawer>

      <Drawer
        variant="temporary"
        classes={{
          root: classes.temporaryDrawerRoot,
          paper: classes.drawerPaper,
        }}
        open={drawerOpen}
        onClose={handleDrawerToggle}
        anchor="left"
      >
        <div
          className={`${classes.drawerHeader} ${classes.temporaryDrawerHeader}`}
        >
          <IconButton
            aria-label="show menu"
            onClick={handleDrawerToggle}
            className={classes.drawerToggle}
          >
            <MenuOpenIcon />
          </IconButton>
          <img
            src={logo}
            className={classes.temporaryDrawerLogo}
            alt="Vivial Connect"
          />
        </div>
        <SidebarContent />
      </Drawer>
    </>
  );
}
