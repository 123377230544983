import React from "react";

import { GetContentfulPage, GetAdditionalContent } from "api/contentful/helper";

import HTMLHead from "components/HTMLHead";

import RichText from "components/Layouts/Brochure/RichText";
import Hero from "components/Layouts/Brochure/Hero";
import Section from "components/Layouts/Brochure/Section";
import PreFooter from "components/Layouts/Brochure/Footer/PreFooter";
import Subtitle from "components/Layouts/Brochure/Subtitle";
import BrochureButton from "components/Layouts/Brochure/BrochureButton";
import WideImage from "components/Layouts/Brochure/WideImage";

export default function About() {
  const [page, setPage] = React.useState(null);
  const [heroContent, setHeroContent] = React.useState({});
  const [jobsButton, setJobsButton] = React.useState({});

  //Get page from contentful on load
  React.useEffect(() => {
    GetContentfulPage("about", setPage);
  }, []);

  //Extract content from contentful content model
  React.useEffect(() => {
    if (!page) return;
    const { fields } = page;

    setHeroContent({
      imageURL: fields.heroImg.fields.file.url,
      header1: fields.heroHdr1,
      header2: fields.heroHdr2,
      button: fields.heroBtn,
    });

    GetAdditionalContent(page, "button", "view_job_openings", setJobsButton);
  }, [page]);

  if (!page) return null;

  return (
    <>
      <HTMLHead page={page} />
      <Hero {...heroContent} />

      <Section>
        <RichText>{page.fields.content1}</RichText>
      </Section>

      <Section className="noTopPadding alignCenter">
        <Subtitle>{page.fields.header2}</Subtitle>

        <RichText>{page.fields.content2}</RichText>

        {jobsButton && (
          <BrochureButton size="small" href={jobsButton.link}>
            {jobsButton.text}
          </BrochureButton>
        )}
      </Section>

      <WideImage
        imageURL={page.fields.image1.fields.file.url}
        altText={page.fields.header2}
      />

      <PreFooter />
    </>
  );
}
