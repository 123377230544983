import React, { useState } from "react";
import { toast } from "react-toastify";
import { Redirect, NavLink } from "react-router-dom";
import ReactGA from "react-ga";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";

import { useAppContext } from "components/Context";
import HTMLHead from "components/HTMLHead";

import LobbyHeader from "components/Layouts/Lobby/Header";
import LobbyFormWrapper from "components/Layouts/Lobby/FormWrapper";

import VerifyYourPhoneNumber from "./VerifyYourPhoneNumber";

import VivialConnectAPI from "api/vivial-connect";
import { useStyles } from "./styles";

const version = process.env.REACT_APP_VERSION;

export default function Login() {
  const classes = useStyles();
  const [login, setLogin] = useState({
    username: "",
    password: "",
    isLogged: VivialConnectAPI.helpers.token.get(),
  });
  const [verifyPhoneNumber, setVerifyPhoneNumber] = useState(false);
  const [forcePasswordReset, setForcePasswordReset] = useState(false);
  const { setAppState, refreshContexts } = useAppContext();

  const handleSubmit = (event) => {
    event.preventDefault();
    const { auth } = VivialConnectAPI.services;

    auth
      .login(login.username, login.password)
      .then(async (res) => {
        setForcePasswordReset(res.force_password_reset);

        //Save username in the session cache.
        //Needed regardless of force_password_reset status
        setAppState({
          username: login.username,
        });

        //If force-reset is required, the auth endpoint will not return any of
        //the normal login data, and the user will not actually be logged in.
        if (!res.force_password_reset) {
          //The auth endpoint returns user's assigned roles as "scopes[]"
          //Save roles in the session cache
          setAppState({
            roles: res.scope,
          });

          // Call accountstatus endpoint and populate acccount/user data cache
          // Since the account_id and plan_type are not comming in the login payload this is a dirty thing to do but it must stay
          // This will fail if the user needs to reset their password
          const accountStatus = await refreshContexts();

          setVerifyPhoneNumber(!accountStatus.phone_verified);
          ReactGA.set({ userId: accountStatus.user_id });

          setLogin((prev) => ({
            ...prev,
            isLogged: true,
          }));
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const handleChange = ({ currentTarget: input }) => {
    login[input.name] = input.value;
    const { username, password } = login;

    setLogin((prev) => ({
      ...prev,
      username: username,
      password: password,
    }));
  };

  if (forcePasswordReset)
    return (
      <Redirect
        to={{ pathname: "/reset/forcePassword", state: { forceReset: true } }}
      />
    );
  if (verifyPhoneNumber) return <VerifyYourPhoneNumber />;
  if (login.isLogged && !verifyPhoneNumber) {
    ReactGA.event({
      category: "Dashboard Logins",
      action: "successful login",
      label: "Dashboard Successful Logins",
    });
    return <Redirect to="/dashboard" />;
  }
  return (
    <>
      <HTMLHead page={{ fields: { slug: "login" } }} />
      <LobbyHeader
        title={"Sign In"}
      />
      <LobbyFormWrapper onSubmit={handleSubmit}>
        <FormControl fullWidth>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Email Address"
            name="username"
            value={login.username}
            onChange={handleChange}
            autoFocus
            autoComplete="username"
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="password"
            value={login.password}
            onChange={handleChange}
          />
        </FormControl>
        <div className={classes.buttonWrapper}>
          <Box mr={2}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
            >
              Login
            </Button>
          </Box>
          <Link component={NavLink} to="/reset/request">
            Forgot your password?
          </Link>
        </div>
      </LobbyFormWrapper>
      <div className={classes.version}>{version}</div>
    </>
  );
}
