import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: "75px 15%",
  },
  dark: {
    backgroundColor: "#ededed",
    // TODO: figure out how to pull color from theme. Line below doesn't work.
    // backgroundColor: theme.palette.colorExtendedGreen1,
  },
  noTopPadding: {
    paddingTop: 0,
  },
  noBottomPadding: {
    paddingBottom: 0,
  },
  alignCenter: {
    textAlign: "center",
  },
  narrow: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: "25%",
      paddingRight: "25%",
    },
  },
}));
