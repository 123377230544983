export const navItems = [
  {
    name: "About",
    route: "/about",
  },
  {
    name: "Products and Services",
    children: [
      {
        name: "SMS/MMS API",
        route: "/products-and-services",
      },
      {
        name: "SpyderLynk",
        route: "/spyderlynk-security",
      },
    ],
  },
  {
    name: "Insights",
    children: [
      {
        name: "Blog",
        route: "/blog",
      },
      {
        name: "Glossary",
        route: "/glossary",
      },
    ],
  },
  {
    name: "Developer Tools",
    route: "/developer-tools",
  },
  {
    name: "Partners",
    route: "/partners",
  },
  {
    name: "Pricing",
    route: "/pricing",
  },
  {
    name: "Contact",
    route: "/contact",
  },
  // {
  //   name: "Sign Up",
  //   route: "/register",
  //   component: "Button",
  //   props: {
  //     size: "small",
  //     variant: "contained",
  //     color: "primary",
  //   },
  //   isProtected: false,
  // },
  {
    name: "Login",
    route: "/login",
    component: "Button",
    props: {
      size: "small",
      variant: "contained",
      color: "primary",
    },
    isProtected: false,
  },
  {
    name: "Dashboard",
    route: "/dashboard",
    component: "Button",
    props: {
      size: "small",
      variant: "contained",
      color: "primary",
    },
    isProtected: true,
  },
];
