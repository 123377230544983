import { makeStyles } from "@material-ui/core/styles";
import defaultImage from "assets/img/home_main.png";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    padding: theme.spacing(2),
  },
  container: {
    backgroundImage: `url(${defaultImage})`,
    backgroundSize: "100%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    color: "transparent",
    "-webkit-background-clip": "text",
  },
  typography: {
    [theme.breakpoints.down("xl")]: {
      fontSize: "25rem",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "20rem",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "15rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "10rem",
    },
    fontWeight: 1000,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

export default useStyles;
