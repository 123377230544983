export const DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss'Z'";

export const TRANSACTION_TYPES = [
  "cancel_subscription",
  "cancellation_fee",
  "cashout_subscription",
  "credit",
  "credit_invoice",
  "credit_number_credits",
  "credit_usage_credits",
  "debit",
  "debit_fee",
  "debit_number_credits",
  "debit_usage_credits",
  "dispatched",
  "mms_local_in",
  "mms_local_out",
  "mms_shortcode_in",
  "mms_shortcode_out",
  "mms_tollfree_in",
  "mms_tollfree_out",
  "number_purchase",
  "number_purchase_tollfree",
  "number_release",
  "number_renew",
  "number_renew_tollfree",
  "rescind",
  "sms_intl_out",
  "sms_local_in",
  "sms_local_out",
  "sms_shortcode_in",
  "sms_shortcode_out",
  "sms_tollfree_in",
  "sms_tollfree_out",
  "subscription",
  "subscription_allocation",
  "unknown",
  "voice_forward",
  "voice_forward_tollfree",
];

export const FILTER_GROUPS = {
  all: {
    label: "All Transactions",
    types: null,
  },
  credits: {
    label: "Payments & Credits",
    types: [
      "credit",
      "credit_invoice",
      "credit_number_credits",
      "credit_usage_credits",
    ],
  },
  fees: {
    label: "Fees & Adjustments",
    types: [
      "debit",
      "debit_fee",
      "debit_number_credits",
      "debit_usage_credits",
    ],
  },
  numbers: {
    label: "Number Purchase & Renewal",
    types: [
      "number_purchase",
      "number_purchase_tollfree",
      "number_renew",
      "number_renew_tollfree",
    ],
  },
  messages: {
    label: "Messaging",
    types: [
      "mms_local_in",
      "mms_local_out",
      "mms_shortcode_in",
      "mms_shortcode_out",
      "mms_tollfree_in",
      "mms_tollfree_out",
      "sms_intl_out",
      "sms_local_in",
      "sms_local_out",
      "sms_shortcode_in",
      "sms_shortcode_out",
      "sms_tollfree_in",
      "sms_tollfree_out",
    ],
  },
  voice: {
    label: "Voice Forwarding",
    types: ["voice_forward", "voice_forward_tollfree"],
  },
};
