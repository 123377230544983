/* eslint-disable no-empty */
import React, { useState, useReducer, useEffect } from "react";
import { toast } from "react-toastify";
import { useParams, Redirect } from "react-router-dom";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import DeleteIcon from "@material-ui/icons/Delete";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";

import { usePageContext } from "components/Context";

import {
  Card as DashboardCard,
  CardContent as DashboardCardContent,
  CardHeader as DashboardCardHeader,
} from "components/Layouts/Dashboard/Card";
import AddNumbers from "./AddNumbers";
import ManageNumbers from "./ManageNumbers";
import ConfigureCallbacks from "components/Numbers/ConfigureCallbacks";
import TestMessage from "components/Numbers/TestMessage";
import ConfirmModal from "@moosetalk-frontend/global/components/ConfirmModal";
import { PageHeader } from "./shared.js";

import { useStyles } from "./styles";
import VivialConnectAPI from "api/vivial-connect";

const { customHooks } = VivialConnectAPI.helpers;

export default function EditConnector() {
  const isMounted = customHooks.useMountedRef();
  const { setPageState } = usePageContext();
  const { validateUrl } = VivialConnectAPI.helpers.validation;
  const classes = useStyles();
  //set local storage
  const { id: connectorId } = useParams();
  const [connector, setConnector] = useState({});
  const [connectorCallbacks, setConnectorCallbacks] = useReducer(
    connectorCallbackReducer,
    {}
  );
  const [connectorNumbers, setConnectorNumbers] = useState([]);
  const [connectorError, setConnectorError] = useState("");
  const [callbackErrors, setCallbackErrors] = useState({
    status: false,
    incoming: false,
    incoming_fallback: false,
  });
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [redirectToIndex, setRedirectToIndex] = useState(false);

  useEffect(() => {
    //Set page context values
    setPageState({
      title: "Connectors",
      subtitle:
        connector && connector.name ? connector.name : "Manage Connector",
    });

    return () => setPageState({});
  }, [setPageState, connector]);

  useEffect(() => {
    const { connectors } = VivialConnectAPI.services;

    connectors.getConnector(connectorId).then((selectedConnector) => {
      //convert callback array to object for editing
      let callbackObj = {};
      selectedConnector.callbacks.forEach((callback) => {
        callbackObj[callback.event_type] = callback;
      });
      setConnectorCallbacks(callbackObj);

      //handleNumberChange strips phone_numbers down to just IDs
      handleNumberChange(selectedConnector.phone_numbers);

      //set connector last to avoid rerender occuring before setNumberCallbacks
      //has finished updating.
      setConnector(selectedConnector);
    });
  }, [connectorId]);

  const handleConnectorChange = (prop) => (event) => {
    setConnector({ ...connector, [prop]: event.target.value });
  };

  function connectorCallbackReducer(prevCallbacks, callbackTree) {
    for (const callback_type in callbackTree) {
      if (callbackTree[callback_type].url !== "") {
        //Copy callbacks that have a URL prop
        prevCallbacks[callback_type] = { ...callbackTree[callback_type] };

        if (prevCallbacks[callback_type].auth_type === "NONE") {
          //delete credentials if auth_type is not set
          delete prevCallbacks[callback_type].credentials;
        }
      } else {
        //Delete callbacks with no URL prop
        delete prevCallbacks[callback_type];
      }
    }

    return prevCallbacks;
  }

  const handleCallbackChange = (callbackTree) => {
    setConnectorCallbacks(callbackTree);
  };

  const handleNumberChange = (numberArray) => {
    const selectedNumberIds = numberArray.map((num) => {
      return { phone_number_id: num.phone_number_id };
    });
    setConnectorNumbers(selectedNumberIds);
  };

  const validate = () => {
    let isValid = true;

    //valdate name
    if (connector.name === "") {
      isValid = false;
      let errStr = "Please choose a Connector name";
      setConnectorError(errStr);
      toast.error(errStr);
    } else {
      setConnectorError("");
    }

    let callbackErr = false;
    let newCallbackErrors = {
      status: false,
      incoming: false,
      incoming_fallback: false,
    };

    try {
      if (!validateUrl(connectorCallbacks.status.url)) {
        callbackErr = true;
        newCallbackErrors.status = true;
      }
    } catch (e) {}

    try {
      if (!validateUrl(connectorCallbacks.incoming.url)) {
        callbackErr = true;
        newCallbackErrors.incoming = true;
      }
    } catch (e) {}

    try {
      if (!validateUrl(connectorCallbacks.incoming_fallback.url)) {
        callbackErr = true;
        newCallbackErrors.incoming_fallback = true;
      }
    } catch (e) {}

    setCallbackErrors(newCallbackErrors);
    if (callbackErr) {
      isValid = false;
      toast.error("Invalid callback URL");
    }

    return isValid;
  };

  const onSubmitConnector = () => {
    if (saveInProgress) return;
    const { connectors } = VivialConnectAPI.services;
    let newCallbacks = [];

    if (!validate()) {
      return;
    }

    //Transform callback object into an Array
    for (const callback_type in connectorCallbacks) {
      connectorCallbacks[callback_type].message_type = "text";
      connectorCallbacks[callback_type].event_type = callback_type;
      newCallbacks.push(connectorCallbacks[callback_type]);
    }

    const payload = {
      connector: {
        callbacks: newCallbacks,
        name: connector.name,
        id: connector.id,
        active: connector.active,
        phone_numbers: connectorNumbers,
      },
    };

    setSaveInProgress(true);
    connectors
      .putConnector(connector.id, payload)
      .then(() => {
        toast.success("Connector successfully updated");
      })
      .catch((error) => toast.error(error))
      .finally(() => {
        if (!isMounted.current) return;
        setSaveInProgress(false);
      });
  };

  const handleDeleteConnector = () => {
    if (deleteInProgress) return;
    const { connectors } = VivialConnectAPI.services;

    setDeleteInProgress(true);
    connectors
      .deleteConnector(connector.id)
      .then(() => {
        toast.success("Connector successfully deleted");
        setRedirectToIndex(true);
      })
      .catch((error) => toast.error(error))
      .finally(() => {
        if (!isMounted.current) return;
        setDeleteInProgress(false);
      });
  };

  if (!connector || !connector.id) return null;

  if (redirectToIndex) return <Redirect to="/dashboard/connectors" />;

  return (
    <div className={classes.root} spacing={2}>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <PageHeader>
            <Button
              color="primary"
              variant="outlined"
              startIcon={<ArrowLeftIcon />}
              onClick={() => setRedirectToIndex(true)}
            >
              Manage Connectors
            </Button>
          </PageHeader>
        </Grid>

        <Grid item xs={12} lg={6}>
          <form
            autoComplete="off"
            onSubmit={(event) => {
              event.preventDefault();
              onSubmitConnector(event);
            }}
          >
            <DashboardCard>
              <DashboardCardHeader
                title={`Edit Connector: ${connector.name}`}
              />
              <DashboardCardContent>
                <Grid item xs={8}>
                  <FormControl fullWidth>
                    <TextField
                      variant="outlined"
                      label="Connector Name"
                      name="connector_name"
                      value={connector.name}
                      onChange={handleConnectorChange("name")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={connectorError !== ""}
                      helperText={connectorError}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <TextField
                      variant="outlined"
                      label="Connector ID"
                      name="connector_id"
                      value={connector.id}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={true}
                    />
                  </FormControl>
                </Grid>{" "}
                <ConfigureCallbacks
                  callbacks={connectorCallbacks}
                  callbackErrors={callbackErrors}
                  handleChange={handleCallbackChange}
                />
                <Grid item xs={6} className={classes.formButtons}>
                  <ConfirmModal
                    title={`Delete Connector`}
                    warningMessage={
                      <>
                        <span>
                          Please confirm that you want to delete Connector{" "}
                          {`"${connector.name}"`}
                        </span>
                      </>
                    }
                    clickableComponent={
                      <Button
                        variant="outlined"
                        startIcon={
                          deleteInProgress ? (
                            <CircularProgress size={24} disableShrink />
                          ) : (
                            <DeleteIcon />
                          )
                        }
                        className={classes.deleteButton}
                      >
                        Delete
                      </Button>
                    }
                    onSubmit={handleDeleteConnector}
                  />
                </Grid>
                <Grid item xs={6} className={classes.saveButtonWrapper}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    startIcon={
                      saveInProgress ? (
                        <CircularProgress
                          size={24}
                          color="inherit"
                          disableShrink
                        />
                      ) : null
                    }
                  >
                    Save Changes
                  </Button>
                </Grid>
              </DashboardCardContent>
            </DashboardCard>
          </form>
        </Grid>
        <Grid item xs={12} lg={6}>
          <DashboardCard>
            <DashboardCardHeader title={"Add new numbers"} />
            <DashboardCardContent alignContent="flex-start">
              <AddNumbers
                connectorNumbers={connectorNumbers}
                handleChange={handleNumberChange}
              />
              <Grid item xs={12} className={classes.saveButtonWrapper}>
                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  onClick={onSubmitConnector}
                >
                  Add Selected
                </Button>
              </Grid>
            </DashboardCardContent>
          </DashboardCard>
        </Grid>
        <Grid item xs={12} lg={6}>
          <DashboardCard>
            <DashboardCardHeader title={"Manage Assigned Numbers"} />
            <ManageNumbers selectedConnector={connector} />
          </DashboardCard>
        </Grid>
        {/*
        Conectors with more than one number cannot send messages.
        Remove test number pane for these connectors
      */}
        {connector.phone_numbers.length <= 1 && (
          <Grid item xs={12} lg={6}>
            <TestMessage connectorId={connector.id} />
          </Grid>
        )}
      </Grid>
    </div>
  );
}
