import React from "react";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";

import {
  Card as DashboardCard,
  CardHeader as DashboardCardHeader,
  CardContent as DashboardCardContent,
} from "components/Layouts/Dashboard/Card";

import CashPriceTable from "./CashPriceTable";
import useStyles from "./styles";

const YourOveragePrices = () => {
  const classes = useStyles();

  return (
    <DashboardCard>
      <DashboardCardHeader title={"Your Overage Prices"} />
      <DashboardCardContent>
        <Typography variant="body1" className={classes.note}>
          Overage fees are applied when more messages are sent than credits
          available. For lower overage charge rates, contact our{" "}
          <Link href="mailto:support@support.vivialconnect.net">
            Sales team
          </Link>{" "}
          for more information on Enterprise plans.
        </Typography>
        <CashPriceTable />
      </DashboardCardContent>
    </DashboardCard>
  );
};

export default YourOveragePrices;
