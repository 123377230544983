/* eslint-disable react/prop-types */

export function formatTCRString(str) {
  if (!str) return "";
  return str
    .replace(/_/, " ")
    .toLowerCase()
    .split(" ")
    .map(function (currentValue) {
      return currentValue[0].toUpperCase() + currentValue.substring(1);
    })
    .join(" ");
}
