/* eslint-disable react/prop-types */
import React from "react";
import { NavLink } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { ThemeProvider } from "@material-ui/core/styles";

import logo from "assets/img/vivial_connect_logo.png";
import { darkTheme } from "themes";

import Copyright from "@moosetalk-frontend/global/components/Copyright";
import { useStyles } from "./styles";

const LobbyLogo = () => {
  const classes = useStyles();
  return (
    <Box className={classes.connectLogo}>
      <NavLink to="/">
        <img src={logo} className={classes.logoImg} alt="Vivial Connect" />
      </NavLink>
    </Box>
  );
};

export default function Lobby({ children }) {
  const classes = useStyles();
  return (
    <ThemeProvider theme={darkTheme}>
      <Grid container component="main" className={classes.root}>
        <Grid item md={5} lg={7} className={classes.image} />
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          item
          xs={12}
          sm={12}
          md={7}
          lg={5}
          elevation={6}
          className={classes.gridRight}
        >
          <Grid item className={classes.paper}>
            <LobbyLogo />
            {children}
          </Grid>
          <Box mt={5} className={classes.copyright}>
            <Copyright showSupport={false} theme="dark" />
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
