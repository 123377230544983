/* eslint-disable react/prop-types */
import React from "react";
import { CSVLink } from "react-csv";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import VpnKeyIcon from "@material-ui/icons/VpnKey";

import {
  Card as DashboardCard,
  CardContent as DashboardCardContent,
} from "components/Layouts/Dashboard/Card";

import useStyles from "./styles";

export default function APIKeysBanner({
  onClick,
  openDialog,
  handleCloseDialog,
  credential,
  apiUrl,
  csvData,
  name,
  handleOnChange,
}) {
  const classes = useStyles();

  return (
    <DashboardCard>
      <DashboardCardContent>
        <div className={classes.bannerWrapper}>
          <Grid item xs={8}>
            <Typography variant="body1" className={classes.bannerHeader}>
              API Keys provide full access to the API via HMAC or Basic
              authentication. Each user can have up to 10 API Keys.
              <br />
              <br />
              For restricted API access we recommend using{" "}
              <Link href="/dashboard/account/tokens">
                Personal Access Tokens
              </Link>
              . See the{" "}
              <Link
                href={`${process.env.REACT_APP_DOCSITE_URL}/quickstart/authentication.html`}
              >
                Vivial Connect API Documentation
              </Link>{" "}
              for more information on authentication options.
            </Typography>
          </Grid>
          <Grid item xs={4} className={classes.buttonWrapper}>
            <Button
              color="primary"
              variant="outlined"
              startIcon={<VpnKeyIcon />}
              onClick={onClick}
            >
              New API Key
            </Button>
            <Dialog
              fullWidth
              maxWidth="sm"
              open={openDialog}
              onClose={() => handleCloseDialog()}
              aria-labelledby="api-key-dialog"
              className={classes.dialog}
            >
              <DialogTitle id="api-key-dialog">New API Key</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  The API Secret is only displayed one time. Please copy and
                  paste it into a safe place. Once you leave this page, you will
                  not be able to access it again.
                </DialogContentText>
                <Grid
                  container
                  spacing={2}
                  className={classes.credentialsWrapper}
                >
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      className={classes.credentialTitle}
                    >
                      API URL:
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {apiUrl}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      className={classes.credentialTitle}
                    >
                      API Key:
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {credential.api_key}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      className={classes.credentialTitle}
                    >
                      API Secret:
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {credential.api_secret}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      className={classes.credentialTitle}
                    >
                      Key Name:
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Key Name"
                      name={`key`}
                      value={name ? name : ""}
                      onChange={handleOnChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => handleCloseDialog(credential)}
                  variant="contained"
                  className={classes.closeDialog}
                >
                  Save &amp; Close
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  component={CSVLink}
                  data={csvData}
                  filename={"credentials.csv"}
                  target="_blank"
                >
                  Download key
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </div>
      </DashboardCardContent>
    </DashboardCard>
  );
}
