import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  copyright: {
    width: "100%",
    maxWidth: "874px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  copyrightItem: {
    fontSize: "0.8333rem",
    whiteSpace: "nowrap",
    textAlign: "center",
    letterSpacing: "0.09em",
    minWidth: "120px",
    margin: theme.spacing(0.5, 4),
    "& a, & a:hover, & a:visited": {
      color: theme.palette.common.black,
      textDecoration: "none",
    },
  },
  copyrightItemLight: {
    color: theme.palette.common.black,
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(2, 4),
    },
  },
  copyrightItemDark: {
    color: theme.palette.common.white,
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(1, 2),
    },
  },
  copyrightLink: {
    color: "inherit !important",
  },
}));
