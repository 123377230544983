const config = {
  host: process.env.REACT_APP_CTF_HOST,
  space: process.env.REACT_APP_CTF_SPACE_ID,
  accessToken: process.env.REACT_APP_CTF_ACCESS_TOKEN,
};

export const contentfulOptions = {
  include: 10,
};

export default config;
