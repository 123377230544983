/* eslint-disable react/prop-types */
import React from "react";

import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Slide from "@material-ui/core/Slide";
import Toolbar from "@material-ui/core/Toolbar";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";

import { NavLink } from "react-router-dom";
import VivialConnectAPI from "api/vivial-connect";

import { useStyles } from "./styles";
import { navItems } from "./constants";
import logo from "assets/img/vivial_connect_logo.png";
import blackLogo from "assets/img/vivial_connect_logo_black.png";

function MenuListItem(item) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ListItem className={classes.menuItem}>
      <button onClick={handleClick} className={classes.menuButton}>
        <nobr>{item.name}</nobr>
      </button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {item.children.map((child, index) => {
          return (
            <MenuItem
              onClick={handleClose}
              key={index}
              className={classes.popMenuItem}
            >
              <NavLink to={child.route}>{child.name}</NavLink>
            </MenuItem>
          );
        })}
      </Menu>
    </ListItem>
  );
}

function TopMenu({ isLoggedOut }) {
  const classes = useStyles();

  return (
    <List className={classes.menuItems}>
      <ListItem className={`${classes.menuItem} ${classes.EOLItem}`} key="eol">
        <NavLink to="/blog/vivial-connect-end-of-life">
            <nobr>End of Life</nobr>
        </NavLink>
      </ListItem>
      {navItems.map((item, index) => {
        const Component = item.component;
        const componentProps = item.props || {};

        if (isLoggedOut && item.isProtected === true) return null;
        if (!isLoggedOut && item.isProtected === false) return null;

        if (item.children) {
          return <MenuListItem key={index} {...item} />;
        } else {
          return (
            <ListItem className={classes.menuItem} key={index}>
              <NavLink to={item.route}>
                {Component ? (
                  <Button {...componentProps}>
                    <nobr>{item.name}</nobr>
                  </Button>
                ) : (
                  <nobr>{item.name}</nobr>
                )}
              </NavLink>
            </ListItem>
          );
        }
      })}
    </List>
  );
}

export default function BrochureAppBar() {
  const classes = useStyles();
  const isLoggedOut = !VivialConnectAPI.helpers.token.get();

  // nav drawer
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  //appbar
  const [appBarScrollClass, setAppBarScrollClass] = React.useState();
  const scrollDownTrigger = useScrollTrigger({ threshold: 50 });
  const scrollUpTrigger = useScrollTrigger({ disableHysteresis: true });
  const handleScrollClassToggle = (isTriggered) => {
    if (isTriggered) {
      setAppBarScrollClass(classes.appBarScrollClass);
    } else {
      setAppBarScrollClass(undefined);
    }
  };

  React.useEffect(() => {
    handleScrollClassToggle(scrollUpTrigger);
  });
  const DrawerItem = (props) => {
    const { item, index } = props;
    return (
      <NavLink to={item.route} onClick={handleDrawerToggle}>
        <ListItem button key={index}>
          <ListItemText primary={item.name} />
        </ListItem>
      </NavLink>
    );
  };

  return (
    <>
      <Slide direction="down" in={!scrollDownTrigger}>
        <AppBar
          position="fixed"
          className={`${classes.appBar} ${appBarScrollClass}`}
          color="inherit"
          elevation={0}
        >
          <Toolbar className={classes.toolbar}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <a href="/">
                  <img
                    src={logo}
                    className={classes.logoImg}
                    alt="Vivial Connect"
                  />
                </a>
              </Grid>

              <Grid item>
                <Hidden lgUp>
                  <IconButton
                    className={classes.drawerOpenIcon}
                    aria-label="show menu"
                    onClick={handleDrawerToggle}
                  >
                    <MenuIcon />
                  </IconButton>
                </Hidden>

                <Hidden mdDown>
                  <TopMenu isLoggedOut={isLoggedOut} />
                </Hidden>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Slide>

      <nav className={classes.drawer} aria-label="slide out menu">
        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawer,
          }}
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="space-between"
            spacing={2}
            className="drawerHeader"
          >
            <Grid item>
              <img className="logo" src={blackLogo} alt="Vivial Connect" />
            </Grid>
            <Grid item>
              <IconButton aria-label="hide menu" onClick={handleDrawerToggle}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>

          <Divider />
          <List>
            <DrawerItem item={{
              name: "End of Life",
              route:"/blog/vivial-connect-end-of-life"
            }} key="eol" />
            {navItems.map((item, index) => {
              if (isLoggedOut && item.isProtected === true) return null;
              if (!isLoggedOut && item.isProtected === false) return null;

              if (item.children) {
                return item.children.map((child, jndex) => {
                  const key = `${index}-${jndex}`;
                  return <DrawerItem item={child} key={key} />;
                });
              } else {
                return <DrawerItem item={item} key={index} />;
              }
            })}
          </List>
        </Drawer>
      </nav>
    </>
  );
}
