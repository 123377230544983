import React, { useEffect, useState } from "react";
import { format, parseISO } from "date-fns";

import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { usePageContext } from "components/Context";

import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MessageIcon from "@material-ui/icons/Message";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import {
  Card as DashboardCard,
  CardHeader as DashboardHeader,
  CardContent as DashboardCardContent,
} from "components/Layouts/Dashboard/Card";
import IOSSwitch from "@moosetalk-frontend/global/components/IOSSwitch";
import TruncateText from "components/TruncateText";

import VivialConnectAPI from "api/vivial-connect";
const { convertNumberToCurrency } = VivialConnectAPI.helpers.numbers;

import { useStyles } from "./styles";
import { DEFAULT_CAMPAIGN } from "./constants";

const EditableList = ({
  sampleMessage,
  sampleMessageName,
  editMessage,
  selectedMessageName,
  selectedMessageText,
  onEdit,
  onSave,
  onCancel,
  handleOnChange,
}) => {
  const classes = useStyles();

  return (
    <ListItem className={classes.listItemWrapper}>
      <ListItemIcon>
        <MessageIcon />
      </ListItemIcon>

      <ListItemText
        primary={
          editMessage && sampleMessageName === selectedMessageName ? (
            <>
              <Grid item xs={7}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label={`${sampleMessageName} Text`}
                  name={`key`}
                  value={selectedMessageText}
                  onChange={handleOnChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={5}>
                <Button variant="contained" size="small" onClick={onCancel}>
                  Cancel
                </Button>
                &nbsp;&nbsp;
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => {
                    onSave(sampleMessageName);
                    onEdit();
                  }}
                >
                  Save
                </Button>
              </Grid>
            </>
          ) : (
            <>
              {sampleMessage}
              <Tooltip title={"Edit"}>
                <IconButton
                  className={classes.editButton}
                  onClick={() => onEdit(sampleMessageName, sampleMessage)}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </>
          )
        }
      />
    </ListItem>
  );
};

EditableList.propTypes = {
  sampleMessage: PropTypes.string,
  sampleMessageName: PropTypes.string,
  editMessage: PropTypes.bool,
  selectedMessageName: PropTypes.string,
  selectedMessageText: PropTypes.string,
  onEdit: PropTypes.func,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  handleOnChange: PropTypes.func,
};

export default function CampaignDetail() {
  const classes = useStyles();
  const { id: campaignId } = useParams();
  const [campaign, setCampaign] = useState(DEFAULT_CAMPAIGN);
  const [editMessage, setEditMessage] = useState(false);
  const [editDescription, setEditDescription] = useState(false);
  const [campaignDescription, setCampaignDescription] = useState("");
  const [selectedMessageName, setSelectedMessageName] = useState("");
  const [selectedMessageText, setSelectedMessageText] = useState("");
  const { setPageState } = usePageContext();
  const { campaigns } = VivialConnectAPI.services;

  const handleOnChange = (event) => {
    setSelectedMessageText(event.target.value || "");
  };

  const onEditMessage = (messageName, messageText) => {
    if (messageName) {
      setEditMessage(true);
      setSelectedMessageName(messageName);
      setSelectedMessageText(messageText);
    } else {
      setEditMessage(false);
      setSelectedMessageName();
      setSelectedMessageText();
    }
  };

  const onCancelMessage = () => {
    setEditMessage(false);
    setSelectedMessageName();
    setSelectedMessageText();
  };

  const onSaveMessage = (message) => {
    const payload = {
      campaign: {
        [message]: selectedMessageText,
      },
    };

    campaigns
      .editCampaign(campaignId, payload)
      .then(() => {
        let newCampaign = { ...campaign };
        newCampaign.tcr_data[message] = selectedMessageText;
        setCampaign(newCampaign);
        toast.success("Your campaign has been updated");
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const handleOnChangeEnabled = (event) => {
    const payload = { renewal: event.target.checked };

    campaigns
      .editCampaignRenewal(campaignId, payload)
      .then((newRenewalValue) => {
        let newCampaign = { ...campaign };
        newCampaign.renewal = newRenewalValue;
        setCampaign(newCampaign);
        toast.success("Your campaign renewal has been updated");
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const handleOnDescriptionChange = (event) => {
    setCampaignDescription(event.target.value || "");
  };

  const onEditDescription = (description) => {
    if (description) {
      setEditDescription(true);
      setCampaignDescription(description);
    } else {
      setEditDescription(false);
      setCampaignDescription();
    }
  };

  const onCancelDescription = () => {
    setEditDescription(false);
    setCampaignDescription();
  };

  const onSaveDescription = () => {
    const payload = {
      campaign: {
        description: campaignDescription,
      },
    };

    campaigns
      .editCampaign(campaignId, payload)
      .then((data) => {
        let newCampaign = { ...campaign };
        newCampaign.data.description = data.description;
        newCampaign.tcr_data.description = data.description;
        setCampaign(newCampaign);
        toast.success("Your campaign has been updated.");
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const safeDateDisplay = (raw_date, fmt = "yyyy-MM-dd") => {
    try {
      return format(parseISO(raw_date), fmt);
    } catch (e) {
      return "";
    }
  };

  useEffect(() => {
    setPageState({
      title: "Campaign",
      subtitle: campaignId,
    });

    campaigns
      .getCampaign(campaignId)
      .then((newCampaign) => {
        if (newCampaign) {
          setCampaign(newCampaign);
        }
      })
      .catch((error) => toast.error(error));

    return () => setPageState({});
  }, [setPageState, campaignId]);

  return (
    <Grid container spacing={5}>
      <Grid item xs={12} md={12} lg={6}>
        <DashboardCard>
          <DashboardHeader title={"Campaign Details"} />
          <DashboardCardContent
            alignItems="flex-start"
            alignContent="flex-start"
          >
            <Grid item xs={6}>
              <Typography variant="body2">Campaign ID:</Typography>
            </Grid>
            <Grid item xs={6}>
              <p>{campaign.id}</p>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Brand ID:</Typography>
            </Grid>
            <Grid item xs={6}>
              <p>{campaign.brand_id}</p>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Campaign Status:</Typography>
            </Grid>
            <Grid item xs={6}>
              <p>{campaign.status}</p>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Use Case:</Typography>
            </Grid>
            <Grid item xs={6}>
              <p>
                <TruncateText text={campaign.tcr_data.usecase} length={20} />
              </p>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Vertical:</Typography>
            </Grid>
            <Grid item xs={6}>
              <p>{campaign.tcr_data.vertical}</p>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Registered on:</Typography>
            </Grid>
            <Grid item xs={6}>
              <p>{safeDateDisplay(campaign.date_created)}</p>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Renewal:</Typography>
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <IOSSwitch
                    checked={campaign.renewal}
                    onChange={handleOnChangeEnabled}
                    name="renewal"
                  />
                }
                label={campaign.renewal ? "ON" : "OFF"}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Renewal Date:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">
                {safeDateDisplay(campaign.renewal_date)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Renewal Price:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">
                {convertNumberToCurrency(campaign.renewal_price, 2, true)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" className={classes.autoRenewLabel}>
                Billing:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" className={classes.autoRenewLabel}>
                {campaign.tcr_data.autoRenewal
                  ? "90 Day Auto Renewal"
                  : "Cancelled"}
              </Typography>
            </Grid>
          </DashboardCardContent>
        </DashboardCard>
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <DashboardCard>
          <DashboardHeader title={"Content Attributes"} />
          <DashboardCardContent
            alignItems="flex-start"
            alignContent="flex-start"
          >
            <Grid item xs={6}>
              <Typography variant="body2">Subscriber Opt-in:</Typography>
            </Grid>
            <Grid item xs={6}>
              <p>{campaign.tcr_data.subscriberOptin ? "YES" : "NO"}</p>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Subscriber Opt-Out</Typography>
            </Grid>
            <Grid item xs={6}>
              <p>{campaign.tcr_data.subscriberOptout ? "YES" : "NO"}</p>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Subscriber Help</Typography>
            </Grid>
            <Grid item xs={6}>
              <p>{campaign.tcr_data.subscriberHelp ? "YES" : "NO"}</p>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Number Pooling</Typography>
            </Grid>
            <Grid item xs={6}>
              <p>{campaign.tcr_data.numberPool ? "YES" : "NO"}</p>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">
                Direct Lending or Loan Arrangement
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <p>{campaign.tcr_data.directLending ? "YES" : "NO"}</p>
            </Grid>{" "}
            <Grid item xs={6}>
              <Typography variant="body2">Embedded Link</Typography>
            </Grid>
            <Grid item xs={6}>
              <p>{campaign.tcr_data.embeddedLink ? "YES" : "NO"}</p>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Embedded Phone Number</Typography>
            </Grid>
            <Grid item xs={6}>
              <p>{campaign.tcr_data.embeddedPhone ? "YES" : "NO"}</p>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Affiliate Marketing</Typography>
            </Grid>
            <Grid item xs={6}>
              <p>{campaign.tcr_data.affiliateMarketing ? "YES" : "NO"}</p>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Age-Gated Content</Typography>
            </Grid>
            <Grid item xs={6}>
              <p>{campaign.tcr_data.ageGated ? "YES" : "NO"}</p>
            </Grid>
          </DashboardCardContent>
        </DashboardCard>
      </Grid>
      <Grid item xs={12}>
        <DashboardCard>
          <DashboardHeader title={"Campaign Description"} />
          <DashboardCardContent>
            <Grid
              item
              xs={12}
              className={`${classes.listItemWrapper}  ${classes.descriptionWrapper}`}
            >
              {editDescription ? (
                <>
                  <Grid item xs={7}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Description"
                      name="description"
                      value={campaignDescription}
                      onChange={handleOnDescriptionChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    &nbsp;&nbsp;
                    <Button
                      variant="contained"
                      size="small"
                      onClick={onCancelDescription}
                    >
                      Cancel
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => {
                        onSaveDescription();
                        onEditDescription();
                      }}
                    >
                      Save
                    </Button>
                  </Grid>
                </>
              ) : (
                <>
                  <Typography variant="body1">
                    {campaign.tcr_data.description}
                  </Typography>
                  <Tooltip title={"Edit"}>
                    <IconButton
                      className={classes.editButton}
                      onClick={() =>
                        onEditDescription(campaign.tcr_data.description)
                      }
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </Grid>
          </DashboardCardContent>
        </DashboardCard>
      </Grid>
      <Grid item xs={12}>
        <DashboardCard>
          <DashboardHeader title={"Sample Messages"} />
          <DashboardCardContent>
            <Grid item xs={12}>
              <List>
                <EditableList
                  sampleMessage={campaign.tcr_data.sample1}
                  sampleMessageName={"sample1"}
                  selectedMessageName={selectedMessageName}
                  selectedMessageText={selectedMessageText}
                  editMessage={editMessage}
                  onEdit={onEditMessage}
                  onSave={onSaveMessage}
                  onCancel={onCancelMessage}
                  handleOnChange={handleOnChange}
                />
                <EditableList
                  sampleMessage={campaign.tcr_data.sample2}
                  sampleMessageName={"sample2"}
                  selectedMessageName={selectedMessageName}
                  selectedMessageText={selectedMessageText}
                  editMessage={editMessage}
                  onEdit={onEditMessage}
                  onSave={onSaveMessage}
                  onCancel={onCancelMessage}
                  handleOnChange={handleOnChange}
                />
                <EditableList
                  sampleMessage={campaign.tcr_data.sample3}
                  sampleMessageName={"sample3"}
                  selectedMessageName={selectedMessageName}
                  selectedMessageText={selectedMessageText}
                  editMessage={editMessage}
                  onEdit={onEditMessage}
                  onSave={onSaveMessage}
                  onCancel={onCancelMessage}
                  handleOnChange={handleOnChange}
                />
                <EditableList
                  sampleMessage={campaign.tcr_data.sample4}
                  sampleMessageName={"sample4"}
                  selectedMessageName={selectedMessageName}
                  selectedMessageText={selectedMessageText}
                  editMessage={editMessage}
                  onEdit={onEditMessage}
                  onSave={onSaveMessage}
                  onCancel={onCancelMessage}
                  handleOnChange={handleOnChange}
                />
                <EditableList
                  sampleMessage={campaign.tcr_data.sample5}
                  sampleMessageName={"sample5"}
                  selectedMessageName={selectedMessageName}
                  selectedMessageText={selectedMessageText}
                  editMessage={editMessage}
                  onEdit={onEditMessage}
                  onSave={onSaveMessage}
                  onCancel={onCancelMessage}
                  handleOnChange={handleOnChange}
                />
              </List>
            </Grid>
          </DashboardCardContent>
        </DashboardCard>
      </Grid>
    </Grid>
  );
}
