import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";

import VivialConnectAPI from "api/vivial-connect";
import { useAppContext } from "components/Context";

export default function Logout() {
  const { clearContexts } = useAppContext();
  const [redirectToLogin, setRedirectToLogin] = useState(false);

  useEffect(() => {
    const { auth } = VivialConnectAPI.services;

    auth
      .logout()
      .then(() => {
        clearContexts();
      })
      .catch((error) => toast.error(error))
      .finally(() => setRedirectToLogin(true));
  }, [clearContexts]);

  return redirectToLogin ? <Redirect to="/login" /> : null;
}
