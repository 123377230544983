/* eslint-disable no-empty */
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import VivialConnectAPI from "api/vivial-connect";

import useStyles from "./styles";

const CashPriceTable = () => {
  const classes = useStyles();
  const { convertNumberToCurrency } = VivialConnectAPI.helpers.numbers;
  const [longcodePrices, setLongcodePrices] = useState({});
  const [rowsMessagingTable, setRowsMessagingTable] = useState([]);
  const [rowsNumberTable, setRowsNumberTable] = useState([]);

  function displayPrice(price) {
    if (price === 0) return "FREE";
    return convertNumberToCurrency(price, 3);
  }

  useEffect(() => {
    const { lookups } = VivialConnectAPI.services;
    // Get Longcode List Prices
    lookups
      .getLookupsPrices({
        country_codes: "US",
      })
      .then((res) => {
        if (
          "price_lookups" in res &&
          res.price_lookups.length &&
          setLongcodePrices(res.price_lookups[0])
        ) {
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  }, []);

  useEffect(() => {
    if (!longcodePrices || !longcodePrices.cash_prices) return;

    let newRowsMessagingTable = [];
    newRowsMessagingTable.push({
      name: "SMS: Local",
      inbound_price: longcodePrices.cash_prices.sms_local_in,
      outbound_price: longcodePrices.cash_prices.sms_local_out,
    });
    newRowsMessagingTable.push({
      name: "MMS: Local",
      inbound_price: longcodePrices.cash_prices.mms_local_in,
      outbound_price: longcodePrices.cash_prices.mms_local_out,
    });
    newRowsMessagingTable.push({
      name: "SMS: Toll-Free",
      inbound_price: longcodePrices.cash_prices.sms_tollfree_in,
      outbound_price: longcodePrices.cash_prices.sms_tollfree_out,
    });
    newRowsMessagingTable.push({
      name: "MMS: Toll-Free",
      inbound_price: longcodePrices.cash_prices.mms_tollfree_in,
      outbound_price: longcodePrices.cash_prices.mms_tollfree_out,
    });
    setRowsMessagingTable(newRowsMessagingTable);

    let newRowsNumberTable = [];
    newRowsNumberTable.push({
      name: "Local Number",
      price: longcodePrices.cash_prices.number_purchase,
    });
    newRowsNumberTable.push({
      name: "Toll-Free Number",
      price: longcodePrices.cash_prices.number_purchase_tollfree,
    });
    newRowsNumberTable.push({
      name: "Call Forwarding",
      price: longcodePrices.cash_prices.voice_forward,
    });
    setRowsNumberTable(newRowsNumberTable);
  }, [longcodePrices]);

  return (
    <>
      <TableContainer>
        <Table className={classes.table} aria-label="simple table">
          <TableHead className={classes.thead}>
            <TableRow>
              <TableCell>
                <Typography variant="h6" color="primary">
                  Messaging
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="h6" color="primary">
                  Inbound
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="h6" color="primary">
                  Outbound
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsMessagingTable.map((row, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  <span className="body-title">{row.name}</span>
                </TableCell>
                <TableCell align="right">
                  {displayPrice(row.inbound_price)}
                </TableCell>
                <TableCell align="right">
                  {displayPrice(row.outbound_price)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer>
        <Table className={classes.table} aria-label="simple table">
          <TableHead className={classes.thead}>
            <TableRow>
              <TableCell>
                <Typography variant="h6" color="primary">
                  Numbers
                </Typography>
              </TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsNumberTable.map((row, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  <span className="body-title">{row.name}</span>
                </TableCell>
                <TableCell align="right">
                  {/Number/.test(row.name)
                    ? `${convertNumberToCurrency(row.price)}/month`
                    : `${displayPrice(row.price)}/minute`}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CashPriceTable;
