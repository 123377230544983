// users/endpoints.js
import { factory } from "vivialconnect-js-core";

//NOTE: do not use spread syntax here.
//helper == {init, helper.BASE_URL}
const helper = factory.initEndpoints();

export default {
  init: helper.init,
  users: (id) => `${helper.BASE_URL}/accounts/${id}/users.json`,
  user: (id, userId) =>
    `${helper.BASE_URL}/accounts/${id}/users/${userId}.json`,
  usersMultiple: (id, userIds) =>
    `${helper.BASE_URL}/accounts/${id}/users/${userIds}/multiple.json`,
  usersCount: (id) => `${helper.BASE_URL}/accounts/${id}/users/count.json`,
  userRegister: (id) => `${helper.BASE_URL}/accounts/${id}/users/register.json`,
  userProfileRoles: (id, userId) =>
    `${helper.BASE_URL}/accounts/${id}/users/${userId}/profile/roles.json`,
  userProfileCredentials: (id, userId) =>
    `${helper.BASE_URL}/accounts/${id}/users/${userId}/profile/credentials.json`,
  userProfileCredentialsCount: (id, userId) =>
    `${helper.BASE_URL}/accounts/${id}/users/${userId}/profile/credentials/count.json`,
  userProfileCredential: (id, userId, credentialId) =>
    `${helper.BASE_URL}/accounts/${id}/users/${userId}/profile/credentials/${credentialId}.json`,
  userProfilePassword: (id, userId) =>
    `${helper.BASE_URL}/accounts/${id}/users/${userId}/profile/password.json`,
  userProfileBasic: (id, userId) =>
    `${helper.BASE_URL}/accounts/${id}/users/${userId}/profile/basic.json`,
  userProfilePasswordVerify: (id, userId) =>
    `${helper.BASE_URL}/accounts/${id}/users/${userId}/profile/password/verify.json`,
  userVerificationToken: (id, userId) =>
    `${helper.BASE_URL}/accounts/${id}/users/${userId}/verificationtoken.json`,
  userOauthPersonalTokens: (id, userId) =>
    `${helper.BASE_URL}/accounts/${id}/users/${userId}/oauth/personal_tokens.json`,
  userOauthPersonalToken: (id, userId, personalTokenId) =>
    `${helper.BASE_URL}/accounts/${id}/users/${userId}/oauth/personal_tokens/${personalTokenId}.json`,
  userOauthPersonalTokensCount: (id, userId) =>
    `${helper.BASE_URL}/accounts/${id}/users/${userId}/oauth/personal_tokens/count.json`,
};
