import endpoints from "./endpoints";
import { factory } from "vivialconnect-js-core";

//NOTE: do not use spread syntax here.
//serviceHelpers == {init, http, appSessionStore}
export const serviceHelpers = factory.initService(endpoints);
const getBillingOptions = async (params, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.get(
      endpoints.billingOptions(id ? id : appSessionStore.getAccountId(), {
        params,
      })
    );
    return data.options;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const putBillingOptions = async (payload, params, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.put(
      endpoints.billingOptions(id ? id : appSessionStore.getAccountId()),
      payload,
      { params }
    );
    return data.options.account_state;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

export default {
  init: serviceHelpers.init,
  getBillingOptions,
  putBillingOptions,
};
