/* eslint-disable react/prop-types */
import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { GroupAddIcon } from "components/VivialIcons";

import {
  Card as DashboardCard,
  CardContent as DashboardCardContent,
} from "components/Layouts/Dashboard/Card";

import useStyles from "./styles";

export default function UsersBanner({
  showManageUserButton,
  showAddUserButton,
  handleShowAddUserForm,
  handleShowDefault,
}) {
  const classes = useStyles();

  return (
    <DashboardCard>
      <DashboardCardContent>
        <div className={classes.userBannerWrapper}>
          <Grid item xs={3}>
            <Typography variant="h5" className={classes.userBannerHeader}>
              Users
            </Typography>
          </Grid>
          <Grid item xs={9} className={classes.addUserButtonWrapper}>
            {showManageUserButton ? (
              <Button
                color="primary"
                variant="outlined"
                startIcon={<ArrowBackIcon />}
                onClick={handleShowDefault}
              >
                Manage Users
              </Button>
            ) : null}
            {showAddUserButton ? (
              <Box ml={2}>
                <Button
                  color="primary"
                  variant="outlined"
                  startIcon={<GroupAddIcon />}
                  onClick={handleShowAddUserForm}
                >
                  Add User
                </Button>
              </Box>
            ) : null}
          </Grid>
        </div>
      </DashboardCardContent>
    </DashboardCard>
  );
}
