export const darkTheme = {
  root: {
    marginTop: 15,
    textDecoration: "none",
  },
};

export const brochureTheme = {
  underlineHover: {
    textDecoration: "underline",
  },
};

export default {
  brochureTheme,
};
