import React from "react";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import makeStyles from "@material-ui/core/styles/makeStyles";

import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import logoImage from "assets/img/vivial_connect_logo.png";

const useStyles = makeStyles((theme) => ({
  logoContainer: {
    backgroundColor: theme.palette.background.default,
    paddingBottom: "45px",
  },
  logoImage: {
    maxWidth: "254px",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "254px",
    },
  },
  socialContainer: {
    paddingTop: "5px",
  },
  socialIcon: {
    color: theme.palette.text.primary,
  },
}));

export default function SocialContainer() {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={classes.logoContainer}
    >
      <Grid item xs={6}>
        <Link href="/" className={classes.logoImage}>
          <img
            src={logoImage}
            className={classes.logoImage}
            alt="Vivial Connect Logo"
          />
        </Link>
      </Grid>

      <Grid
        item
        container
        xs={6}
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={1}
        className={classes.socialContainer}
      >
        <Grid item>
          <a
            href="https://www.facebook.com/vivialconnect/"
            className={classes.logoImage}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookIcon className={classes.socialIcon} />
          </a>
        </Grid>
        <Grid item>
          <a
            href="https://twitter.com/vivialconnect"
            className={classes.logoImage}
            target="_blank"
            rel="noopener noreferrer"
          >
            <TwitterIcon className={classes.socialIcon} />
          </a>
        </Grid>
        <Grid item>
          <a
            href="https://www.linkedin.com/company/vivial-connect"
            className={classes.logoImage}
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedInIcon className={classes.socialIcon} />
          </a>
        </Grid>
      </Grid>
    </Grid>
  );
}
