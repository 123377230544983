import { alpha } from "@material-ui/core/styles";
import {
  // fontSizeP,
  // fontSizeH5,
  // fontSizeH6,
  fontPrimaryPublic,
} from "../typography";
import {
  colorPrimaryWhite,
  colorPrimaryVivialGreen,
  colorBrochureGray,
  colorExtendedGray2,
} from "../palette";

export const darkTheme = {
  contained: {
    fontSize: "1.4444rem",
    fontWeight: 700,
  },
  containedPrimary: {
    color: colorPrimaryWhite,
  },
  containedSizeLarge: {
    fontSize: "1.0625rem",
    padding: "8px 24px 8px 24px",
  },
};

export const lightTheme = {
  sizeSmall: {
    lineHeight: 1.5,
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  contained: {
    color: colorPrimaryWhite,
    backgroundColor: colorExtendedGray2,
    fontSize: "1.1111rem",
    fontWeight: 600,
  },
  containedSecondary: {
    color: colorPrimaryWhite,
  },
};

export const brochureTheme = {
  root: {
    fontSize: "1rem",
    paddingTop: "8px",
    paddingBottom: "4px",
    borderRadius: "25px",
    textTransform: "none",
    textDecoration: "none",
    "& .MuiButton-endIcon": {
      position: "relative",
      top: "-2px",
    },
  },
  sizeSmall: {
    fontSize: "0.8888rem",
    padding: "2px 12px 0px 15px",
    borderRadius: "15px",
    "& .MuiButton-endIcon": {
      position: "relative",
      top: "-1px",
    },
  },
  outlined: {
    paddingTop: "7px",
    paddingBottom: "3px",
    "& .MuiButton-label, & .MuiButton-endIcon": {
      transition: "color 250ms",
    },
  },
  outlinedPrimary: {
    color: colorBrochureGray,
    "& .MuiButton-endIcon": {
      color: colorPrimaryVivialGreen,
    },
    "&:hover": {
      "& .MuiButton-label, & .MuiButton-endIcon": {
        color: colorPrimaryWhite,
      },
    },
  },
  outlinedSecondary: {
    color: colorPrimaryWhite,
    border: `1px solid ${alpha(colorPrimaryVivialGreen, 0.5)}`,
    "& .MuiButton-endIcon": {
      color: colorPrimaryVivialGreen,
    },
    "&:hover": {
      border: `1px solid ${colorPrimaryVivialGreen}`,
      backgroundColor: colorPrimaryVivialGreen,
      "& .MuiButton-endIcon": {
        color: colorPrimaryWhite,
      },
    },
  },
  containedPrimary: {
    color: colorPrimaryWhite,
    border: "1px solid transparent",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      color: colorPrimaryVivialGreen,
      borderColor: colorPrimaryVivialGreen,
      "background-color": colorPrimaryWhite,
      "& .MuiButton-endIcon": {
        color: colorPrimaryVivialGreen,
      },
    },
  },
  label: {
    fontFamily: fontPrimaryPublic,
  },
};

export default {
  darkTheme,
  lightTheme,
  brochureTheme,
};
