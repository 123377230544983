import React from "react";
import { NavLink } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";

import { PhoneIcon, GroupAddIcon } from "components/VivialIcons";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";

import {
  Card as DashboardCard,
  CardHeader as DashboardHeader,
  CardContent as DashboardCardContent,
} from "components/Layouts/Dashboard/Card";

import pigImg from "assets/img/logo_pig.png";

import useStyles from "./styles";

export const PhoneNumbers = () => {
  const classes = useStyles();
  return (
    <DashboardCard>
      <DashboardHeader icon={PhoneIcon} title={"Phone Numbers"} />
      <DashboardCardContent alignContent="flex-start">
        <Grid item xs={12}>
          Purchase local &amp; toll free numbers to get started with messaging
        </Grid>
        <Grid
          item
          xs={12}
          component={NavLink}
          to="/dashboard/numbers/purchase"
          className={`${classes.link} ${classes.text}`}
        >
          Buy Numbers Now
        </Grid>
      </DashboardCardContent>
    </DashboardCard>
  );
};

export const MessagesAd = () => {
  const classes = useStyles();
  return (
    <DashboardCard color="dark">
      <DashboardHeader
        icon={AttachMoneyIcon}
        title={"Need to send more messages?"}
      />
      <DashboardCardContent alignContent="flex-start">
        <Grid item lg={12} xl={8}>
          <div className={classes.messagesAdContent}>
            <Typography variant="body1" paragraph={true}>
              Upgrade your subscription plan and get 20% off!
            </Typography>
            <NavLink to="/dashboard/upgrade" className={classes.link}>
              Click here to upgrade!
            </NavLink>
          </div>
        </Grid>
        <Hidden lgDown>
          <Grid item xl={4}>
            <img src={pigImg} alt="Upgrade Now!" />
          </Grid>
        </Hidden>
      </DashboardCardContent>
    </DashboardCard>
  );
};

export const ReferralAd = () => {
  const classes = useStyles();
  return (
    <DashboardCard>
      <DashboardHeader
        icon={GroupAddIcon}
        title={"Interested in Becoming a Partner?"}
      />
      <DashboardCardContent alignContent="flex-start">
        <Grid item xs={12}>
          Earn Cash with Vivial Connect&apos;s Referral Partner Program
        </Grid>
        <Grid
          item
          xs={12}
          component={NavLink}
          to="/partners"
          target="_blank"
          className={`${classes.link} ${classes.text}`}
        >
          Click here to learn more!
        </Grid>
      </DashboardCardContent>
    </DashboardCard>
  );
};

export default {
  PhoneNumbers,
  MessagesAd,
  ReferralAd,
};
