import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  Card as DashboardCard,
  CardHeader as DashboardHeader,
  CardContent as DashboardCardContent,
} from "components/Layouts/Dashboard/Card";

import useStyles from "./styles";
import VivialConnectAPI from "api/vivial-connect";

export default function BillingAddressForm({
  title,
  billingContact,
  handleOnChangeBillingContact,
  onSubmit,
  children,
  loading,
  setOpen,
}) {
  const classes = useStyles();
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const { address1, postal_code, state, city, first_name, last_name, country } =
    billingContact;
  const { lookups } = VivialConnectAPI.services;

  useEffect(() => {
    lookups
      .getLookups("country")
      .then((res) => {
        const { country_lookup } = res;
        setCountries(country_lookup);
      })
      .catch((error) => toast.error(error));
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      lookups
        .getLookups("state")
        .then((res) => {
          const { state_lookup } = res;

          const countryCode = countries.filter(
            (country) => country.name === billingContact.country
          )[0].code;

          setStates(state_lookup.filter((e) => e.group_code === countryCode));
        })
        .catch((error) => toast.error(error));
    }, 400);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [country, countries]);

  return (
    <DashboardCard>
      <DashboardHeader title={title} />
      <DashboardCardContent>
        <form onSubmit={onSubmit}>
          <Grid container spacing={3}>
            {children}
            <Grid item xs={12}>
              <Typography
                variant="body1"
                className={classes.addCreditCardLabel}
              >
                <span className="body-title">Billing Address</span>
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <TextField
                      variant="outlined"
                      label="First Name"
                      name="first_name"
                      value={first_name}
                      onChange={handleOnChangeBillingContact}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <TextField
                      variant="outlined"
                      label="Last Name"
                      name="last_name"
                      value={last_name}
                      onChange={handleOnChangeBillingContact}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      variant="outlined"
                      label="Address"
                      name="address1"
                      value={address1}
                      onChange={handleOnChangeBillingContact}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <TextField
                      variant="outlined"
                      label="City"
                      name="city"
                      value={city}
                      onChange={handleOnChangeBillingContact}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <TextField
                      select={states.length !== 0}
                      variant="outlined"
                      label="State / Province"
                      name="state"
                      value={state}
                      onChange={handleOnChangeBillingContact}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      <MenuItem value="">
                        <em>Please select a state</em>
                      </MenuItem>
                      {states.map((state, indx) => (
                        <MenuItem value={state.code} key={indx}>
                          {state.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <TextField
                      required
                      select
                      variant="outlined"
                      label="Country"
                      name="country"
                      value={country}
                      onChange={handleOnChangeBillingContact}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      {countries.map((country, indx) => (
                        <MenuItem value={country.name} key={indx}>
                          {country.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <TextField
                      required
                      variant="outlined"
                      label="Zip / Postal Code"
                      name="postal_code"
                      value={postal_code}
                      onChange={handleOnChangeBillingContact}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.button}>
                <Box ml={3}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={loading}
                  >
                    Save
                    {loading && (
                      <CircularProgress
                        size={24}
                        color="primary"
                        className={classes.spinner}
                        disableShrink
                      />
                    )}
                  </Button>
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    onClick={() => setOpen("payments")}
                  >
                    Cancel
                  </Button>
                </Box>
              </div>
            </Grid>
          </Grid>
        </form>
      </DashboardCardContent>
    </DashboardCard>
  );
}

BillingAddressForm.propTypes = {
  title: PropTypes.string,
  billingContact: PropTypes.object,
  handleOnChangeBillingContact: PropTypes.func,
  onSubmit: PropTypes.func,
  setOpen: PropTypes.func,
  loading: PropTypes.bool,
  children: PropTypes.node,
};
