/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useParams, NavLink } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

import {
  RegistrationCard,
  RegistrationCardContent,
} from "components/Layouts/Registration/Card";
import VivialConnectAPI from "api/vivial-connect";

import { useStyles } from "./styles";

export default function Confirm({ location }) {
  const classes = useStyles();
  const { id, token } = useParams();

  const [email, setEmail] = useState("");
  const [confirmed, setConfirmed] = useState(null);
  const [error, setError] = useState("");
  const { validateEmail } = VivialConnectAPI.helpers.validation;

  const resendEmail = async () => {
    if (validateForm()) return;
    const { registration } = VivialConnectAPI.services;
    const data = JSON.stringify({
      email: email,
    });

    await registration
      .postResendEmail(data)
      .then(() => toast.success("Thanks! We'll resend your registration link."))
      .catch((error) => toast.error(error));
  };

  const validateForm = () => {
    let errorFound = false;

    if (!validateEmail(email)) {
      setError("Please enter a valid email");
      errorFound = true;
    } else {
      setError("");
    }
    return errorFound;
  };

  useEffect(() => {
    const { registration } = VivialConnectAPI.services;
    const confirmationMethod = location.pathname.includes("invite")
      ? registration.getInvitationConfirm
      : registration.getConfirm;

    confirmationMethod(id, token)
      .then((isConfirmed) => setConfirmed(isConfirmed))
      .catch(() => setConfirmed(false));
  }, [id, token, location.pathname]);

  if (confirmed) {
    return (
      <RegistrationCard>
        <RegistrationCardContent>
          <div className={classes.form}>
            <Container className={classes.sendEmailContainer}>
              <Typography variant="h2">Welcome to Vivial Connect!</Typography>

              <Typography variant="body1">
                Your email address has been verified. Please login to complete
                your account setup.
              </Typography>

              <NavLink to="/login">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.submit}
                >
                  Log in
                </Button>
              </NavLink>
            </Container>
          </div>
        </RegistrationCardContent>
      </RegistrationCard>
    );
  } else if (confirmed === false) {
    return (
      <RegistrationCard>
        <RegistrationCardContent>
          <div className={classes.form}>
            <Container className={classes.sendEmailContainer}>
              <Typography variant="h2">
                Whoops! Invalid Email Verification
              </Typography>

              <Typography variant="body1">
                The verify link has either been used or has expired. Try{" "}
                <Link component={NavLink} to="/login" className={classes.link}>
                  logging in
                </Link>
                . If that doesn&apos;t work, submit your email address and
                we&apos;ll send you another link.
              </Typography>

              <form
                className={classes.confirmForm}
                onSubmit={(event) => {
                  event.preventDefault();
                  resendEmail();
                }}
                autoComplete="off"
              >
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    error={error !== ""}
                    helperText={error}
                  />
                </Grid>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.submit}
                >
                  Verify
                </Button>
              </form>

              <Typography variant="body1">
                Need help? Email us at{" "}
                <Link
                  href="mailto:developer@support.vivialconnect.net"
                  component="a"
                >
                  developer@support.vivialconnect.net
                </Link>
                .
              </Typography>
            </Container>
          </div>
        </RegistrationCardContent>
      </RegistrationCard>
    );
  } else {
    return null;
  }
}
