import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Grid from "@material-ui/core/Grid";
import TokensBanner from "./TokensBanner";
import TokensTable from "./TokensTable";

import VivialConnectAPI from "api/vivial-connect";
import { useAppContext } from "components/Context";
import withPageContext from "components/Context/WithPageContext";

const CSVHeaders = [
  "Account ID",
  "API URL",
  "Access Token",
  "Token Description",
  "Scope",
];
export default withPageContext(OAuthTokens, {
  title: "Account",
  subtitle: "Personal Access Tokens",
});

function OAuthTokens() {
  const { appState } = useAppContext();
  const [openDialog, setOpenDialog] = useState(false);
  const [tokens, setTokens] = useState([]);
  const [tokensCount, setTokensCount] = useState([]);
  const [selectedToken, setSelectedToken] = useState({});
  const [description, setDescription] = useState("");
  const [scopeName, setScopeName] = useState(["basic"]);
  const [scopes, setScopes] = useState([]);
  const [tokenCreated, setTokenCreated] = useState(false);
  const [csvData, setCsvData] = useState([CSVHeaders]);
  const API_URL = process.env.REACT_APP_BACKEND_API_URL;
  const [copyRow, setCopyRow] = useState("");

  const handleOnChange = (event) => {
    setDescription(event.target.value || "");
  };

  const handleChangeScope = (event) => {
    setScopeName(event.target.value);
  };

  const onSave = (token) => {
    const { oauth } = VivialConnectAPI.services;

    const payload = {
      description: description,
    };

    if (description !== token.description) {
      oauth
        .putToken(token.id, payload, appState.user_id)
        .then(() => {
          handleCloseDialog();
          oauth
            .getTokens(appState.user_id)
            .then((newTokens) => setTokens(newTokens))
            .catch((error) => toast.error(error));
        })
        .catch((error) => toast.error(error));
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setTokenCreated(false);
    setSelectedToken({});
    setDescription("");
    setScopeName(["basic"]);
    setCsvData([CSVHeaders]);
  };

  const handleShowDialog = () => {
    setOpenDialog(true);
    setDescription(`Personal Token ${tokensCount + 1}`);
  };

  const copyToClipboard = (event, token) => {
    setCopyRow(token);
    navigator.clipboard.writeText(token);
  };

  const handleAddNewToken = () => {
    const { oauth } = VivialConnectAPI.services;
    if (tokens.length === 10)
      return toast.error(
        "An individual user can only have up to 10 Tokens. Please delete an existing token before creating a new one."
      );

    const payload = {
      scopes: scopeName,
      description: description,
    };

    oauth.postToken(payload, appState.user_id).then((newToken) => {
      setSelectedToken(newToken);
      setTokenCreated(true);
      let data = csvData;
      data.push([
        appState.id,
        API_URL,
        newToken.access_token,
        newToken.description,
        newToken.scope,
      ]);
      setCsvData(data);

      oauth
        .getTokens(appState.user_id)
        .then((newTokens) => {
          setTokens(newTokens);
        })
        .catch((error) => toast.error(error));

      oauth
        .getTokensCount(appState.user_id)
        .then((count) => {
          setTokensCount(count);
        })
        .catch((error) => toast.error(error));
    });
  };

  useEffect(() => {
    const { oauth } = VivialConnectAPI.services;

    oauth
      .getTokens(appState.user_id)
      .then((newTokens) => {
        setTokens(newTokens);
      })
      .catch((error) => toast.error(error));

    oauth
      .getTokensCount(appState.user_id)
      .then((count) => {
        setTokensCount(count);
      })
      .catch((error) => toast.error(error));

    oauth
      .getScopes()
      .then((newScopes) => setScopes(newScopes))
      .catch((error) => toast.error(error));
  }, [appState.user_id]);

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <TokensBanner
          onClick={handleAddNewToken}
          openDialog={openDialog}
          handleShowDialog={handleShowDialog}
          handleCloseDialog={handleCloseDialog}
          token={selectedToken}
          apiUrl={API_URL}
          csvData={csvData}
          description={description}
          handleOnChange={handleOnChange}
          scopeName={scopeName}
          scopes={scopes}
          handleChangeScope={handleChangeScope}
          tokenCreated={tokenCreated}
          copyToClipboard={copyToClipboard}
        />
      </Grid>
      <Grid item xs={12}>
        <TokensTable
          tokens={tokens}
          setTokens={setTokens}
          description={description}
          setDescription={setDescription}
          handleOnChange={handleOnChange}
          onSave={onSave}
          copyRow={copyRow}
          copyToClipboard={copyToClipboard}
        />
      </Grid>
    </Grid>
  );
}
