/* eslint-disable react/prop-types */
import React, { useState } from "react";

import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";

import ReCAPTCHA from "react-google-recaptcha";

import VivialConnectAPI from "api/vivial-connect";
import config from "config/app";

import BrochureButton from "components/Layouts/Brochure/BrochureButton";

import { toast } from "react-toastify";

const { validatePhone, validateEmail } = VivialConnectAPI.helpers.validation;

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root, & .MuiFormControl-root": {
      marginBottom: theme.spacing(8),
    },
    "& .MuiFormLabel-root": {
      fontWeight: 700,
      fontStype: "normal",
      color: theme.palette.text.primary,
    },
    "& .MuiFormLabel-asterisk": {
      color: theme.palette.error.main,
    },
  },
}));

export default function ContactForm() {
  const classes = useStyles();

  /****************************************************************************
   * FORM DATA CONTROLLER
   ****************************************************************************/
  const reasonOptions = ["Sales", "General Inquiries", "International SMS"];
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    company_name: "",
    email: "",
    mobile_phone: "",
    reason_for_contact: "",
    comment: "",
  });
  const handleChange = (prop) => (event) => {
    setFormData({ ...formData, [prop]: event.target.value });
  };

  /****************************************************************************
   * ReCAPTCHA CONTROLLER
   ****************************************************************************/
  const recaptchaRef = React.createRef();
  const handleRecaptchaChange = (value) => {
    recaptchaRef.current.value = value;
  };

  /****************************************************************************
   * FORM ERROR CONTROLLER
   ****************************************************************************/
  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    company_name: "",
    email: "",
    mobile_phone: "",
    reason_for_contact: "",
    comment: "",
    reCaptcha: "",
  });
  const validateForm = () => {
    let errorFound = false;

    if (!validateEmail(formData.email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Please enter a valid email",
      }));
      errorFound = true;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, email: "" }));
    }

    if (formData.mobile_phone !== "" && !validatePhone(formData.mobile_phone)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        mobile_phone: "Please enter a valid phone number",
      }));
      errorFound = true;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, mobile_phone: "" }));
    }

    if (formData.reason_for_contact === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        reason_for_contact: "Please select a contact reason",
      }));
      errorFound = true;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, reason_for_contact: "" }));
    }

    if (recaptchaRef.current.value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        reCaptcha: "",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        reCaptcha: "Please solve the reCaptcha",
      }));
      errorFound = true;
    }

    return errorFound;
  };

  /****************************************************************************
   * FORM SUBMIT HANDLER
   ****************************************************************************/
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);
  const handleSubmit = async () => {
    if (validateForm()) {
      window.location.href = "#form";
    } else {
      const { mailings } = VivialConnectAPI.services;
      const payload = {
        ...formData,
        "g-recaptcha-response": recaptchaRef.current.value,
      };

      mailings
        .postContactSignup(payload)
        .then(() => {
          setIsSubmitSuccessful(true);
        })
        .catch((error) => {
          toast.error(error);
        });
    }

    recaptchaRef.current.reset();
  };

  const ContactTextField = ({ name, label, ...rest }) => {
    if (!name || !label) return null;

    return (
      <TextField
        name={name}
        id={name}
        label={label}
        autoComplete={name}
        value={formData[name]}
        onChange={handleChange(name)}
        error={errors[name] !== ""}
        helperText={errors[name]}
        required
        fullWidth
        {...rest}
      />
    );
  };

  return isSubmitSuccessful ? (
    <Typography className="center">
      Thanks for contacting us! We will get in touch with you shortly.
    </Typography>
  ) : (
    <form
      className={classes.root}
      onSubmit={(event) => {
        event.preventDefault();
        handleSubmit();
      }}
      autoComplete="off"
    >
      <a name="form" href="#form" className={classes.anchor}>
        {" "}
      </a>

      {ContactTextField({
        name: "first_name",
        label: "First Name",
      })}
      {ContactTextField({
        name: "last_name",
        label: "Last Name",
      })}
      {ContactTextField({
        name: "company_name",
        label: "Company Name",
      })}
      {ContactTextField({
        name: "email",
        label: "Email",
      })}
      {ContactTextField({
        name: "mobile_phone",
        label: "Mobile Phone",
      })}

      <ContactTextField
        name="reason_for_contact"
        label="Reason for Contact"
        select
      >
        {reasonOptions.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </ContactTextField>

      {ContactTextField({
        name: "comment",
        label: "Comments",
        variant: "outlined",
        multiline: true,
        rows: 4,
      })}

      <div>
        <FormControl
          component="fieldset"
          required
          error={errors.reCaptcha !== ""}
        >
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={config.RECAPTCHA_SITE_KEY}
            onChange={handleRecaptchaChange}
          />
          <FormHelperText>{errors.reCaptcha}</FormHelperText>
        </FormControl>
      </div>

      <BrochureButton type="submit">Submit</BrochureButton>
    </form>
  );
}
