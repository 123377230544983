import React from "react";

import {
  GetContentfulPage,
  GetAdditionalContent,
  getItemContent,
} from "api/contentful/helper";

import HTMLHead from "components/HTMLHead";

import Hero from "components/Layouts/Brochure/Hero";
import Section from "components/Layouts/Brochure/Section";
import PreFooter from "components/Layouts/Brochure/Footer/PreFooter";

import HomeItem from "./HomeItem";
import HomeFeatureList from "./HomeFeatureList";

export default function Home() {
  const [page, setPage] = React.useState(null);
  const [heroContent, setHeroContent] = React.useState({});
  const [homeItemContent, setHomeItemContent] = React.useState({});
  const [homeFeatureList, setHomeFeatureList] = React.useState({});
  const [homeItem2Content, setHomeItem2Content] = React.useState({});

  //Get page from contentful on load
  React.useEffect(() => {
    GetContentfulPage("home", setPage);
  }, []);

  //Extract content from contentful content model
  React.useEffect(() => {
    if (!page) return;
    const { fields } = page;

    setHeroContent({
      imageURL: fields.heroImg.fields.file.url,
      header1: fields.heroHdr1,
      header2: fields.heroHdr2,
      button: fields.heroBtn,
      fullHeight: true,
    });

    getItemContent(page, "home_item", setHomeItemContent);
    getItemContent(page, "home_item_2", setHomeItem2Content);
    GetAdditionalContent(
      page,
      "itemList",
      "home_feature_list",
      setHomeFeatureList
    );
  }, [page]);

  if (!page) return null;

  return (
    <>
      <HTMLHead page={page} />

      <Hero {...heroContent} />

      <Section bg="dark">
        <HomeItem {...homeItemContent} />
      </Section>

      <Section>
        <HomeFeatureList {...homeFeatureList} />
      </Section>

      <Section bg="dark">
        <HomeItem direction="row-reverse" {...homeItem2Content} />
      </Section>

      <PreFooter />
    </>
  );
}
