import { makeStyles } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
import { colorExtendedGray1, colorExtendedBlue } from "themes/palette";

export const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
    marginTop: theme.spacing(4),
  },
  button: {
    marginTop: theme.spacing(0.5),
  },
  warningMessage: {
    color: red["A700"],
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  autoRenewLabel: {
    marginTop: theme.spacing(1),
  },
  editButton: {
    visibility: "hidden",
    paddingTop: theme.spacing(0.75),
  },
  listItemWrapper: {
    "&:hover": {
      backgroundColor: colorExtendedGray1,
      "& button": {
        visibility: "visible",
      },
    },
  },
  descriptionWrapper: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  rowWrapper: {
    "&:hover": {
      "& td, & th": {
        cursor: "pointer",
        backgroundColor: "rgba(0,0,0,0.2)",
      },
    },
  },
  orderedList: {
    listStylePosition: "inside",
    listStyleType: "none",
    margin: 0,
    padding: 0,
    color: colorExtendedBlue,
    marginBottom: "1em",
    "& li": {
      marginBottom: "5px",
    },
  },
}));
