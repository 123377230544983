import { makeStyles } from "@material-ui/core";
import { fontPrimaryTitleSubheading } from "themes/typography";
import {
  colorExtendedGreen4,
  colorExtendedGray1,
  colorExtendedGray2,
  colorExtendedGray4,
} from "themes/palette";

export const useStyles = makeStyles((theme) => ({
  permanentDrawerRoot: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  temporaryDrawerRoot: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: "300px",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: colorExtendedGray2,
    border: 0,
    overflowX: "hidden",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    ...theme.mixins.toolbar,
    backgroundColor: theme.palette.common.black,
  },
  permanentDrawerHeader: {
    justifyContent: "center",
  },
  temporaryDrawerHeader: {
    justifyContent: "flex-start",
    paddingLeft: theme.spacing(3),
  },
  permanentDrawerLogo: {
    maxWidth: 290,
    maxHeight: 35,
  },
  temporaryDrawerLogo: {
    maxWidth: 200,
    maxHeight: 35,
  },
  drawerToggle: {
    paddingRight: theme.spacing(3),
    "& .MuiSvgIcon-root": {
      color: theme.palette.text.primary,
    },
  },
  nested: {
    padding: theme.spacing(0.5, 2, 0.5, 7), //no icon display padding
    // padding: theme.spacing(0.5, 2, 0.5, 9),
    whiteSpace: "normal",
  },
  list: {
    padding: theme.spacing(3, 0),
    color: theme.palette.common.white,
    "& *": {
      fontFamily: fontPrimaryTitleSubheading,
      color: colorExtendedGray4,
      // paddingTop: theme.spacing(0.63),
    },
    "& .MuiList-padding": {
      padding: 0,
    },
  },
  listItem: {
    padding: theme.spacing(1.5, 2, 1.5, 4), //no icon display padding
    // padding: theme.spacing(1.5, 2, 1.5, 2),
    "&:hover": {
      backgroundColor: "rgba(4,134,126,0.2)",
    },
  },

  listItemSelected: {
    backgroundColor: colorExtendedGray1,
    color: colorExtendedGreen4,
    fontWeight: 500,
    "&:hover": {
      backgroundColor: colorExtendedGray1,
    },
    "& *": {
      color: "inherit",
      fontWeight: "inherit",
    },
  },
  listItemCollapseSelected: {
    backgroundColor: colorExtendedGray1,
  },
  listItemChild: {
    "&:hover": {
      backgroundColor: "rgba(4,134,126,0.2)",
    },
    "& *": {
      fontSize: "1.2777rem",
      fontWeight: 300,
    },
  },
  listItemChildSelected: {
    "&:hover": {
      backgroundColor: colorExtendedGray1,
    },
    "& *": {
      color: colorExtendedGreen4,
      fontSize: "1.2777rem",
      fontWeight: 300,
    },
  },
  menuIcon: {
    justifyContent: "center",
    fontSize: "2.25rem",
    // fontSize: "1.5555rem",
    fontWeight: 300,
    "& *": {
      fontSize: "inherit",
      color: "inherit",
    },
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.625),
  },
  dashboardIcon: {
    justifyContent: "center",
    fontSize: "2.25rem",
    fontWeight: 300,
    "& *": {
      fontSize: "inherit",
      color: "inherit",
    },
    marginTop: theme.spacing(0.125),
    marginBottom: theme.spacing(0.5),
  },
  arrowIcon: {
    fontSize: "2.7777rem",
    "& *": {
      fontSize: "inherit",
      color: "inherit",
    },
  },
  dividerBottom: {
    width: "auto",
    padding: theme.spacing(2, 0, 0, 0),
    margin: theme.spacing(0, 3, 2, 3),
    borderColor: colorExtendedGray4,
  },
  listItemText: {
    whiteSpace: "normal",
    "& .MuiListItemText-primary": {
      fontSize: "1.5555rem",
      fontWeight: 300,
      lineHeight: 1.5,
    },
  },
}));
