import { makeStyles } from "@material-ui/core";
import { colorExtendedGreen4, colorPrimaryWhite } from "themes/palette";
import { red } from "@material-ui/core/colors";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
  bannerWrapper: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  cardBanner: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  bannerHeader: {
    color: colorExtendedGreen4,
  },
  bannerTitle: {
    flexGrow: 1,
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    "& a, & button": {
      marginLeft: theme.spacing(2),
    },
  },
  formButtons: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "space-between",
    alignContent: "space-between",
  },
  releaseNumberButton: {
    backgroundColor: red["A700"],
    color: colorPrimaryWhite,
  },
  formControl: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  manageNumber: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& button": {
      color: "#f00",
      borderColor: "#f00",
    },
  },
  deleteButton: {
    color: "#f00",
    borderColor: "#f00",
  },
  saveButtonWrapper: {
    textAlign: "right",
  },
  alignRight: {
    textAlign: "right",
  },
}));
