/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import ReactGA from "react-ga";
import { toast } from "react-toastify";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";

import { useAppContext } from "components/Context";

import LobbyHeader from "components/Layouts/Lobby/Header";
import LobbyFormWrapper from "components/Layouts/Lobby/FormWrapper";

import VivialConnectAPI from "../../api/vivial-connect";
import { useStyles } from "./styles";

export default function VerifyYourPhoneNumber() {
  const classes = useStyles();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [code, setCode] = useState("");
  const [open, setOpen] = useState(false);
  const [mainContact, setMainContact] = useState({});
  const [codeSuccess, setCodeSuccess] = useState(false);
  const [setupRequired, setSetupRequired] = useState(false);
  const { setAppState } = useAppContext();
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState("US");

  const handleChange = ({ target }) => {
    const { value } = target;
    setPhoneNumber(value);
  };

  const handleChangeCode = ({ target }) => {
    const { value } = target;
    setCode(value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const { contacts, mfa, validate } = VivialConnectAPI.services;
    const handleError = (error) => {
      error
        ? toast.error(error)
        : toast.error(
            "Unable to send verification code. There may be a problem with your mobile number. Please try again or contact: developer@support.vivialconnect.net."
          );
    };

    if (mainContact.mobile_phone === phoneNumber) {
      //If the contact number has not changed, send the mfa code
      mfa.postPhoneSend().catch(handleError);
      setOpen(true);

    } else {
      // If the contact number HAS changed then...

      // 1. validate the new number...
        const verification_payload = {
          phone_number: {
            phone_number: phoneNumber,
            country_letter_code: country,
          },
        };
        validate
          .postPhoneVerify(verification_payload)
          .then((response) => {
            setPhoneNumberError("");
            const contact_payload = {
              contact: {
                mobile_phone: phoneNumber,
              },
            };

            // 2. update the contact on the server...
            contacts.putContact(mainContact.id, contact_payload).then((contact) => {
              if (contact) {
                setMainContact(contact);
              }

              // 3. send the mfa code
              mfa.postPhoneSend().catch(handleError);
            });

            // 4.display code entry input
            setOpen(true);
          })
          .catch((error) => {
            setPhoneNumberError(error);
          });
    }
  };

  const handleSubmitCode = (event) => {
    event.preventDefault();
    const { mfa } = VivialConnectAPI.services;

    const payload = {
      code,
    };

    mfa
      .postPhoneVerify(payload)
      .then((res) => {
        setCode("");
        setSetupRequired(res.setup_required);
        setCodeSuccess(true);
        setAppState({ setup_required: res.setup_required });
      })
      .catch((error) => {
        error
          ? toast.error(error)
          : toast.error("Verification code incorrect or expired.");
      });
  };

  useEffect(() => {
    const { contacts, lookups } = VivialConnectAPI.services;

    contacts
      .getContacts()
      .then(async (res) => {
        const contact = res.filter(
          (contact) => contact.contact_type === "main"
        )[0];

        if (contact) {
          setMainContact(contact);
          setPhoneNumber(contact.mobile_phone);
        }
      })
      .catch((error) => toast.error(`${error}`));

    // Get List of Countries
    lookups
      .getLookups("country")
      .then((res) => {
        const { country_lookup } = res;
        setCountries(country_lookup);
      })
      .catch((error) => toast.error(error));
  }, []);

  if (codeSuccess) {
    ReactGA.event({
      category: "Dashboard Logins",
      action: "successful login",
      label: "Dashboard Successful Logins",
    });
    if (setupRequired) return <Redirect to="/dashboard/upgrade" />;
    return <Redirect to="/dashboard" />;
  }

  return (
    <>
      <LobbyHeader
        title={"Verify Your Mobile Number"}
        subtitle={
          <>
            We require a verified mobile number for all accounts in order to
            keep our platform secure. We will send a verification code to the
            number below.
          </>
        }
      />
      {open ? (
        <LobbyFormWrapper onSubmit={handleSubmitCode}>
          <FormControl fullWidth>
            <TextField
              variant="outlined"
              margin="normal"
              required
              type="code"
              id="code"
              label="Verification Code"
              name="code"
              value={code}
              onChange={handleChangeCode}
              inputRef={(input) => (open ? input && input.focus() : "")}
              placeholder={`Input code sent to ${phoneNumber}`}
            />
          </FormControl>
          <div className={classes.buttonWrapper}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
            >
              Verify
            </Button>
            <Box mr={2}>
              <Button
                color="primary"
                size="large"
                className={classes.submit}
                onClick={() => setOpen(false)}
              >
                Resend Code
              </Button>
            </Box>
          </div>
        </LobbyFormWrapper>
      ) : (
        <LobbyFormWrapper onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <TextField
                select
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="country"
                label="Country"
                name="country"
                autoComplete="country"
                SelectProps={{
                  renderValue: (option) => option,
                }}
                value={country}
                onChange={(event) => setCountry(event.target.value)}
              >
                {countries.map((item, key) => (
                  <MenuItem key={key} value={item.code}>
                    {`${item.name} (${item.code})`}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                variant="outlined"
                required
                fullWidth
                margin="normal"
                type="phone_number"
                id="phone_number"
                label="Your Mobile Number"
                name="phone_number"
                autoComplete="phone_number"
                value={phoneNumber}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                error={phoneNumberError !== ""}
                helperText={phoneNumberError}
              />
            </Grid>
          </Grid>
          <div className={classes.buttonWrapper}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              className={classes.submit}
            >
              Send Code
            </Button>
          </div>
        </LobbyFormWrapper>
      )}
    </>
  );
}
