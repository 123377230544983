/* eslint-disable react/prop-types */
import React from "react";
import { useLocation } from "react-router-dom";

import { ThemeProvider } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

import Copyright from "@moosetalk-frontend/global/components/Copyright";
import MainAppBar from "./AppBar";
import SideBar from "./SideBar";

import { alternativeTheme, darkTheme } from "themes";
import { useStyles } from "./styles";

function DocsMainComponent(props) {
  const classes = useStyles();
  const { children, handleDrawerToggle } = props;
  const location = useLocation();

  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      <div className={classes.children}>
        <MainAppBar handleDrawerToggle={handleDrawerToggle} />
        <ThemeProvider theme={alternativeTheme}>
          {/*
            key=location.pathname
            forces React to rerender paper component so scroll position is always on top
          */}
          <Paper className={classes.paper} key={location.pathname}>
            <div className={classes.maxWidth}>{children}</div>
            <Copyright showSupport={true} />
          </Paper>
        </ThemeProvider>
      </div>
    </main>
  );
}

export default function Documentation(props) {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <div className={classes.root}>
      <ThemeProvider theme={darkTheme}>
        <SideBar
          handleDrawerToggle={handleDrawerToggle}
          drawerOpen={drawerOpen}
        />
        <DocsMainComponent handleDrawerToggle={handleDrawerToggle} {...props} />
      </ThemeProvider>
    </div>
  );
}
