/* eslint-disable react/display-name */
import React from "react";
import { Redirect } from "react-router-dom";

import Account from "pages/Account";
import APIKeys from "pages/APIKeys";
import APISpec from "pages/Documentation/APISpec";
// import Reports from "pages/Reports";
import DocPage from "pages/Documentation";
import OAuthPersonalTokens from "pages/OAuthTokens";
import Billing from "pages/Billing";
import BillingHistory from "pages/BillingHistory";
import BillingPayment from "pages/BillingPayment";
import Connectors from "pages/Connectors";
import EditConnector from "pages/Connectors/EditConnector";
import CreateConnector from "pages/Connectors/CreateConnector";
import Campaigns from "pages/Campaigns";
import CampaignDetail from "pages/Campaigns/CampaignDetail";
import Brands from "pages/Campaigns/Brands";
// import CreateBrand from "pages/CreateBrand";
// import CreateCampaign from "pages/CreateCampaign";
import Dashboard from "pages/Dashboard";
import Login from "pages/Login";
import Logout from "pages/Logout";
import Numbers from "pages/Numbers";
import ManageNumber from "pages/Numbers/ManageNumber";
import NumberPurchase from "pages/Numbers/NumberPurchase";
// import Upgrade from "pages/Upgrade";
import ForgotPassword from "pages/ForgotPassword";
import ResetPassword from "pages/ResetPassword";
// import SignUp from "pages/SignUp";
// import SendEmail from "pages/SignUp/SendEmail";
import Confirm from "pages/SignUp/Confirm";

import Home from "pages/Brochure/Home";
import About from "pages/Brochure/About";
import Products from "pages/Brochure/Products";
import DeveloperTools from "pages/Brochure/DeveloperTools";
import Spyderlynk from "pages/Brochure/Spyderlynk";
import Pricing from "pages/Brochure/Pricing";
import Blog from "pages/Brochure/Blog";
import BlogPost from "pages/Brochure/Blog/BlogPost";
import Partners from "pages/Brochure/Partners";
import Contact from "pages/Brochure/Contact";
import Legal from "pages/Brochure/Legal";
import PrivacyPolicy from "pages/Brochure/Legal/PrivacyPolicy";
import Glossary from "pages/Brochure/Glossary";
import GlossaryTermPage from "pages/Brochure/Glossary/TermPage";

import BrochureLayout from "components/Layouts/Brochure";
import DashboardLayout from "components/Layouts/Dashboard";
import DocumentationLayout from "components/Layouts/Documentation";
import LobbyLayout from "components/Layouts/Lobby";
import RegistrationLayout from "components/Layouts/Registration";

export const routes = [
  {
    layout: null,
    subRoutes: [
      // {
      //   path: "/dashboard/upgrade",
      //   component: Upgrade,
      //   isProtected: true,
      //   exact: true,
      // },
      {
        path: "/numbers",
        component: () => {
          window.location.href = `${process.env.REACT_APP_WEB_URL}/dashboard/numbers`;
          return null;
        },
        isProtected: true,
        exact: true,
      },
      // {
      //   path: "/dashboard/campaigns/brands/create",
      //   component: CreateBrand,
      //   isProtected: true,
      //   exact: true,
      // },
      // {
      //   path: "/dashboard/campaigns/create",
      //   component: CreateCampaign,
      //   isProtected: true,
      //   exact: true,
      // },
    ],
  },
  {
    layout: BrochureLayout,
    subRoutes: [
      {
        path: ["/", "/home"],
        component: Home,
        isProtected: false,
        exact: true,
      },
      {
        path: "/about",
        component: About,
        isProtected: false,
        exact: true,
      },
      {
        path: ["/products-and-services", "/features"],
        component: Products,
        isProtected: false,
        exact: true,
      },
      {
        path: "/developer-tools",
        component: DeveloperTools,
        isProtected: false,
        exact: true,
      },
      {
        path: "/spyderlynk-security",
        component: Spyderlynk,
        isProtected: false,
        exact: true,
      },
      {
        path: "/pricing",
        component: Pricing,
        isProtected: false,
        exact: true,
      },
      {
        path: "/use-cases",
        component: () => {
          return <Redirect to="/docs/10dlc-prices" />;
        },
        isProtected: false,
        exact: true,
      },
      {
        path: "/blog",
        component: Blog,
        isProtected: false,
        exact: true,
      },
      {
        path: "/blog/:slug",
        component: BlogPost,
        isProtected: false,
        exact: false,
      },
      {
        path: "/partners",
        component: Partners,
        isProtected: false,
        exact: true,
      },
      {
        path: ["/contact", "/support"],
        component: Contact,
        isProtected: false,
        exact: true,
      },
      {
        path: "/legal",
        component: Legal,
        isProtected: false,
        exact: true,
      },
      {
        path: "/privacy-policy",
        component: PrivacyPolicy,
        isProtected: false,
        exact: true,
      },
      {
        path: "/glossary",
        component: Glossary,
        isProtected: false,
        exact: true,
      },
      {
        path: "/glossary/:termSlug",
        component: GlossaryTermPage,
        isProtected: false,
        exact: true,
      },
    ],
  },
  {
    layout: LobbyLayout,
    subRoutes: [
      {
        path: "/login",
        component: Login,
        isProtected: false,
        exact: true,
      },
      {
        path: "/logout",
        component: Logout,
        isProtected: false,
        exact: true,
      },
      {
        path: "/reset/request",
        component: ForgotPassword,
        isProtected: false,
        exact: true,
      },
      {
        path: "/reset/forcePassword",
        component: ResetPassword,
        isProtected: false,
        exact: true,
      },
      {
        path: "/reset/:userId/:verficationToken",
        component: ResetPassword,
        isProtected: false,
        exact: true,
      },
    ],
  },
  {
    layout: DocumentationLayout,
    path: "/docs",
    subRoutes: [
      {
        path: "/docs",
        component: () => {
          return <Redirect to="/docs/intro" />;
        },
        isProtected: false,
        exact: true,
      },
      {
        path: ["/docs/api", "/docs/api/*"],
        component: APISpec,
        isProtected: false,
        exact: true,
      },
      {
        path: ["/docs/:slug", "/docs/:slug/*"],
        component: (props) => {
          if (props.match.params.slug !== "api") {
            return <DocPage />;
          } else return null;
        },
        isProtected: false,
        exact: true,
      },
    ],
  },
  {
    layout: DashboardLayout,
    path: "/dashboard",
    subRoutes: [
      {
        path: "/dashboard",
        component: Dashboard,
        isProtected: true,
        exact: true,
      },
      {
        path: "/dashboard/account",
        component: Account,
        isProtected: true,
        exact: true,
      },
      {
        path: "/dashboard/account/keys",
        component: APIKeys,
        isProtected: true,
        exact: true,
      },
      {
        path: "/dashboard/account/tokens",
        component: OAuthPersonalTokens,
        isProtected: true,
        exact: true,
      },
      {
        path: "/dashboard/billing",
        component: Billing,
        isProtected: true,
        exact: true,
      },
      {
        path: "/dashboard/billing/history",
        component: BillingHistory,
        isProtected: true,
        exact: true,
      },
      {
        path: "/dashboard/billing/payment",
        component: BillingPayment,
        isProtected: true,
        exact: true,
      },
      {
        path: "/dashboard/connectors",
        component: Connectors,
        isProtected: true,
        exact: true,
      },
      {
        path: "/dashboard/connectors/edit/:id",
        component: EditConnector,
        isProtected: true,
        exact: true,
      },
      {
        path: "/dashboard/connectors/create",
        component: CreateConnector,
        isProtected: true,
        exact: true,
      },
      {
        path: "/dashboard/numbers",
        component: Numbers,
        isProtected: true,
        exact: true,
      },
      {
        path: "/dashboard/numbers/edit/:id",
        component: ManageNumber,
        isProtected: true,
        exact: true,
      },
      {
        path: "/dashboard/numbers/purchase",
        component: NumberPurchase,
        isProtected: true,
        exact: true,
      },
      {
        path: "/dashboard/campaigns",
        component: Campaigns,
        isProtected: true,
        exact: true,
      },
      {
        path: "/dashboard/campaigns/brands",
        component: Brands,
        isProtected: true,
        exact: true,
      },
      {
        path: "/dashboard/campaign/:id",
        component: CampaignDetail,
        isProtected: true,
        exact: true,
      },
      // {
      //   path: "/dashboard/reports",
      //   component: Reports,
      //   isProtected: true,
      //   exact: true,
      // },
    ],
  },
  {
    layout: RegistrationLayout,
    path: "/register",
    subRoutes: [
      // {
      //   path: "/register",
      //   component: SignUp,
      //   isProtected: false,
      //   exact: true,
      // },
      // {
      //   path: "/register/email",
      //   component: SendEmail,
      //   isProtected: false,
      //   exact: true,
      // },
      // {
      //   path: "/register/confirm/:id/:token",
      //   component: Confirm,
      //   isProtected: false,
      //   exact: true,
      // },
    ],
  },
  {
    layout: RegistrationLayout,
    path: "/invite",
    subRoutes: [
      {
        path: "/invite/confirm/:id/:token",
        component: Confirm,
        isProtected: false,
        exact: true,
      },
    ],
  },
];

export default routes;
