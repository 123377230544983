// contacts/endpoints.js
import { factory } from "vivialconnect-js-core";

//NOTE: do not use spread syntax here.
//helper == {init, helper.BASE_URL}
const helper = factory.initEndpoints();

export default {
  init: helper.init,
  accounts: () => `${helper.BASE_URL}/accounts.json`,
  accountsCount: () => `${helper.BASE_URL}/accounts/count.json`,
  // Account
  account: (id) => `${helper.BASE_URL}/accounts/${id}.json`,
  accountServices: (id) => `${helper.BASE_URL}/accounts/${id}/services.json`,
  accountBalance: (id) => `${helper.BASE_URL}/accounts/${id}/balance.json`,
  accountAutoRecharge: (id) =>
    `${helper.BASE_URL}/accounts/${id}/autorecharge.json`,
  accountCharge: (id) => `${helper.BASE_URL}/accounts/${id}/charge.json`,
  accountStatus: (id) => `${helper.BASE_URL}/accounts/${id}/status.json`,
  accountStatusContext: () => `${helper.BASE_URL}/accounts/status.json`,
  accountProvision: (id) => `${helper.BASE_URL}/accounts/${id}/provision.json`,
  accountUpgrade: (id) => `${helper.BASE_URL}/accounts/${id}/upgrade.json`,
  accountCancel: (id) => `${helper.BASE_URL}/accounts/${id}/cancel.json`,
  accountOptOuts: (id) => `${helper.BASE_URL}/accounts/${id}/optouts.json`,
  accountPlanTemplates: (id) =>
    `${helper.BASE_URL}/accounts/${id}/plan_templates.json`,
  // Account Payment Sources
  accountPaymentSources: (id) =>
    `${helper.BASE_URL}/accounts/${id}/payment_sources.json`,
  accountPaymentSourcesCount: (id) =>
    `${helper.BASE_URL}/accounts/${id}/payment_sources/count.json`,
  accountPaymentSource: (id, sourceId) =>
    `${helper.BASE_URL}/accounts/${id}/payment_sources/${sourceId}.json`,
  // Account Transactions
  accountTransactions: (id) =>
    `${helper.BASE_URL}/accounts/${id}/transactions.json`,
  accountTransactionsCount: (id) =>
    `${helper.BASE_URL}/accounts/${id}/transactions/count.json`,
  accountTransaction: (id, transactionId) =>
    `${helper.BASE_URL}/accounts/${id}/transactions/${transactionId}.json`,
  accountTransactionRefund: (id, transactionId) =>
    `${helper.BASE_URL}/accounts/${id}/transactions/${transactionId}/refund.json`,
  accountPrices: () => `${helper.BASE_URL}/accounts/prices.json`,
};
