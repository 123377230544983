/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";

import {
  Card as DashboardCard,
  CardHeader as DashboardCardHeader,
  CardContent as DashboardCardContent,
} from "components/Layouts/Dashboard/Card";
import ConfirmModal from "@moosetalk-frontend/global/components/ConfirmModal";
import IOSSwitch from "@moosetalk-frontend/global/components/IOSSwitch";
import { DefaultPaymentMethod } from "./index";

import VivialConnectAPI from "api/vivial-connect";
const { convertFromHundredCents, convertToHundredCents } =
  VivialConnectAPI.helpers.numbers;

const autorecharge_defaults = {
  autorecharge_enabled: false,
  autorecharge_amount: 100000,
  autorecharge_threshold: 10000,
};

const AutomaticBilling = ({ defaultPaymentMethod, status }) => {
  const [autorecharge, setAutorecharge] = useState(autorecharge_defaults);
  const { cash_balance: cashBalance } = status;

  const {
    autorecharge_enabled: autorechargeEnabled,
    autorecharge_amount: autorechargeAmount,
    autorecharge_threshold: autorechargeThreshold,
  } = autorecharge;

  const handlePutAutorecharge = (
    autorecharge_enabled,
    autorecharge_amount,
    autorecharge_threshold
  ) => {
    const { accounts } = VivialConnectAPI.services;

    const payload = {
      account: {
        autorecharge_enabled,
        autorecharge_amount:
          convertToHundredCents(autorecharge_amount) ||
          autorecharge_defaults.autorecharge_amount,
        autorecharge_threshold:
          convertToHundredCents(autorecharge_threshold) ||
          autorecharge_defaults.autorecharge_threshold,
      },
    };

    accounts
      .putAccountAutoRecharge(payload)
      .then(() => {
        handleGetAutorecharge();
        toast.success("Automatic billing updated");
      })
      .catch((error) => toast.error(error));
  };

  const handleGetAutorecharge = () => {
    const { accounts } = VivialConnectAPI.services;

    accounts
      .getAccountAutoRecharge()
      .then((autorecharge) => {
        setAutorecharge(autorecharge);
      })
      .catch((error) => toast.error(error));
  };

  const handleOnChangeEnabled = (event) => {
    handlePutAutorecharge(
      event.target.checked,
      convertFromHundredCents(autorechargeAmount),
      convertFromHundredCents(autorechargeThreshold)
    );
  };

  const handleOnChargeAmountOrThreshold = (event) => {
    let { name, value } = event.target;
    if (!value && name === "autorecharge_amount") value = 10;
    if (!value && name === "autorecharge_threshold") value = 1;

    setAutorecharge({ ...autorecharge, [name]: convertToHundredCents(value) });
  };

  const UpdateAutobillButton = ({ onClick }) => {
    return (
      <Button
        disabled={!defaultPaymentMethod || !autorechargeEnabled}
        color="primary"
        variant="contained"
        onClick={onClick}
      >
        Update
      </Button>
    );
  };

  const onUpdateAutobill = () => {
    handlePutAutorecharge(
      autorechargeEnabled,
      convertFromHundredCents(autorechargeAmount),
      convertFromHundredCents(autorechargeThreshold)
    );
  };

  useEffect(() => {
    handleGetAutorecharge();
  }, []);

  return (
    <DashboardCard>
      <DashboardCardHeader title={"Automatic Billing"} />
      <DashboardCardContent alignItems="center" alignContent="flex-start">
        <Grid item xs={7}>
          <Typography variant="body2">Autobill Status:</Typography>
        </Grid>
        <Grid item xs={5}>
          <FormControlLabel
            control={
              <IOSSwitch
                disabled={!defaultPaymentMethod}
                checked={autorechargeEnabled}
                onChange={handleOnChangeEnabled}
                name="autorecharge_enabled"
              />
            }
            label={autorechargeEnabled ? "ON" : "OFF"}
          />
        </Grid>
        <Grid item xs={7}>
          <Typography variant="body2">
            When Your Balance Falls Below:
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <TextField
            type="number"
            disabled={!defaultPaymentMethod || !autorechargeEnabled}
            variant="outlined"
            name="autorecharge_threshold"
            value={
              autorechargeThreshold
                ? convertFromHundredCents(autorechargeThreshold)
                : convertFromHundredCents(
                    autorecharge_defaults.autorecharge_threshold
                  )
            }
            onChange={handleOnChargeAmountOrThreshold}
            inputProps={{ min: 1, step: 1 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={7}>
          <Typography variant="body2">We Will Replenish It To:</Typography>
        </Grid>
        <Grid item xs={5}>
          <TextField
            fullWidth
            type="number"
            disabled={!defaultPaymentMethod || !autorechargeEnabled}
            variant="outlined"
            name="autorecharge_amount"
            value={
              autorechargeAmount
                ? convertFromHundredCents(autorechargeAmount)
                : convertFromHundredCents(
                    autorecharge_defaults.autorecharge_amount
                  )
            }
            onChange={handleOnChargeAmountOrThreshold}
            inputProps={{ min: 10, step: 1 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid container item xs={12} alignItems="flex-start">
          <Grid item xs={7}>
            <Typography variant="body2">Payment Method:</Typography>
          </Grid>
          <Grid item xs={5}>
            <DefaultPaymentMethod hasDefault={defaultPaymentMethod} />
          </Grid>
        </Grid>
        <Grid item xs={7} />
        <Grid item xs={5} style={{ textAlign: "right" }}>
          {autorechargeThreshold > cashBalance ? (
            <ConfirmModal
              title={`Update autobill settings`}
              warningMessage={
                <>
                  <span>
                    Updating Automatic billing will cause your card to be
                    charged immediately.
                  </span>
                </>
              }
              clickableComponent={<UpdateAutobillButton />}
              onSubmit={() => onUpdateAutobill()}
            />
          ) : (
            <UpdateAutobillButton
              onClick={() => {
                onUpdateAutobill();
              }}
            />
          )}
        </Grid>
      </DashboardCardContent>
    </DashboardCard>
  );
};

export default AutomaticBilling;
