/* eslint-disable react/prop-types */
import React from "react";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";

import Subtitle from "components/Layouts/Brochure/Subtitle";

const useStyles = makeStyles(() => ({
  homeFeatureContainer: {
    padding: "0 5%",
  },
  homeFeatureItem: {
    textAlign: "center",
    "& img": {
      marginBottom: "25px",
    },
  },
}));

export default function HomeFeatureList(props) {
  const classes = useStyles();
  const { header, items } = props;
  //prevent component rendering before the item list is acquired
  if (!items || !items.length) return null;

  return (
    <div className="center">
      <Subtitle>{header}</Subtitle>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        spacing={10}
        className={classes.homeFeatureContainer}
      >
        {items.map((item, index) => {
          return (
            <Grid item md={4} className={classes.homeFeatureItem} key={index}>
              {/*TODO: link img to features page*/}
              <img
                alt="{item.header}"
                src={item.fields.image.fields.file.url}
              />

              <Typography variant="h4" gutterBottom>
                {item.fields.header}
              </Typography>

              <Typography className={classes.homeItemBody}>
                {item.fields.shortDescription}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}
