import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import useStyles from "./styles";
import VivialConnectAPI from "api/vivial-connect";
import { toast } from "react-toastify";
import BillingAddressForm from "./BillingAddressForm";
import { validateForm, requiredFields } from "./shared";

export default function UpdateCreditCardForm({
  selectedCreditCard,
  setOpen,
  handleGetPaymentSources,
  isMounted,
}) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [creditCard, setCreditCard] = useState({
    last4: "",
    exp_month: "",
    exp_year: "",
    first_name: "",
    last_name: "",
  });
  const [billingContact, setBillingContact] = useState(requiredFields);
  const [errors, setErrors] = useState({
    expiryMonth: "",
    expiryYear: "",
  });
  const { last4, exp_month, exp_year } = creditCard;
  const { accounts, contacts } = VivialConnectAPI.services;

  const handleSubmit = (event) => {
    event.preventDefault();
    if (cardValidation()) return;
    let { hasError, errorMessages } = validateForm(
      errors,
      setErrors,
      billingContact
    );

    if (hasError) {
      errorMessages.length
        ? errorMessages.forEach((msg) => {
            toast.error(msg);
          })
        : toast.error("Please fill in all required fields.");
    } else {
      handleSaveCreditCard();
    }
  };

  const handleSaveCreditCard = () => {
    const { exp_month, exp_year, id } = creditCard;
    const {
      id: contactId,
      address1,
      postal_code,
      state,
      city,
      first_name,
      last_name,
      country,
    } = billingContact;

    const contactPayload = {
      contact: {
        id: contactId,
        address1,
        postal_code,
        state,
        country,
        city,
        first_name,
        last_name,
      },
    };

    const payload = {
      payment_source: {
        address_city: city,
        address_line1: address1,
        address_state: state,
        address_zip: postal_code,
        exp_month,
        exp_year,
        name: `${first_name} ${last_name}`,
      },
    };

    setLoading(true);
    contacts
      .putContact(contactId, contactPayload)
      .then(() => {
        accounts
          .putAccountPaymentSource(id, payload)
          .then(() => {
            toast.success("Your card was updated.");
            handleGetPaymentSources();
          })
          .catch((error) => toast.error(error))
          .finally(() => {
            if (!isMounted.current) return;
            setOpen("payments");
            setLoading(false);
          });
      })
      .catch((error) => toast.error(error));
  };

  const cardValidation = () => {
    let errorFound = false;
    if (!(exp_month >= 1 && exp_month <= 12)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        expiryMonth: "Please enter a valid month",
      }));
      errorFound = true;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        expiryMonth: "",
      }));
    }
    if (new Date(exp_year, exp_month - 1) < new Date()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        expiryYear: "Please enter a valid year",
      }));
      errorFound = true;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        expiryYear: "",
      }));
    }
    return errorFound;
  };

  const handleOnChangeCreditCard = (event) => {
    const { name, value } = event.target;
    creditCard[name] = value;
    setCreditCard({ ...creditCard });
  };

  const handleOnChangeBillingContact = (event) => {
    const { name, value } = event.target;
    if (name === "country") billingContact["state"] = "";
    billingContact[name] = value;
    setBillingContact({ ...billingContact });
  };

  useEffect(() => {
    if (!Object.prototype.hasOwnProperty.call(creditCard, "id")) {
      const { contacts } = VivialConnectAPI.services;
      const { card_expiration, card_owner_name, last4, id, payment_type } =
        selectedCreditCard;

      const first_name = card_owner_name
        ? card_owner_name.split(" ")[0]
        : "OWNER";
      const last_name = card_owner_name
        ? card_owner_name.split(" ")[1]
        : "NAME";
      const exp_month = card_expiration.split("/")[0];
      const exp_year = card_expiration.split("/")[1];

      setCreditCard({
        ...creditCard,
        last4: `XXXX XXXX XXXX ${last4}`,
        exp_month,
        exp_year,
        first_name,
        last_name,
        id,
        payment_type,
      });

      contacts
        .getContacts()
        .then((res) => {
          if (!isMounted.current) return;
          const billingContact = res.filter(
            (contact) => contact.contact_type === "billing"
          )[0];

          if (billingContact) setBillingContact(billingContact);
        })
        .catch((error) => toast.error(error));
    }
  }, [creditCard, selectedCreditCard, isMounted]);

  return (
    <BillingAddressForm
      title="Update Credit Card"
      billingContact={billingContact}
      handleOnChangeBillingContact={handleOnChangeBillingContact}
      onSubmit={handleSubmit}
      loading={loading}
      setOpen={setOpen}
    >
      <Grid item xs={12}>
        <Typography variant="body1" className={classes.addCreditCardLabel}>
          <span className="body-title">Card Information</span>
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                disabled
                variant="outlined"
                label="Card Number"
                name="last4"
                value={last4}
                onChange={handleOnChangeCreditCard}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <TextField
                required
                variant="outlined"
                label="Exp. Month"
                name="exp_month"
                value={exp_month}
                onChange={handleOnChangeCreditCard}
                InputLabelProps={{
                  shrink: true,
                }}
                error={errors.expiryMonth !== ""}
                helperText={errors.expiryMonth}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <TextField
                required
                variant="outlined"
                label="Exp. Year"
                name="exp_year"
                value={exp_year}
                onChange={handleOnChangeCreditCard}
                InputLabelProps={{
                  shrink: true,
                }}
                error={errors.expiryYear !== ""}
                helperText={errors.expiryYear}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </BillingAddressForm>
  );
}

UpdateCreditCardForm.propTypes = {
  selectedCreditCard: PropTypes.object,
  setOpen: PropTypes.func,
  handleGetPaymentSources: PropTypes.func,
  isMounted: PropTypes.bool,
};
