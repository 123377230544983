/* eslint-disable react/prop-types */
/* eslint-disable no-empty */
import React, { useState, useReducer, useEffect } from "react";
import { useParams, Redirect } from "react-router-dom";
import { toast } from "react-toastify";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import DeleteIcon from "@material-ui/icons/Delete";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";

import VivialConnectAPI from "../../api/vivial-connect";
import { useAppContext, usePageContext } from "components/Context";

import {
  Card as DashboardCard,
  CardContent as DashboardCardContent,
  CardHeader as DashboardCardHeader,
} from "components/Layouts/Dashboard/Card";
import ConfirmPassword from "components/ConfirmPassword";
import { PhoneIcon } from "components/VivialIcons";
import MainItem from "components/ListView/MainItem";
import TestMessage from "components/Numbers/TestMessage";

import ConfigureCallbacks from "components/Numbers/ConfigureCallbacks";
import { useStyles } from "./styles";

const callbackTemplate = {
  url: "",
  method: "POST",
  auth_type: "NONE",
  credentials: {},
};
const callbackMeta = [
  {
    name: "status",
    prefix: "status_text",
  },
  {
    name: "incoming",
    prefix: "incoming_text",
  },
  {
    name: "incoming_fallback",
    prefix: "incoming_text_fallback",
  },
];

const { validatePhone } = VivialConnectAPI.helpers.validation;

export default function ManageNumber({ setOpen, refreshNumbers }) {
  const { appState } = useAppContext();
  const { setPageState } = usePageContext();
  const classes = useStyles();
  const { id: numberId } = useParams();
  const { validateUrl } = VivialConnectAPI.helpers.validation;
  const [number, setNumber] = useState();
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [numberCallbacks, setNumberCallbacks] = useReducer(
    numberCallbackReducer,
    {}
  );
  const [numberError, setNumberError] = useState("");
  const [callbackErrors, setCallbackErrors] = useState({
    status: false,
    incoming: false,
    incoming_fallback: false,
  });
  const [redirectToMain, setRedirectToMain] = useState(false);
  const [campaigns, setCampaigns] = useState([]);

  useEffect(() => {
    //Set page context values
    setPageState({
      title: "Numbers",
      subtitle:
        number && number.phone_number ? number.phone_number : "Manage Numbers",
    });

    return () => setPageState({});
  }, [setPageState, number]);

  function numberCallbackReducer(prevCallbacks, callbackTree) {
    for (const callback_type in callbackTree) {
      if (callbackTree[callback_type].url !== "") {
        //Copy callbacks that have a URL prop
        prevCallbacks[callback_type] = { ...callbackTree[callback_type] };

        if (prevCallbacks[callback_type].auth_type === "NONE") {
          //delete credentials if auth_type is not set
          delete prevCallbacks[callback_type].credentials;
        }
      } else {
        //Delete callbacks with no URL prop
        delete prevCallbacks[callback_type];
      }
    }

    return prevCallbacks;
  }

  const handleNumberChange = (prop) => (event) => {
    setNumber({ ...number, [prop]: event.target.value });
  };

  const handleCallbackChange = (callbackTree) => {
    setNumberCallbacks(callbackTree);
  };

  const validate = () => {
    let isValid = true;

    //valdate voice forwarding number
    if (
      number.voice_forwarding_number &&
      !validatePhone(number.voice_forwarding_number)
    ) {
      isValid = false;
      let errStr = "Please enter a valid phone number for Voice Forwarding";
      setNumberError(errStr);
      toast.error(errStr);
    } else {
      setNumberError("");
    }

    let callbackErr = false;
    let newCallbackErrors = {
      status: false,
      incoming: false,
      incoming_fallback: false,
    };

    try {
      if (!validateUrl(numberCallbacks.status.url)) {
        callbackErr = true;
        newCallbackErrors.status = true;
      }
    } catch (e) {}

    try {
      if (!validateUrl(numberCallbacks.incoming.url)) {
        callbackErr = true;
        newCallbackErrors.incoming = true;
      }
    } catch (e) {}

    try {
      if (!validateUrl(numberCallbacks.incoming_fallback.url)) {
        callbackErr = true;
        newCallbackErrors.incoming_fallback = true;
      }
    } catch (e) {}

    setCallbackErrors(newCallbackErrors);
    if (callbackErr) {
      isValid = false;
      toast.error("Invalid callback URL");
    }

    return isValid;
  };

  const numberCleanup = (number) => {
    let cleanNumber = {};
    const neededProperties = [
      "id",
      "incoming_text_auth_type",
      "incoming_text_credentials",
      "incoming_text_fallback_auth_type",
      "incoming_text_fallback_credentials",
      "incoming_text_fallback_method",
      "incoming_text_fallback_url",
      "incoming_text_method",
      "incoming_text_url",
      "name",
      "status_text_auth_type",
      "status_text_credentials",
      "status_text_method",
      "status_text_url",
      "voice_forwarding_number",
      "campaign_id",
    ];

    neededProperties.forEach((prop) => {
      cleanNumber[prop] = number[prop];
    });

    return cleanNumber;
  };

  const onSubmitNumber = () => {
    if (saveInProgress) return;
    const { numbers } = VivialConnectAPI.services;

    if (!validate()) {
      return;
    }

    //copy callbacks into phone number object
    let newNumber = number;
    callbackMeta.forEach((meta) => {
      for (const key in callbackTemplate) {
        let attributeName = `${meta.prefix}_${key}`;
        newNumber[attributeName] = numberCallbacks[meta.name]
          ? numberCallbacks[meta.name][key]
          : callbackTemplate[key];
      }
    });
    setNumber(newNumber);
    newNumber = numberCleanup(newNumber);

    const payload = {
      phone_number: newNumber,
    };

    setSaveInProgress(true);
    numbers
      .putNumber(number.id, payload)
      .then(() => {
        toast.success("Your number was updated!");
        refreshNumbers(true);
        setOpen("index");
      })
      .catch((error) => toast.error(error))
      .finally(() => {
        setSaveInProgress(false);
      });
  };

  const releaseNumber = () => {
    if (deleteInProgress) return;
    const { numbers } = VivialConnectAPI.services;

    setDeleteInProgress(true);
    numbers
      .deleteNumber(number.id)
      .then(() => {
        toast.success(`Number ${number.phone_number} released.`);
        setOpen("index");
        refreshNumbers(true);
      })
      .catch((error) => toast.error(error))
      .finally(() => {
        setDeleteInProgress(false);
        setRedirectToMain(true);
      });
  };
  /*
   {
    "status": {
      url: ""
      method: ""
      auth_type: ""
      credentials: {}
    },
    incoming: ...
    incoming_fallback: ...
    }
  */

  useEffect(() => {
    const { numbers, campaigns: campaignServices } = VivialConnectAPI.services;
    //set local storage

    numbers
      .getNumber(numberId)
      .then((phoneNumber) => {
        //convert callback array to object for editing
        //numbers mechanism for storing this data is painful to deal with
        let callbackObj = {};
        callbackMeta.forEach((meta) => {
          //skip undefined callbacks
          if (!phoneNumber[`${meta.prefix}_url`]) return;

          callbackObj[meta.name] = Object.assign({}, callbackTemplate);
          for (const key in callbackTemplate) {
            //leave credentials undefined
            if (key === "credentials") delete callbackObj[meta.name][key];

            //copy url, method, auth_type
            let attributeName = `${meta.prefix}_${key}`;
            callbackObj[meta.name][key] = phoneNumber[attributeName];
          }
        });
        setNumberCallbacks(callbackObj);

        //set number last to avoid rerender occuring before setNumberCallbacks
        //has finished updating.
        setNumber(phoneNumber);
      })
      .catch((error) => toast.error(error));

    campaignServices
      .getCampaigns()
      .then((newCampaigns) => setCampaigns(newCampaigns))
      .catch((error) => toast.error(error));
  }, [numberId]);

  if (!number || !number.id) return null;

  if (redirectToMain) return <Redirect to="/dashboard/numbers/" />;

  return (
    <div className={classes.root} spacing={2}>
      <Grid container spacing={5}>
        <Grid item xs={12} md={6}>
          <DashboardCard>
            <div className={classes.bannerWrapper}>
              <div className={classes.bannerTitle}>
                <Typography variant="h5" className={classes.bannerHeader}>
                  EDIT LOCAL NUMBER
                </Typography>
              </div>
              <Typography
                component="span"
                variant="h5"
                className={classes.bannerHeader}
              >
                ID: &nbsp;
              </Typography>
              <Typography component="span" variant="h5">
                {number.id}
              </Typography>
            </div>
          </DashboardCard>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <DashboardCard>
            <Grid container spacing={0}>
              <MainItem
                primaryText={"Phone Number"}
                secondaryText={number.phone_number}
                avatarIcon={<PhoneIcon />}
                itemType="first"
              />
            </Grid>
          </DashboardCard>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <DashboardCard>
            <Grid container spacing={0}>
              <MainItem
                primaryText={"Location"}
                secondaryText={
                  number.phone_number_type === "tollfree"
                    ? "Toll-Free"
                    : `${number.city}, ${number.region}`
                }
                avatarIcon={<LocationOnOutlinedIcon />}
              />
            </Grid>
          </DashboardCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <form
            autoComplete="off"
            onSubmit={(event) => {
              event.preventDefault();
              onSubmitNumber(event);
            }}
          >
            <DashboardCard>
              <DashboardCardHeader title="Configure" />
              <DashboardCardContent>
                <Grid item xs={12} sm={6} md={12} lg={6}>
                  <FormControl fullWidth>
                    <TextField
                      variant="outlined"
                      label="Phone Number Label"
                      name="phone_number_label"
                      value={number.name}
                      onChange={handleNumberChange("name")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormControl>
                </Grid>
                {appState.plan_type !== "freetrial" && (
                  <Grid item xs={12} sm={6} md={12} lg={6}>
                    <FormControl fullWidth>
                      <TextField
                        variant="outlined"
                        label="Voice Forwarding Number"
                        name="voice_forwarding_number"
                        value={number.voice_forwarding_number || ""}
                        onChange={handleNumberChange("voice_forwarding_number")}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={numberError !== ""}
                        helperText={numberError}
                      />
                    </FormControl>
                  </Grid>
                )}

                {number.phone_number_type !== "tollfree" && (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="body1">10DLC Campaign</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={12} lg={6}>
                      <FormControl fullWidth>
                        <TextField
                          select
                          variant="outlined"
                          label="Campaign ID"
                          name="campaign_id"
                          value={number.campaign_id}
                          onChange={handleNumberChange("campaign_id")}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          disabled={
                            !(
                              Array.isArray(campaigns) && campaigns.length !== 0
                            )
                          }
                          helperText={
                            !(
                              Array.isArray(campaigns) && campaigns.length !== 0
                            )
                              ? "No campaigns available"
                              : ""
                          }
                        >
                          {Array.isArray(campaigns) && campaigns.length !== 0
                            ? campaigns.map((campaign) => (
                                <MenuItem
                                  key={`campaign-${campaign.id}`}
                                  value={campaign.id}
                                >
                                  {`${campaign.id}`}
                                </MenuItem>
                              ))
                            : null}
                        </TextField>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={12} lg={6} />
                  </>
                )}

                <ConfigureCallbacks
                  callbacks={numberCallbacks}
                  callbackErrors={callbackErrors}
                  handleChange={handleCallbackChange}
                />

                <Grid item xs={6} className={classes.formButtons}>
                  <ConfirmPassword
                    title={`RELEASE NUMBER ${number.phone_number}`}
                    warningMessage={`Warning: Releasing a number makes it unavailable for sending or receiving messages. Released numbers will not be availble for immediate re-purchase.`}
                    clickableComponent={
                      <Button
                        variant="outlined"
                        startIcon={
                          deleteInProgress ? (
                            <CircularProgress size={24} disableShrink />
                          ) : (
                            <DeleteIcon />
                          )
                        }
                        className={classes.releaseNumberButton}
                      >
                        Release Number
                      </Button>
                    }
                    onSubmit={releaseNumber}
                  />
                </Grid>
                <Grid item xs={6} className={classes.saveButtonWrapper}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    startIcon={
                      saveInProgress ? (
                        <CircularProgress
                          size={24}
                          color="inherit"
                          disableShrink
                        />
                      ) : null
                    }
                  >
                    Save Changes
                  </Button>
                </Grid>
              </DashboardCardContent>
            </DashboardCard>
          </form>
        </Grid>
        <Grid item xs={12} md={6}>
          <TestMessage phoneNumber={number.phone_number} />
        </Grid>
      </Grid>
    </div>
  );
}
