/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { format } from "date-fns";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import Link from "@material-ui/core/Link";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  Card as DashboardCard,
  CardHeader as DashboardCardHeader,
  CardContent as DashboardCardContent,
} from "components/Layouts/Dashboard/Card";
import ConfirmModal from "@moosetalk-frontend/global/components/ConfirmModal";

import { DefaultPaymentMethod } from "./index";

import useStyles from "./styles";

import VivialConnectAPI from "api/vivial-connect";
const {
  convertNumberToCurrency,
  withCommaSeparator,
} = VivialConnectAPI.helpers.numbers;

const SubscriptionAccountStatus = ({
  status,
  defaultPaymentMethod,
  handleAddFunds,
  billingOptions,
  loading,
}) => {
  const classes = useStyles();
  const [funds, setFunds] = useState(20);
  const {
    cash_balance: cashBalance,
    usage_credits: usageCredits,
    numbers_owned: numbersOwned,
    next_renewal_date: nextRenewalDate,
    next_allocation_date: nextAllocationDate,
    plan_max_numbers: planMaxNumbers,
  } = status;
  const {
    disable_renewal: disableRenewal,
    // disable_edit: disableEdit,
    // disable_cancel: disableCancel,
    // disable_upgrade: disableUpgrade,
  } = billingOptions;

  const handleOnChange = (event) => {
    const { value } = event.target;
    setFunds(value ? value : 20);
  };

  return (
    <DashboardCard>
      <DashboardCardHeader title={"Account Status"} />
      <DashboardCardContent alignItems="flex-start" alignContent="flex-start">
        <Grid item xs={6}>
          <Typography variant="body2">Cash Balance:</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">{`${
            cashBalance ? convertNumberToCurrency(cashBalance) : "$0.00"
          }`}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">Credit Balance:</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">
            {usageCredits ? withCommaSeparator(usageCredits) : "0"}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">Numbers Owned:</Typography>
          <Typography variant="body1" className={classes.note}>
            Your plan includes {planMaxNumbers} number credits,{" "}
            <Link component={NavLink} to="/dashboard/numbers/purchase">
              click here
            </Link>{" "}
            to add numbers. Numbers exceeding your plan allocation will incur an
            overage cost each month.
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">
            {numbersOwned ? withCommaSeparator(numbersOwned) : "0"}
          </Typography>
        </Grid>
        {disableRenewal ? (
          <>
            <Grid item xs={6}>
              <Typography variant="body2">Plan Ends:</Typography>
              <Typography variant="body1" className={classes.note}>
                Resume plan if you wish to renew
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">
                {nextAllocationDate
                  ? format(Date.parse(nextAllocationDate), "MMMM do, yyyy")
                  : ""}
              </Typography>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={6}>
              <Typography variant="body2">Credit Renewal:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">
                {nextRenewalDate
                  ? format(Date.parse(nextRenewalDate), "MMMM do, yyyy")
                  : ""}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Plan Renewal:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">
                {nextAllocationDate
                  ? format(Date.parse(nextAllocationDate), "MMMM do, yyyy")
                  : ""}
              </Typography>
            </Grid>
          </>
        )}
        <Grid container item xs={12} alignItems="center">
          <Grid item xs={6}>
            <Typography variant="body2">Add Funds:</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="number"
              disabled={!defaultPaymentMethod}
              variant="outlined"
              value={funds ? funds : 20}
              onChange={handleOnChange}
              inputProps={{
                min: 20,
                step: 1,
                className: classes.addFundsInput,
              }}
              InputProps={{
                endAdornment: (
                  <ConfirmModal
                    title={`ADD FUNDS`}
                    warningMessage={
                      <>
                        <span>Your credit card will be charged ${funds}.</span>
                      </>
                    }
                    clickableComponent={
                      <Button
                        disabled={!defaultPaymentMethod || loading}
                        color="primary"
                        variant="contained"
                      >
                        Add
                        {loading && (
                          <CircularProgress
                            size={24}
                            color="primary"
                            disableShrink
                            className={classes.spinner}
                          />
                        )}
                      </Button>
                    }
                    onSubmit={() => handleAddFunds(funds)}
                  />
                ),
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">Payment Method:</Typography>
        </Grid>
        <Grid item xs={6}>
          <DefaultPaymentMethod hasDefault={defaultPaymentMethod} />
        </Grid>
      </DashboardCardContent>
    </DashboardCard>
  );
};

export default SubscriptionAccountStatus;
