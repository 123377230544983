import endpoints from "./endpoints.js";
import { factory } from "vivialconnect-js-core";

//NOTE: do not use spread syntax here.
//serviceHelpers == {init, http, appSessionStore}
export const serviceHelpers = factory.initService(endpoints);

const getAccounts = async (params) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.get(endpoints.accounts(), { params });
    return data.accounts;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const postAccounts = async (payload) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.post(endpoints.accounts(), payload);
    return data.accounts;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getAccountsCount = async (params) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.get(endpoints.accountsCount(), { params });
    return data.count;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getAccount = async (id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.get(
      endpoints.account(id ? id : appSessionStore.getAccountId())
    );
    return data.account;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const putAccount = async (payload = {}, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.put(
      endpoints.account(id ? id : appSessionStore.getAccountId()),
      payload
    );
    return data.account;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getAccountServices = async (id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.get(
      endpoints.accountServices(id ? id : appSessionStore.getAccountId())
    );
    return data.account;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const putAccountServices = async (payload = {}, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.put(
      endpoints.accountServices(id ? id : appSessionStore.getAccountId()),
      payload
    );
    return data.account;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getAccountBalance = async (id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.get(
      endpoints.accountBalance(id ? id : appSessionStore.getAccountId())
    );
    return data.account;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getAccountStatus = async (id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    let response;

    if (id) {
      response = await http.get(endpoints.accountStatus(id));
    } else {
      response = await http.get(
        //accountStatusContext gets account status info for the current logged-in user
        endpoints.accountStatusContext()
      );
    }

    const { data } = response;
    return data.account;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const postAccountProvision = async (payload = {}, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.post(
      endpoints.accountProvision(id ? id : appSessionStore.getAccountId()),
      payload
    );
    return data.plan;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getAccountAutoRecharge = async (id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.get(
      endpoints.accountAutoRecharge(id ? id : appSessionStore.getAccountId())
    );
    return data.account;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const putAccountAutoRecharge = async (payload = {}, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.put(
      endpoints.accountAutoRecharge(id ? id : appSessionStore.getAccountId()),
      payload
    );
    return data.account;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getAccountPaymentSources = async (id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.get(
      endpoints.accountPaymentSources(id ? id : appSessionStore.getAccountId())
    );
    return data.payment_sources;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const postAccountPaymentSources = async (payload = {}, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.post(
      endpoints.accountPaymentSources(id ? id : appSessionStore.getAccountId()),
      payload
    );
    return data.payment_source;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const putAccountPaymentSource = async (sourceId, payload = {}, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.put(
      endpoints.accountPaymentSource(
        id ? id : appSessionStore.getAccountId(),
        sourceId
      ),
      payload
    );
    return data.payment_source;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const deleteAccountPaymentSource = async (sourceId, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    await http.delete(
      endpoints.accountPaymentSource(
        id ? id : appSessionStore.getAccountId(),
        sourceId
      )
    );
    return true;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const postAccountCancel = async (id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.post(
      endpoints.accountCancel(id ? id : appSessionStore.getAccountId())
    );
    return data.plan;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const postAccountCharge = async (payload, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.post(
      endpoints.accountCharge(id ? id : appSessionStore.getAccountId()),
      payload
    );
    return data;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getAccountPlanTemplates = async (id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.get(
      endpoints.accountPlanTemplates(id ? id : appSessionStore.getAccountId())
    );
    return data.plan_templates;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const postAccountPlanTemplates = async (payload, params, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.post(
      endpoints.accountPlanTemplates(id ? id : appSessionStore.getAccountId()),
      payload,
      { params }
    );
    return data.plan_template;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};
const getAccountTransactions = async (params, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.get(
      endpoints.accountTransactions(id ? id : appSessionStore.getAccountId()),
      { params }
    );
    return data.transactions;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getAccountTransactionsCount = async (params, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.get(
      endpoints.accountTransactionsCount(
        id ? id : appSessionStore.getAccountId()
      ),
      {
        params,
      }
    );
    return data.count;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getAccountPrices = async (params) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.get(endpoints.accountPrices(), { params });
    return data;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const postAccountUpgrade = async (payload, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.post(
      endpoints.accountUpgrade(id ? id : appSessionStore.getAccountId()),
      payload
    );
    return data.plan;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

export default {
  init: serviceHelpers.init,
  deleteAccountPaymentSource,
  getAccount,
  getAccountAutoRecharge,
  getAccountBalance,
  getAccountPaymentSources,
  getAccountServices,
  getAccountStatus,
  getAccounts,
  getAccountsCount,
  postAccountCancel,
  postAccountCharge,
  postAccountPaymentSources,
  postAccountProvision,
  postAccounts,
  putAccount,
  putAccountAutoRecharge,
  putAccountPaymentSource,
  putAccountServices,
  getAccountPlanTemplates,
  postAccountPlanTemplates,
  getAccountTransactions,
  getAccountTransactionsCount,
  getAccountPrices,
  postAccountUpgrade,
};
