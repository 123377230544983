import React from "react";

import {
  Card as DashboardCard,
  CardHeader as DashboardCardHeader,
  CardContent as DashboardCardContent,
} from "components/Layouts/Dashboard/Card";

import CashPriceTable from "./CashPriceTable";

const YourPrices = () => {
  return (
    <DashboardCard>
      <DashboardCardHeader title={"Your Prices"} />
      <DashboardCardContent>
        <CashPriceTable />
      </DashboardCardContent>
    </DashboardCard>
  );
};

export default YourPrices;
