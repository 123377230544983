import { colorPrimaryWhite } from "../palette";

export const darkTheme = {
  notchedOutline: {
    borderWidth: 1,
    borderColor: colorPrimaryWhite,
  },
};

export default {
  darkTheme,
};
