import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Grid from "@material-ui/core/Grid";
import APIKeysBanner from "./APIKeysBanner";
import APIKeysTable from "./APIKeysTable";

import VivialConnectAPI from "api/vivial-connect";
import { useAppContext } from "components/Context";
import withPageContext from "components/Context/WithPageContext";

const CSVHeaders = ["Account ID", "API URL", "Key", "Secret"];

export default withPageContext(APIKeys, {
  title: "Account",
  subtitle: "API Keys",
});

function APIKeys() {
  const { appState } = useAppContext();
  const [openDialog, setOpenDialog] = useState(false);
  const [credentials, setCredentials] = useState([]);
  const [selectedCredential, setSelectedCredential] = useState({});
  const [name, setName] = useState("");
  const [csvData, setCsvData] = useState([CSVHeaders]);
  const API_URL = process.env.REACT_APP_BACKEND_API_URL;

  const handleOnChange = (event) => {
    setName(event.target.value || "");
  };

  const onSave = (credential) => {
    const { users } = VivialConnectAPI.services;

    // NOTE: We must explicitly set 'active' in this payload to prevent the API
    // from deactivating the creds on update. This may be a bug in the API.
    // When/if it gets fixed, remove 'active' from this payload object.
    const payload = {
      user: {
        credential: {
          name,
          active: true,
        },
      },
    };

    if (name !== credential.name) {
      users
        .putUserCredential(credential.user_id, credential.id, payload)
        .then(() => {
          users
            .getUserCredentials(credential.user_id)
            .then((newCredentials) => setCredentials(newCredentials))
            .catch((error) => toast.error(error));
        })
        .catch((error) => toast.error(error));
    }
  };

  const handleCloseDialog = (credential) => {
    setOpenDialog(false);
    setCsvData([CSVHeaders]);
    if (credential) onSave(credential);
  };

  const handleAddNewKey = () => {
    setName("Friendly Key Label");
    if (credentials.length === 10)
      return toast.error(
        "An individual user can only have up to 10 API keys. Please delete an existing key before creating a new one."
      );
    const { users } = VivialConnectAPI.services;

    const payload = {
      user: {
        credential: { name: "Friendly Key Label" },
      },
    };

    users
      .postUserCredentials(appState.user_id, payload)
      .then((newCredential) => {
        setSelectedCredential(newCredential);
        let data = csvData;
        data.push([
          appState.id,
          API_URL,
          newCredential.api_key,
          newCredential.api_secret,
        ]);
        setCsvData(data);

        users
          .getUserCredentials(appState.user_id)
          .then((newCredentials) => {
            setOpenDialog(true);
            setCredentials(newCredentials);
          })
          .catch((error) => toast.error(error));
      });
  };

  useEffect(() => {
    const { users } = VivialConnectAPI.services;

    users
      .getUserCredentials(appState.user_id)
      .then((newCredentials) => setCredentials(newCredentials))
      .catch((error) => toast.error(error));
  }, [appState.user_id]);

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <APIKeysBanner
          onClick={handleAddNewKey}
          credential={selectedCredential}
          openDialog={openDialog}
          handleCloseDialog={handleCloseDialog}
          apiUrl={API_URL}
          csvData={csvData}
          name={name}
          setName={setName}
          handleOnChange={handleOnChange}
          onSave={onSave}
        />
      </Grid>
      <Grid item xs={12}>
        <APIKeysTable
          credentials={credentials}
          setCredentials={setCredentials}
          name={name}
          setName={setName}
          handleOnChange={handleOnChange}
          onSave={onSave}
        />
      </Grid>
    </Grid>
  );
}
