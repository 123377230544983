import { makeStyles } from "@material-ui/core/styles";
import {
  colorExtendedGreen4,
  colorExtendedGray1,
} from "../../../../themes/palette";

const useStyles = makeStyles((theme) => ({
  paper: {
    height: "100%",
    padding: theme.spacing(2),
    borderRadius: 10,
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2, 3),
      borderRadius: 15,
    },
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(3, 4),
      borderRadius: 15,
    },
  },
  dark: {
    backgroundColor: colorExtendedGreen4,
    "& h5, & h6, & a, & a:visited, & a:hover": {
      color: theme.palette.common.white,
    },
  },
  cardHeader: {
    color: colorExtendedGreen4,
    display: "flex",
    alignItems: "center",
    "& h5, & h6": {
      textTransform: "uppercase",
    },
  },
  avatar: {
    backgroundColor: colorExtendedGray1,
    pointerEvents: "none",
    marginRight: theme.spacing(1),
  },
  block: {
    display: "block",
  },
  itemLabel: {
    color: theme.palette.common.black,
  },
  icon: {
    color: colorExtendedGreen4,
  },
}));

export default useStyles;
