/* eslint-disable no-empty */
import React, { useState, useReducer } from "react";
import { useHistory } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";

import withPageContext from "components/Context/WithPageContext";

import {
  Card as DashboardCard,
  CardContent as DashboardCardContent,
  CardHeader as DashboardCardHeader,
} from "components/Layouts/Dashboard/Card";
import AddNumbers from "./AddNumbers";
import ConfigureCallbacks from "components/Numbers/ConfigureCallbacks";

import { useStyles } from "./styles";
import { PageHeader } from "./shared.js";
import VivialConnectAPI from "api/vivial-connect";

export default withPageContext(ConfigureConnector, {
  title: "Connectors",
  subtitle: "create connector",
});

function ConfigureConnector() {
  const { validateUrl } = VivialConnectAPI.helpers.validation;
  const classes = useStyles();
  let history = useHistory();
  const [connector, setConnector] = useState({
    name: "",
  });
  const [connectorCallbacks, setConnectorCallbacks] = useReducer(
    connectorCallbackReducer,
    {}
  );
  const [connectorNumbers, setConnectorNumbers] = useState([]);
  const [connectorError, setConnectorError] = useState("");
  const [callbackErrors, setCallbackErrors] = useState({
    status: false,
    incoming: false,
    incoming_fallback: false,
  });
  const [saveInProgress, setSaveInProgress] = useState(false);

  const handleConnectorChange = (prop) => (event) => {
    setConnector({ ...connector, [prop]: event.target.value });
  };

  function connectorCallbackReducer(prevCallbacks, callbackTree) {
    for (const callback_type in callbackTree) {
      if (callbackTree[callback_type].url !== "") {
        //Copy callbacks that have a URL prop
        prevCallbacks[callback_type] = { ...callbackTree[callback_type] };

        if (prevCallbacks[callback_type].auth_type === "NONE") {
          //delete credentials if auth_type is not set
          delete prevCallbacks[callback_type].credentials;
        }
      } else {
        //Delete callbacks with no URL prop
        delete prevCallbacks[callback_type];
      }
    }

    return prevCallbacks;
  }

  const handleCallbackChange = (callbackTree) => {
    setConnectorCallbacks(callbackTree);
  };

  const handleNumberChange = (numberArray) => {
    const selectedNumberIds = numberArray.map((num) => {
      return { phone_number_id: num.phone_number_id };
    });
    setConnectorNumbers(selectedNumberIds);
  };

  /******************************************************************************
  Example API payload:
  {
    connector: {
      name: "name",
      callbacks: [
        "message_type": "text",
        "event_type": "status",
        "url": "https://example.com",
        "method": "POST",
        "auth_type": "BASIC",
        "credentials": {
            "username": "uname",
            "password": "pword"
        },
      ]
    }
  }
******************************************************************************/

  const validate = () => {
    let isValid = true;

    //valdate name
    if (connector.name === "") {
      isValid = false;
      let errStr = "Please choose a Connector name";
      setConnectorError(errStr);
      toast.error(errStr);
    } else {
      setConnectorError("");
    }

    let callbackErr = false;
    let newCallbackErrors = {
      status: false,
      incoming: false,
      incoming_fallback: false,
    };

    try {
      if (!validateUrl(connectorCallbacks.status.url)) {
        callbackErr = true;
        newCallbackErrors.status = true;
      }
    } catch (e) {}

    try {
      if (!validateUrl(connectorCallbacks.incoming.url)) {
        callbackErr = true;
        newCallbackErrors.incoming = true;
      }
    } catch (e) {}

    try {
      if (!validateUrl(connectorCallbacks.incoming_fallback.url)) {
        callbackErr = true;
        newCallbackErrors.incoming_fallback = true;
      }
    } catch (e) {}

    setCallbackErrors(newCallbackErrors);
    if (callbackErr) {
      isValid = false;
      toast.error("Invalid callback URL");
    }

    return isValid;
  };

  const onSubmitConnector = () => {
    if (saveInProgress) return;
    const { connectors } = VivialConnectAPI.services;
    let newCallbacks = [];

    if (!validate()) {
      return;
    }

    //Transform callback object into an Array
    for (const callback_type in connectorCallbacks) {
      connectorCallbacks[callback_type].message_type = "text";
      connectorCallbacks[callback_type].event_type = callback_type;
      newCallbacks.push(connectorCallbacks[callback_type]);
    }

    const payload = {
      connector: {
        callbacks: newCallbacks,
        name: connector.name,
        phone_numbers: connectorNumbers,
      },
    };

    setSaveInProgress(true);
    connectors
      .postConnectors(payload)
      .then(() => {
        toast.success("New Connector Created");
        history.push("/dashboard/connectors");
      })
      .catch((error) => toast.error(error))
      .finally(() => {
        setSaveInProgress(false);
      });
  };

  const pageHeaderButtons = (
    <Button
      color="primary"
      variant="outlined"
      startIcon={<ArrowLeftIcon />}
      component={NavLink}
      to={"/dashboard/connectors"}
    >
      Manage Connectors
    </Button>
  );

  return (
    <div className={classes.root} spacing={2}>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <PageHeader>{pageHeaderButtons}</PageHeader>
        </Grid>
        <Grid item xs={12} lg={6}>
          <form
            autoComplete="off"
            onSubmit={(event) => {
              event.preventDefault();
              onSubmitConnector(event);
            }}
          >
            <DashboardCard>
              <DashboardCardHeader title="Create Connector" />
              <DashboardCardContent>
                <Grid item xs={12} className={classes.formButtons}>
                  <FormControl fullWidth>
                    <TextField
                      variant="outlined"
                      label="Connector Name"
                      name="connector_name"
                      value={connector.name}
                      onChange={handleConnectorChange("name")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={connectorError !== ""}
                      helperText={connectorError}
                    />
                  </FormControl>
                </Grid>
                <ConfigureCallbacks
                  callbacks={connectorCallbacks}
                  callbackErrors={callbackErrors}
                  handleChange={handleCallbackChange}
                />
                <Grid item xs={12} className={classes.alignRight}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    startIcon={
                      saveInProgress ? (
                        <CircularProgress
                          size={24}
                          color="inherit"
                          disableShrink
                        />
                      ) : null
                    }
                  >
                    Create Connector
                  </Button>
                </Grid>
              </DashboardCardContent>
            </DashboardCard>
          </form>
        </Grid>
        <Grid item xs={12} lg={6}>
          <DashboardCard>
            <DashboardCardHeader title={"Add numbers"} />
            <DashboardCardContent alignContent="flex-start" spacing={0}>
              <AddNumbers
                connectorNumbers={connectorNumbers}
                handleChange={handleNumberChange}
              />
            </DashboardCardContent>
          </DashboardCard>
        </Grid>
      </Grid>
    </div>
  );
}
