import endpoints from "./endpoints.js";
import { factory } from "vivialconnect-js-core";

//NOTE: do not use spread syntax here.
//serviceHelpers == {init, http, appSessionStore}
const serviceHelpers = factory.initService(endpoints);

const getReferralCodes = async (id, params) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.get(
      endpoints.referralCodes(id ? id : appSessionStore.getAccountId()),
      { params }
    );
    return data.referral_codes;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getReferralCodesCount = async (id, params) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.get(
      endpoints.referralCodesCount(id ? id : appSessionStore.getAccountId()),
      { params }
    );
    return data.count;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

export default {
  init: serviceHelpers.init,
  getReferralCodes,
  getReferralCodesCount,
};
