import React from "react";

import { GetContentfulPage, GetAdditionalContent } from "api/contentful/helper";

import HTMLHead from "components/HTMLHead";

import Hero from "components/Layouts/Brochure/Hero";
import Section from "components/Layouts/Brochure/Section";
import UnorderedList from "components/Layouts/Brochure/Lists/UnorderedList";
import PreFooter from "components/Layouts/Brochure/Footer/PreFooter";

export default function Products() {
  const [page, setPage] = React.useState(null);
  const [heroContent, setHeroContent] = React.useState({});
  const [featureList, setFeatureList] = React.useState({});

  //Get page from contentful on load
  React.useEffect(() => {
    GetContentfulPage("products-and-services", setPage);
  }, []);

  //Extract content from contentful content model
  React.useEffect(() => {
    if (!page) return;
    const { fields } = page;

    setHeroContent({
      imageURL: fields.heroImg.fields.file.url,
      header1: fields.heroHdr1,
      header2: fields.heroHdr2,
      button: fields.heroBtn,
    });

    GetAdditionalContent(page, "itemList", "feature_list", setFeatureList);
  }, [page]);

  if (!page) return null;

  return (
    <>
      <HTMLHead page={page} />
      <Hero {...heroContent} />

      <Section>
        <UnorderedList
          {...featureList}
          setImageWidth={false}
          contentField="longDescription"
        />
      </Section>

      <PreFooter />
    </>
  );
}
