/* eslint-disable react/prop-types */
import React from "react";
import { toast } from "react-toastify";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import VivialConnectAPI from "api/vivial-connect";

import { useAppContext } from "components/Context";
import useStyles from "./styles";

export default function ConfirmPassword({
  title,
  warningMessage,
  clickableComponent,
  onSubmit,
}) {
  const { appState } = useAppContext();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [values, setValues] = React.useState({
    password: "",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickShowPassword = (name) => {
    const newValues = { ...values };
    newValues[name] = !values[name];
    setValues({ ...newValues });
  };

  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value ? event.target.value : "",
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleOnSubmit = (event) => {
    event.preventDefault();
    const { users } = VivialConnectAPI.services;
    const { password } = values;

    const payload = {
      user: {
        password,
      },
    };

    users
      .postUserPasswordVerify(appState.user_id, payload)
      .then(() => {
        onSubmit();
        handleClose();
      })
      .catch((error) => toast.error(error));
  };

  return (
    <>
      <div onClick={handleClickOpen}>{clickableComponent}</div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        className={classes.dialog}
      >
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{warningMessage}</DialogContentText>
          <br />
          <br />
          <FormControl fullWidth>
            <TextField
              required
              variant="outlined"
              label="Password"
              name="password"
              id="outlined-adornment-password"
              type={values.showPassword ? "text" : "password"}
              value={values.password}
              onChange={handleChange("password")}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  handleOnSubmit(event);
                }
              }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword("showPassword")}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </DialogContent>
        <br />
        <br />
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="contained"
            className={classes.cancelPlanDialogButton}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            onClick={handleOnSubmit}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
