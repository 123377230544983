import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  dialog: {
    "& > div > div": {
      padding: 30,
    },
  },
  dialogTitle: {
    textTransform: "uppercase",
  },
}));

export default useStyles;
