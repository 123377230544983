import React from "react";
import { useParams } from "react-router-dom";
import { format } from "date-fns";

import { GetContentfulBlogPost } from "api/contentful/helper";

import HTMLHead from "components/HTMLHead";

import Hero from "components/Layouts/Brochure/Hero";
import Section from "components/Layouts/Brochure/Section";
import RichText from "components/Layouts/Brochure/RichText";

export default function BlogPost() {
  const [post, setPost] = React.useState(null);
  const [heroContent, setHeroContent] = React.useState({});
  const { slug } = useParams();

  //Get post from contentful on load
  React.useEffect(() => {
    GetContentfulBlogPost(slug, setPost);
  }, [slug]);

  //Extract content from contentful content model
  React.useEffect(() => {
    if (!post) return;
    const { fields } = post;

    setHeroContent({
      imageURL: fields.image ? fields.image.fields.file.url : undefined,
      header1: "blog",
      header2: fields.header ? fields.header : undefined,
      date: fields.created ? format(Date.parse(fields.created), "MMMM do, yyyy") : undefined,
    });
  }, [post]);

  if (!post) return null;

  return (
    <>
      <HTMLHead page={post} />
      <Hero {...heroContent} />

      <Section className="narrow">
        <RichText>{post.fields.body}</RichText>
      </Section>
    </>
  );
}
