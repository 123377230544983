import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import withPageContext from "components/Context/WithPageContext";
import {
  Card as DashboardCard,
  CardHeader as DashboardHeader,
  CardContent as DashboardCardContent,
} from "components/Layouts/Dashboard/Card";
import CampaignsTable from "./CampaignsTable";
import { useStyles } from "./styles";

import VivialConnectAPI from "api/vivial-connect";

export default withPageContext(Campaigns, {
  title: "Campaigns",
  subtitle: "My Campaigns",
});

function Campaigns() {
  const classes = useStyles();
  const [campaignId, setCampaignId] = useState("");
  const [isEnabled, setIsEnabled] = useState(false);
  const { accounts, campaigns } = VivialConnectAPI.services;

  const handleCampaignChange = (event) => {
    setCampaignId(event.target.value);
  };

  const handleSubmit = () => {
    const payload = {
      campaign_id: campaignId,
    };

    campaigns
      .addCampaign(payload)
      .then(() => {
        toast.success(`Campaign imported`);
        setCampaignId("");
      })
      .catch((error) => toast.error(error));
  };

  useEffect(() => {
    accounts
      .getAccountStatus()
      .then((status) => setIsEnabled(status.campaign_import_enabled))
      .catch((error) => toast.error(error));
  }, []);

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <DashboardCard>
          <DashboardCardContent
            justifyContent="space-between"
            alignItems="center"
            spacing={0}
          >
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Typography variant="body1" paragraph={true}>
                To get started follow this simple 3-step process:
              </Typography>
              <ol className={classes.orderedList}>
                <li>Step 1: Register Brand</li>
                <li>
                  <b>Step 2: Register Campaign</b>
                </li>
                <li>Step 3: Add Campaign to Numbers</li>
              </ol>
              <Typography variant="body1" paragraph={true}>
                Once you have registered a brand, the next step is to register a
                campaign by associating it to an existing brand.
              </Typography>

              <Typography variant="body1" paragraph={true}>
                Campaign fees vary depending on the use case and are billed on a
                recurring basis every 90 Days. For details see{" "}
                <Link href="/docs/10dlc-prices">
                  Campaign pricing documentation
                </Link>
                .
              </Typography>
            </Grid>
          </DashboardCardContent>
        </DashboardCard>
      </Grid>
      {isEnabled ? (
        <Grid item xs={12}>
          <DashboardCard>
            <DashboardHeader title={"Import Campaign"} />
            <DashboardCardContent>
              <Typography variant="body1" paragraph={true}>
                You may import existing campaigns into Vivial Connect if they
                are already registered with The Campaign Registry. Before
                importing a campaign below, you must select ‘Vivial Connect’ as
                the connectivity partner via the{" "}
                <Link href="https://csp.campaignregistry.com">
                  TCR interface
                </Link>
                .
              </Typography>
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  handleSubmit();
                }}
                autoComplete="off"
                className={classes.form}
              >
                <Grid container spacing={5} alignItems="center">
                  <Grid item lg={3} md={4} sm={6} xs={12}>
                    <FormControl fullWidth>
                      <TextField
                        required
                        variant="outlined"
                        id="campaignId"
                        label="Campaign ID to Import"
                        name="campaignId"
                        value={campaignId}
                        onChange={handleCampaignChange}
                        disabled={!isEnabled}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      disabled={!isEnabled}
                      fullWidth
                    >
                      Create Campaign
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </DashboardCardContent>
          </DashboardCard>
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <CampaignsTable />
      </Grid>
    </Grid>
  );
}
