// roles/endpoints.js
import { factory } from "vivialconnect-js-core";

//NOTE: do not use spread syntax here.
//helper == {init, helper.BASE_URL}
const helper = factory.initEndpoints();

export default {
  init: helper.init,
  //GET only. Returns only public roles available to clients
  roles: () => `${helper.BASE_URL}/utility/roles.json`,
};
