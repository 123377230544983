import { makeStyles } from "@material-ui/core/styles";
import {
  colorExtendedGray2,
  colorExtendedGray4,
  colorExtendedGreen4,
  colorPrimaryBlack,
} from "themes/palette";
import { red } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  bannerWrapper: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  bannerHeader: {
    color: colorExtendedGray4,
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  rowWrapper: {
    "&:hover": {
      "& td, & th": {
        backgroundColor: "rgba(0,0,0,0.2)",
      },
      "& button": {
        visibility: "visible",
      },
    },
  },
  tablePagination: {
    display: "flex",
    width: "100%",
    marginTop: 20,
    justifyContent: "center",
  },
  tableColumnHeader: {
    color: colorExtendedGreen4,
  },
  tableDeleteButton: {
    color: red["A700"],
  },
  tableClickableCell: {
    cursor: "pointer",
  },
  keyTitle: {
    color: colorExtendedGreen4,
    fontWeight: "bold",
  },
  warningText: {
    color: red["400"],
  },
  copyButton: {
    visibility: "hidden",
    paddingTop: theme.spacing(0.75),
  },
  dialog: {
    "& > div > div": {
      padding: 30,
    },
  },
  closeDialog: {
    "& :hover": {
      color: colorPrimaryBlack,
    },
  },
  tokenTitle: {
    fontWeight: "bolder",
  },
  tokensWrapper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  keyLabel: {
    color: colorExtendedGreen4,
  },
  keyField: {
    minWidth: 160,
    padding: theme.spacing(1),
  },
  multipleSelect: {
    "&.Mui-selected": {
      backgroundColor: colorExtendedGray2,
    },
  },
  selectMainOption: {
    textTransform: "capitalize",
    fontWeight: "bold",
  },
}));

export default useStyles;
