import React from "react";
import Grid from "@material-ui/core/Grid";

import TransactionsPane from "@moosetalk-frontend/global/components/TransactionsPane";

import withPageContext from "components/Context/WithPageContext";
import VivialConnectAPI from "api/vivial-connect";

const { accounts } = VivialConnectAPI.services;

export default withPageContext(BillingHistory, {
  title: "Billing",
  subtitle: "payments & transactions history",
});

function BillingHistory() {
  const getAccountTransactions = (params) => {
    return accounts.getAccountTransactions(params);
  };

  const getAccountTransactionsCount = (params) => {
    return accounts.getAccountTransactionsCount(params);
  };

  return (
    <div>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <TransactionsPane
            title="Transaction History"
            helperService={VivialConnectAPI.helpers}
            getAccountTransactions={getAccountTransactions}
            getAccountTransactionsCount={getAccountTransactionsCount}
            useLocalTime={true}
          />
        </Grid>
      </Grid>
    </div>
  );
}
