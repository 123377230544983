import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "inherit",
    height: "inherit",
    overflow: "auto",
  },
  content: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  children: {
    height: "100%",
    display: "flex",
    flexGrow: 1,
    overflow: "hidden",
  },
  paper: {
    height: "inherit",
    flexGrow: 1,
    borderRadius: 0,
    overflow: "hidden",
    overflowY: "auto",
    padding: "15px",
    [theme.breakpoints.up("sm")]: {
      padding: "30px 50px",
    },
  },
  maxWidth: {
    minHeight: "100%",
    maxWidth: "1600px",
    margin: "0 auto",
    paddingBottom: "50px", //make room for footer
  },
  appBarSpacer: theme.mixins.toolbar,
}));
