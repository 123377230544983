// reports/endpoints.js
import { factory } from "vivialconnect-js-core";

//NOTE: do not use spread syntax here.
//helper == {init, BASE_URL}
const helper = factory.initEndpoints();

export default {
  init: helper.init,
  reports: (accountId) =>
    `${helper.BASE_URL}/accounts/${accountId}/reports.json`,
  report: (accountId, reportId) =>
    `${helper.BASE_URL}/accounts/${accountId}/reports/${reportId}.json`,
  reportCredit: (accountId) =>
    `${helper.BASE_URL}/accounts/${accountId}/reports/credit.json`,
  reportMessage: (accountId) =>
    `${helper.BASE_URL}/accounts/${accountId}/reports/message.json`,
  reportTransaction: (accountId) =>
    `${helper.BASE_URL}/accounts/${accountId}/reports/transaction.json`,
  reportOptout: (accountId) =>
    `${helper.BASE_URL}/accounts/${accountId}/reports/optout.json`,
};
