export const colorPrimaryVivialGreen = "#24d235";
export const colorPrimaryBlack = "#000";
export const colorPrimaryWhite = "#fff";

export const colorExtendedGreen1 = "#d8e699";
export const colorExtendedGreen2 = "#a5d05e";
export const colorExtendedGreen3 = "#58be89";
export const colorExtendedGreen4 = "#04867e";
export const colorExtendedGray1 = "#ebebeb";
export const colorExtendedGray2 = "#cdcdcd";
export const colorExtendedGray3 = "#989898";
export const colorExtendedGray4 = "#676767";
export const colorExtendedBlue = "#3C6A89";

// legacy brochure site color
export const colorBrochureGray = "#4d4d4d";

export const lightPalette = {
  action: {
    active: colorExtendedGreen4,
    selected: colorExtendedGreen4,
    disabledBackground: colorExtendedGray2,
  },
  background: {
    paper: colorPrimaryWhite,
    default: colorPrimaryWhite,
  },
  primary: {
    main: colorExtendedGreen4,
  },
  secondary: {
    main: colorExtendedGreen3,
  },
  text: {
    primary: colorExtendedGray4,
  },
};

export const darkPalette = {
  type: "dark",
  action: {
    active: colorExtendedGreen4,
    selected: colorExtendedGreen4,
  },
  background: {
    paper: colorPrimaryBlack,
    default: colorPrimaryBlack,
  },
  primary: {
    main: colorPrimaryVivialGreen,
  },
  secondary: {
    main: colorExtendedGreen3,
  },
  text: {
    primary: colorPrimaryWhite,
  },
};

export const alternativePalette = {
  action: {
    active: colorExtendedGreen4,
    selected: colorExtendedGreen4,
  },
  background: {
    paper: colorExtendedGray1,
    default: colorExtendedGray1,
  },
  primary: {
    main: colorExtendedGreen4,
  },
  secondary: {
    main: colorExtendedGreen3,
  },
  text: {
    primary: colorPrimaryBlack,
  },
};

export const brochurePalette = {
  ...lightPalette,
  //override lightPalette settings here
  action: {
    hoverOpacity: 1,
    active: colorPrimaryVivialGreen,
    selected: colorPrimaryVivialGreen,
  },
  primary: {
    main: colorPrimaryVivialGreen,
  },
  secondary: {
    main: colorExtendedGreen4,
  },
  text: {
    primary: colorBrochureGray,
  },
};

export default {
  brochurePalette,
  alternativePalette,
  darkPalette,
  lightPalette,
};
