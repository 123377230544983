import VivialConnectAPI from "api/vivial-connect";
const { validation } = VivialConnectAPI.helpers;

export const requiredFields = {
  address1: "",
  postal_code: "",
  state: "",
  city: "",
  country: "",
  first_name: "",
  last_name: "",
};

export const validateForm = (inputErrors, setInputErrors, billingContact) => {
  const newErrors = { ...inputErrors };

  let errorMessages = [];
  let hasError = false;

  const handleError = (name, msg) => {
    if (msg) errorMessages.push(msg);
    hasError = true;
    newErrors[name] = true;
  };

  for (const name in requiredFields) {
    const value = billingContact[name];

    if (!validation.validateHasData(value)) {
      hasError = true;
      newErrors[name] = true;
    } else {
      switch (name) {
        case "postal_code":
          if (billingContact.country === "United States of America") {
            if (!validation.validateUSPostalCode(value))
              handleError(name, "Invalid US Postal Code");
          } else if (billingContact.country === "Canada") {
            if (
              !validation.validateUSPostalCode(value) &&
              !validation.validateCAPostalCode(value)
            )
              handleError(name, "Invalid Canadian Postal Code");
          }
          break;
        default:
          newErrors[name] = false;
      }
    }
  }

  setInputErrors(newErrors);
  return { hasError, errorMessages };
};
