import React, { useState, useEffect, useRef } from "react";
import Grid from "@material-ui/core/Grid";

import withPageContext from "components/Context/WithPageContext";

import Payments from "./Payments";
import AddCreditCardForm from "./AddCreditCardForm";
import UpdateCreditCardForm from "./UpdateCreditCardForm";

import useStyles from "./styles";
import VivialConnectAPI from "api/vivial-connect";

export default withPageContext(BillingPayment, {
  title: "Billing",
  subtitle: "payment methods",
});

function BillingPayment() {
  const isMounted = useRef(true);
  const [open, setOpen] = useState("payments");
  const [payments, setPayments] = useState([]);
  const [selectedCreditCard, setSelectedCreditCard] = useState({});
  const classes = useStyles();

  const handleGetPaymentSources = () => {
    const { accounts } = VivialConnectAPI.services;

    accounts
      .getAccountPaymentSources()
      .then((payments) => {
        if (isMounted.current) setPayments(payments);
      })
      .catch(() => {
        if (isMounted.current) setPayments([]);
      });
  };

  const handleDisplayDefaultCard = (cardId) => {
    const updatedPayments = payments.map((paymentSource) => {
      if (paymentSource.id === cardId) {
        paymentSource.is_default = true;
      } else {
        paymentSource.is_default = false;
      }
      return paymentSource;
    });
    setPayments(updatedPayments);
  };

  useEffect(() => {
    isMounted.current = true;

    handleGetPaymentSources();

    return () => (isMounted.current = false);
  }, []);

  return (
    <div className={classes.root}>
      <Grid container spacing={5}>
        <Grid item xs={12} md={12} lg={12}>
          {open === "payments" ? (
            <Payments
              setOpen={setOpen}
              payments={payments}
              handleGetPaymentSources={handleGetPaymentSources}
              handleDisplayDefaultCard={handleDisplayDefaultCard}
              selectedCreditCard={selectedCreditCard}
              setSelectedCreditCard={setSelectedCreditCard}
              isMounted={isMounted}
            />
          ) : null}
          {open === "addCreditCard" ? (
            <AddCreditCardForm
              setOpen={setOpen}
              handleGetPaymentSources={handleGetPaymentSources}
              isMounted={isMounted}
            />
          ) : null}
          {open === "updateCreditCard" ? (
            <UpdateCreditCardForm
              setOpen={setOpen}
              selectedCreditCard={selectedCreditCard}
              handleGetPaymentSources={handleGetPaymentSources}
              isMounted={isMounted}
            />
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
}
