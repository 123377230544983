import React from "react";

import { GetContentfulPage } from "api/contentful/helper";

import HTMLHead from "components/HTMLHead";

import RichText from "components/Layouts/Brochure/RichText";
import Hero from "components/Layouts/Brochure/Hero";
import Section from "components/Layouts/Brochure/Section";

export default function Legal() {
  const [page, setPage] = React.useState(null);
  const [heroContent, setHeroContent] = React.useState({});

  //Get page from contentful on load
  React.useEffect(() => {
    GetContentfulPage("legal", setPage);
  }, []);

  //Extract content from contentful content model
  React.useEffect(() => {
    if (!page) return;
    const { fields } = page;

    setHeroContent({
      imageURL: fields.heroImg.fields.file.url,
      header1: fields.heroHdr1,
    });
  }, [page]);

  if (!page) return null;

  return (
    <>
      <HTMLHead page={page} />
      <Hero {...heroContent} />

      <Section>
        <div className="center">
          <RichText>{page.fields.content1}</RichText>
        </div>

        <RichText>{page.fields.content2}</RichText>
      </Section>
    </>
  );
}
