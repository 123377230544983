import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { ThemeProvider } from "@material-ui/core/styles";
import { lightTheme } from "themes";
import useStyles from "./styles";
import VivialConnectAPI from "api/vivial-connect";

export default function NotFound() {
  const classes = useStyles();
  const [path, setPath] = useState("/login");

  useEffect(() => {
    const { token } = VivialConnectAPI.helpers;
    if (token.get()) {
      setPath("/dashboard");
    }
  }, []);

  return (
    <ThemeProvider theme={lightTheme}>
      <Container className={classes.root}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <div className={classes.container}>
              <Typography variant="h1" className={classes.typography}>
                404
              </Typography>
            </div>
          </Grid>
          <Grid item>
            <Typography variant="h2">Page not found</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h5">
              The page you are looking for doesn&apos;t exist.
            </Typography>
          </Grid>
          <Grid item>
            <Button
              fullWidth
              component={NavLink}
              to={"/"}
              color="primary"
              variant="outlined"
              className={classes.button}
              startIcon={<ArrowBackIcon />}
            >
              Main site
            </Button>
            <Button
              fullWidth
              component={NavLink}
              to={path}
              color="primary"
              variant="contained"
              className={classes.button}
              endIcon={<ArrowForwardIcon />}
            >
              {`${path === "/login" ? "login" : "dashboard"}`}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}
