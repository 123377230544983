/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from "react";

import { toast } from "react-toastify";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

import VivialConnectAPI from "api/vivial-connect";
import { useAppContext } from "components/Context";

import {
  Card as DashboardCard,
  CardHeader as DashboardCardHeader,
  CardContent as DashboardCardContent,
} from "components/Layouts/Dashboard/Card";

import useStyles from "./styles";

const renderLabelAndValuePairs = (label, value, spacing = 5, cols = [5, 7]) => {
  if (!Array.isArray(cols)) cols = [5, 7];
  return (
    <Grid container spacing={spacing}>
      <Grid item xs={cols[0]}>
        <Typography variant="body2">{label}</Typography>
      </Grid>
      <Grid item xs={cols[1]}>
        <Typography variant="body1">{value}</Typography>
      </Grid>
    </Grid>
  );
};

const CompanyNameForm = () => {
  const { appState, setAppState } = useAppContext();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [companyName, setCompanyName] = useState(appState.company_name);

  const handleOnChange = (event) => {
    setCompanyName(event.target.value);
  };

  const handleOnClick = () => {
    const { accounts } = VivialConnectAPI.services;

    const payload = {
      account: { id: appState.id, company_name: companyName },
    };

    setLoading(true);
    accounts
      .putAccount(payload)
      .then((data) => {
        setAppState({ company_name: data.company_name });
        toast.success("Company name updated");
      })
      .catch((error) => toast.error(error))
      .finally(() => setLoading(false));
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <TextField
        required
        variant="outlined"
        value={companyName}
        onChange={handleOnChange}
        label={"Company Name"}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          endAdornment: (
            <>
              <Button
                color="primary"
                variant="contained"
                onClick={handleOnClick}
                disabled={loading}
              >
                Save
                {loading && (
                  <CircularProgress
                    size={24}
                    color="primary"
                    disableShrink
                    className={classes.spinner}
                  />
                )}
              </Button>
            </>
          ),
        }}
      />
    </div>
  );
};

export default function GeneralSettings() {
  const { appState, isAdmin } = useAppContext();
  const classes = useStyles();
  const [account, setAccount] = useState({
    company_name: "",
    id: 1,
  });
  const [options, setOptions] = useState({
    disable_international: true,
  });
  const [referralPath, setReferralPath] = useState(null);
  const WEB_URL = process.env.REACT_APP_WEB_URL;
  const API_URL = process.env.REACT_APP_BACKEND_API_URL;

  useEffect(() => {
    const { accounts, billing } = VivialConnectAPI.services;

    accounts
      .getAccount(appState.id)
      .then((newAccount) => setAccount(newAccount));

    billing.getBillingOptions().then((newOptions) => setOptions(newOptions));
  }, [appState.id]);

  useEffect(() => {
    const { referrals } = VivialConnectAPI.services;

    if (isAdmin()) {
      referrals.getReferralCodes(appState.id).then((data) => {
        if (data.length && data[0].code !== undefined) {
          setReferralPath(
            `/register?${process.env.REACT_APP_REFERRAL_KEY}=${data[0].code}`
          );
        }
      });
    }
  }, [isAdmin, appState.id]);

  return (
    <DashboardCard>
      <DashboardCardHeader title={"General Settings"} />
      <DashboardCardContent>
        <Grid item xs={12} md={6}>
          {isAdmin() ? (
            <Grid container spacing={5} alignItems="center">
              <Grid item xs={12} sm={5} md={12} lg={5}>
                <Typography variant="body2">Company Name:</Typography>
              </Grid>
              <Grid item xs={12} sm={7} md={12} lg={7}>
                <CompanyNameForm name={`${account.company_name}`} />
              </Grid>
            </Grid>
          ) : (
            renderLabelAndValuePairs("Company Name:", `${account.company_name}`)
          )}
          {/*renderLabelAndValuePairs(
            "Referral URL",
            <NavLink to="/#">{`${referral.url}`}</NavLink>
          )*/}
          {renderLabelAndValuePairs(
            <>
              International Messaging:
              <br />
              <Typography component="span" className={classes.note}>
                <Link href="mailto:support@support.vivialconnect.net">
                  Contact support
                </Link>{" "}
                to enable international messaging.
              </Typography>{" "}
            </>,
            !options.hasOwnProperty("disable_international") ||
              options.disable_international
              ? "Disabled"
              : "Enabled"
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {renderLabelAndValuePairs("Account ID:", `${account.id}`)}
          {referralPath &&
            renderLabelAndValuePairs(
              "Referral Link",
              <Link href={referralPath}>{`${WEB_URL}${referralPath}`}</Link>
            )}
          {renderLabelAndValuePairs("API URL:", API_URL)}
        </Grid>
      </DashboardCardContent>
    </DashboardCard>
  );
}
