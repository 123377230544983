import { makeStyles } from "@material-ui/core/styles";
import { colorExtendedGreen4 } from "themes/palette";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    marginTop: theme.spacing(3),
    maxHeight: 500,
  },
  tablePagination: {
    display: "flex",
    width: "100%",
    marginTop: 20,
    justifyContent: "center",
  },
  button: {
    paddingTop: theme.spacing(3),
    "& :hover": {
      color: colorExtendedGreen4,
    },
  },
  spinner: {
    position: "absolute",
  },
  textField: {
    width: 250,
  },
}));

export default useStyles;
