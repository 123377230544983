/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import { ADMIN_ROLES } from "components/Context";
import {
  Card as DashboardCard,
  CardHeader as DashboardCardHeader,
  CardContent as DashboardCardContent,
} from "components/Layouts/Dashboard/Card";

import useStyles from "./styles";
import VivialConnectAPI from "api/vivial-connect";

const { customHooks } = VivialConnectAPI.helpers;

export default function EditUserForm({ handleShowDefault, selectedUser }) {
  const isMounted = customHooks.useMountedRef();
  const classes = useStyles();

  //list of available roles
  const [roles, setRoles] = useState([]);

  //stores role OBJECT to be submitted on save
  const [selectedRole, setSelectedRole] = useState({});

  //stores role name STRING selected by drop  down
  const [userRole, setUserRole] = useState("");

  const [values, setValues] = useState({
    id: 0,
    first_name: "",
    last_name: "",
    roles: [],
  });

  const onChange = (event) => {
    const { name, value } = event.target;
    const newValues = { ...values };
    newValues[name] = value;

    setValues({
      ...newValues,
    });
  };

  const onSelectRole = async (event) => {
    setSelectedRole(
      roles.filter((role) => role.name === event.target.value)[0]
    );
    setUserRole(event.target.value);
  };

  const handleOnSubmit = (event) => {
    event.preventDefault();
    const { users } = VivialConnectAPI.services;

    const payload = {
      user: {
        ...values,
        roles: [selectedRole],
      },
    };

    users
      .putUser(values.id, payload)
      .then(() => {
        if (!isMounted.current) return;
        handleShowDefault();
      })
      .catch((error) => toast.error(error));
  };

  useEffect(() => {
    const { roles } = VivialConnectAPI.services;

    (async () => {
      const hasAdminRole = selectedUser.roles.filter((role) =>
        ADMIN_ROLES.includes(role.name)
      )[0];
      const user_role = hasAdminRole ? "AccountAdministrator" : "User";

      roles
        .getPublicRoles()
        .then((roles) => {
          setRoles(roles);

          setUserRole(user_role);
          setSelectedRole(roles.filter((role) => role.name === user_role)[0]);
        })
        .catch((error) => toast.error(error));
    })();

    setValues(selectedUser);
  }, [selectedUser]);

  const { first_name, last_name } = values;

  return (
    <DashboardCard>
      <DashboardCardHeader title={`Edit User - ${selectedUser.email}`} />
      <form onSubmit={handleOnSubmit}>
        <DashboardCardContent>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                label="First Name"
                name="first_name"
                value={first_name ? first_name : ""}
                onChange={onChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                label="Last Name"
                name="last_name"
                value={last_name ? last_name : ""}
                onChange={onChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="select-outlined-label">Role</InputLabel>
              <Select
                labelId="select-outlined-label"
                id="select-outlined"
                name="role"
                label="Role"
                value={userRole}
                onChange={onSelectRole}
              >
                <MenuItem value={"AccountAdministrator"}>
                  Account Administrator
                </MenuItem>
                <MenuItem value={"User"}>User</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.editYourProfileButton}>
              <Box>
                <Button variant="contained" onClick={handleShowDefault}>
                  Cancel
                </Button>
              </Box>
              <Box ml={2}>
                <Button type="submit" color="primary" variant="contained">
                  Save Changes
                </Button>
              </Box>
            </div>
          </Grid>
        </DashboardCardContent>
      </form>
    </DashboardCard>
  );
}
