import { useEffect, useState } from "react";
import { GetContentfulPage } from "api/contentful/helper";

export const fetchGlossary = () => {
  const [page, setPage] = useState(null);
  const [glossary, setGlossary] = useState({});
  const [heroContent, setHeroContent] = useState({});

  useEffect(() => {
    GetContentfulPage("glossary", setPage);
  }, []);

  useEffect(() => {
    if (!page) return;
    const { fields } = page;

    setHeroContent({
      imageURL: fields.heroImg.fields.file.url,
      header1: fields.heroHdr1,
      header2: fields.heroHdr2,
    });
    setGlossary(generateGlossary(page.fields.additionalContent));
  }, [page]);

  return {
    page,
    glossary,
    heroContent,
    setGlossary: (value) => setGlossary(value),
  };
};

export const generateGlossary = (data) => {
  let letters = "#ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let glossaryDict = {};
  letters.split("").forEach((letter) => {
    glossaryDict[letter] = [];
  });

  data.forEach((element) => {
    const termElement = element.fields;
    const firstChar = termElement
      ? termElement.term.charAt(0).toUpperCase()
      : null;
    if (firstChar in glossaryDict) {
      glossaryDict[firstChar].push(termElement);
    } else {
      if (firstChar) {
        glossaryDict["#"].push(termElement);
      }
    }
  });

  return glossaryDict;
};
