/* eslint-disable react/prop-types */
import React, { useState, useReducer, useEffect } from "react";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

import VivialConnectAPI from "api/vivial-connect";

const { validateUrl } = VivialConnectAPI.helpers.validation;

const callbackMethods = ["POST", "GET"];
const callbackAuths = ["NONE", "BASIC", "DIGEST"];
const callbackTypes = [
  { type: "status", name: "Message Status Callback" },
  { type: "incoming", name: "Incoming Message Callback" },
  {
    type: "incoming_fallback",
    name: "Incoming Message Fallback",
  },
];
const callbackDefault = {
  url: "",
  method: "POST",
  auth_type: "NONE",
};
const credentialsDefault = {
  username: "",
  password: "",
};

//initDataObject
//returns a clean callbackTree populated with defualt values
function initDataObject() {
  const obj = {};
  callbackTypes.forEach(({ type }) => {
    obj[type] = { credentials: {} };
    for (const key in callbackDefault) {
      obj[type][key] = callbackDefault[key];
    }
    for (const key in credentialsDefault) {
      obj[type]["credentials"][key] = credentialsDefault[key];
    }
  });
  return obj;
}

/******************************************************************************
 * ConfigureCallbacks
 *
 * The goal here is to allow users to edit callback configurations with using
 * "controlled" input fields or worrying about the different formats for
 * callbacks used by the Numbers and Connectors endpoints. Controlled inputs
 * causes unacceptably slow user experience when editing complex objects.
 *
 * ConfigureCallbacks stores callback data locally using a full CallbackTree
 * data structure like:
 *
 *    {
 *       status: {
 *         url,
 *         method,
 *         auth_type,
 *         credentials: {
 *           username,
 *           password,
 *         }
 *       },
 *       incoming: {
 *         ...
 *    }
 *
 * All callback types are always represented even if they aren't defined on
 * the parent object.
 *
 * ConfigureCallbacks accepts:
 *  1. An object containing information about the callbacks to be edited.
 *     The callback object can be empty.
 *  2. An object that can be populated with error messages.
 *  3. A method that accepts updates. The method must accept a CallbackTree
 *     object structured like the one above. This method gets on every
 *     change and always returns the full tree.
 *
 * The HOC is responsible for:
 *  1. submitting initial data formated like CallbackTree (can be partial)
 *  2. transforming the updated callback data back to whatever format it
 *     it requires.
 *
 * Most of the complexity arises from the need to avoid controlled inputs
 * and useState - which is bad at storing/updating complex objects. The PROBLEM
 * is that using a plain object + a reducer to store state doesn't force React
 * to rerender on updates.
 *
 *
 * TODO:
 *   - refactor so connector/number edit pages don't need to define a reducer
 *   - only track data in a single object ()
 *   - find a good mechanism to force a rerender after the callbacks are
 *      initalized
 *
 ******************************************************************************/

export default function ConfigureCallbacks({
  callbacks,
  callbackErrors,
  handleChange = () => {},
}) {
  const [showAuth, setShowAuth] = useState({
    status: false,
    incoming: false,
    incoming_fallback: false,
  });

  // data:
  // local var for maintaining CallbackTree state. Data gets updated on each
  // change and passed back to HOC.
  const [data, setData] = useReducer(dataReducer, initDataObject());

  // the reducer simply copies new data into the CallbackTree structure in an
  // efficient manner.
  function dataReducer(prevData, newData) {
    for (const callback_type in prevData) {
      if (newData[callback_type]) {
        for (const prop in newData[callback_type]) {
          if (prop === "credentials") {
            for (const cred in newData[callback_type][prop]) {
              prevData[callback_type][prop][cred] =
                newData[callback_type][prop][cred];
            }
          } else {
            prevData[callback_type][prop] = newData[callback_type][prop];
          }
        }
      }
    }
    return prevData;
  }

  // make sure URLs are valid
  const validate = (callback_type, callback_name, value) => {
    let hasError = false;
    if (callback_name === "url" && value !== "") {
      if (!validateUrl(value)) {
        hasError = true;
      }
    }
    return hasError;
  };

  // handleLocalChange
  // copy data from uncontrolled inputs into Data and trigger the HOC callback
  const handleLocalChange = (callback_type, callback_name, credential) => (
    event
  ) => {
    //validate form
    const isValid = validate(callback_type, callback_name, event.target.value);

    if (credential) {
      setData({
        [callback_type]: {
          [callback_name]: {
            [credential]: event.target.value,
          },
        },
      });
    } else {
      setData({
        [callback_type]: {
          [callback_name]: event.target.value,
        },
      });
    }

    //show auth?
    if (callback_name === "auth_type") {
      setShowAuth({
        ...showAuth,
        [callback_type]: event.target.value !== callbackDefault.auth_type,
      });
    }

    //Update parent
    handleChange(data, isValid);
  };

  //The typical lifecycle for ConfigureCallbacks involves multiple renders
  //with incomplete callback data before the final render. Making sure Data
  //is properly initialized has been tricky. A useEffect is the best approach
  //I've found so far but it has its limitations.
  //
  //NOTE: Data is not fully initialized when the text inputs are rendered for
  //the final time. Consequently, it can not be used to load default values
  //into inputs. See below where we use the callbacks object directly to
  //initialize the inputs.
  useEffect(() => {
    const initData = initDataObject();

    callbackTypes.forEach(({ type }) => {
      if (callbacks[type]) {
        //set URL, Method, and Auth Type values for each callback type
        for (const key in callbackDefault) {
          if (callbacks[type][key]) {
            initData[type][key] = callbacks[type][key];
          }
        }

        //credentials aren't actually returned from the API, so this block
        //should never run. Including it for completeness.
        if (callbacks[type]["credentials"]) {
          for (const key in credentialsDefault) {
            initData[type]["credentials"][key] =
              callbacks[type]["credentials"][key];
          }
        }
      }
    });
    setData(initData);
  }, [
    callbacks,
    callbacks.status,
    callbacks.incoming,
    callbacks.incoming_fallback,
    //NOTE: need to explicity include each type in this block or data will go missing
  ]);

  return (
    <>
      {callbackTypes.map((callback, index) => {
        return (
          <EditCallbackForm
            key={`editform_${index}`}
            callback_type={callback.type}
            callback_name={callback.name}
          />
        );
      })}
    </>
  );

  // Generate uncontrolled input fields
  function EditCallbackForm({ callback_type, callback_name }) {
    return (
      <>
        <Grid item xs={12}>
          <Typography variant="body1">{callback_name}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={12} lg={6}>
          <FormControl fullWidth>
            <TextField
              variant="outlined"
              label="Callback URL"
              name={`${callback_type}_url`}
              onChange={handleLocalChange(callback_type, "url")}
              defaultValue={
                callbacks[callback_type]
                  ? callbacks[callback_type].url
                  : callbackDefault.url
              }
              InputLabelProps={{
                shrink: true,
              }}
              error={callbackErrors[callback_type]}
              helperText={
                callbackErrors[callback_type] ? "Please enter a valid URL" : ""
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={3} md={6} lg={3}>
          <FormControl fullWidth>
            <TextField
              select
              variant="outlined"
              label="Method"
              name={`${callback_type}_method`}
              defaultValue={
                callbacks[callback_type]
                  ? callbacks[callback_type].method
                  : callbackDefault.method
              }
              onChange={handleLocalChange(callback_type, "method")}
              InputLabelProps={{
                shrink: true,
              }}
            >
              {callbackMethods.map((item, key) => (
                <MenuItem key={key} value={item}>
                  {item}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={3} md={6} lg={3}>
          <FormControl fullWidth>
            <TextField
              select
              variant="outlined"
              label="Auth"
              name={`${callback_type}_auth_type`}
              defaultValue={
                callbacks[callback_type]
                  ? callbacks[callback_type].auth_type
                  : callbackDefault.auth_type
              }
              onChange={handleLocalChange(callback_type, "auth_type")}
              InputLabelProps={{
                shrink: true,
              }}
            >
              {callbackAuths.map((item, key) => (
                <MenuItem key={key} value={item}>
                  {item}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{ display: showAuth[callback_type] ? "block" : "none" }}
        >
          <FormControl fullWidth>
            <TextField
              variant="outlined"
              label="Auth Username"
              name={`${callback_type}_auth_username`}
              //NOTE: credentials never get passed in from HOC so we probably
              //don't need to check for callbacks[callback_type].credentials
              //however, I am including it here just in case.
              defaultValue={
                callbacks[callback_type] && callbacks[callback_type].credentials
                  ? callbacks[callback_type].credentials.username
                  : credentialsDefault.username
              }
              onChange={handleLocalChange(
                callback_type,
                "credentials",
                "username"
              )}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{ display: showAuth[callback_type] ? "block" : "none" }}
        >
          <FormControl fullWidth>
            <TextField
              variant="outlined"
              label="Auth Password"
              name={`${callback_type}_auth_password`}
              type="password"
              //NOTE: credentials never get passed in from HOC so we probably
              //don't need to check for callbacks[callback_type].credentials
              //however, I am including it here just in case.
              defaultValue={
                callbacks[callback_type] && callbacks[callback_type].credentials
                  ? callbacks[callback_type].credentials.password
                  : credentialsDefault.password
              }
              onChange={handleLocalChange(
                callback_type,
                "credentials",
                "password"
              )}
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="new-password"
            />
          </FormControl>
        </Grid>
      </>
    );
  }
}
