/* eslint-disable react/prop-types */
import React from "react";
import { CSVLink } from "react-csv";
import Link from "@material-ui/core/Link";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import FileCopyIcon from "@material-ui/icons/FileCopy";

import {
  Card as DashboardCard,
  CardContent as DashboardCardContent,
} from "components/Layouts/Dashboard/Card";

import useStyles from "./styles";

export default function TokensBanner({
  onClick,
  openDialog,
  handleShowDialog,
  handleCloseDialog,
  token,
  apiUrl,
  csvData,
  description,
  handleOnChange,
  scopeName,
  scopes,
  handleChangeScope,
  tokenCreated,
  copyToClipboard,
}) {
  const classes = useStyles();

  return (
    <DashboardCard>
      <DashboardCardContent>
        <div className={classes.bannerWrapper}>
          <Grid item xs={8}>
            <Typography variant="body1">
              Personal Access Tokens provide restricted access to the API. They
              allow messaging and number management, but can not access billing
              or account management features. Select the appropriate scopes to
              grant access to specific Vivial Connect features.
            </Typography>
            <br />
            <Typography variant="body1">
              For full API access use{" "}
              <Link href="/dashboard/account/keys">API Keys</Link> with HMAC or
              Basic Auth. See the{" "}
              <Link
                href={`${process.env.REACT_APP_DOCSITE_URL}/quickstart/authentication.html`}
              >
                Vivial Connect API Documentation
              </Link>{" "}
              for more information on authentication options.
            </Typography>
          </Grid>
          <Grid item xs={4} className={classes.buttonWrapper}>
            <Button
              color="primary"
              variant="outlined"
              startIcon={<VpnKeyIcon />}
              onClick={handleShowDialog}
            >
              New Token
            </Button>
            <Dialog
              fullWidth
              maxWidth="sm"
              open={openDialog}
              onClose={() => handleCloseDialog()}
              aria-labelledby="api-key-dialog"
              className={classes.dialog}
            >
              <DialogTitle id="api-key-dialog">
                {tokenCreated
                  ? "Personal Access Token"
                  : "Create Personal Access Token"}
              </DialogTitle>
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  onClick(token);
                }}
              >
                <DialogContent>
                  <DialogContentText>
                    The Personal Access Token is only displayed one time. Once
                    created, please copy and paste it to a safe place. Once you
                    leave this page, you will not be able to access it again.
                  </DialogContentText>
                  <Grid container spacing={2} className={classes.tokensWrapper}>
                    {tokenCreated ? (
                      <>
                        <Grid item xs={12}>
                          <Typography
                            variant="body1"
                            className={classes.tokenTitle}
                          >
                            Access Token
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          {token.access_token}
                          <IconButton
                            onClick={(e) =>
                              copyToClipboard(e, token.access_token)
                            }
                          >
                            <FileCopyIcon />
                          </IconButton>
                        </Grid>
                      </>
                    ) : null}
                    <Grid item xs={12}>
                      <Typography
                        variant="body1"
                        className={classes.tokenTitle}
                      >
                        API URL:
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {apiUrl}
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="body1"
                        className={classes.tokenTitle}
                      >
                        Token Scope:
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id="scope-select">Scope</InputLabel>
                        <Select
                          required
                          multiple
                          fullWidth
                          labelId="scope-select"
                          id="scope-multiple-select"
                          variant="outlined"
                          label="Scope"
                          value={scopeName}
                          onChange={handleChangeScope}
                          disabled={tokenCreated}
                          renderValue={(selected) => selected.join(", ")}
                          MenuProps={{ getContentAnchorEl: null }}
                        >
                          {scopes.map((item, key) => (
                            <MenuItem
                              key={key}
                              value={item.name}
                              className={classes.multipleSelect}
                            >
                              <Checkbox
                                checked={scopeName.indexOf(item.name) > -1}
                              />
                              <ListItemText
                                primary={
                                  <>
                                    <span className={classes.selectMainOption}>
                                      {item.name}
                                    </span>{" "}
                                    - {item.description}
                                  </>
                                }
                              />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="body1"
                        className={classes.tokenTitle}
                      >
                        Description:
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {tokenCreated ? (
                        token.description
                      ) : (
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="Token Description"
                          name={`token`}
                          value={description ? description : ""}
                          onChange={handleOnChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  {tokenCreated ? (
                    <>
                      <Button
                        variant="contained"
                        onClick={handleCloseDialog}
                        className={classes.closeDialog}
                      >
                        Close
                      </Button>
                      <Button
                        color="primary"
                        variant="contained"
                        component={CSVLink}
                        data={csvData}
                        filename={"personal_token.csv"}
                        target="_blank"
                      >
                        Download Access Token
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        onClick={handleCloseDialog}
                        className={classes.closeDialog}
                      >
                        Close
                      </Button>
                      <Button variant="contained" color="primary" type="submit">
                        Create Token
                      </Button>
                    </>
                  )}
                </DialogActions>
              </form>
            </Dialog>
          </Grid>
        </div>
      </DashboardCardContent>
    </DashboardCard>
  );
}
