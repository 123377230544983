/* eslint-disable react/prop-types */
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Section from "../Section";

export const usePreFooterStyles = makeStyles((theme) => ({
  root: {
    //TODO: add this color to theme? Possible this is the only place it's used
    backgroundColor: "#232323",
    textAlign: "center",

    "& a": {
      marginBottom: "20px",
    },
    "& h3": {
      fontFamily: theme.typography.fontTitle,
      borderWidth: "0px",
      margin: "0 0 20px",
      color: theme.palette.common.white,
    },
    "& p": {
      fontSize: "20px",
      marginBottom: "20px",
      color: theme.palette.common.white,
    },
  },
}));


export function PreFooter() {
  const classes = usePreFooterStyles();

  return (
    <Section className={classes.root}>
    </Section>
  );
}

export default PreFooter;
