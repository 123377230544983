import endpoints from "./endpoints";
import { factory } from "vivialconnect-js-core";

//NOTE: do not use spread syntax here.
//serviceHelpers == {init, http, appSessionStore}
const serviceHelpers = factory.initService(endpoints);

const postPhoneVerify = async (payload) => {
  const { http } = serviceHelpers;
  try {
    const { data } = await http.post(`${endpoints.phoneNumber()}`, payload);

    return data.phone_number;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

export default {
  init: serviceHelpers.init,
  postPhoneVerify,
};
