import MuiButton from "./MuiButton";
import MuiSvgIcon from "./MuiSvgIcon";
import MuiTableHead from "./MuiTableHead";
import MuiTableCell from "./MuiTableCell";
import MuiAvatar from "./MuiAvatar";
import MuiTypography from "./MuiTypography";
import MuiCssBaseline from "./MuiCssBaseline";
import MuiLink from "./MuiLink";
import MuiInputBase from "./MuiInputBase";
import MuiInputLabel from "./MuiInputLabel";
import MuiOutlinedInput from "./MuiOutlinedInput";
import MuiFormHelperText from "./MuiFormHelperText";

export const lightTheme = {
  MuiAvatar: MuiAvatar.lightTheme,
  MuiButton: MuiButton.lightTheme,
  MuiTableHead: MuiTableHead.lightTheme,
  MuiTableCell: MuiTableCell.lightTheme,
  MuiFormHelperText: MuiFormHelperText.lightTheme,
};

export const darkTheme = {
  MuiAvatar: MuiAvatar.darkTheme,
  MuiButton: MuiButton.darkTheme,
  MuiSvgIcon: MuiSvgIcon.darkTheme,
  MuiTableHead: MuiTableHead.darkTheme,
  MuiTableCell: MuiTableCell.darkTheme,
  MuiTypography: MuiTypography.darkTheme,
  MuiInputLabel: MuiInputLabel.darkTheme,
  MuiInputBase: MuiInputBase.darkTheme,
  MuiOutlinedInput: MuiOutlinedInput.darkTheme,
  MuiLink: MuiLink.darkTheme,
  MuiFormHelperText: MuiFormHelperText.lightTheme,
};

export const brochureTheme = {
  MuiAvatar: MuiAvatar.lightTheme,
  MuiButton: MuiButton.brochureTheme,
  MuiTypography: MuiTypography.brochureTheme,
  MuiCssBaseline: MuiCssBaseline.brochureTheme,
  MuiLink: MuiLink.brochureTheme,
};

export default {
  darkTheme,
  lightTheme,
  brochureTheme,
};
