/* eslint-disable react/prop-types */
import React, { useState } from "react";

import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";

import ReCAPTCHA from "react-google-recaptcha";

import VivialConnectAPI from "api/vivial-connect";
import config from "config/app";

import Subtitle from "components/Layouts/Brochure/Subtitle";
import BrochureButton from "components/Layouts/Brochure/BrochureButton";

import { toast } from "react-toastify";

const {
  validatePhone,
  validateEmail,
  validateUrl,
} = VivialConnectAPI.helpers.validation;

const fieldNameObj = {
  first_name: "",
  last_name: "",
  company_name: "",
  email: "",
  mobile_phone: "",
  website: "",
  comment: "",
  address: "",
};

const addressFieldNameObj = {
  city: "",
  state: "",
  postal: "",
  country: "",
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root, & .MuiFormControl-root": {
      marginBottom: theme.spacing(4),
    },
    "& .MuiFormLabel-root": {
      fontWeight: 700,
      color: theme.palette.text.primary,
    },
    "& .MuiFormLabel-asterisk": {
      color: theme.palette.error.main,
    },
  },
  anchor: {
    position: "relative",
    height: 0,
  },
  success: {
    textAlign: "center",
    "& p": {
      marginBottom: "30px",
    },
  },
}));

export default function PartnersForm({ header }) {
  const classes = useStyles();

  /****************************************************************************
   * FORM DATA CONTROLLER
   ****************************************************************************/
  const [formData, setFormData] = useState({
    ...fieldNameObj,
  });
  const [addressFormData, setAddressFormData] = useState({
    ...addressFieldNameObj,
  });
  const handleChange = (prop) => (event) => {
    setFormData({ ...formData, [prop]: event.target.value });
  };
  const handleAddressChange = (prop) => (event) => {
    setAddressFormData({ ...addressFormData, [prop]: event.target.value });
    setFormData({
      ...formData,
      address: Object.values(addressFormData).join(", "),
    });
  };

  /****************************************************************************
   * ReCAPTCHA CONTROLLER
   ****************************************************************************/
  const recaptchaRef = React.createRef();
  const handleRecaptchaChange = (value) => {
    recaptchaRef.current.value = value;
  };

  /****************************************************************************
   * FORM ERROR CONTROLLER
   ****************************************************************************/
  const [errors, setErrors] = useState({
    ...fieldNameObj,
    ...addressFieldNameObj,
    reCaptcha: "",
  });
  const validateForm = () => {
    let errorFound = false;

    // EMAIL
    if (!validateEmail(formData.email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Please enter a valid email",
      }));
      errorFound = true;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, email: "" }));
    }

    // PHONE
    if (!validatePhone(formData.mobile_phone)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        mobile_phone: "Please enter a valid phone number",
      }));
      errorFound = true;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, mobile_phone: "" }));
    }

    // WEBSITE
    if (formData.website !== "" && !validateUrl(formData.website)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        website: "Enter a valid website url starting with http:// or https://",
      }));
      errorFound = true;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, website: "" }));
    }

    // RECAPTCHA
    if (recaptchaRef.current.value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        reCaptcha: "",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        reCaptcha: "Please solve the reCaptcha",
      }));
      errorFound = true;
    }

    return errorFound;
  };

  /****************************************************************************
   * FORM SUBMIT HANDLER
   ****************************************************************************/
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);

  const handleSubmit = async () => {
    if (validateForm()) {
      window.location.href = "#form";
    } else {
      const { mailings } = VivialConnectAPI.services;
      const payload = {
        ...formData,
        "g-recaptcha-response": recaptchaRef.current.value,
      };

      mailings
        .postPartnerSignup(payload)
        .then(() => {
          setIsSubmitSuccessful(true);
        })
        .catch((error) => {
          toast.error(error);
        });
    }

    recaptchaRef.current.reset();
  };

  const PartnersTextField = ({ name, label, onChangeMethod, ...rest }) => {
    if (!name || !label) return null;
    onChangeMethod = onChangeMethod || handleChange;

    return (
      <TextField
        name={name}
        id={name}
        label={label}
        autoComplete={name}
        value={formData[name]}
        onChange={onChangeMethod(name)}
        error={errors[name] !== ""}
        helperText={errors[name]}
        fullWidth
        {...rest}
      />
    );
  };

  return (
    <>
      <a name="form" href="#form" className={classes.anchor}>
        {" "}
      </a>
      {header && (
        <div className="center">
          <Subtitle className="noBorder">{header}</Subtitle>
        </div>
      )}
      {isSubmitSuccessful ? (
        <div className={classes.success}>
          <Typography>
            Thanks for contacting us! We will get in touch with you shortly.
          </Typography>
        </div>
      ) : (
        <form
          className={classes.root}
          onSubmit={(event) => {
            event.preventDefault();
            handleSubmit();
          }}
          autoComplete="off"
        >
          <Grid container spacing={4}>
            <Grid item sm={6} xs={12}>
              {PartnersTextField({
                name: "first_name",
                label: "First Name",
                required: true,
              })}
            </Grid>
            <Grid item sm={6} xs={12}>
              {PartnersTextField({
                name: "last_name",
                label: "Last Name",
                required: true,
              })}
            </Grid>
            <Grid item sm={12} xs={12}>
              {PartnersTextField({
                name: "company_name",
                label: "Company Name",
                required: true,
              })}
            </Grid>
            <Grid item sm={6} xs={12}>
              {PartnersTextField({
                name: "email",
                label: "Email",
                required: true,
              })}
            </Grid>
            <Grid item sm={6} xs={12}>
              {PartnersTextField({
                name: "mobile_phone",
                label: "Mobile Phone",
                required: true,
              })}
            </Grid>
            <Grid item sm={6} xs={12}>
              {PartnersTextField({
                name: "city",
                label: "City",
                onChangeMethod: handleAddressChange,
              })}
            </Grid>
            <Grid item sm={6} xs={12}>
              {PartnersTextField({
                name: "state",
                label: "State",
                onChangeMethod: handleAddressChange,
              })}
            </Grid>
            <Grid item sm={6} xs={12}>
              {PartnersTextField({
                name: "postal",
                label: "Zip / Postal",
                onChangeMethod: handleAddressChange,
              })}
            </Grid>
            <Grid item sm={6} xs={12}>
              {PartnersTextField({
                name: "country",
                label: "Country",
                required: true,
                onChangeMethod: handleAddressChange,
              })}
            </Grid>
            <Grid item xs={12}>
              {PartnersTextField({
                name: "website",
                label: "Website",
                required: true,
              })}
            </Grid>
            <Grid item xs={12}>
              {PartnersTextField({
                name: "comment",
                label: "Message",
                variant: "outlined",
                multiline: true,
                rows: 4,
                required: true,
              })}
            </Grid>
            <Grid item xs={12}>
              <FormControl
                component="fieldset"
                required
                error={errors.reCaptcha !== ""}
              >
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={config.RECAPTCHA_SITE_KEY}
                  onChange={handleRecaptchaChange}
                />
                <FormHelperText>{errors.reCaptcha}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <BrochureButton type="submit">Submit</BrochureButton>
            </Grid>
          </Grid>
        </form>
      )}
    </>
  );
}
