import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import {
  Card as DashboardCard,
  CardHeader as DashboardCardHeader,
  CardContent as DashboardCardContent,
} from "components/Layouts/Dashboard/Card";

const YourPlanOnDemand = () => {

  return (
    <DashboardCard>
      <DashboardCardHeader title={"Your Plan"} />
      <DashboardCardContent>
        <Grid item xs={3}>
          <Typography variant="body2">Type:</Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography variant="h6">On-Demand</Typography>
          <p>
            Messaging costs will be deducted from your account balance in
            real-time. Standard rates apply.
          </p>
        </Grid>
      </DashboardCardContent>
    </DashboardCard>
  );
};

export default YourPlanOnDemand;
