import { colorExtendedGreen4 } from "../palette";

export const darkTheme = {
  root: {
    // TODO: review this override to see if it's necessary.
    //       Similar override in lightTheme ended up being a mistake.
    color: colorExtendedGreen4,
  },
};

export default {
  darkTheme,
};
