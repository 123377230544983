/* eslint-disable react/prop-types */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  ellipsis: {
    fontFamily: theme.typography.fontFamily,
  },
}));
export default function TruncateText({ text, length }) {
  const classes = useStyles();
  const truncatedText = text.substring(0, length);
  return (
    <>
      {truncatedText}
      {text.length > length ? (
        <span className={classes.ellipsis}>{"\u2026"}</span>
      ) : (
        ""
      )}
    </>
  );
}
