/* eslint-disable no-useless-catch */
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Link from "@material-ui/core/Link";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import Typography from "@material-ui/core/Typography";

import { useAppContext } from "components/Context";
import {
  Card as DashboardCard,
  CardContent as DashboardCardContent,
} from "components/Layouts/Dashboard/Card";
import useStyles from "./styles";
import VivialConnectAPI from "api/vivial-connect";

const csvHeaders = [
  ["Account ID", "API URL", "Access Token", "Token Description", "Scope"],
];
//Throws async & custom errors. Must be wrapped in try/catch block.
const numSearchAndPurchase = async () => {
  const { contacts, numbers } = VivialConnectAPI.services;
  const { validateAreaCode } = VivialConnectAPI.helpers.validation;
  let contact;
  let area_code;
  let number;

  /********
  1. Get contact
  *******/
  const acct_contacts = await contacts.getContacts();
  contact = acct_contacts.filter(
    (contact) => contact.contact_type === "main"
  )[0];

  /********
  2. Extract area code from contact.mobile_phone
  *******/
  let match;
  if (
    contact &&
    contact.mobile_phone &&
    (match = validateAreaCode(contact.mobile_phone))
  ) {
    //after match, area code is in array elements 1,2,3
    area_code = match.filter((m, index) => index > 0 && index < 4).join("");
  }
  if (!area_code) throw new Error("No area code found");

  /********
  3. Search for an available number with alternate area codes enabled
  *******/
  try {
    const available_numbers = await numbers.getNumberAvailableCountryCodeNumberType(
      {
        area_code: area_code,
        limit: 1,
        alternative: true,
      }
    );
    if (!available_numbers.length) throw new Error("No numbers found");
    number = available_numbers[0].phone_number;
  } catch (error) {
    throw error;
  }

  /********
  4. If we actually have a number after all that, try to buy it
  *******/
  const purchase_payload = {
    phone_number: {
      phone_number: number,
      phone_number_type: "local",
    },
  };
  try {
    const phoneNumber = await numbers.postNumbers(purchase_payload);
    return phoneNumber.phone_number;
  } catch (error) {
    throw error;
  }
};

export default function FirstLogin() {
  const { appState } = useAppContext();
  const classes = useStyles();
  const [token, setToken] = useState({});
  const [firstNumber, setFirstNumber] = useState(
    <small>
      <CircularProgress size={16} className={classes.smallSpinner} /> purchasing
      number&hellip;
    </small>
  );
  const [csvData, setCsvData] = useState(csvHeaders);
  const API_URL = process.env.REACT_APP_BACKEND_API_URL;

  useEffect(() => {
    //Defining handleNumberPurchase inside the useEffect block prevents React warnings.
    const handleNumberPurchase = async () => {
      let purchasedNumber;

      try {
        //Attempt to find a number
        purchasedNumber = await numSearchAndPurchase();
      } catch (error) {
        //Suppress errors
      }

      purchasedNumber
        ? setFirstNumber(purchasedNumber)
        : setFirstNumber(
            <small>
              <em>
                Unable to find a number in your area code.{" "}
                <Link to="/dashboard/numbers/purchase" component={NavLink}>
                  Search for numbers
                </Link>
                .
              </em>
            </small>
          );
    };

    const { numbers } = VivialConnectAPI.services;
    const params = {
      page: 1,
      limit: 1,
    };
    numbers
      .getNumbers(params)
      .then((phoneNumber) => {
        phoneNumber.length
          ? setFirstNumber(phoneNumber[0].name)
          : handleNumberPurchase();
      })
      .catch((error) => toast.error(error));
  }, []);

  useEffect(() => {
    const { oauth } = VivialConnectAPI.services;

    const payload = {
      description: "Auto-Generated Personal Token",
      scopes: ["basic", "messages", "numbers", "voice"],
    };

    oauth.postToken(payload, appState.user_id).then((newToken) => {
      setToken(newToken);
      let data = csvHeaders;
      data.push([
        appState.id,
        API_URL,
        newToken.access_token,
        newToken.description,
        newToken.scope,
      ]);
      setCsvData(data);
    });
  }, [appState, API_URL]);

  return (
    <Grid item xs={12}>
      <DashboardCard>
        <Typography
          variant="h3"
          className={`${classes.text} ${classes.upperText} ${classes.title}`}
        >
          Thanks for joining the vivial connect team!
        </Typography>
        <DashboardCardContent spacing={5}>
          <Grid item xs={12}></Grid>
          <Hidden smDown>
            <Grid item sm={1}></Grid>
          </Hidden>
          <Hidden xsDown>
            <Grid item sm={4} md={3}>
              <div className={classes.firstLoginImage}></div>
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={8} md={7}>
            <Box pb={5}>
              <Typography paragraph={true} className={classes.firstLoginText}>
                To get started, check out our{" "}
                <Link href="/docs/api">API Documentation</Link>.
              </Typography>
              <Typography paragraph={true} className={classes.firstLoginText}>
                Below is all the information you need to access the API. This is
                the only time this Personal Access Token will be displayed, so
                please <em>download it</em> and keep it somewhere safe. Don’t
                worry, if it gets lost you can always create a new token.
              </Typography>
            </Box>
            <Grid container spacing={2} className={classes.firstLoginText}>
              <Grid item xs={6} sm={3}>
                <Typography variant="body2">Account ID:</Typography>
              </Grid>
              <Grid item xs={6} sm={9}>
                {appState.id || ""}
              </Grid>
              <Grid item xs={6} sm={3}>
                <Typography variant="body2">API URL:</Typography>
              </Grid>
              <Grid item xs={6} sm={9}>
                {API_URL}
              </Grid>
              {token ? (
                <>
                  <Grid item xs={6} sm={3}>
                    <Typography variant="body2">Access Token:</Typography>
                  </Grid>
                  <Grid item xs={6} sm={9}>
                    {token.access_token}
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Typography variant="body2">Allowed Scopes:</Typography>
                  </Grid>
                  <Grid item xs={6} sm={9}>
                    {token.scope ? token.scope.split(" ").join(", ") : ""}
                  </Grid>
                </>
              ) : null}
              <Grid item xs={6} sm={3}>
                <Typography variant="body2">Phone Number:</Typography>
              </Grid>
              <Grid item xs={6} sm={9}>
                {firstNumber}
              </Grid>
              <Grid item xs={6} sm={3} />
              <Grid item xs={6} sm={9}>
                <Button
                  color="primary"
                  variant="outlined"
                  startIcon={<SystemUpdateAltIcon />}
                  component={CSVLink}
                  data={csvData}
                  filename={"personal_token.csv"}
                  target="_blank"
                >
                  Download Access Token
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Hidden smDown>
            <Grid item sm={1}></Grid>
          </Hidden>
        </DashboardCardContent>
      </DashboardCard>
    </Grid>
  );
}
