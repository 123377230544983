/* eslint-disable react/prop-types */
import React from "react";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  root: {
    margin: 0,
    padding: 0,
  },
  img: {
    verticalAlign: "middle",
    width: "100%",
    height: "auto",
  },
}));

export default function WideImage(props) {
  const classes = useStyles();
  const { imageURL, altText } = props;

  return imageURL ? (
    <div
      className={classes.root}
      style={{ backgroundImage: `url(${imageURL})` }}
    >
      <img alt={altText} src={imageURL} className={classes.img} />
    </div>
  ) : (
    ""
  );
}
