import React from "react";

import Divider from "@material-ui/core/Divider";
import makeStyles from "@material-ui/core/styles/makeStyles";

import { GetContentfulPage, GetAdditionalContent } from "api/contentful/helper";

import HTMLHead from "components/HTMLHead";

import Hero from "components/Layouts/Brochure/Hero";
import Section from "components/Layouts/Brochure/Section";
import PreFooter from "components/Layouts/Brochure/Footer/PreFooter";
import Subtitle from "components/Layouts/Brochure/Subtitle";
import RichText from "components/Layouts/Brochure/RichText";
import WideImage from "components/Layouts/Brochure/WideImage";
import BenefitsList from "components/Layouts/Brochure/Lists/BenefitsList";
import OrderedList from "components/Layouts/Brochure/Lists/OrderedList";

import PartnersForm from "./PartnersForm";

const useStyles = makeStyles(() => ({
  horizontalRule: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
  },
  footerText: {
    textAlign: "center",
    marginTop: "40px",
  },
}));

export default function Partners() {
  const classes = useStyles();
  const [page, setPage] = React.useState(null);
  const [heroContent, setHeroContent] = React.useState({});
  const [benefitsList, setBenefitsList] = React.useState({});
  const [stepsList, setStepsList] = React.useState({});

  //Get page from contentful on load
  React.useEffect(() => {
    GetContentfulPage("partners", setPage);
  }, []);

  //Extract content from contentful content model
  React.useEffect(() => {
    if (!page) return;
    const { fields } = page;

    setHeroContent({
      imageURL: fields.heroImg.fields.file.url,
      header1: fields.heroHdr1,
      header2: fields.heroHdr2,
    });

    GetAdditionalContent(page, "itemList", "partner_benefits", setBenefitsList);
    GetAdditionalContent(page, "itemList", "partner_steps", setStepsList);
  }, [page]);

  if (!page) return null;

  return (
    <>
      <HTMLHead page={page} />
      <Hero {...heroContent} />

      <Section className="narrow center">
        <Subtitle>{page.fields.header1}</Subtitle>
        <RichText>{page.fields.content1}</RichText>
      </Section>

      <WideImage
        imageURL={page.fields.image1.fields.file.url}
        altText={page.fields.header1}
      />

      <Section>
        <BenefitsList headerType="h6" {...benefitsList} />
      </Section>

      <Section bg="dark" className="narrow">
        <OrderedList {...stepsList} />
      </Section>

      <Section bg="dark">
        <Divider className={classes.horizontalRule} />
      </Section>

      <Section bg="dark" className="narrow">
        <PartnersForm header={page.fields.header2} />
        <div className={classes.footerText}>
          <RichText>{page.fields.content2}</RichText>
        </div>
      </Section>

      <PreFooter />
    </>
  );
}
