import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";

import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";

import VivialConnectAPI from "api/vivial-connect";
import { GetContentfulPage, getItemContent } from "api/contentful/helper";

import HTMLHead from "components/HTMLHead";

import Hero from "components/Layouts/Brochure/Hero";
import PreFooter from "components/Layouts/Brochure/Footer/PreFooter";
import RichText from "components/Layouts/Brochure/RichText";
import Section from "components/Layouts/Brochure/Section";
import Subtitle from "components/Layouts/Brochure/Subtitle";

import IntroItem from "./IntroItem";
import PlanCard from "./PlanCard";
import PricingTable from "./PricingTable";

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    marginBottom: theme.spacing(6),
  },
  listPrice: {
    color: "#f00",
    textDecoration: "line-through",
  },
}));

export default function Pricing() {
  const { convertNumberToCurrency } = VivialConnectAPI.helpers.numbers;
  const classes = useStyles();
  const [longcodePrices, setLongcodePrices] = useState({
    "US": {},
    "CA": {},
  });
  const [shortcodePrices, setShortcodePrices] = useState({});
  const [page, setPage] = useState(null);
  const [heroContent, setHeroContent] = useState({});
  const [introItemContent, setIntroItemContent] = useState({});

  useEffect(() => {
    const { lookups } = VivialConnectAPI.services;

    //Get page from contentful on load
    GetContentfulPage("pricing", setPage);

    // Get Longcode List Prices
    lookups
      .getLookupsPrices({
        vendor: "bandwidth",
        country_codes: ["US","CA"],
      })
      .then(
        (res) => {
          let _prices = longcodePrices;
          if("price_lookups" in res && res.price_lookups.length) {
            res.price_lookups.forEach(set => {
              if(set.code) _prices[set.code] = set;
            })
            setLongcodePrices(_prices);
          }
        }
      )
      .catch((error) => {
        toast.error(error);
      });

    // Get Shortcode List Prices
    lookups
      .getLookupsPrices({
        vendor: "mgage_atl",
        country_codes: "US",
      })
      .then(
        (res) =>
          "price_lookups" in res &&
          res.price_lookups.length &&
          setShortcodePrices(res.price_lookups[0])
      )
      .catch((error) => {
        toast.error(error);
      });
  }, []);

  //Extract content from contentful content model
  useEffect(() => {
    if (!page) return;
    const { fields } = page;

    setHeroContent({
      imageURL: fields.heroImg.fields.file.url,
      header1: fields.heroHdr1,
      header2: fields.heroHdr2,
    });

    getItemContent(page, "pricing_intro", setIntroItemContent);
  }, [page]);

  if (!page) return null;

  return (
    <>
      <HTMLHead page={page} />
      <Hero {...heroContent} />

      <Section className="noTopPadding alignCenter">
        <Subtitle>{page.fields.header1}</Subtitle>
        <IntroItem {...introItemContent} />
      </Section>

      <Section className="noTopPadding alignCenter">
        <Grid container justifyContent="center" spacing={3}>
          <Grid
            item
            xs={12}
            sm={6}
            align="right"
            className={classes.cardWrapper}
          >
            <PlanCard
              header="subscription"
              subheader="90 day plan"
              primary={
                <>
                  {longcodePrices.US.cash_prices && (
                    <div>
                      Starting at
                      <Typography variant="h5" component="h5" color="secondary">
                        5,000 Credits
                      </Typography>
                      <span className={classes.listPrice}>
                        {convertNumberToCurrency(
                          longcodePrices.US.cash_prices.sms_local_out,
                          3,
                          true
                        )}
                      </span>{" "}
                      Only{" "}
                      <span>
                        {convertNumberToCurrency(
                          longcodePrices.US.cash_prices.sms_local_out * 0.8,
                          4,
                          true
                        )}
                      </span>{" "}
                      per Credit!
                    </div>
                  )}
                </>
              }
              secondary={
                <>
                  All Inbound SMS is Free
                  <br />
                  Configurable in 1,000 Credit Increments
                  <br />
                  Credits Must Be Used Within the 90-Day Window
                  <br />
                  Subscription is Prepaid and Auto-Renews Every 90 Days
                  <br />
                  Credits are for Messaging Only
                  <br />
                  On-Demand Pricing Applies to Phone Numbers and Overages
                </>
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.cardWrapper}>
            <PlanCard
              header="on-demand"
              subheader="only buy what you need"
              primary={
                <div>
                  <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  <Typography variant="h5" component="h5" color="secondary">
                    Pay-As-You-Go
                  </Typography>
                </div>
              }
              secondary={
                <>
                  All Inbound SMS is Free
                  <br />
                  $20 Minimum to Start
                  <br />
                  Includes All Applicable Carrier Fees
                  <br />
                  <Link href="/docs/billing-plans" target="_blank">
                    View Price List
                  </Link>
                </>
              }
            />
          </Grid>
        </Grid>
      </Section>

      <Section className="noTopPadding noBottomPadding alignCenter">
        <Subtitle>{page.fields.header2}</Subtitle>
        <RichText>{page.fields.content2}</RichText>
      </Section>
      <PricingTable
        USLongcodePrices={longcodePrices.US}
        CALongcodePrices={longcodePrices.CA}
        shortcodePrices={shortcodePrices}
      />
      <Section className="noTopPadding alignCenter">
        <Typography paragraph={true} variant="body1">
          <strong>
            For a complete list of countries by zone, please see
            <Link target="_blank" href="/docs/international-prices">
              {" "}
              International SMS Prices
            </Link>
            .
          </strong>
          <br />
          <strong>
            For a complete list of 10DLC use cases, please see
            <Link target="_blank" href="/docs/10dlc-prices">
              {" "}
              10DLC Campaign Prices
            </Link>
            .
          </strong>
          <br />
          <sup>*</sup>
          Applies only to Local Numbers
          <br />
          <sup>**</sup>
          <Link to="/contact" component={NavLink}>
            Contact Us
          </Link>{" "}
          to activate your International SMS or setup your US Short Code.
        </Typography>
      </Section>

      <PreFooter />
    </>
  );
}
