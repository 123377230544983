import { makeStyles } from "@material-ui/core/styles";
import {
  colorExtendedGreen4,
  colorExtendedGray1,
  colorPrimaryBlack,
} from "themes/palette";

import { fontPrimaryTitleSubheading, fontMonospace } from "themes/typography";

export const useStyles = makeStyles((theme) => ({
  p: {
    marginBottom: 30,
    whiteSpace: "pre-wrap",
  },
  header: {
    color: colorExtendedGreen4,
    marginBottom: theme.spacing(2),
    fontWeight: 500,
  },
  h2: {
    marginTop: theme.spacing(4),
  },
  h3: {
    marginTop: theme.spacing(4),
  },
  h4: {
    marginTop: theme.spacing(2),
  },
  h5: {
    marginTop: theme.spacing(2),
  },
  h6: {
    marginTop: theme.spacing(2),
  },
  embeddedImage: {
    maxWidth: "80%",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  list: {
    marginBottom: 30,
    "& li::marker": {
      color: colorExtendedGreen4,
      fontWeight: "bold",
      fontSize: "120%",
      display: "inline-flex",
    },
    "padding-inline-start": 40,
    "& p": {
      marginBottom: ".5em",
    },
    "& ol, & ul": {
      "list-style-type": "upper-alpha",
      "& ol, & ul": {
        "list-style-type": "lower-roman",
      },
    },
  },
  codeSnippet: {
    display: "block",
    whiteSpace: "pre-wrap",
    backgroundColor: colorPrimaryBlack,
    fontFamily: fontMonospace,
    color: theme.palette.common.white,
    height: "100%",
    padding: theme.spacing(3),
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(2),
    borderRadius: 10,
    wordBreak: "break-word",
  },
}));

export const useMDStyles = makeStyles((theme) => ({
  tableContainer: {
    width: "100%",
    overflowX: "auto",
  },
  markdownStyle: {
    [theme.breakpoints.up("md")]: {
      padding: "0 15%",
    },
    "& a": {
      color: theme.palette.primary.main,
    },
    "& table": {
      minWidth: 400,
      width: "100%",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      display: "table",
      borderSpacing: 0,
      borderCollapse: "collapse",
    },
    "& thead": {
      display: "table-header-group",
      "& tr": {
        backgroundColor: "transparent",
      },
    },
    "& tbody": {
      display: "table-row-group",
      "& tr": {
        display: "table-row",
        outline: 0,
        verticalAlign: "middle",
        textAlign: "right",
      },
    },
    "& td, th": {
      display: "table-cell",
      width: "33%",
      padding: 16,
      fontSize: "1rem",
      fontFamily: fontPrimaryTitleSubheading,
      fontWeight: 400,
      lineHeight: "1.5rem",
      borderBottomStyle: "solid",
      borderBottomWidth: 1,
      borderBottomColor: colorExtendedGray1,
      textAlign: "left",
    },
    "& th": {
      color: colorExtendedGreen4,
      fontFamily: fontPrimaryTitleSubheading,
      fontWeight: 500,
      fontSize: "1.125rem",
      textAlign: "left",
      lineHeight: "1.5rem",
      padding: 16,
      borderBottomStyle: "solid",
      borderBottomWidth: 1,
      borderBottomColor: colorExtendedGray1,
    },
  },
}));
