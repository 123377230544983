import {
  subDays,
  endOfToday,
  startOfToday,
  startOfDay,
  endOfDay,
  startOfWeek,
  endOfWeek,
  subWeeks,
  startOfMonth,
  endOfMonth,
  subMonths,
  startOfYear,
  endOfYear,
  subYears,
} from "date-fns";
import { formatResultDateForLogs } from "./dataHelpers";

// Different endpoints use different date formats.
// dateFormat: public endpoint uses DynamoDB string dates
export const dateFormat = "yyyyMMdd'T'HHmmss'Z'";
// systemDateFormat: admin endpoint uses ISO8601 dates
export const systemDateFormat = "yyyy-MM-dd'T'HH:mm:ss";

export const chartDateFormats = {
  days: "yyyyMMdd",
  months: "yyyyMM",
};

export const timeSpanOptions = {
  last_seven: "Last 7 Days",
  this_week: "This Week",
  last_week: "Last Week",
  this_month: "This Month",
  last_month: "Last Month",
  last_30: "Last 30 days",
  last_60: "Last 60 days",
  last_90: "Last 90 days",
  last_365: "Last 12 months",
  this_year: "This Year",
  last_year: "Last Year",
  custom: "Custom",
};

export const timeSpanFilters = {
  last_seven: {
    aggregatorType: "days",
    format: "yyyyMMdd",
    startDate: startOfDay(subDays(Date.now(), 6)),
    endDate: endOfToday(),
  },
  this_week: {
    aggregatorType: "days",
    format: "yyyyMMdd",
    startDate: startOfDay(startOfWeek(Date.now())),
    endDate: endOfWeek(Date.now()),
  },
  last_week: {
    aggregatorType: "days",
    format: "yyyyMMdd",
    startDate: startOfWeek(subWeeks(Date.now(), 1)),
    endDate: endOfWeek(subWeeks(Date.now(), 1)),
  },
  this_month: {
    aggregatorType: "days",
    format: "yyyyMMdd",
    startDate: startOfMonth(Date.now()),
    endDate: endOfMonth(Date.now()),
  },
  last_month: {
    aggregatorType: "days",
    format: "yyyyMMdd",
    startDate: startOfMonth(subMonths(Date.now(), 1)),
    endDate: endOfMonth(subMonths(Date.now(), 1)),
  },
  last_30: {
    aggregatorType: "days",
    format: "yyyyMMdd",
    startDate: startOfDay(subDays(Date.now(), 29)),
    endDate: endOfDay(Date.now()),
  },
  last_60: {
    aggregatorType: "days",
    format: "yyyyMMdd",
    startDate: startOfDay(subDays(Date.now(), 59)),
    endDate: endOfDay(Date.now()),
  },
  last_90: {
    aggregatorType: "days",
    format: "yyyyMMdd",
    startDate: startOfDay(subDays(Date.now(), 89)),
    endDate: endOfDay(Date.now()),
  },
  last_365: {
    aggregatorType: "months",
    format: "yyyyMM",
    startDate: startOfDay(subMonths(Date.now(), 12)),
    endDate: endOfDay(Date.now()),
  },
  this_year: {
    aggregatorType: "months",
    format: "yyyyMM",
    startDate: startOfYear(Date.now()),
    endDate: endOfYear(Date.now()),
  },
  last_year: {
    aggregatorType: "months",
    format: "yyyyMM",
    startDate: startOfYear(subYears(Date.now(), 1)),
    endDate: endOfYear(subYears(Date.now(), 1)),
  },
  custom: {
    aggregatorType: "months",
    format: "yyyyMM",
    startDate: null,
    endDate: null,
  },
};

export const logTypes = {
  "message.received": "Messages Received",
  "message.delivered": "Messages Delivered",
  "message.queued": "Messages Sent",
  "message.failed": "Messages Send Failures",
  "message.sending": "Messages Sending",
  "message.undelivered": "Messages Undelivered",
  "message.error": "Messages Error",
  "mms.received": "MMS Received",
  "mms.delivered": "MMS Delivered",
  "mms.queued": "MMS Sent",
  "mms.failed": "MMS Send Failures",
  "sms.received": "SMS Received",
  "sms.delivered": "SMS Delivered",
  "sms.queued": "SMS Sent",
  "sms.failed": "SMS Failures",
  "sms.sending": "SMS Sending",
  "sms.error": "SMS Error",
  "voice.hangup": "Voice Forwards",
  "number.purchased": "Numbers Purchased",
  "number.released": "Numbers Released",
  "webhook.error": "Callback Errors",
  "user.login": "User Logins",
  "user.login_success": "User Successful Logins",
};

export const logGroups = {
  msgs: {
    label: "Sent & Received",
    types: ["message.received", "message.queued"],
  },
  failures: {
    label: "Errors & Failures",
    types: ["message.failed", "message.error"],
  },
  callError: {
    label: "Callback Errors",
    types: ["webhook.error"],
  },
  msgSent: {
    label: "Messages Sent",
    types: ["sms.queued", "mms.queued"],
  },
  undelivered: {
    label: "Messages Undelivered",
    types: ["message.undelivered"],
  },
  sendFailures: {
    label: "Messages Sent Failures",
    types: ["message.failed"],
  },
  incoming: {
    label: "Incoming Received",
    types: ["message.received"],
  },
  voice: {
    label: "Voice Forwarding",
    types: ["voice.hangup"],
  },
  all: {
    label: "All Logs",
    types: [
      "message.queued",
      "message.received",
      "message.failed",
      "message.error",
      "voice.hangup",
      "webhook.error",
    ],
  },
};

const colTemplate = {
  msg: [
    {
      id: "log_timestamp",
      label: "Date (UTC)",
      minWidth: 200,
      format: (value) => formatResultDateForLogs(value),
    },
    {
      id: "sender",
      label: "From",
      minWidth: 100,
      align: "left",
    },
    {
      id: "receiver",
      label: "To",
      minWidth: 100,
      align: "left",
    },
    {
      id: "text",
      label: "Message Body",
      minWidth: 200,
      align: "left",
    },
    {
      id: "num_media",
      label: "MMS",
      minWidth: 20,
      align: "center",
    },
  ],
  msg_sms: [
    {
      id: "log_timestamp",
      label: "Date (UTC)",
      minWidth: 200,
      format: (value) => formatResultDateForLogs(value),
    },
    {
      id: "sender",
      label: "From",
      minWidth: 100,
      align: "left",
    },
    {
      id: "receiver",
      label: "To",
      minWidth: 100,
      align: "left",
    },
    {
      id: "text",
      label: "Message Body",
      minWidth: 200,
      align: "left",
    },
  ],
  msgerr: [
    {
      id: "log_timestamp",
      label: "Date (UTC)",
      minWidth: 80,
      format: (value) => formatResultDateForLogs(value),
    },
    {
      id: "sender",
      label: "From",
      minWidth: 100,
      align: "center",
    },
    {
      id: "receiver",
      label: "To",
      minWidth: 100,
      align: "center",
    },
    {
      id: "error_code",
      label: "Error Code",
      minWidth: 100,
      align: "center",
    },
    {
      id: "error_message",
      label: "Error Message",
      minWidth: 200,
      align: "left",
    },
  ],
  webhook: [
    {
      id: "log_timestamp",
      label: "Date (UTC)",
      minWidth: 80,
      format: (value) => formatResultDateForLogs(value),
    },
    {
      id: "url",
      label: "URL",
      minWidth: 220,
      align: "center",
    },
    {
      id: "status_code",
      label: "Status",
      minWidth: 100,
      align: "center",
    },
    {
      id: "response_body",
      label: "Response Body",
      minWidth: 100,
      align: "center",
    },
  ],
  voice: [
    {
      id: "log_timestamp",
      label: "Date (UTC)",
      minWidth: 80,
      format: (value) => formatResultDateForLogs(value),
    },
    {
      id: "phone_number_from",
      label: "From",
      minWidth: 220,
      align: "center",
    },
    {
      id: "call_forwarded_to",
      label: "To",
      minWidth: 100,
      align: "center",
    },
    {
      id: "phone_number_to",
      label: "Via",
      minWidth: 100,
      align: "center",
    },
    {
      id: "call_time_in_minutes",
      label: "Call Time",
      minWidth: 220,
      align: "center",
      format: (value) => `${value} Minute${value > 1 ? "s" : ""}`,
    },
  ],
  number: [
    {
      id: "log_timestamp",
      label: "Date (UTC)",
      minWidth: 80,
      format: (value) => formatResultDateForLogs(value),
    },
    {
      id: "id",
      label: "ID",
      minWidth: 220,
      align: "center",
    },
    {
      id: "phone_number",
      label: "Number",
      minWidth: 100,
      align: "center",
    },
  ],
  login: [
    {
      id: "log_timestamp",
      label: "Date (UTC)",
      minWidth: 80,
      format: (value) => formatResultDateForLogs(value),
    },
    {
      id: "username",
      label: "User",
      minWidth: 100,
      align: "center",
    },
  ],
};

export const logColumns = {
  "message.queued": colTemplate.msg,
  "message.received": colTemplate.msg,
  "message.failed": colTemplate.msgerr,
  "message.undelivered": colTemplate.msgerr,
  "message.error": colTemplate.msgerr,
  "webhook.error": colTemplate.webhook,
  "voice.hangup": colTemplate.voice,
  "number.purchased": colTemplate.number,
  "number.released": colTemplate.number,
  "user.login": colTemplate.login,

  "message.delivered": colTemplate.msg,
  "message.sending": colTemplate.msg,
  "mms.received": colTemplate.msg,
  "mms.delivered": colTemplate.msg,
  "mms.queued": colTemplate.msg,
  "mms.failed": colTemplate.msgerr,
  "sms.received": colTemplate.msg_sms,
  "sms.delivered": colTemplate.msg_sms,
  "sms.queued": colTemplate.msg_sms,
  "sms.failed": colTemplate.msgerr,
  "sms.sending": colTemplate.msg_sms,
  "sms.error": colTemplate.msgerr,
  "user.login_success": colTemplate.login,
};

export default {
  dateFormat,
  systemDateFormat,
  chartDateFormats,
  logGroups,
  logTypes,
  logColumns,
  timeSpanFilters,
  timeSpanOptions,
};
