import { colorPrimaryBlack } from "./palette";

export const fontPrimaryTitleSubheading = "Aktiv Grotesk, Arial, serif";
export const fontPrimaryPublic = "Fakt Slab Pro, Arial, sans-serif";
export const fontPrimaryBody = "Arial, serif";
export const fontSystemSubheadingBody = "Arial, sans-serif";
export const fontMonospace = "'Courier New', monospace";

const fontSizeH1 = "2.5rem"; //40px @ 16px base
const fontSizeH2 = "2.1875rem"; //35px @ 16px base
const fontSizeH3 = "1.875em"; //30px @ 16px base
const fontSizeH4 = "1.5rem"; //24px @ 16px base
const fontSizeH5 = "1.25rem"; //20px @ 16px base
const fontSizeH6 = "1.125rem"; //18px @ 16px base
const fontSizeP = "1rem"; //16px @ 16px base

export const typography = {
  fontFamily: fontPrimaryBody,
  fontPrimaryPublic: fontPrimaryPublic,
  fontTitle: fontPrimaryTitleSubheading,
  fontSystemFamily: fontSystemSubheadingBody,
  h1: {
    fontFamily: fontPrimaryTitleSubheading,
    fontSize: fontSizeH1,
    fontWeight: 400,
  },
  h2: {
    fontFamily: fontPrimaryTitleSubheading,
    fontSize: fontSizeH2,
    fontWeight: 400,
  },
  h3: {
    fontFamily: fontPrimaryTitleSubheading,
    fontSize: fontSizeH3,
  },
  h4: {
    fontFamily: fontPrimaryTitleSubheading,
    fontSize: fontSizeH4,
  },
  h5: {
    fontFamily: fontPrimaryTitleSubheading,
    fontSize: fontSizeH5,
  },
  h6: {
    fontFamily: fontPrimaryTitleSubheading,
    fontSize: fontSizeH6,
  },
  subtitle1: {
    fontFamily: fontPrimaryBody,
    fontSize: fontSizeP,
    fontWeight: 700,
    color: "primary",
  },
  subtitle2: {
    fontFamily: fontPrimaryTitleSubheading,
    fontSize: fontSizeH6,
  },
  body1: {
    fontFamily: fontPrimaryBody,
    fontSize: fontSizeP,
  },
  body2: {
    fontFamily: fontPrimaryTitleSubheading,
    fontSize: fontSizeP,
    color: colorPrimaryBlack,
  },
  button: {
    fontFamily: fontSystemSubheadingBody,
    fontSize: fontSizeP,
  },
  caption: {
    fontFamily: fontSystemSubheadingBody,
    fontSize: fontSizeP,
  },
  overline: {
    fontFamily: fontSystemSubheadingBody,
    fontSize: fontSizeP,
  },
};

const brochureFontSizeH1 = "2.6666rem"; //48px @ 18px base
const brochureFontSizeH2 = "2.2222rem"; //40px @ 18px base
const brochureFontSizeH3 = "1.9444rem"; //35px @ 18px base
const brochureFontSizeH4 = "1.6666rem"; //30px @ 18px base
const brochureFontSizeH5 = "1.4444rem"; //26px @ 18px base
const brochureFontSizeH6 = "1.1111rem"; //20px @ 18px base
const brochureFontSizeP = "1rem"; //18px @ 18px base

export const brochureTypography = {
  fontFamily: fontPrimaryPublic,
  fontTitle: fontPrimaryTitleSubheading,
  h1: {
    fontFamily: fontPrimaryPublic,
    fontSize: brochureFontSizeH1,
    fontWeight: 200,
  },
  h2: {
    fontFamily: fontPrimaryPublic,
    fontSize: brochureFontSizeH2,
    fontWeight: 200,
  },
  h3: {
    fontFamily: fontPrimaryPublic,
    fontSize: brochureFontSizeH3,
    fontWeight: 300,
  },
  h4: {
    fontFamily: fontPrimaryPublic,
    fontSize: brochureFontSizeH4,
    fontWeight: 500,
  },
  h5: {
    fontFamily: fontPrimaryPublic,
    fontSize: brochureFontSizeH5,
  },
  h6: {
    fontFamily: fontPrimaryPublic,
    fontSize: brochureFontSizeH6,
  },
  subtitle1: {
    fontWeight: 200,
    fontSize: brochureFontSizeH6,
  },
  body1: {
    fontFamily: fontPrimaryPublic,
    fontSize: brochureFontSizeP,
    fontWeight: 300,
    lineHeight: 1.7,
  },
  body2: {
    fontFamily: fontPrimaryPublic,
    fontSize: brochureFontSizeP,
    fontWeight: 300,
    lineHeight: 1.7,
    color: colorPrimaryBlack,
  },
  button: {
    fontFamily: fontPrimaryPublic,
    fontSize: brochureFontSizeP,
  },
};

export default {
  typography,
  brochureTypography,
};
