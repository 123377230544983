// contacts/endpoints.js
import { factory } from "vivialconnect-js-core";

//NOTE: do not use spread syntax here.
//helper == {init, BASE_URL}
const helper = factory.initEndpoints();

export default {
  init: helper.init,
  contacts: (accountId) =>
    `${helper.BASE_URL}/accounts/${accountId}/contacts.json`,
  contactsCount: (accountId) =>
    `${helper.BASE_URL}/accounts/${accountId}/contacts/count.json`,
  contact: (accountId, contactId) =>
    `${helper.BASE_URL}/accounts/${accountId}/contacts/${contactId}.json`,
};
