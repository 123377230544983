/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { toast } from "react-toastify";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import VivialConnectAPI from "api/vivial-connect";

import { useAppContext } from "components/Context";
import {
  Card as DashboardCard,
  CardHeader as DashboardCardHeader,
  CardContent as DashboardCardContent,
} from "components/Layouts/Dashboard/Card";

import useStyles from "./styles";

const { customHooks } = VivialConnectAPI.helpers;

export default function AddUserForm({ handleShowDefault }) {
  const isMounted = customHooks.useMountedRef();
  const { appState } = useAppContext();
  const classes = useStyles();
  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    password: "",
    email: "",
    force_password_reset: false,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [role, setRole] = useState("AccountAdministrator");

  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value ? event.target.value : "",
    });
  };

  const handleChangeCheck = () => {
    const { force_password_reset } = values;

    setValues({
      ...values,
      force_password_reset: !force_password_reset,
    });
  };

  const onSelectRole = (event) => {
    setRole(event.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleOnSubmit = (event) => {
    event.preventDefault();
    const { users } = VivialConnectAPI.services;

    const payload = {
      user: {
        ...values,
        role,
        account_id: appState.id,
        username: values.email,
      },
    };

    users
      .postUserRegister(payload)
      .then((response) => {
        if (!response) {
          toast.error("Unable to create user");
        } else if (isMounted.current) {
          handleShowDefault();
        }
      })
      .catch((error) => toast.error(error));
  };

  return (
    <DashboardCard>
      <DashboardCardHeader title={`Add User`} />
      <form autoComplete="off" onSubmit={handleOnSubmit}>
        <DashboardCardContent>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                required
                variant="outlined"
                label="First Name"
                name="first_name"
                value={values.first_name}
                onChange={handleChange("first_name")}
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                type="email"
                required
                variant="outlined"
                label="Email"
                name="email"
                value={values.email}
                onChange={handleChange("email")}
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                required
                variant="outlined"
                label="Last Name"
                name="last_name"
                value={values.last_name}
                onChange={handleChange("last_name")}
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="select-outlined-label">User Role</InputLabel>
              <Select
                labelId="select-outlined-label"
                id="select-outlined"
                value={role}
                name="role"
                onChange={onSelectRole}
                label="User Role"
              >
                <MenuItem value={"AccountAdministrator"}>
                  Account Administrator
                </MenuItem>
                <MenuItem value={"User"}>User</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                required
                variant="outlined"
                label="Password"
                name="password"
                id="add-user-password"
                type={showPassword ? "text" : "password"}
                value={values.password}
                onChange={handleChange("password")}
                autoComplete="off"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <FormGroup>
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      color="primary"
                      checked={values.force_password_reset}
                      name="force_password_reset"
                      onChange={handleChangeCheck}
                    />
                  }
                  label="User must reset password on first login."
                  labelPlacement="end"
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.editYourProfileButton}>
              <Box>
                <Button variant="contained" onClick={handleShowDefault}>
                  Cancel
                </Button>
              </Box>
              <Box ml={2}>
                <Button type="submit" color="primary" variant="contained">
                  Save
                </Button>
              </Box>
            </div>
          </Grid>
        </DashboardCardContent>
      </form>
    </DashboardCard>
  );
}
