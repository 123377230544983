export default {
  values: {
    xs: 0,
    sm: 600,
    md: 1140,
    lg: 1520,
    xl: 1800,
  },
};

/*
 * MD default breakpoints
 */
// xs: 0,
// sm: 600,
// md: 960,
// lg: 1280,
// xl: 1920,
