import makeStyles from "@material-ui/core/styles/makeStyles";
import {
  colorPrimaryVivialGreen,
  colorExtendedGreen4,
  colorExtendedGray4,
} from "themes/palette";

export const useStyles = makeStyles((theme) => ({
  linkItems: {
    display: "flex",
    flexDirection: "row",
    padding: 0,
    fontSize: "1.2em",
    fontWeight: 500,
    "& a": {
      color: colorPrimaryVivialGreen,
      textDecoration: "underline",
    },
    flexWrap: "wrap",
    justifyContent: "center",
  },
  menuItem: {
    width: "auto",
    alignItems: "center",
    padding: "0 10px",
    "& a:hover": {
      color: colorExtendedGreen4,
    },
  },
  button: {
    width: "auto",
    "& a": {
      color: theme.palette.common.white,
      textDecoration: "none",
    },
  },
  listItems: {
    paddingTop: theme.spacing(6),
  },
  listItemsTitle: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    color: colorExtendedGreen4,
    fontWeight: 400,
  },
  listItemsLinks: {
    paddingLeft: 0,
    color: colorExtendedGray4,
    fontSize: "1.2em",
    fontWeight: 500,
    "&:hover": {
      color: colorPrimaryVivialGreen,
      textDecoration: "underline",
    },
  },
  listItemsDivider: {
    marginTop: theme.spacing(2),
  },
  contentDivider: {
    marginTop: theme.spacing(3),
  },
  header: {
    fontFamily: theme.typography.fontTitle,
    fontSize: "2.8em",
    lineHeight: "1.1",
    marginTop: 0,
    fontWeight: 400,
  },
  textField: {
    [theme.breakpoints.down("sm")]: {
      width: 300,
    },
    width: 500,
  },
}));
