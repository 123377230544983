/* eslint-disable react/display-name */
import React, { useEffect } from "react";
import { usePageContext } from "components/Context";

export const withPageContext = (Component, state = {}) => (props) => {
  const { setPageState } = usePageContext();

  useEffect(() => {
    //Set page context values
    setPageState(state);

    return () => setPageState({});
  }, [setPageState]);

  return <Component {...props} />;
};

export default withPageContext;
