/* eslint-disable react/prop-types */
/* eslint-disable no-prototype-builtins */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import chipImage from "assets/img/chip.png";
import visaLogo from "assets/img/visa.png";
import mastercardLogo from "assets/img/mastercard.png";
import americanExpressLogo from "assets/img/american-express.png";
import discoveryLogo from "assets/img/discover.png";

const darkColors = {
  backgroundColor: "#000000",
  backgroundImage: "linear-gradient(147deg, #000000 0%, #2c3e50 74%)",
};

const ligthColors = {
  backgroundColor: "#5b6467",
  backgroundImage: "linear-gradient(315deg, #5b6467 0%, #8b939a 74%)",
};

const blueColors = {
  backgroundColor: "#36096d",
  backgroundImage: "linear-gradient(315deg, #2a2a72 0%, #009ffd 74%)",
};

const root = {
  width: 336,
  height: "100%",
  color: "#FFF",
  border: "1px solid whismoke",
  borderRadius: 18,
  padding: 22,
};

const useStyles = makeStyles((theme) => ({
  root: {
    ...root,
    ...ligthColors,
  },
  rootVisa: {
    ...root,
    ...blueColors,
  },
  rootMasterCard: {
    ...root,
    ...darkColors,
  },
  rootAmericanExpress: {
    ...root,
    ...darkColors,
  },
  rootDiscover: {
    ...root,
    ...ligthColors,
  },
  brandWrapper: {
    display: "inline-block",
    width: theme.spacing(7),
    height: theme.spacing(6),
  },
  image: {
    maxWidth: "100%",
    maxHeight: "100%",
    display: "block",
  },
  chip: { width: 42, marginLeft: -7 },
  groupDigits: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    textShadow: "0 0 5px #000",
  },
  digits: {
    fontFamily: theme.typography.fontPrimaryPublic,
    letterSpacing: 2,
  },
  expirationWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  expirationLabel: {
    fontSize: "0.4em",
    fontWeight: "bold",
    marginRight: theme.spacing(1),
  },
  expirationDate: {
    "& > span": { fontFamily: theme.typography.fontPrimaryPublic },
    fontSize: "0.88em",
    textShadow: "0 0 5px #000",
  },
  owner: { fontSize: "0.7em", textShadow: "0 0 5px #000" },
}));

const getCreditCardImage = (brand) => {
  const visa = { image: visaLogo, alt: "Visa" };
  const mastercard = { image: mastercardLogo, alt: "MasterCard" };
  const discover = { image: discoveryLogo, alt: "Discover" };
  const americanExpress = {
    image: americanExpressLogo,
    alt: "American Express",
  };

  if (brand === "Visa") return visa;
  if (brand === "MasterCard") return mastercard;
  if (brand === "Discover") return discover;
  if (brand === "American Express") return americanExpress;
  return {};
};

const getCreditCardStyle = (classes, brand) => {
  const keyName = `root${brand.replace(/\s+/g, "")}`;
  return classes.hasOwnProperty(keyName) ? classes[keyName] : classes.root;
};

const CreditCard = (props) => {
  const classes = useStyles();
  const { brand, digits, expiration, owner } = props;
  const [month, year] = expiration.split("/");
  const { image, alt } = getCreditCardImage(brand);
  const cardStyle = getCreditCardStyle(classes, brand);

  return (
    <div className={cardStyle}>
      <Grid container spacing={1}>
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <div className={classes.brandWrapper}>
            <img src={image} alt={alt} className={classes.image} />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.chip}>
            <img
              src={chipImage}
              alt="credit card chip"
              className={classes.image}
            />
          </div>
          <div>
            <p className={classes.groupDigits}>
              <span className={classes.digits}>XXXX</span>
              <span className={classes.digits}>XXXX</span>
              <span className={classes.digits}>XXXX</span>
              <span className={classes.digits}>{digits}</span>
            </p>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.expirationWrapper}>
            <span className={classes.expirationLabel}>
              VALID
              <br /> THRU
            </span>
            <p className={classes.expirationDate}>
              <span>{month < 10 ? `0${month}` : month}</span> /{" "}
              <span>{year}</span>
            </p>
          </div>
        </Grid>
        <Grid item xs={12}>
          <p className={classes.owner}>
            {owner ? owner.toUpperCase() : "NO OWNER NAME"}
          </p>
        </Grid>
      </Grid>
    </div>
  );
};

export default CreditCard;
