/* logsTable.js
 * Component for displaying a list of logs for a specfied account & date range
 */
import React, { useState, useEffect, useCallback } from "react";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Typography from "@material-ui/core/Typography";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import { format } from "date-fns";

import { dateFormat, logColumns } from "./constants";
import { formatResultDateForLogs } from "./dataHelpers";
import useStyles from "./styles";
import { toast } from "react-toastify";

/****
 * LogsTable
 * Regarding pagination: logs are stored in AWS Dynamo DB which presents some
 * limitations. Each logs request only returns the contents of a single page
 * plus a pointer to the next page. It does not provide a page count, track
 * the current page, or provide a pointer back to the previous page.
 *
 * It is difficult to use existing Material Design pagination components with
 * these limitations. Much of the code below is dedicated to tracking the page
 * pointer (key) history and explicitly enabling/disabling pagination settings.
 *
 * keyHistory[lastIndex] contains the pointer to the PREVIOUS page
 * logs.last_key contains the pointer for the NEXT page.
 * startKey contain the pointer to the CURRENT page.
 *
 * On the last page, logs.last_key will equal "". If you pass "" as startKey
 * it will load the first page

 * Reseting startKey will NOT trigger a reload, as you might expect. React
 * tends to make too many calls when startKey is a dependency.
 ****/

export default function LogsTable({
  filters,
  logType,
  publicAPIServices,
  accountId,
}) {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [logs, setLogs] = useState({
    log_items: [],
    last_key: "",
  });

  //start history with empty string to represent first page
  const [keyHistory, setKeyHistory] = useState([]);
  const [startKey, setStartKey] = useState();
  const [nextIconButtonProps, setNextIconButtonProps] = useState({
    disabled: false,
  });
  const [backIconButtonProps, setBackIconButtonProps] = useState({
    disabled: true,
  });
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [selectedMessageStatus, setSelectedMessageStatus] = useState(null);
  const [selectedMessageAttachments, setSelectedMessageAttachments] = useState(
    null
  );
  const start_time = format(filters.startDate, dateFormat);
  const end_time = format(filters.endDate, dateFormat);

  const [openDialog, setOpenDialog] = React.useState(false);

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const showMessageDialog = (log) => {
    const { messages } = publicAPIServices;

    if (!log || log.operator_type !== "message") return;

    setSelectedMessage(log);
    setOpenDialog(true);

    setSelectedMessageStatus(
      <small>
        <CircularProgress size={16} className={classes.smallSpinner} /> fetching
        status&hellip;
      </small>
    );

    messages
      .getMessage(log.operator_id, accountId)
      .then((message) => {
        setSelectedMessageStatus(message.status);
      })
      .catch((error) => {
        setSelectedMessageStatus("");

        // GET requests to messages.json endpoint will return 404 for certain
        // inbound messages to free trial accounts. The simplest way to
        // handle it is to supress error display.
        // toast.error(error);
      });

    messages
      .getMessageAttachments(log.operator_id, accountId)
      .then((attachments) => {
        setSelectedMessageAttachments(attachments);
      })
      .catch((error) => toast.error(error));
  };

  const getAttachmentURL = (messageId, attachmentId) => {
    const { messages } = publicAPIServices;
    return messages.getMessageAttachmentURL(messageId, attachmentId, accountId);
  };

  /*****
   * Maintain key history and pagination display setting
   * HERE BE DRAGONS!
   ****/
  const handleChangePage = (event, newPage) => {
    let keys = [...keyHistory];
    let newStartKey;

    // Sanity check: reset if page is zero
    if (newPage < 0) newPage = 0;
    if (page > newPage) {
      /* BACKWARD PAGINATION */

      // 1. enable/disable pagination buttons
      setNextIconButtonProps({ disabled: false });
      if (newPage === 0) {
        setBackIconButtonProps({ disabled: true });
      }

      // 2. Remove PREVIOUS page key from history
      // 3. Assign PREVIOUS page key to newPage
      newStartKey = keys.pop();
    } else if (page < newPage) {
      /* FORWARD PAGINATION */

      // 0. Sanity check: already on last page
      if (logs.last_key === "") return;

      // 1. enable/disable pagination buttons
      setBackIconButtonProps({ disabled: false });

      // 2. Save CURRENT page key in history
      keys.push(startKey);

      // 3. Assign key for NEXT page to newPage
      newStartKey = logs.last_key;
    }

    // 4. Update state
    setPage(newPage);
    setStartKey(newStartKey);
    setKeyHistory(keys);

    // 5. Get page content from server
    handleGetLogs(newStartKey);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
  };

  const handleGetLogs = useCallback(
    (start_key) => {
      //TODO: disable pagination while next page loads. Fast clicks on
      //      pagination buttons will cause duplicate server calls and
      //      put the pagination component out of sync.
      const { logs: logsService } = publicAPIServices;
      const params = {
        log_type: logType,
        start_time,
        end_time,
        limit: rowsPerPage,
        start_key: start_key,
      };

      logsService
        .getLogs(params, accountId)
        .then((res) => {
          setLogs(res);
          if (res.last_key === "") {
            //on last page disable next button
            setNextIconButtonProps({ disabled: true });
          } else {
            setNextIconButtonProps({ disabled: false });
          }
        })
        .catch((error) => toast.error(error));
    },
    [logType, rowsPerPage, start_time, end_time, accountId]
  );

  useEffect(() => {
    setLogs({
      log_items: [],
      last_key: "",
    });
    setPage(0);
    setKeyHistory([]);
    setBackIconButtonProps({ disabled: true });
    setNextIconButtonProps({ disabled: true });
    setStartKey("");

    handleGetLogs("");
  }, [logType, rowsPerPage, handleGetLogs, filters]);

  return (
    <>
      <TableContainer className={classes.tableContainer}>
        <Table
          stickyHeader
          className={"classes.table"}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              {logColumns[logType].map((column, indx) => (
                <TableCell
                  key={indx}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <Typography variant="caption">{column.label}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {logs.log_items.map((row, indx) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={`row-${indx}`}
                  className={classes.rowWrapper}
                  onClick={() => showMessageDialog(row)}
                >
                  {logColumns[logType].map((column, indx2) => {
                    let value;
                    if (column.id === "log_timestamp" || column.id === "id") {
                      value = row[column.id];
                    } else if (column.id === "num_media") {
                      value =
                        row.item_type && row.item_type.indexOf("mms") > -1 ? (
                          <CheckCircleIcon color="primary" />
                        ) : (
                          <CheckCircleIcon color="disabled" />
                        );
                    } else if (column.id === "error_code") {
                      if (row.log_data.message) {
                        value = row.log_data.message.error_code;
                      }
                    } else if (column.id === "error_message") {
                      if (row.log_data.message) {
                        value = row.log_data.message.error_message;
                      }
                    } else {
                      value = row.log_data[column.id];
                    }
                    return (
                      <TableCell
                        key={`cell-${indx}-${indx2}`}
                        align={column.align}
                        className={classes.tableCell}
                      >
                        {column.format ? column.format(value) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25]}
        component="div"
        count={-1}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className={classes.tablePagination}
        labelRowsPerPage={"Rows Per Page"}
        backIconButtonProps={backIconButtonProps}
        nextIconButtonProps={nextIconButtonProps}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to}`}
      />

      <Dialog
        maxWidth="sm"
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="api-key-dialog"
        className={classes.dialog}
      >
        <DialogTitle id="api-key-dialog">
          {selectedMessage && (
            <span>
              {(selectedMessage.item_type &&
                selectedMessage.item_type.indexOf("in") > -1) ||
              //TODO: Delete this validation when logs.json endpoint is updated to always bring item_type
              (selectedMessage.log_data &&
                selectedMessage.log_data.message &&
                selectedMessage.log_data.message.direction.indexOf("in") > -1)
                ? "Inbound"
                : "Outbound"}{" "}
              Message {`(#${selectedMessage.operator_id})`}
            </span>
          )}
        </DialogTitle>

        <DialogContent style={{ paddingBottom: "12px" }}>
          {selectedMessage && (
            <Grid container spacing={3} className={classes.logsDialogContent}>
              <Grid item xs={4}>
                <Typography variant="body2">Date Created:</Typography>
              </Grid>
              <Grid item xs={8}>
                {selectedMessage
                  ? formatResultDateForLogs(selectedMessage.log_timestamp)
                  : ""}
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2">Log Event Status:</Typography>
              </Grid>
              <Grid item xs={8}>
                {selectedMessage.log_data.message
                  ? selectedMessage.log_data.message.status
                  : selectedMessage.description}
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2">Current Status:</Typography>
              </Grid>
              <Grid item xs={8}>
                {selectedMessageStatus}
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2">Additional Info:</Typography>
              </Grid>
              <Grid item xs={8}>
                {selectedMessage.log_data.message &&
                selectedMessage.log_data.message.error_code
                  ? `Error ${selectedMessage.log_data.message.error_code} ${selectedMessage.log_data.message.error_message}`
                  : `None`}
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2">To Number:</Typography>
              </Grid>
              <Grid item xs={8}>
                {selectedMessage.log_data.receiver}
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2">From Number:</Typography>
              </Grid>
              <Grid item xs={8}>
                {selectedMessage.log_data.sender}
              </Grid>
              {selectedMessageAttachments &&
              selectedMessageAttachments.length ? (
                <Grid item xs={12}>
                  <Typography variant="body2" paragraph={true}>
                    Attachments:
                  </Typography>
                  {selectedMessageAttachments.map((file, key) => {
                    const url = getAttachmentURL(
                      selectedMessage.operator_id,
                      file.id
                    );
                    return (
                      <div>
                        <Link href={url} key={key} target="_new">
                          {file.file_name}
                        </Link>
                      </div>
                    );
                  })}
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <Typography variant="body2" paragraph={true}>
                  Message Body:
                </Typography>
                <Typography variant="body1">
                  {selectedMessage.log_data.text &&
                    selectedMessage.log_data.text}
                </Typography>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <br />
        <br />
        <DialogActions>
          <Button
            onClick={handleDialogClose}
            variant="contained"
            className={classes.closeDialog}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
