/* eslint-disable react/prop-types */
import React, { useEffect } from "react";

import Grid from "@material-ui/core/Grid";

import { useAppContext, usePageContext } from "components/Context";

import { PhoneNumbers } from "./shared";
import { AccountStatus } from "./AccountStatus";
import UsageLogs from "./UsageLogs";
import FirstLogin from "./FirstLogin";
import { useStyles } from "./styles";

export default function Dashboard() {
  const { appState, setAppState } = useAppContext();
  const { setPageState } = usePageContext();
  const classes = useStyles();

  useEffect(() => {
    //Set page context values
    setPageState({
      title: "Dashboard",
      subtitle: appState.username ? `Hello, ${appState.username}` : "Hello",
    });

    return () => setPageState({});
  }, [setPageState, appState.username]);

  useEffect(() => {
    return () => {
      //On page exit disable the setup flag
      if (appState.setup_required) {
        setAppState({ setup_required: false });
      }
    };
  }, [appState.setup_required, setAppState]);

  return (
    <Grid container spacing={3}>
      <Grid item md={12} lg={6} className={classes.gridTiles}>
        <AccountStatus />
      </Grid>
      <Grid item md={12} lg={6} className={classes.gridTiles}>
        <PhoneNumbers />
      </Grid>
      {appState.setup_required ? <FirstLogin /> : <UsageLogs />}
    </Grid>
  );
}

// export default withPageContext(Dashboard);
