/* eslint-disable react/prop-types */
import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import { useStyles } from "./styles";

export default function MainItem({
  primaryText,
  secondaryText,
  avatarIcon,
  itemType,
}) {
  const classes = useStyles();

  return (
    <ListItem>
      {avatarIcon ? (
        <ListItemAvatar className={classes.noHover}>
          <Avatar className={classes.avatar}>{avatarIcon}</Avatar>
        </ListItemAvatar>
      ) : null}
      <ListItemText
        primary={primaryText}
        primaryTypographyProps={{ className: classes.itemPrimaryText }}
        secondary={secondaryText}
        secondaryTypographyProps={{
          className:
            itemType === "first"
              ? classes.firstItemSecondaryText
              : classes.itemSecondaryText,
        }}
      />
    </ListItem>
  );
}
