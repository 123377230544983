/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
/* eslint-disable no-empty */
import React from "react";
import marked from "marked";

import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { useStyles, useMDStyles } from "./styles";

import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const MarkDownText = ({ children }) => {
  const classes = useMDStyles();

  if (!children) return null;
  const rawMarkup = marked(children);

  return (
    <div className={classes.tableContainer}>
      <span
        className={classes.markdownStyle}
        dangerouslySetInnerHTML={{ __html: rawMarkup }}
      ></span>
    </div>
  );
};

const RichLink = ({ uri, children }) => {
  uri = uri || "";
  return (
    <Link href={uri} target={uri.match(/^http|mailto/) ? "_blank" : "_self"}>
      {children}
    </Link>
  );
};
const Header = ({ children, variant }) => {
  const classes = useStyles();
  return (
    <Typography
      className={`${classes.header} ${classes[variant]}`}
      variant={variant}
    >
      {children}
    </Typography>
  );
};
const Text = ({ children }) => {
  const classes = useStyles();
  return <Typography className={classes.p}>{children}</Typography>;
};
const Image = ({ title, description, url }) => {
  const classes = useStyles();
  return (
    <img
      alt={description}
      title={title}
      src={url}
      className={classes.embeddedImage}
    />
  );
};
const CodeSnippet = ({ children }) => {
  const classes = useStyles();
  return <Paper className={classes.codeSnippet}>{children}</Paper>;
};
const OrderedList = ({ children }) => {
  const classes = useStyles();
  return <ol className={classes.list}>{children}</ol>;
};
const UnorderedList = ({ children }) => {
  const classes = useStyles();
  return <ul className={classes.list}>{children}</ul>;
};

export const richContentOptions = {
  renderMark: {
    // [MARKS.BOLD]: text => <Bold>{text}</Bold>
    [MARKS.CODE]: (code) => <CodeSnippet>{code}</CodeSnippet>,
  },
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => (
      <RichLink uri={node.data.uri}>{children}</RichLink>
    ),

    [INLINES.ENTRY_HYPERLINK]: (node, children) => {
      let uri = "/docs";
      try {
        uri = `${uri}/${node.data.target.fields.slug}`;
      } catch (e) {}

      return <RichLink uri={uri}>{children}</RichLink>;
    },

    [BLOCKS.HEADING_1]: (node, children) => (
      <Header variant="h1">{children}</Header>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <Header variant="h2">{children}</Header>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <Header variant="h3">{children}</Header>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <Header variant="h4">{children}</Header>
    ),
    [BLOCKS.HEADING_5]: (node, children) => (
      <Header variant="h5">{children}</Header>
    ),
    [BLOCKS.HEADING_6]: (node, children) => (
      <Header variant="h6">{children}</Header>
    ),

    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,

    [BLOCKS.OL_LIST]: (node, children) => (
      <OrderedList node={node}>{children}</OrderedList>
    ),

    [BLOCKS.UL_LIST]: (node, children) => (
      <UnorderedList>{children}</UnorderedList>
    ),

    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const { mdContent } = node.data.target.fields;

      if (mdContent) {
        return <MarkDownText>{mdContent}</MarkDownText>;
      }

      return null;
    },
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const { title, description, file } = node.data.target.fields;

      if (file && file.contentType.includes("image")) {
        return <Image title={title} description={description} url={file.url} />;
      }

      return null;
    },
    [BLOCKS.QUOTE]: (node, children) => {
      let quote = children[0].props.children;
      return <CodeSnippet>{quote}</CodeSnippet>;
    },
  },
};

const RichText = ({ children }) => {
  return documentToReactComponents(children, richContentOptions);
};

export default RichText;
