/* eslint-disable react/prop-types */
import React from "react";
import { Route, Redirect } from "react-router-dom";
import VivialConnectAPI from "api/vivial-connect";

import routes from "config/routes";
import NotFound from "pages/NotFound";

//*****************************************************************************
// VCRoutes
// Generates React Router structure

// Expects a config array like:
// [
//   {
//     layout: null,
//     subRoutes: [
//       {
//         path: "/dashboard/upgrade",
//         component: NotFound,
//         isProtected: true,
//         exact: true,
//       },
//       ...
//     ],
//   },
//   {
//     layout: BrochureLayout,
//     subRoutes: [
//       {
//         path: "/",
//         component: Home,
//         isProtected: false,
//         exact: true,
//       },
//       ...
//     ],
//   },
//   ...
// ]

// Outputs Routes like:

//     <Route
//       key=0
//       exact=true
//       path=["/dashboard/upgrade", ...]
//     >
//       <Route
//         key=0
//         exact=true
//         path="/dashboard/upgrade"
//         render={(props) => <Upgrade {...props} />}
//       />
//       ...
//     </Route>
//     <Route
//       key=0
//       exact=true
//       path=["/", ...]
//     >
//       <BrochureLayout>
//         <Route
//           key=0
//           exact=true
//           path="/"
//           render={(props) => <Home {...props} />}
//         />
//         ...
//       </BrochureLayout>
//     </Route>
//     ...

//*****************************************************************************

export default function VCRoutes() {
  // For each top-level set of routes...
  const vcRoutes = routes.map((route, index) => {
    return renderRoute({ index: index, route: route });
  });

  // Add a catch-all Route for 404s
  // Include unique key to suppress React warning
  vcRoutes.push(
    <Route key={vcRoutes.length}>
      <NotFound />
    </Route>
  );

  return vcRoutes;
}

function renderRoute({ index, route }) {
  const { layout: Layout, subRoutes } = route;

  // Render a Route component to wrap sub-routes...

  // Note: path expects an array of all sub-route path strings. flatMap unpacks
  // path arrays when there are multiple paths assigned the same Route
  // e.g. ["/","/home"]

  // Note 2021-12-30: flatMap is not supported by some lesser-known browsers
  // such as Baidu, UC, QQ, and KaiOS. These browsers have limited support for
  // several array methods which makes writing a shim for flatMap difficult.
  // Ignoring for now. Occasional errors may crop up in production.

  return (
    <Route
      key={index}
      exact={subRoutes.some((element) => element.exact)}
      path={subRoutes.flatMap((element) => element.path)}
    >
      {/* If Layout is defined, wrap sub-routes in Layout... */}
      {Layout ? (
        <Layout>
          <RenderSubRoutes subRoutes={subRoutes} />
        </Layout>
      ) : (
        <RenderSubRoutes subRoutes={subRoutes} />
      )}
    </Route>
  );
}

function RenderSubRoutes({ subRoutes }) {
  return subRoutes.map((subRoute, index) => {
    const { exact, path, component: Component, isProtected } = subRoute;
    const RouteComponent = isProtected ? ProtectedRoute : Route;

    // For each sub-route, render a Route component...
    // Each Route renders a page component like src/pages/Dashboard
    return (
      <RouteComponent
        key={index}
        exact={exact}
        path={path}
        render={(props) => <Component {...props} />}
      />
    );
  });
}

// ProtectedRoute
// Check for login token before rendering page.
// If not logged in then rediret to /login
function ProtectedRoute(props) {
  const { component: Component, render, ...rest } = props;
  const { token } = VivialConnectAPI.helpers;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!token.get()) return <Redirect to="/login" />;
        return Component ? <Component {...props} /> : render(props);
      }}
    />
  );
}
