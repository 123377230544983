import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import withPageContext from "components/Context/WithPageContext";
import {
  Card as DashboardCard,
  CardHeader as DashboardHeader,
  CardContent as DashboardCardContent,
} from "components/Layouts/Dashboard/Card";
import { format, parseISO } from "date-fns";

import VivialConnectAPI from "api/vivial-connect";

import { formatTCRString } from "./shared.js";
import { useStyles } from "./styles";

export default withPageContext(Brands, {
  title: "Brands",
  subtitle: "My Brands",
});

function Brands() {
  const classes = useStyles();
  const [brands, setBrands] = useState([]);
  const { campaigns } = VivialConnectAPI.services;

  useEffect(() => {
    campaigns
      .getBrands()
      .then((brands) => setBrands(brands))
      .catch((error) => toast.error(error));
  }, []);

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <DashboardCard>
          <DashboardCardContent
            justifyContent="space-between"
            alignItems="center"
            spacing={0}
          >
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Typography variant="body1" paragraph={true}>
                You must to register for A2P 10DLC (application-to-person
                10-digit long code) to send messages using local numbers.
                Toll-Free numbers are not impacted at this time.
              </Typography>
              <Typography variant="body1" paragraph={true}>
                To get started follow this simple 3-step process:
              </Typography>
              <ol className={classes.orderedList}>
                <li>
                  <b>Step 1 Register Brand</b>
                </li>
                <li>Step 2 Register Campaign</li>
                <li>Step 3 Add Campaign to Numbers</li>
              </ol>
              <Typography variant="body1" paragraph={true}>
                Brand registration has a one-time fee of $5.00.
              </Typography>
              <Typography variant="body1" paragraph={true}>
                For brands details see{" "}
                <Link href="/docs/brands">Brand documentation</Link>.
              </Typography>
            </Grid>
          </DashboardCardContent>
        </DashboardCard>
      </Grid>
      <Grid item xs={12}>
        <DashboardCard>
          <DashboardHeader title={"Brands"} />
          <DashboardCardContent>
            <TableContainer>
              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="h6">Name</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6">ID</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6">Type</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6">Status</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6">Date Created</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {brands.map((brand, index) => {
                    return (
                      <TableRow hover key={index}>
                        <TableCell>{brand.name}</TableCell>
                        <TableCell>{brand.id}</TableCell>
                        <TableCell>
                          {formatTCRString(brand.entity_type)}
                        </TableCell>
                        <TableCell>
                          {brand.status ? brand.status : "-"}
                        </TableCell>
                        <TableCell>
                          {format(parseISO(brand.date_created), "yyyy-MM-dd")}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </DashboardCardContent>
        </DashboardCard>
      </Grid>
    </Grid>
  );
}
