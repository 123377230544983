import endpoints from "./endpoints.js";
import { factory } from "vivialconnect-js-core";

//NOTE: do not use spread syntax here.
//serviceHelpers == {init, http, appSessionStore}
const serviceHelpers = factory.initService(endpoints);

const getMessages = async (id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.get(
      endpoints.messages(id ? id : appSessionStore.getAccountId())
    );
    return data.messages;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const postMessages = async (payload, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.post(
      endpoints.messages(id ? id : appSessionStore.getAccountId()),
      payload
    );
    return data.message;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getMessage = async (messageId, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.get(
      endpoints.message(id ? id : appSessionStore.getAccountId(), messageId)
    );
    return data.message;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getMessageAttachment = async (messageId, attachmentId, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.get(
      endpoints.messageAttachments(
        id ? id : appSessionStore.getAccountId(),
        messageId,
        attachmentId
      )
    );
    return data.attachment;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getMessageAttachmentURL = (messageId, attachmentId, id) => {
  const { http, appSessionStore } = serviceHelpers;
  const attachmentLink = endpoints
    .messageAttachment(
      id ? id : appSessionStore.getAccountId(),
      messageId,
      attachmentId
    )
    .split(".json")[0];
  return attachmentLink;
};

const getMessageAttachments = async (messageId, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.get(
      endpoints.messageAttachments(
        id ? id : appSessionStore.getAccountId(),
        messageId
      )
    );
    return data.attachments;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

export default {
  init: serviceHelpers.init,
  getMessage,
  getMessages,
  postMessages,
  getMessageAttachment,
  getMessageAttachmentURL,
  getMessageAttachments,
};
