/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from "react";
import { NavLink, useLocation, matchPath } from "react-router-dom";

import Collapse from "@material-ui/core/Collapse";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";

import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import ArrowRight from "@material-ui/icons/ArrowRight";
import { DocumentsIcon } from "components/VivialIcons";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";

import { useAppContext } from "components/Context";

import logo from "assets/img/vivial_connect_logo.png";
import { useStyles } from "./styles";
import { menuItems as items } from "./constants";

const MenuItem = React.memo((props) => {
  const classes = useStyles();
  const { appState } = useAppContext();
  const planType = appState.plan_type;
  let {
    item,
    index,
    handleClickItem,
    handleDrawerToggle,
    isActive,
    openItem,
    companyName,
  } = props;

  const showFreeTrailItem = (item) => {
    if (item.freetrial !== undefined) {
      if (item.freetrial && planType !== "freetrial") return false;
      if (!item.freetrial && planType === "freetrial") return false;
    }
    return true;
  };

  // Ignore special menu items
  if (!showFreeTrailItem(item)) return null;

  if (item.children) {
    let accountID = null;
    if (item.id === "company") {
      accountID = appState.id;
    }

    const firstInitial = companyName.substring(0, 1);
    let itemIcon =
      item.id !== "company" ? (
        item.icon
      ) : (
        <Avatar alt="Company" className={classes.companyNameAvatar}>
          {firstInitial}
        </Avatar>
      );

    return (
      <List>
        <ListItem
          key={index}
          button
          onClick={handleClickItem(item.id)}
          className={`${classes.listItem}
            ${isActive(item) ? classes.listItemSelected : ""}
            ${item.id !== "company" ? classes.listItemCompany : ""}`}
        >
          <ListItemIcon className={classes.menuIcon}>{itemIcon}</ListItemIcon>
          {item.id !== "company" ? (
            <ListItemText
              className={classes.listItemText}
              primary={item.name}
            />
          ) : (
            <ListItemText
              className={classes.listItemTextCompany}
              primary={companyName}
              secondary={`#${accountID}`}
            />
          )}

          {openItem[item.id] ? (
            <ArrowDropDown className={classes.arrowIcon} />
          ) : (
            <ArrowRight className={classes.arrowIcon} />
          )}
        </ListItem>
        <Collapse
          in={openItem[item.id]}
          timeout="auto"
          unmountOnExit
          className={isActive(item) ? classes.listItemCollapseSelected : ""}
        >
          <List component="div" disablePadding>
            {item.children.map(
              (child, index2) =>
                showFreeTrailItem(child) && (
                  <ListItem
                    button
                    component={NavLink}
                    to={child.route}
                    key={index2}
                    onClick={handleDrawerToggle}
                    className={`${classes.nested}
                  ${
                    isActive(child)
                      ? classes.listItemChildSelected
                      : classes.listItemChild
                  }`}
                  >
                    <ListItemText primary={child.optionName} />
                  </ListItem>
                )
            )}
          </List>
        </Collapse>
        {item.id === "company" ? (
          <ListItem
            key={`${item.id}${index}2`}
            divider
            className={classes.dividerBottom}
          />
        ) : null}
      </List>
    );
  } else {
    return (
      <ListItem
        button
        component={NavLink}
        to={item.route}
        key={index}
        onClick={handleDrawerToggle}
        className={classes.listItem}
        activeClassName={isActive(item) ? classes.listItemSelected : ""}
      >
        <ListItemIcon
          className={
            item.iconClassName ? classes[item.iconClassName] : classes.menuIcon
          }
        >
          {item.icon}
        </ListItemIcon>
        <ListItemText className={classes.listItemText} primary={item.name} />
      </ListItem>
    );
  }
});

export default function SideBar(props) {
  const { appState } = useAppContext();
  const classes = useStyles();
  const location = useLocation();
  const { pathname } = location;
  const companyNamePlaceholder = "Company Name";

  const { handleDrawerToggle, drawerOpen } = props;
  const [openItem, setOpenItem] = React.useState({
    company: false,
    billing: false,
    numbers: false,
    connectors: false,
  });

  const handleClickItem = (prop) => () => {
    setOpenItem({ ...openItem, [prop]: !openItem[prop] });
  };

  const matchRoutes = (path, route) => {
    const match = matchPath(path, {
      path: route,
      exact: true,
      strict: false,
    });
    if (match && match.isExact) {
      return true;
    }
  };

  const isActive = React.useCallback(
    (item) => {
      if (matchRoutes(pathname, item.route)) {
        return true;
      } else if (item.alternativeRoute) {
        return matchRoutes(pathname, item.alternativeRoute);
      } else if (item.children) {
        for (const i in item.children) {
          if (isActive(item.children[i])) return true;
        }
      }
      return false;
    },
    [pathname]
  );

  React.useEffect(() => {
    if (items.length) {
      let openItems = {};
      items.forEach((item) => {
        openItems[item.id] = isActive(item);
      });
      setOpenItem(openItems);
    }
  }, [isActive]);

  const SidebarContent = (props) => {
    const { isTemporaryDrawer } = props;
    return (
      <List className={classes.list}>
        {items.map((item, index) => {
          return (
            <MenuItem
              key={index}
              item={item}
              index={index}
              handleClickItem={handleClickItem}
              handleDrawerToggle={handleDrawerToggle}
              isActive={isActive}
              openItem={openItem}
              companyName={appState.company_name || companyNamePlaceholder}
            />
          );
        })}
        {isTemporaryDrawer && (
          <ListItem
            button
            component={Link}
            href="/docs"
            target="_blank"
            key="docslink"
            onClick={handleDrawerToggle}
            className={classes.listItem}
          >
            <ListItemIcon className={classes.menuIcon}>
              <DocumentsIcon />
            </ListItemIcon>
            <ListItemText
              className={classes.listItemText}
              primary="Documentation"
            />
          </ListItem>
        )}
      </List>
    );
  };

  return (
    <>
      <Drawer
        variant="permanent"
        classes={{
          root: classes.permanentDrawerRoot,
          paper: classes.drawerPaper,
        }}
        open={props.open}
      >
        <div
          className={`${classes.drawerHeader} ${classes.permanentDrawerHeader}`}
        >
          <NavLink to="/">
            <img
              src={logo}
              className={classes.permanentDrawerLogo}
              alt="Vivial Connect"
            />
          </NavLink>
        </div>
        <SidebarContent isTemporaryDrawer={false} />
      </Drawer>

      <Drawer
        variant="temporary"
        classes={{
          root: classes.temporaryDrawerRoot,
          paper: classes.drawerPaper,
        }}
        open={drawerOpen}
        onClose={handleDrawerToggle}
        anchor="left"
      >
        <div
          className={`${classes.drawerHeader} ${classes.temporaryDrawerHeader}`}
        >
          <IconButton
            aria-label="show menu"
            onClick={handleDrawerToggle}
            className={classes.drawerToggle}
          >
            <MenuOpenIcon />
          </IconButton>
          <img
            src={logo}
            className={classes.temporaryDrawerLogo}
            alt="Vivial Connect"
          />
        </div>
        <SidebarContent isTemporaryDrawer={true} />
      </Drawer>
    </>
  );
}
