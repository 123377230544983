import React from "react";
import PropTypes from "prop-types";
import HTMLHead from "components/HTMLHead";
import Hero from "components/Layouts/Brochure/Hero";

export function GlossaryShared({ page, heroContent, children }) {
  return (
    <>
      <HTMLHead page={page} />
      <Hero {...heroContent} />

      {children}
    </>
  );
}

GlossaryShared.propTypes = {
  children: PropTypes.node,
  page: PropTypes.object,
  heroContent: PropTypes.object,
};
