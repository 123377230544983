/* eslint-disable react/prop-types */
import React, { useState, useEffect, createRef, useCallback } from "react";
import { toast } from "react-toastify";

import Grid from "@material-ui/core/Grid";
import TablePagination from "@material-ui/core/TablePagination";
import Typography from "@material-ui/core/Typography";

import SearchField from "components/SearchField";

import NumbersList from "./NumbersList";
import VivialConnectAPI from "api/vivial-connect";

const { customHooks } = VivialConnectAPI.helpers;

export default function AddNumbers({
  connectorNumbers = [],
  handleChange = () => {},
}) {
  const isMounted = customHooks.useMountedRef();
  let searchNumberRef = createRef();
  const [selectedNumbers, setSelectedNumbers] = useState([]);
  const [numbers, setNumbers] = useState([]);
  const [numbersCount, setNumbersCount] = useState(0);
  const [page, setPage] = useState(0);

  const limit = 12;

  const processNumberListForDisplay = useCallback(
    (numbers) => {
      return numbers.map((number) => {
        let isSelected =
          selectedNumbers.filter(
            (num) => num.phone_number_id === number.id && num.selected
          ).length > 0;

        let item = {
          name: number.name,
          phone_number: number.phone_number,
          phone_number_id: number.id,
          selected: isSelected,
        };
        return item;
      });
    },
    [selectedNumbers]
  );

  const handlePageChange = async (event, newPage) => {
    const { numbers } = VivialConnectAPI.services;
    const search = searchNumberRef.current.value;

    let params = {
      page: newPage + 1, //API pagination is 1-based. MD pagination is 0-based :(
      limit: limit,
    };
    if (search !== "") params["contains"] = search;

    numbers
      .getNumbers(params)
      .then((phoneNumbers) => {
        const newNumbers = processNumberListForDisplay(phoneNumbers);
        setNumbers(newNumbers);
        setPage(newPage);
      })
      .catch((error) => toast.error(error));
  };

  const handleNumbersChange = (number) => {
    //toggle number selection
    number.selected = !number.selected;

    let newSelectedNumbers;
    if (number.selected) {
      //add number if selecting
      newSelectedNumbers = [...selectedNumbers];
      newSelectedNumbers.push(number);
    } else {
      //remove number if deselecting
      newSelectedNumbers = selectedNumbers.filter(
        (num) => num.phone_number_id !== number.phone_number_id
      );
    }
    setSelectedNumbers(newSelectedNumbers);

    handleChange(newSelectedNumbers);
  };

  const handleSearchNumbers = () => {
    const { numbers } = VivialConnectAPI.services;
    const search = searchNumberRef.current ? searchNumberRef.current.value : "";

    setNumbers([]);
    setPage(0);

    const params = {
      page: 1,
      limit: limit,
      contains: search,
    };
    numbers
      .getNumbers(params)
      .then((phoneNumbers) => {
        const newNumbers = processNumberListForDisplay(phoneNumbers);
        setNumbers(newNumbers);
      })
      .catch((error) => toast.error(error));
    numbers
      .getNumbersCount({ contains: search })
      .then((count) => setNumbersCount(count))
      .catch((error) => toast.error(error));
  };

  useEffect(() => {
    const { numbers } = VivialConnectAPI.services;

    const params = {
      page: 1,
      limit: limit,
    };
    numbers
      .getNumbers(params)
      .then((phoneNumbers) => {
        if (!isMounted.current) return;
        const newNumbers = processNumberListForDisplay(phoneNumbers);
        setNumbers(newNumbers);
      })
      .catch((error) => toast.error(error));
    numbers
      .getNumbersCount()
      .then((count) => {
        if (!isMounted.current) return;
        setNumbersCount(count);
      })
      .catch((error) => toast.error(error));
  }, [processNumberListForDisplay, isMounted]);

  useEffect(() => {
    const pns = connectorNumbers.map((number) => {
      let item = {
        phone_number_id: number.id || number.phone_number_id,
        selected: true,
      };
      return item;
    });

    setSelectedNumbers(pns);
  }, [connectorNumbers]);

  return (
    <>
      <Grid item xs={12}>
        <SearchField
          label="Search Numbers"
          searchValue={searchNumberRef}
          onSearch={handleSearchNumbers}
          fullWidth={true}
        />
      </Grid>
      {numbersCount > 0 ? (
        <>
          <Grid item xs={12} sm={6}>
            <NumbersList
              numbers={numbers.slice(0, limit / 2)}
              handleChange={handleNumbersChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <NumbersList
              numbers={numbers.slice(limit / 2, limit)}
              handleChange={handleNumbersChange}
              startIndex={limit / 2}
            />
          </Grid>
        </>
      ) : (
        <Typography variant="body1">
          {searchNumberRef.current && searchNumberRef.current.value !== "" ? (
            <b>
              No numbers found for &quot;{searchNumberRef.current.value}&quot;
            </b>
          ) : (
            <b>No numbers found</b>
          )}
        </Typography>
      )}
      {numbersCount > limit && (
        <Grid item xs={12}>
          <TablePagination
            rowsPerPageOptions={[12]}
            component="div"
            count={numbersCount}
            rowsPerPage={limit}
            page={page}
            onPageChange={handlePageChange}
          />
        </Grid>
      )}
    </>
  );
}
