import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";

import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import {
  Card as DashboardCard,
  CardHeader as DashboardHeader,
  CardContent as DashboardCardContent,
} from "components/Layouts/Dashboard/Card";
import { format, parseISO } from "date-fns";

import VivialConnectAPI from "api/vivial-connect";

import { formatTCRString } from "./shared.js";
import { useStyles } from "./styles";

export default function CampaignsTable() {
  let history = useHistory();
  const classes = useStyles();
  const [campaigns, setCampaigns] = useState([]);
  const { campaigns: campaignsServices } = VivialConnectAPI.services;

  const redirectToDetailsView = (campaignId) => {
    history.push(`/dashboard/campaign/${campaignId}`);
  };

  useEffect(() => {
    const params = {
      active: 1,
    };

    campaignsServices
      .getCampaigns(params)
      .then((campaigns) => setCampaigns(campaigns))
      .catch((error) => toast.error(error));
  }, []);

  return (
    <DashboardCard>
      <DashboardHeader title={"Campaigns"} />
      <DashboardCardContent>
        <TableContainer>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">Campaign ID</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6">Brand ID</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6">Use Case</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6">Status</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6">Renewal</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6">Date Created</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {campaigns.map((campaign, index) => {
                return (
                  <TableRow
                    hover
                    key={index}
                    onClick={() => redirectToDetailsView(campaign.id)}
                    className={classes.rowWrapper}
                  >
                    <TableCell>{campaign.id}</TableCell>
                    <TableCell>{campaign.brand_id}</TableCell>
                    <TableCell>{formatTCRString(campaign.usecase)}</TableCell>
                    <TableCell>{campaign.status}</TableCell>
                    <TableCell>{campaign.renewal ? "ON" : "OFF"}</TableCell>
                    <TableCell>
                      {format(parseISO(campaign.date_created), "yyyy-MM-dd")}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DashboardCardContent>
    </DashboardCard>
  );
}
