/* eslint-disable react/prop-types */
import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Copyright from "@moosetalk-frontend/global/components/Copyright";
import logo from "assets/img/vivial_connect_logo_black.png";
import useStyles from "./styles";

export const RegistrationCard = ({ children, contentClass = "narrow" }) => {
  const classes = useStyles();

  return (
    <>
      <Paper className={classes.paper} elevation={5}>
        <Box className={classes.connectLogo}>
          <img
            src={logo}
            className={classes[contentClass]}
            alt="Vivial Connect"
          />
        </Box>
        {children}
      </Paper>
      <Copyright showSupport={false} />
    </>
  );
};

export const RegistrationCardContent = ({ children }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent="center"
      spacing={3}
      className={classes.cardContent}
    >
      {children}
    </Grid>
  );
};

export default {
  RegistrationCard,
  RegistrationCardContent,
};
