/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

import DeleteIcon from "@material-ui/icons/Delete";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";

import {
  Card as DashboardCard,
  CardContent as DashboardCardContent,
} from "components/Layouts/Dashboard/Card";
import ConfirmPassword from "components/ConfirmPassword";

import useStyles from "./styles";
import VivialConnectAPI from "api/vivial-connect";

export default function UsersTable({ currentUser, handleSelectedUser }) {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);

  const handleOnCellClick = (user) => {
    handleSelectedUser(user);
  };

  const handleOnDelete = (selectedUser) => {
    const { users } = VivialConnectAPI.services;

    users
      .deleteUser(selectedUser.id)
      .then(() => {
        toast.success(`User ${selectedUser.id} was deleted`);
        users
          .getUsers()
          .then((newUsers) => setUsers(newUsers))
          .catch((error) => toast.error(error));
      })
      .catch((error) => toast.error(error));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function createUserData(user, email, role, userInfo) {
    return { user, email, role, userInfo };
  }

  const convertDataToRows = (user) => {
    const {
      first_name: firstName,
      last_name: lastName,
      id,
      email,
      roles,
    } = user;

    const isAdmin = roles.filter(
      (role) => role.name === "AccountAdministrator"
    )[0];

    return createUserData(
      { firstName, lastName, id },
      email,
      isAdmin ? "Account Administrator" : "User",
      user
    );
  };

  const rowsUsersTable = users.map((user) => convertDataToRows(user));

  useEffect(() => {
    const { users } = VivialConnectAPI.services;

    const params = {
      page: page + 1,
      limit: rowsPerPage,
    };

    users.getUsers(params).then((newUsers) => setUsers(newUsers));
    users.getUsersCount().then((count) => setCount(count));
  }, [page, rowsPerPage]);

  return (
    <DashboardCard>
      <DashboardCardContent>
        <TableContainer className={classes.tableContainer}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography
                    variant="h6"
                    className={classes.tableColumnHeader}
                  >
                    User
                  </Typography>
                </TableCell>
                <Hidden smDown>
                  <TableCell>
                    <Typography
                      variant="h6"
                      className={classes.tableColumnHeader}
                    >
                      Email/Username
                    </Typography>
                  </TableCell>
                </Hidden>
                <TableCell>
                  <Typography
                    variant="h6"
                    className={classes.tableColumnHeader}
                  >
                    Role
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    variant="h6"
                    className={classes.tableColumnHeader}
                  >
                    Actions
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowsUsersTable.map((row, indx) => (
                <TableRow hover key={indx}>
                  <TableCell
                    component="th"
                    scope="row"
                    onClick={() => handleOnCellClick(row.userInfo)}
                    className={classes.tableClickableCell}
                  >
                    ID: {row.user.id}
                    <Typography variant="h5">
                      {row.user.firstName} {row.user.lastName}
                    </Typography>
                  </TableCell>
                  <TableCell
                    onClick={() => handleOnCellClick(row.userInfo)}
                    className={classes.tableClickableCell}
                  >
                    {row.email}
                  </TableCell>
                  <TableCell
                    onClick={() => handleOnCellClick(row.userInfo)}
                    className={classes.tableClickableCell}
                  >
                    {row.role}
                  </TableCell>
                  <TableCell align="center">
                    {currentUser.id !== row.user.id && (
                      <ConfirmPassword
                        title={`DELETE USER - ${row.email}`}
                        warningMessage={`This action will delete all API keys associated with this user. Applications using these keys will be unable to access the Vivial Connect API.`}
                        clickableComponent={
                          <IconButton>
                            <DeleteIcon className={classes.tableDeleteButton} />
                          </IconButton>
                        }
                        onSubmit={() => handleOnDelete(row.userInfo)}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {count > rowsPerPage && (
          <TablePagination
            rowsPerPageOptions={[10, 20, 50, 100]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            className={classes.tablePagination}
            labelRowsPerPage={"Rows Per Page"}
          />
        )}
      </DashboardCardContent>
    </DashboardCard>
  );
}
