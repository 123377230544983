/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import {
  Card as DashboardCard,
  CardContent as DashboardCardContent,
  CardHeader as DashboardCardHeader,
} from "../Layouts/Dashboard/Card";
import VivialConnectAPI from "api/vivial-connect";
import { useAppContext } from "components/Context";

const {
  validatePhone,
  validateShortCode,
  validateUrl,
} = VivialConnectAPI.helpers.validation;

const useStyles = makeStyles(() => ({
  testForm: {
    height: "100%",
  },
  testFormButton: {
    textAlign: "right",
  },
}));

export default function TestMessage({ phoneNumber, connectorId }) {
  const { appState } = useAppContext();
  const classes = useStyles();
  const [testNumber, setTestNumber] = useState({
    to_number: "",
    media_urls: "",
    body: "",
  });
  const [testErrors, setTestErrors] = useState({
    toNumber: "",
    mediaURL: "",
    body: "",
  });

  const handleChangeTestNumber = (prop) => (event) => {
    setTestNumber({ ...testNumber, [prop]: event.target.value });
  };

  const validateTestNumbersForm = () => {
    let errorFound = false;

    if (!validateShortCode(testNumber.to_number)) {
      if (!validatePhone(testNumber.to_number)) {
        setTestErrors((prevErrors) => ({
          ...prevErrors,
          toNumber: "Please enter a valid phone number",
        }));
        errorFound = true;
      } else {
        setTestErrors((prevErrors) => ({ ...prevErrors, toNumber: "" }));
      }
    } else {
      setTestErrors((prevErrors) => ({ ...prevErrors, toNumber: "" }));
    }
    if (testNumber.media_urls) {
      if (!validateUrl(testNumber.media_urls)) {
        setTestErrors((prevErrors) => ({
          ...prevErrors,
          mediaURL: "Please enter a valid URL",
        }));
        errorFound = true;
      } else {
        setTestErrors((prevErrors) => ({
          ...prevErrors,
          mediaURL: "",
        }));
      }
    } else {
      setTestErrors((prevErrors) => ({
        ...prevErrors,
        mediaURL: "",
      }));
    }

    if (testNumber.body.length > 2048) {
      setTestErrors((prevErrors) => ({
        ...prevErrors,
        body: "Message body must be less than 2048 characters",
      }));
      errorFound = true;
    } else {
      setTestErrors((prevErrors) => ({
        ...prevErrors,
        body: "",
      }));
    }

    return errorFound;
  };

  const onSubmitTestNumber = (event) => {
    event.preventDefault();
    if (validateTestNumbersForm()) return;

    const { messages } = VivialConnectAPI.services;

    const payload = {
      message: {
        to_number: testNumber.to_number,
        body: testNumber.body,
      },
    };
    if (connectorId) {
      payload.message.connector_id = connectorId;
    } else {
      payload.message.from_number = phoneNumber;
    }

    if (testNumber.media_urls)
      payload.message.media_urls = [testNumber.media_urls];

    messages
      .postMessages(payload)
      .then(() => {
        toast.success(`Message sent to ${testNumber.to_number}`);
      })
      .catch((error) => toast.error(error));
  };

  useEffect(() => {
    const { contacts } = VivialConnectAPI.services;

    contacts
      .getContacts()
      .then(async (res) => {
        const contact = res.filter(
          (contact) => contact.contact_type === "main"
        )[0];

        if (contact) {
          setTestNumber({ ...testNumber, to_number: contact.mobile_phone });
        }
      })
      .catch(() => {});
  }, []);

  return (
    <form
      autoComplete="off"
      className={classes.testForm}
      onSubmit={onSubmitTestNumber}
    >
      <DashboardCard>
        <DashboardCardHeader title={"Send Test Message"} />
        <DashboardCardContent block={true}>
          {appState.plan_type === "freetrial" ? (
            <Grid item xs={12}>
              <Typography paragraph={true}>
                Free Trial accounts can only send messages to their verified
                mobile number.
              </Typography>
            </Grid>
          ) : (
            ""
          )}
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                label="Mobile Number"
                name="to_number"
                value={testNumber.to_number}
                onChange={handleChangeTestNumber("to_number")}
                InputLabelProps={{
                  shrink: true,
                }}
                error={testErrors.toNumber !== ""}
                helperText={testErrors.toNumber}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                label="Media URL"
                name="media_urls"
                value={testNumber.media_urls}
                onChange={handleChangeTestNumber("media_urls")}
                InputLabelProps={{
                  shrink: true,
                }}
                error={testErrors.mediaURL !== ""}
                helperText={testErrors.mediaURL}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                label="Message Body"
                name="body"
                value={testNumber.body}
                onChange={handleChangeTestNumber("body")}
                multiline
                rows={6}
                rowsMax={6}
                InputLabelProps={{
                  shrink: true,
                }}
                error={testErrors.body !== ""}
                helperText={testErrors.body}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} className={classes.testFormButton}>
            <Button type="submit" color="primary" variant="contained">
              Send
            </Button>
          </Grid>
        </DashboardCardContent>
      </DashboardCard>
    </form>
  );
}
