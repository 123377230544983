import React, { createRef } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import Section from "components/Layouts/Brochure/Section";
import ScrollTop from "@moosetalk-frontend/global/components/ScrollTop";

import AlphaList from "./AlphaList";
import GlossaryTermsLinks from "./GlossaryTermsLinks";
import { fetchGlossary, generateGlossary } from "./helper";
import { useStyles } from "./styles";
import { GlossaryShared } from "./shared";

export default function Glossary() {
  const classes = useStyles();
  let searchGlossaryRef = createRef();
  let { page, glossary, heroContent, setGlossary } = fetchGlossary();

  const handleSearchGlossary = () => {
    const searchGlossary =
      searchGlossaryRef.current.value !== ""
        ? searchGlossaryRef.current.value
        : null;

    if (searchGlossary) {
      let filteredGlossary = page.fields.additionalContent.filter((element) => {
        if (
          element.fields &&
          element.fields.term
            .toLowerCase()
            .includes(searchGlossary.toLowerCase())
        )
          return element;
      });

      setGlossary(generateGlossary(filteredGlossary));
    } else {
      setGlossary(generateGlossary(page.fields.additionalContent));
    }
  };

  if (!page) return null;
  return (
    <GlossaryShared heroContent={heroContent} page={page}>
      <Section>
        <Grid container direction="column" alignItems="center" spacing={4}>
          <Grid item xs={12}>
            <Typography
              className={classes.header}
              variant="h2"
              color="inherit"
              id="glossaryTop"
            >
              Glossary
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="searchGlossary"
              label="Enter Glossary Term"
              onChange={handleSearchGlossary}
              variant="outlined"
              color="secondary"
              inputProps={{ ref: searchGlossaryRef }}
              fullWidth
              name="searchGlossary"
              className={classes.textField}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <AlphaList glossary={glossary} />
          </Grid>

          <GlossaryTermsLinks glossary={glossary} />
        </Grid>
      </Section>
      <ScrollTop topAnchor="glossaryTop" scrollTriggerThreshold={650}>
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </GlossaryShared>
  );
}
