import endpoints from "./endpoints.js";
import { factory } from "vivialconnect-js-core";

//NOTE: do not use spread syntax here.
//serviceHelpers == {init, http, appSessionStore}
const serviceHelpers = factory.initService(endpoints);

const postRegister = async (payload, params) => {
  const { http } = serviceHelpers;
  try {
    await http.post(endpoints.register(), payload, { params });
    return true;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getConfirm = async (userId, verificationToken) => {
  const { http } = serviceHelpers;
  try {
    const { data } = await http.get(
      endpoints.confirm(userId, verificationToken)
    );
    return data.valid;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getInvitationConfirm = async (userId, verificationToken) => {
  const { http } = serviceHelpers;
  try {
    const { data } = await http.get(
      endpoints.invitationConfirm(userId, verificationToken)
    );
    return data.valid;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const postResendEmail = async (payload, params) => {
  const { http } = serviceHelpers;
  try {
    await http.post(endpoints.resendEmail(), payload, {
      params,
    });
    return true;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const postInvitationResendEmail = async (payload, params) => {
  const { http } = serviceHelpers;
  try {
    await http.post(endpoints.invitationResendEmail(), payload, {
      params,
    });
    return true;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

export default {
  init: serviceHelpers.init,
  postRegister,
  getConfirm,
  getInvitationConfirm,
  postResendEmail,
  postInvitationResendEmail,
};
