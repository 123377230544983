// lookups/endpoints.js
import { factory } from "vivialconnect-js-core";

//NOTE: do not use spread syntax here.
//helper == {init, BASE_URL}
const helper = factory.initEndpoints();

export default {
  init: helper.init,
  lookups: (name) => `${helper.BASE_URL}/lookups/${name}.json`,
  lookupsPrices: () => `${helper.BASE_URL}/lookups/prices.json`,
  states: () => `${helper.BASE_URL}/lookups/state.json`,
  contries: () => `${helper.BASE_URL}/lookups/country.json`,
  contactTypes: () => `${helper.BASE_URL}/lookups/contact_type.json`,
};
