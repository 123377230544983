/* eslint-disable react/prop-types */
import React from "react";
import Typography from "@material-ui/core/Typography";

import { useStyles } from "./styles";

const LobbyHeader = ({ title, subtitle }) => {
  const classes = useStyles();
  return (
    <>
      <Typography component="h1" variant="h1" className={classes.title}>
        {title}
      </Typography>
      <Typography component="p" variant="body1" className={classes.subtitle}>
        {subtitle}
      </Typography>
    </>
  );
};

export default LobbyHeader;
