// mailings/endpoints.js
import { factory } from "vivialconnect-js-core";

//NOTE: do not use spread syntax here.
//helper == {init, helper.BASE_URL}
const helper = factory.initEndpoints();

export default {
  init: helper.init,
  newsletterSignup: () => `${helper.BASE_URL}/mailings/newsletter/signup.json`,
  partnerSignup: () => `${helper.BASE_URL}/mailings/partner_signup/signup.json`,
  contactSignup: () => `${helper.BASE_URL}/mailings/contact_signup/signup.json`,
};
