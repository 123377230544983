/* eslint-disable react/prop-types */
import React from "react";

import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";

import { colorExtendedGray2 } from "themes/palette";

import Subtitle from "components/Layouts/Brochure/Subtitle";
import BrochureButton from "components/Layouts/Brochure/BrochureButton";
import MarkDownText from "components/Layouts/Brochure/MarkDownText";

const useStyles = makeStyles((theme) => ({
  featureContainer: {
    margin: "0 30px 75px",
  },
  featureItem: {
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
    borderBottomColor: colorExtendedGray2,
    "& img": {
      marginBottom: "20px",
    },
    [theme.breakpoints.down("xs")]: {
      "& .MuiButton-root": {
        marginBottom: "20px",
      },
    },
  },
  featureHeader: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
}));

export default function FeatureList(props) {
  const classes = useStyles();
  const { header, items } = props;

  //prevent component rendering before the item list is acquired
  if (!items || !items.length) return null;

  return (
    <div className={classes.featureContainer}>
      {header && (
        <div className={classes.featureHeader}>
          <Subtitle>{header}</Subtitle>
        </div>
      )}

      <Grid container direction="column" spacing={10}>
        {items.map((item, index) => {
          return (
            <Grid
              container
              item
              xs={12}
              direction="row"
              className={classes.featureItem}
              alignItems="center"
              key={index}
            >
              <Grid item sm={4} xs={12} className="center">
                <Typography variant="h4" gutterBottom>
                  {item.fields.header}
                </Typography>

                {item.fields.image && (
                  <img
                    alt="{item.header}"
                    src={item.fields.image.fields.file.url}
                  />
                )}

                {item.fields.button && (
                  <BrochureButton
                    variant="contained"
                    size="small"
                    href={item.fields.button.fields.link}
                    target={
                      item.fields.button.fields.link.startsWith("http")
                        ? "_blank"
                        : "_self"
                    }
                  >
                    {item.fields.button.fields.text}
                  </BrochureButton>
                )}
              </Grid>

              <Grid item sm={8} xs={12}>
                <Typography variant="body1">
                  <MarkDownText>
                    {item.fields.longDescription
                      ? item.fields.longDescription
                      : item.fields.shortDescription}
                  </MarkDownText>
                </Typography>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}
