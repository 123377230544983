/* eslint-disable react/prop-types */
import React from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import makeStyles from "@material-ui/core/styles/makeStyles";
import withStyles from "@material-ui/core/styles/withStyles";

import {
  colorExtendedGray1,
  colorExtendedGray2,
  colorExtendedGreen4,
} from "themes/palette";

import VivialConnectAPI from "api/vivial-connect";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("md")]: {
      padding: "0 15%",
    },
  },
  tableContainer: {
    maxWidth: "848px",
    margin: "0 auto",
  },
}));

const StyledTableCell = withStyles((theme) => ({
  root: {
    width: "33%",
    borderStyle: "solid",
    borderWidth: "2px",
    borderColor: theme.palette.common.white,
    [theme.breakpoints.up("md")]: {
      whiteSpace: "nowrap",
    },
  },
  head: {
    backgroundColor: colorExtendedGreen4,
    color: theme.palette.common.white,
    fontWeight: 700,
    textAlign: "center",
  },
  body: {
    textAlign: "center",
  },
}))(TableCell);

const StyledTableRow = withStyles(() => ({
  root: {
    backgroundColor: colorExtendedGray2,
    "&:nth-of-type(odd)": {
      backgroundColor: colorExtendedGray1,
    },
    "& th": {
      fontWeight: 700,
    },
  },
}))(TableRow);

export default function PricingTable({
  USLongcodePrices,
  CALongcodePrices,
  shortcodePrices,
}) {
  const classes = useStyles();
  const { convertNumberToCurrency } = VivialConnectAPI.helpers.numbers;

  const getPrice = (name, prices = USLongcodePrices, decimalPlaces = 4) => {
    if (prices.cash_prices && prices.cash_prices[name] !== undefined) {
      return convertNumberToCurrency(
        prices.cash_prices[name],
        decimalPlaces,
        true
      );
    }
    return "N/A";
  };

  return (
    <div className={classes.root}>
      <TableContainer className={classes.tableContainer}>
        <Table>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>US Messaging</StyledTableCell>
              <StyledTableCell>Inbound</StyledTableCell>
              <StyledTableCell>Outbound</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            <StyledTableRow>
              <StyledTableCell component="th">SMS</StyledTableCell>
              <StyledTableCell>{getPrice("sms_local_in")}</StyledTableCell>
              <StyledTableCell>{getPrice("sms_local_out")}</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell component="th">MMS</StyledTableCell>
              <StyledTableCell>{getPrice("mms_local_in")}</StyledTableCell>
              <StyledTableCell>{getPrice("mms_local_out")}</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell component="th">Toll-Free SMS</StyledTableCell>
              <StyledTableCell>{getPrice("sms_tollfree_in")}</StyledTableCell>
              <StyledTableCell>{getPrice("sms_tollfree_out")}</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell component="th">Toll-Free MMS</StyledTableCell>
              <StyledTableCell>{getPrice("mms_tollfree_in")}</StyledTableCell>
              <StyledTableCell>{getPrice("mms_tollfree_out")}</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell component="th">
                Shortcode Gateway
              </StyledTableCell>
              <StyledTableCell>{getPrice("sms_shortcode_in")}</StyledTableCell>
              <StyledTableCell>{getPrice("sms_shortcode_out")}</StyledTableCell>
            </StyledTableRow>
          </TableBody>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Canadian Messaging</StyledTableCell>
              <StyledTableCell>Inbound</StyledTableCell>
              <StyledTableCell>Outbound</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            <StyledTableRow>
              <StyledTableCell component="th">SMS</StyledTableCell>
              <StyledTableCell>
                {getPrice("sms_local_in", CALongcodePrices)}
              </StyledTableCell>
              <StyledTableCell>
                {getPrice("sms_local_out", CALongcodePrices)}
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell component="th">MMS</StyledTableCell>
              <StyledTableCell>
                {getPrice("mms_local_in", CALongcodePrices)}
              </StyledTableCell>
              <StyledTableCell>
                {getPrice("mms_local_out", CALongcodePrices)}
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell component="th">Toll-Free SMS</StyledTableCell>
              <StyledTableCell>
                {getPrice("sms_tollfree_in", CALongcodePrices)}
              </StyledTableCell>
              <StyledTableCell>
                {getPrice("sms_tollfree_out", CALongcodePrices)}
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell component="th">Toll-Free MMS</StyledTableCell>
              <StyledTableCell>
                {getPrice("mms_tollfree_in", CALongcodePrices)}
              </StyledTableCell>
              <StyledTableCell>
                {getPrice("mms_tollfree_out", CALongcodePrices)}
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell component="th">
                Shortcode Gateway
              </StyledTableCell>
              <StyledTableCell>
                {getPrice("sms_shortcode_in", CALongcodePrices)}
              </StyledTableCell>
              <StyledTableCell>
                {getPrice("sms_shortcode_out", CALongcodePrices)}
              </StyledTableCell>
            </StyledTableRow>
          </TableBody>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell colSpan={3}>
                US / Canadian Number Purchase & Voice
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            <StyledTableRow>
              <StyledTableCell component="th">Local Numbers</StyledTableCell>
              <StyledTableCell colSpan={2}>
                {getPrice("number_purchase", USLongcodePrices, 2)}/Month
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell component="th">
                Toll-Free Numbers
              </StyledTableCell>
              <StyledTableCell colSpan={2}>
                {getPrice("number_purchase_tollfree", USLongcodePrices, 2)}
                /Month
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell component="th">Call Forwarding</StyledTableCell>
              <StyledTableCell colSpan={2}>
                {getPrice("voice_forward")}/Minute
              </StyledTableCell>
            </StyledTableRow>
          </TableBody>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>
                10DLC<sup>*</sup>
              </StyledTableCell>
              <StyledTableCell>Standard Campaign</StyledTableCell>
              <StyledTableCell>Specialty Campaign</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            <StyledTableRow>
              <StyledTableCell component="th">
                Campaign Registration
              </StyledTableCell>
              <StyledTableCell>$2 - $10 / Month</StyledTableCell>
              <StyledTableCell>$0 - $30 / Month</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell component="th">
                Brand Registration
              </StyledTableCell>
              <StyledTableCell colSpan={2}>$5.00/One-Time</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell component="th">
                T-Mobile Campaign Activation Fee
              </StyledTableCell>
              <StyledTableCell colSpan={2}>$50.00/One-Time</StyledTableCell>
            </StyledTableRow>
          </TableBody>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>
                US Shortcode<sup>**</sup>
              </StyledTableCell>
              <StyledTableCell>Inbound</StyledTableCell>
              <StyledTableCell>Outbound</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            <StyledTableRow>
              <StyledTableCell component="th">SMS</StyledTableCell>
              <StyledTableCell>
                {getPrice("sms_shortcode_in", shortcodePrices)}
              </StyledTableCell>
              <StyledTableCell>
                {getPrice("sms_shortcode_out", shortcodePrices)}
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell component="th">MMS</StyledTableCell>
              <StyledTableCell>
                {getPrice("mms_shortcode_in", shortcodePrices)}
              </StyledTableCell>
              <StyledTableCell>
                {getPrice("mms_shortcode_out", shortcodePrices)}
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell component="th">
                Short Code One-Time Setup Fee
              </StyledTableCell>
              <StyledTableCell colSpan={2}>$1,200</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell component="th">
                Short Code Hosting
              </StyledTableCell>
              <StyledTableCell colSpan={2}>$500/Month</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell component="th">
                Short Code Lease – Random
              </StyledTableCell>
              <StyledTableCell colSpan={2}>$1,500/Quarter</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell component="th">
                Short Code Lease – Vanity
              </StyledTableCell>
              <StyledTableCell colSpan={2}>$3,000/Quarter</StyledTableCell>
            </StyledTableRow>
          </TableBody>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>
                International SMS<sup>**</sup>
              </StyledTableCell>
              <StyledTableCell>Outbound Cash</StyledTableCell>
              <StyledTableCell>Outbound Credit</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            <StyledTableRow>
              <StyledTableCell component="th">Zone A</StyledTableCell>
              <StyledTableCell>$0.0080</StyledTableCell>
              <StyledTableCell>1</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell component="th">Zone B</StyledTableCell>
              <StyledTableCell>$0.0320</StyledTableCell>
              <StyledTableCell>4</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell component="th">Zone C</StyledTableCell>
              <StyledTableCell>$0.0560</StyledTableCell>
              <StyledTableCell>7</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell component="th">Zone D</StyledTableCell>
              <StyledTableCell>$0.0880</StyledTableCell>
              <StyledTableCell>11</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell component="th">Zone E</StyledTableCell>
              <StyledTableCell>$0.1280</StyledTableCell>
              <StyledTableCell>16</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell component="th">Zone F</StyledTableCell>
              <StyledTableCell>$0.1840</StyledTableCell>
              <StyledTableCell>23</StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
