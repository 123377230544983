import endpoints from "./endpoints.js";
import { factory } from "vivialconnect-js-core";

//NOTE: do not use spread syntax here.
//serviceHelpers == {init, http, appSessionStore}
const serviceHelpers = factory.initService(endpoints);

const getToken = async (tokenId, userId, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.get(
      endpoints.personalToken(
        id ? id : appSessionStore.getAccountId(),
        userId,
        tokenId
      )
    );
    return data.token;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getTokens = async (userId, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.get(
      endpoints.personalTokens(id ? id : appSessionStore.getAccountId(), userId)
    );
    return data.tokens;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getTokensCount = async (userId, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.get(
      endpoints.personalTokensCount(
        id ? id : appSessionStore.getAccountId(),
        userId
      )
    );
    return data.count;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getScopes = async () => {
  const { http } = serviceHelpers;
  try {
    const { data } = await http.get(endpoints.scopes());
    return data.scopes;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const postToken = async (payload, userId, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.post(
      endpoints.personalTokens(
        id ? id : appSessionStore.getAccountId(),
        userId
      ),
      payload
    );
    return data.token;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const putToken = async (tokenId, payload, userId, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.put(
      endpoints.personalToken(
        id ? id : appSessionStore.getAccountId(),
        userId,
        tokenId
      ),
      payload
    );
    return data.token;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const deleteToken = async (tokenId, userId, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.delete(
      endpoints.personalToken(
        id ? id : appSessionStore.getAccountId(),
        userId,
        tokenId
      )
    );
    return data;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

export default {
  init: serviceHelpers.init,
  getToken,
  getTokens,
  getTokensCount,
  getScopes,
  postToken,
  putToken,
  deleteToken,
};
