import { colorPrimaryWhite, colorBrochureGray } from "../palette";

export const darkTheme = {
  root: {
    color: colorPrimaryWhite,
  },
};

export const brochureTheme = {
  root: {
    color: colorBrochureGray,
  },
};

export default {
  darkTheme,
  brochureTheme,
};
