/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

import ConfirmModal from "@moosetalk-frontend/global/components/ConfirmModal";
import {
  Card as DashboardCard,
  CardHeader as DashboardCardHeader,
  CardContent as DashboardCardContent,
} from "components/Layouts/Dashboard/Card";

import useStyles from "./styles";

import VivialConnectAPI from "api/vivial-connect";
import { toast } from "react-toastify";

const {
  convertNumberToCurrency,
  withCommaSeparator,
} = VivialConnectAPI.helpers.numbers;

export const CancelSubscriptionRadioButtonsGroup = ({
  disableRenewal,
  value,
  setValue,
  is90DaysSubscription,
}) => {
  const classes = useStyles();

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <FormControl component="fieldset">
      <RadioGroup
        aria-label="cancelation-type"
        name="cancel-suscription"
        value={value}
        onChange={handleChange}
      >
        {is90DaysSubscription ? null : (
          <>
            <FormControlLabel
              value="immediately"
              control={<Radio />}
              label="Cancel Immediately"
              className={classes.radioButtonOption}
            />
            <Typography variant="body1" className={classes.radioNote}>
              Convert your account to On-Demand pricing, remove existing
              credits, and refund any unspent credits.
            </Typography>
            <br />
            <Typography variant="body1" className={classes.radioNote}>
              Refunded amount will be added to your account balance.
              Subscriptions older than 30 days are subject to a cancellation
              fee. See{" "}
              <Link target="_new" href="/docs/billing-plans">
                Billing and Susbcriptions
              </Link>{" "}
              for more information.
            </Typography>
          </>
        )}
        {disableRenewal ? null : (
          <>
            <FormControlLabel
              value="renewal"
              control={<Radio />}
              label="Cancel on Renewal"
              className={classes.radioButtonOption}
            />
            <Typography variant="body1" className={classes.radioNote}>
              When your subscription period ends, your current plan will not
              automatically renew and your account will convert to an On-Demand
              plan.
            </Typography>
            <br />
            <Typography variant="body1" className={classes.radioNote}>
              See{" "}
              <Link target="_new" href="/docs/billing-plans">
                Billing and Susbcriptions
              </Link>{" "}
              for more information.
            </Typography>
          </>
        )}
      </RadioGroup>
    </FormControl>
  );
};

export const UpgradeSubscriptionDialog = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button color="primary" variant="contained" onClick={handleClickOpen}>
        Upgrade
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        className={classes.dialog}
      >
        <DialogTitle id="form-dialog-title">90-Day Plan Upgrade</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Please Contact{" "}
            <Link target="_new" href="mailto:support@support.vivialconnect.net">
              support@support.vivialconnect.net
            </Link>{" "}
            To Upgrade Your 90-Day Subscription Plan.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained">
            ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

//TODO: why is this component exported?
export const CancelSubscriptionFormDialog = ({
  disableRenewal,
  handleCancelOnRenewal,
  is90DaysSubscription,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState("renewal");
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancel = async () => {
    if (value === "immediately") {
      const { accounts } = VivialConnectAPI.services;

      accounts.postAccountCancel().catch((error) => toast.error(error));
    }

    if (value === "renewal") {
      handleCancelOnRenewal();
    }
    setValue("immediately");
    handleClose();
  };

  return (
    <>
      <Button
        color="primary"
        variant="text"
        onClick={handleClickOpen}
        size="small"
        className={classes.textButton}
      >
        Cancel Plan
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        className={classes.dialog}
      >
        <DialogTitle id="form-dialog-title" disableTypography={true}>
          <Typography variant="h5" color="primary">
            Cancel Subscription Plan
          </Typography>
        </DialogTitle>
        <DialogContent>
          <CancelSubscriptionRadioButtonsGroup
            value={is90DaysSubscription ? "renewal" : value}
            setValue={setValue}
            disableRenewal={disableRenewal}
            is90DaysSubscription={is90DaysSubscription}
          />
        </DialogContent>
        <DialogActions style={{ marginTop: "20px" }}>
          <Button
            onClick={handleClose}
            variant="contained"
            className={classes.cancelPlanDialogButton}
          >
            No Thanks
          </Button>
          <Button onClick={handleCancel} color="primary" variant="contained">
            Cancel Subscription
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const is90DaysSubscription = (planDates) => {
  const { alloc_type: allocType, renewal_type: renewalType } = planDates;
  return allocType === "quarter" && allocType === renewalType;
};

const YourPlanSubscription = ({
  status,
  billingOptions,
  setBillingOptions,
}) => {
  const classes = useStyles();
  const {
    plan_max_credits: planMaxCredits,
    plan_max_numbers: planMaxNumbers,
    // plan_description: planDescription,
    plan_dates: planDates,
    prices,
  } = status;
  const planPrice = prices.plan ? convertNumberToCurrency(prices.plan) : null;
  const {
    disable_renewal: disableRenewal,
    disable_edit: disableEdit,
    // disable_cancel: disableCancel,
    // disable_upgrade: disableUpgrade,
  } = billingOptions;
  const { billing } = VivialConnectAPI.services;
  let planDescription = "90-Day Subscription"; //make the description more descriptive
  let planMessage =
    "Messaging credits are granted every 90 days. If more messages are sent than credits available, overage rates will apply.";

  const handleCancelOnRenewal = () => {
    const payload = {
      disable_renewal: true,
    };

    billing
      .putBillingOptions(payload)
      .then((billingOptions) => setBillingOptions(billingOptions))
      .catch((error) => toast.error(error));
  };

  const handleResumeSubscription = () => {
    const payload = {
      disable_renewal: false,
    };

    billing
      .putBillingOptions(payload)
      .then((billingOptions) => setBillingOptions(billingOptions))
      .catch((error) => toast.error(error));
  };

  if (planDates.renewal_type === "year") {
    planDescription = "Annual Subscription"; //make the description more descriptive
    planMessage =
      "Plan renews yearly and messaging credits are granted each month. If more messages are sent than credits available, overage rates will apply.";
  }

  return (
    <DashboardCard>
      <DashboardCardHeader title={"Your Plan"} />
      <DashboardCardContent alignContent="flex-start">
        <Grid item xs={3}>
          <Typography variant="body2">Type:</Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography variant="body1">
            <strong>{planDescription}</strong>
          </Typography>
          <Typography variant="body1" className={classes.note}>
            {planMessage}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body2">Messaging Credits:</Typography>
        </Grid>
        <Grid item xs={9}>
          <p>{planMaxCredits ? withCommaSeparator(planMaxCredits) : "0"}</p>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body2">Number Credits:</Typography>
        </Grid>
        <Grid item xs={9}>
          <p>{planMaxNumbers}</p>
          <Typography variant="body1" className={classes.note}>
            Local numbers consume one number credit.
            <br />
            Toll-Free numbers consume two.
          </Typography>
        </Grid>
        {planPrice && (
          <>
            <Grid item xs={3}>
              <Typography variant="body2">Price:</Typography>
            </Grid>
            <Grid item xs={9}>
              <p>{`${planPrice}`}</p>
            </Grid>
          </>
        )}
        <Grid item container xs={12} alignItems="center" spacing={3}>
          <Grid item xs={3}>
            {disableEdit ? null : (
              <Typography variant="body2">Need more credits?</Typography>
            )}
          </Grid>
          <Grid item xs={9} className={classes.yourPlanButtonWrapper}>
            {disableEdit ? null : (
              <>
                {is90DaysSubscription(planDates) ? (
                  <UpgradeSubscriptionDialog />
                ) : (
                  <NavLink
                    className={classes.upgradeButton}
                    to="/dashboard/upgrade"
                  >
                    <Button color="primary" variant="contained">
                      Upgrade
                    </Button>
                  </NavLink>
                )}
                {disableRenewal ? (
                  <ConfirmModal
                    title={`RENEW PLAN`}
                    warningMessage={
                      <span>
                        Your plan will automatically renew on the renewal date.
                      </span>
                    }
                    clickableComponent={
                      <Button
                        color="primary"
                        variant="text"
                        size="small"
                        className={classes.textButton}
                      >
                        Resume Plan
                      </Button>
                    }
                    onSubmit={handleResumeSubscription}
                  />
                ) : (
                  <CancelSubscriptionFormDialog
                    disableRenewal={disableRenewal}
                    handleCancelOnRenewal={handleCancelOnRenewal}
                    is90DaysSubscription={is90DaysSubscription(planDates)}
                  />
                )}
              </>
            )}
          </Grid>
        </Grid>
      </DashboardCardContent>
    </DashboardCard>
  );
};

export default YourPlanSubscription;
