/* eslint-disable react/prop-types */
import React from "react";
import marked from "marked";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  markdownStyle: {
    "& a": {
      color: theme.palette.primary.main,
      // textDecoration: "none",
    },
  },
}));

export default function MarkDownText({ children }) {
  const classes = useStyles();

  if (!children) return null;
  const rawMarkup = marked(children);

  return (
    <span
      className={classes.markdownStyle}
      dangerouslySetInnerHTML={{ __html: rawMarkup }}
    ></span>
  );
}
