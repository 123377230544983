/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";

import { CardContent as DashboardCardContent } from "components/Layouts/Dashboard/Card";

import VivialConnectAPI from "api/vivial-connect";

import { useStyles } from "./styles";

const { connectors } = VivialConnectAPI.services;
const { customHooks } = VivialConnectAPI.helpers;

export default function ManageNumbers({ selectedConnector }) {
  const isMounted = customHooks.useMountedRef();
  const classes = useStyles();
  const [numbers, setNumbers] = useState([]);
  const [numbersCount, setNumbersCount] = useState(0);

  const deleteNumber = (number) => () => {
    const payload = {
      connector: {
        phone_numbers: [
          {
            phone_number: number.phone_number,
            phone_number_id: number.phone_number_id,
          },
        ],
      },
    };

    connectors
      .deleteConnectorPhoneNumbers(selectedConnector.id, payload)
      .then(() => {
        if (!isMounted.current) return;
        setNumbers(
          numbers.filter(
            (num) => num.phone_number_id !== number.phone_number_id
          )
        );
        setNumbersCount(numbersCount - 1);
        toast.success(`${number.phone_number} removed`);
      })
      .catch((error) => toast.error(error));
  };

  useEffect(() => {
    const params = {
      page: 1,
      limit: 50,
    };
    connectors
      .getConnectorPhoneNumbers(selectedConnector.id, params)
      .then((connector) => {
        if (!isMounted.current) return;
        setNumbers(connector.phone_numbers || []);
        setNumbersCount(connector.phone_numbers.length || 0);
      })
      .catch((error) => toast.error(error));
  }, [selectedConnector.id, isMounted]);

  /*
{
  "connector": {
    "date_modified": "2020-08-11T14:16:27", 
    "phone_numbers": [
      {
        "phone_number": "+19132140416", 
        "phone_number_id": 1
      }
    ]
  }, 
  "count": 1, 
  "next": null, 
  "pages": 1, 
  "previous": null
}
*/

  return (
    <DashboardCardContent alignContent="flex-start" justify="space-between">
      {numbersCount === 0 && (
        <Typography variant="body1">
          <b>No numbers assigned</b>
        </Typography>
      )}
      {numbers.map((number, index) => {
        return (
          <Grid
            item
            xs={12}
            sm={6}
            key={`num_${index}`}
            className={classes.manageNumber}
          >
            <Typography variant="body1" component="span">
              {number.phone_number}
            </Typography>
            <Button
              variant="outlined"
              size="small"
              onClick={deleteNumber(number)}
            >
              <DeleteIcon />
            </Button>
          </Grid>
        );
      })}
    </DashboardCardContent>
  );
}
