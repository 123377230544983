import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

export const init = () => {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENV,
    integrations: [new Integrations.BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
};

export const setUser = (user) => {
  Sentry.configureScope((scope) => {
    scope.setUser(user);
  });
};

export const log = (error) => {
  Sentry.captureException(new Error(error));
};

export default {
  init,
  setUser,
  log,
};
