/* eslint-disable react/prop-types */
import React from "react";
import { format } from "date-fns";

import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";

import Subtitle from "components/Layouts/Brochure/Subtitle";
import MarkDownText from "components/Layouts/Brochure/MarkDownText";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0 3%",
  },
  setImageWidth: {
    "& img": {
      width: "100%",
      maxWidth: "360px",
    },
  },
  noImageWidth: {
    textAlign: "right",
  },
  text: {
    "& h4": {
      marginBottom: "0.5rem",
    },
  },
  subtitle: {
    color: theme.palette.common.black,
    marginTop: "-0.5rem",
    marginBottom: "1rem",
  },
}));

const RenderContentWithLink = ({ item, children }) => {
  if (!item) return null;

  return item.fields.slug ? (
    <Link href={`/blog/${item.fields.slug}`}>{children}</Link>
  ) : (
    children
  );
};

export default function UnorderedList(props) {
  const classes = useStyles();
  const {
    header,
    items,
    showDate = false,
    setImageWidth = true,
    contentField = "shortDescription",
  } = props;

  //prevent component rendering before the item list is acquired
  if (!items || !items.length) return null;

  return (
    <div className={classes.root}>
      {header && (
        <div className="center">
          <Subtitle>{header}</Subtitle>
        </div>
      )}

      <Grid container direction="column" spacing={8}>
        {items.map((item, index) => {
          //Skip list items that have no text
          if (!item.fields.header && !item.fields[contentField]) return null;

          return (
            <Grid
              container
              item
              direction="row"
              className={classes.featureItem}
              spacing={6}
              key={index}
            >
              {item.fields.image && (
                <Grid
                  item
                  xs={setImageWidth ? 12 : 4}
                  md={4}
                  className={
                    setImageWidth ? classes.setImageWidth : classes.noImageWidth
                  }
                >
                  <RenderContentWithLink item={item}>
                    <img
                      alt="{item.header}"
                      src={item.fields.image.fields.file.url}
                    />
                  </RenderContentWithLink>
                </Grid>
              )}

              {(item.fields.header || item.fields[contentField]) && (
                <Grid
                  item
                  xs={setImageWidth ? 12 : 8}
                  md={8}
                  className={classes.text}
                >
                  <Typography variant="h4">
                    <RenderContentWithLink item={item}>
                      {item.fields.header}
                    </RenderContentWithLink>
                  </Typography>
                  {showDate && (
                    <Typography
                      variant="subtitle2"
                      className={classes.subtitle}
                    >
                      {format(Date.parse(item.fields.created), "MMMM do, yyyy")}
                    </Typography>
                  )}
                  <Typography variant="body1">
                    <MarkDownText>{item.fields[contentField]}</MarkDownText>
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}
