import React, { useState, createRef } from "react";
import { toast } from "react-toastify";
import { useHistory, Redirect } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import TablePagination from "@material-ui/core/TablePagination";

import withPageContext from "components/Context/WithPageContext";

import { PhoneForwardedIcon } from "components/VivialIcons";
import {
  Card as DashboardCard,
  CardContent as DashboardCardContent,
} from "components/Layouts/Dashboard/Card";
import SearchField from "components/SearchField";
import ListView from "components/ListView";

import VivialConnectAPI from "api/vivial-connect";
import { handleNumbers, BannerText } from "./helper";
import { useStyles } from "./styles";

export default withPageContext(Numbers, {
  title: "Numbers",
  subtitle: "manage numbers",
});

function Numbers() {
  const classes = useStyles();
  let history = useHistory();
  const [status, setStatus] = useState({});
  const [numbers, setNumbers] = useState([]);
  const [numberSelected, setNumberSelected] = useState({});
  const [count, setCount] = useState(null);
  const [page, setPage] = useState(0);
  const [numbersInfo, setNumbersInfo] = useState({
    accountNumber: 0,
    numbersOwned: 0,
  });
  const [redirectToEdit, setRedirectToEdit] = useState(false);
  const [refresh, setRefresh] = useState(null);
  const limit = 10;

  let searchNumberRef = createRef();

  React.useEffect(() => {
    if (refresh === false) return;

    const { accounts } = VivialConnectAPI.services;
    setRefresh(false);

    accounts
      .getAccountStatus()
      .then((account_status) => {
        setStatus(account_status);
        setNumbersInfo({
          accountNumber: account_status.plan_max_numbers,
          numbersOwned: account_status.numbers_owned,
        });
      })
      .catch((error) => toast.error(error));

    const { numbers } = VivialConnectAPI.services;
    const params = {
      page: 1,
      limit: limit,
    };

    numbers
      .getNumbers(params)
      .then((phoneNumbers) => {
        setNumbers(phoneNumbers);
        setPage(0);
      })
      .catch((error) => toast.error(error));
    numbers
      .getNumbersCount()
      .then((count) => setCount(count))
      .catch((error) => toast.error(error));
  }, [refresh]);

  const handleSearchNumbers = async () => {
    const { numbers } = VivialConnectAPI.services;
    const search = searchNumberRef.current ? searchNumberRef.current.value : "";
    const params = {
      page: 1,
      limit: limit,
      contains: search,
    };

    numbers
      .getNumbers(params)
      .then((phoneNumbers) => {
        setNumbers(phoneNumbers);
        setPage(0);
      })
      .catch((error) => toast.error(error));
    numbers
      .getNumbersCount({ contains: search })
      .then((count) => setCount(count))
      .catch((error) => toast.error(error));
  };

  const handlePageChange = async (event, newPage) => {
    const { numbers } = VivialConnectAPI.services;
    const search = searchNumberRef.current.value;

    let params = {
      page: newPage + 1, //API pagination is 1-based. MD pagination is 0-based :(
      limit: limit,
    };
    if (search !== "") params["contains"] = search;

    numbers
      .getNumbers(params)
      .then((phoneNumbers) => {
        setNumbers(phoneNumbers);
        setPage(newPage);
      })
      .catch((error) => toast.error(error));
  };

  const handleClickNumbers = (phoneNumber) => {
    setNumberSelected(phoneNumber);
    setRedirectToEdit(true);
  };

  const redirectToNumberPurchase = (event) => {
    if (event) event.preventDefault();
    history.push("/dashboard/numbers/purchase");
  };

  if (!Object.keys(status).length) return null;
  const { plan_type } = status;

  if (redirectToEdit)
    return <Redirect to={`/dashboard/numbers/edit/${numberSelected.id}`} />;

  return (
    <div className={classes.root} spacing={2}>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <DashboardCard>
            <DashboardCardContent
              justify="space-between"
              alignItems="center"
              spacing={0}
            >
              <Grid item xs={12}>
                <BannerText
                  plan_type={plan_type}
                  included={numbersInfo.accountNumber}
                  owned={numbersInfo.numbersOwned}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <SearchField
                  label="Search Numbers"
                  placeholder="Search My Numbers"
                  searchValue={searchNumberRef}
                  onSearch={handleSearchNumbers}
                  fullWidth={true}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={6}
                className={classes.buttonWrapper}
              >
                <Button
                  color="primary"
                  variant="outlined"
                  startIcon={<PhoneForwardedIcon />}
                  onClick={redirectToNumberPurchase}
                >
                  <nobr>Buy numbers</nobr>
                </Button>
              </Grid>
            </DashboardCardContent>
          </DashboardCard>{" "}
        </Grid>
        <IndexView numbers={numbers} />
      </Grid>
    </div>
  );

  function IndexView() {
    const classes = useStyles();

    const renderItem = (phone, index) => {
      return handleNumbers(phone, index);
    };

    const ShowError = () => (
      <Typography variant="subtitle1">
        No numbers found.{" "}
        <Link href="#" onClick={redirectToNumberPurchase}>
          Buy a number now
        </Link>
        .
      </Typography>
    );

    const ShowList = () => {
      const headers = ["Phone Number", "Type", "Voice", "Campaign"];
      const lastIndex = Math.min(numbers.length, limit);
      const midpoint = Math.ceil(lastIndex / 2);

      return (
        <DashboardCard>
          <DashboardCardContent>
            <Grid item xs={12} lg={6}>
              <ListView
                headers={headers}
                list={numbers.slice(0, midpoint)}
                notFoundMessage="No numbers found."
                columns={2}
                renderItem={(row) => renderItem(row)}
                onClick={handleClickNumbers}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <ListView
                headers={headers}
                hideHeaderBreakpoints={{ mdDown: true }}
                list={numbers.slice(midpoint, lastIndex)}
                columns={2}
                renderItem={(row) => renderItem(row)}
                onClick={handleClickNumbers}
              />
            </Grid>
            {count !== null && count > limit && (
              <Grid item xs={12} className={classes.paginationWrapper}>
                <TablePagination
                  rowsPerPageOptions={[20]}
                  component="div"
                  count={count}
                  rowsPerPage={limit}
                  page={page}
                  onPageChange={handlePageChange}
                />
              </Grid>
            )}
          </DashboardCardContent>
        </DashboardCard>
      );
    };

    return (
      <Grid item xs={12}>
        {count > 0 && <ShowList />}
        {count === 0 && <ShowError />}
      </Grid>
    );
  }
}
