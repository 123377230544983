/* eslint-disable react/prop-types */
import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { lightTheme } from "themes";

import { useStyles } from "./styles";

export default function Registration({
  children,
  rootClass = "verticalCenter",
  contentClass = "narrow",
}) {
  const classes = useStyles();
  return (
    <ThemeProvider theme={lightTheme}>
      <div
        className={`${classes.root} ${
          classes[rootClass] ? classes[rootClass] : ""
        }`}
        id="content"
      >
        <div
          className={`${classes.contentWrapper} ${
            classes[contentClass] ? classes[contentClass] : ""
          }`}
        >
          {children}
        </div>
      </div>
    </ThemeProvider>
  );
}
