/* eslint-disable react/prop-types */
import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

import {
  Card as DashboardCard,
  CardContent as DashboardCardContent,
} from "components/Layouts/Dashboard/Card";

import { useStyles } from "./styles";

export function PageHeader({ children }) {
  const classes = useStyles();

  return (
    <DashboardCard>
      <DashboardCardContent
        justify="space-between"
        alignItems="center"
        spacing={0}
      >
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Typography variant="body1">
            Use connectors to manage callbacks for multiple numbers. For
            additional configuration details, please see our API Reference Guide{" "}
            <Link href="/docs/api#/Connectors" className={classes.link}>
              here.
            </Link>
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          className={classes.buttonWrapper}
        >
          {children}
        </Grid>
      </DashboardCardContent>
    </DashboardCard>
  );
}
