// messages/endpoints.js
import { factory } from "vivialconnect-js-core";

//NOTE: do not use spread syntax here.
//helper == {init, helper.BASE_URL}
const helper = factory.initEndpoints();

export default {
  init: helper.init,
  messages: (id) => `${helper.BASE_URL}/accounts/${id}/messages.json`,
  message: (id, messageId) =>
    `${helper.BASE_URL}/accounts/${id}/messages/${messageId}.json`,
  messagesCount: (id) =>
    `${helper.BASE_URL}/accounts/${id}/messages/count.json`,
  messageAttachment: (id, messageId, attachmentId) =>
    `${helper.BASE_URL}/accounts/${id}/messages/${messageId}/attachments/${attachmentId}.json`,
  messageAttachments: (id, messageId) =>
    `${helper.BASE_URL}/accounts/${id}/messages/${messageId}/attachments.json`,
  messageAttachmentsCount: (id, messageId) =>
    `${helper.BASE_URL}/accounts/${id}/messages/${messageId}/attachments/count.json`,
  messageMetafields: (id, messageId) =>
    `${helper.BASE_URL}/accounts/${id}/messages/${messageId}/metafields.json`,
  messagesBulk: (id) => `${helper.BASE_URL}/accounts/${id}/messages/bulk.json`,
  messageBulk: (id, bulkId) =>
    `${helper.BASE_URL}/accounts/${id}/messages/bulk/${bulkId}.json`,
  messagesByVendor: (id, vendorId) =>
    `${helper.BASE_URL}/accounts/${id}/messages/by_vendor/${vendorId}.json`,
};
