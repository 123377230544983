/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from "react";

import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";

import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const useStyles = makeStyles(() => ({
  p: {
    marginBottom: "30px",
  },
  embeddedImage: {
    maxWidth: "80%",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  list: {
    "padding-inline-start": "40px",
    "& p": {
      marginBottom: ".5em",
    },
    "& ol, & ul": {
      "list-style-type": "upper-alpha",
      "& ol, & ul": {
        "list-style-type": "lower-roman",
      },
    },
  },
}));

const RichLink = ({ uri, children }) => {
  return (
    <Link href={uri} target={uri.match(/^http|mailto/) ? "_blank" : "_self"}>
      {children}
    </Link>
  );
};
const Text = ({ children }) => {
  const classes = useStyles();
  return <Typography className={classes.p}>{children}</Typography>;
};
const Image = ({ title, description, url }) => {
  const classes = useStyles();
  return (
    <img
      alt={description}
      title={title}
      src={url}
      className={classes.embeddedImage}
    />
  );
};
const OrderedList = ({ children }) => {
  const classes = useStyles();
  return <ol className={classes.list}>{children}</ol>;
};
const UnorderedList = ({ children }) => {
  const classes = useStyles();
  return <ul className={classes.list}>{children}</ul>;
};

export const richContentOptions = {
  renderMark: {
    // [MARKS.BOLD]: text => <Bold>{text}</Bold>
  },
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => (
      <RichLink uri={node.data.uri}>{children}</RichLink>
    ),

    /*
     * leaving these commented out for now.
     * default header sizes are too large for rich text blocks
     */
    // [BLOCKS.HEADING_1]: (node, children) => <Typography variant="h1">{children}</Typography>,
    // [BLOCKS.HEADING_2]: (node, children) => <Typography variant="h2">{children}</Typography>,
    // [BLOCKS.HEADING_3]: (node, children) => <Typography variant="h3">{children}</Typography>,
    // [BLOCKS.HEADING_4]: (node, children) => <Typography variant="h4">{children}</Typography>,
    // [BLOCKS.HEADING_5]: (node, children) => <Typography variant="h5">{children}</Typography>,
    // [BLOCKS.HEADING_6]: (node, children) => <Typography variant="h6">{children}</Typography>,

    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,

    [BLOCKS.OL_LIST]: (node, children) => (
      <OrderedList node={node}>{children}</OrderedList>
    ),

    [BLOCKS.UL_LIST]: (node, children) => (
      <UnorderedList>{children}</UnorderedList>
    ),

    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const { title, description, file } = node.data.target.fields;

      if (file && file.contentType.includes("image")) {
        return <Image title={title} description={description} url={file.url} />;
      }

      return null;
    },
  },
};

const RichText = ({ children }) => {
  return documentToReactComponents(children, richContentOptions);
};

export default RichText;
