import { makeStyles } from "@material-ui/core/styles";

import { colorExtendedGray4, colorExtendedGreen4 } from "themes/palette";
import PhoneDashboard from "assets/img/phone_dashboard.png";

export const useStyles = makeStyles((theme) => ({
  text: {
    color: colorExtendedGreen4,
  },
  title: {
    textAlign: "center",
  },
  link: {
    textDecoration: "underline",
  },
  upperText: {
    textTransform: "uppercase",
  },
  logsHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  show: {
    display: "block",
  },
  hide: {
    display: "none",
  },
  cardsSummary: {
    display: "flex",
    flexDirection: "column",
  },
  summaryIcon: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  summaryContent: {
    flex: "1 0 auto",
    justifyContent: "center",
  },
  textField: {
    minWidth: 240,
  },
  firstLoginText: {
    fontSize: "1.125rem",
  },
  firstLoginImage: {
    with: "100%",
    paddingTop: "100%",
    backgroundImage: `url(${PhoneDashboard})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius: "50%",
    boxShadow: `inset 0 0 0 1px ${colorExtendedGray4}`,
  },
  smallSpinner: {
    position: "relative",
    top: "3px",
  },
  messagesAdContent: {
    paddingLeft: theme.spacing(2),
  },
  tableContainer: {
    minHeight: theme.spacing(40),
  },
  rowWrapper: {
    "&:hover": {
      cursor: "pointer",
      "& td, & th": {
        backgroundColor: "rgba(0,0,0,0.2)",
      },
      "& button": {
        visibility: "visible",
      },
    },
  },
  tableCell: {
    fontFamily: "helvetica",
    maxWidth: "300px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  dialog: {
    "& > div > div": {
      padding: 30,
    },
  },
  logsDialogContent: {
    marginTop: theme.spacing(1),
  },
  gridTiles: {
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
  },
}));

export default useStyles;
