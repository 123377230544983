import { makeStyles } from "@material-ui/core/styles";
import { colorExtendedGray1, colorExtendedGreen4 } from "themes/palette";

export const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    fontSize: "3rem",
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    textAlign: "center",
  },
  form: {
    width: "100%",
    margin: theme.spacing(3, 0),
  },
  confirmForm: {
    margin: theme.spacing(6, 0, 3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  sendEmailContainer: {
    backgroundColor: colorExtendedGray1,
    padding: theme.spacing(6, 7),
    margin: theme.spacing(4, 0),
    alignItems: "center",
    justifyContent: "center",
    "& h2, & p": {
      marginBottom: theme.spacing(3),
    },
  },
  link: {
    cursor: "pointer",
  },
  snackbar: {
    backgroundColor: colorExtendedGreen4,
  },
  hide: {
    display: "none",
  },
  FormControlLabel: {
    color: theme.palette.text.secondary,
    fontSize: "0.8888rem",
  },
}));
