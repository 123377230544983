import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import { useStyles } from "./styles";

export default function Copyright({ showSupport = false, theme = "light" }) {
  const classes = useStyles();
  const copyrightItemClass = `${classes.copyrightItem} ${
    theme === "light" ? classes.copyrightItemLight : classes.copyrightItemDark
  }`;
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent={theme === "light" ? "space-evenly" : "center"}
      className={classes.copyright}
    >
      <Grid item className={copyrightItemClass}>
        {"© Copyright "}
        {new Date().getFullYear()}
        {" Vivial Inc."}
      </Grid>
      <Grid item className={copyrightItemClass}>
        <Link
          component={NavLink}
          to="/legal/"
          className={classes.copyrightLink}
        >
          Terms of Service
        </Link>
      </Grid>
      <Grid item className={copyrightItemClass}>
        <Link
          component={NavLink}
          to="/privacy-policy/"
          className={classes.copyrightLink}
        >
          Privacy Policy
        </Link>
      </Grid>
      {showSupport ? (
        <Grid item className={copyrightItemClass}>
          <a
            href="mailto:developer@support.vivialconnect.net"
            className={classes.copyrightLink}
          >
            Email Support
          </a>
        </Grid>
      ) : (
        ""
      )}
    </Grid>
  );
}

Copyright.propTypes = {
  showSupport: PropTypes.bool,
  theme: PropTypes.string,
};
