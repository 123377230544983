import { makeStyles } from "@material-ui/core/styles";
import { colorExtendedGreen4 } from "themes/palette";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    paddingTop: 20,
  },
  formControl: {
    width: "100%",
  },
  selectInput: {
    minWidth: [[230], ["!important"]],
  },
  menu: {
    width: 200,
  },
  paper: {
    marginTop: 20,
  },
  text: {
    color: colorExtendedGreen4,
  },
  upperText: {
    textTransform: "uppercase",
  },
  logsHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export default useStyles;
