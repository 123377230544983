/* eslint-disable react/prop-types */
import React from "react";
import { useLocation } from "react-router-dom";

import { ThemeProvider, makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import { brochureTheme } from "themes";

import BrochureAppBar from "./AppBar";
import BrochureFooter from "./Footer";

const useStyles = makeStyles(() => ({
  wrapper: {
    minHeight: "650px",
  },
}));

export default function Brochure(props) {
  const { children } = props;
  const classes = useStyles();
  const location = useLocation();

  return (
    <ThemeProvider theme={brochureTheme}>
      <CssBaseline />

      <BrochureAppBar />
      {/*
      key=location.pathname
      forces React to rerender paper component so scroll position is always on top
      */}
      <div className={classes.wrapper} key={location.pathname}>
        {children}
      </div>
      <BrochureFooter />
    </ThemeProvider>
  );
}
