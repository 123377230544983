// mfa/endpoints.js
import { factory } from "vivialconnect-js-core";

//NOTE: do not use spread syntax here.
//helper == {init, helper.BASE_URL}
const helper = factory.initEndpoints();

export default {
  init: helper.init,
  phoneSend: (id) => `${helper.BASE_URL}/accounts/${id}/mfa/phone/send.json`,
  phoneVerify: (id) =>
    `${helper.BASE_URL}/accounts/${id}/mfa/phone/verify.json`,
};
