import { colorExtendedGreen4 } from "../palette";

export const fontPrimaryTitleSubheading = "Aktiv Grotesk, Arial, serif";

export const darkTheme = {
  head: {
    color: colorExtendedGreen4,
    fontSize: 14,
    "& span": {
      fontFamily: fontPrimaryTitleSubheading,
      fontWeight: 500,
    },
  },
};

export const lightTheme = {
  head: {
    color: colorExtendedGreen4,
    fontSize: 14,
    "& span": {
      fontFamily: fontPrimaryTitleSubheading,
      fontWeight: 500,
    },
  },
};

export default {
  darkTheme,
  lightTheme,
};
