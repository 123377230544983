import React, { useEffect } from "react";
import { useParams, Redirect } from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import { Card } from "components/Layouts/Dashboard/Card";
import RichText from "components/RichText";
import { GetDoc } from "api/contentful/helper";
import { useStyles } from "./styles";
import { usePageContext } from "components/Context";

export default function DocPage() {
  const { slug } = useParams();
  const { setPageState } = usePageContext();
  const [page, setPage] = React.useState(null);
  const classes = useStyles();

  //Get page from contentful on load
  useEffect(() => {
    GetDoc(slug, setPage);
  }, [slug]);

  //Extract content from contentful content model
  useEffect(() => {
    if (!page) return;
    setPageState({
      title: "Documentation",
      subtitle: page.fields.title,
    });
  }, [page, setPageState]);

  //handle initial state
  if (page === null) return null;
  //handle unknown slug
  if (page === undefined) return <Redirect to="/docs" />;

  return (
    <div className={classes.slugCardWidth}>
      <Card>
        <div className={classes.content}>
          <Typography className={classes.mainHeader} variant="h1">
            {page.fields.title}
          </Typography>
          <RichText>{page.fields.content}</RichText>
        </div>
      </Card>
    </div>
  );
}
