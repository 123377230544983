import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";

import { useStyles } from "./styles";

export default function GlossaryTerms({ glossary }) {
  const classes = useStyles();

  const retrieveTermNames = (glossaryTerms, initialIndex, endIndex) => {
    const termsArray = glossaryTerms.slice(initialIndex, endIndex);
    return (
      <>
        {termsArray.map((element, index) => {
          return (
            <ListItem
              className={classes.listItemsLinks}
              key={index}
              to={`glossary/${element.slug}`}
              component={NavLink}
            >
              {element.term}
            </ListItem>
          );
        })}
      </>
    );
  };

  return (
    <Grid container direction="column" className={classes.listItems}>
      <Divider />
      {Object.entries(glossary).map(([key, array]) => {
        const lastIndex = array.length;
        const midpoint = Math.ceil(lastIndex / 2);
        if (Array.isArray(array) && array.length) {
          return (
            <Grid item key={`glossary-${key}`}>
              <Typography
                variant="h1"
                className={classes.listItemsTitle}
                id={key}
              >
                {key}
              </Typography>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  {retrieveTermNames(array, 0, midpoint)}
                </Grid>
                <Grid item xs={6}>
                  {retrieveTermNames(array, midpoint, lastIndex)}
                </Grid>
              </Grid>
              <Divider className={classes.listItemsDivider} />
            </Grid>
          );
        } else return null;
      })}
    </Grid>
  );
}

GlossaryTerms.propTypes = {
  glossary: PropTypes.object,
};
