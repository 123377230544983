import { makeStyles } from "@material-ui/core";
import {
  colorExtendedGray1,
  colorExtendedGray3,
  colorPrimaryVivialGreen,
} from "themes/palette";

export const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 40, // keep right padding when drawer closed
  },
  appBar: {
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    borderLeft: "1px solid",
    borderLeftColor: colorExtendedGray1,
  },
  menuItems: {
    display: "flex",
    alignItems: "center",
    fontSize: "1.2222rem",
    fontWeight: 300,
  },
  menuIconDocs: {
    color: theme.palette.common.white,
    fontSize: "1.6666rem",
  },
  menuIconArrow: {
    color: theme.palette.common.white,
    fontSize: "2.2222rem",
  },
  menuIconPower: {
    color: theme.palette.common.white,
    fontSize: "1.9444rem",
  },
  title: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
  },
  typography: {
    display: "inline-block",
    marginLeft: theme.spacing(0.5),
    fontWeight: 500,
  },
  subTitle: {
    textTransform: "uppercase",
    color: colorExtendedGray3,
    fontSize: "0.875rem",
    alignSelf: "center",
  },
  box: {
    minHeight: "50px",
    borderLeft: `2px solid ${colorPrimaryVivialGreen}`,
    paddingLeft: theme.spacing(2),
    marginLeft: theme.spacing(2),
    display: "flex",
  },
  drawerToggle: {
    "& .MuiSvgIcon-root": {
      color: theme.palette.text.primary,
    },
  },
}));
