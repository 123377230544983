import React from "react";
import PropTypes from "prop-types";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "@material-ui/core/Button";
import { HashLink as Link } from "react-router-hash-link";

import MenuBookIcon from "@material-ui/icons/MenuBook";

import { useStyles } from "./styles";

export default function AlphaList({ glossary, showButton = false }) {
  const classes = useStyles();
  const letters = Object.keys(glossary);

  const letterHasWords = (array) => {
    return Array.isArray(array) && array.length;
  };

  return (
    <List className={classes.linkItems}>
      {showButton ? (
        <ListItem key={"backButton"} className={classes.button}>
          <Button
            color="primary"
            variant="contained"
            startIcon={<MenuBookIcon />}
            href="/glossary"
          >
            Glossary
          </Button>
        </ListItem>
      ) : null}
      {letters.map((item, index) => {
        if (letterHasWords(glossary[item])) {
          return (
            <ListItem className={classes.menuItem} key={`alpha-${index}`}>
              <Link to={`/glossary#${item}`}>{item}</Link>
            </ListItem>
          );
        } else {
          return (
            <ListItem className={classes.menuItem} key={`alpha-${index}`}>
              {item}
            </ListItem>
          );
        }
      })}
    </List>
  );
}

AlphaList.propTypes = {
  glossary: PropTypes.object,
  showButton: PropTypes.bool,
};
