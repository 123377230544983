import { format } from "date-fns";
import NumberHelpers from "@moosetalk-frontend/global/vivialconnect-js-core/src/helpers/numbers";

import { TRANSACTION_TYPES } from "./constants";
const {
  withCommaSeparator,
  convertNumberToCurrency,
  convertFromHundredCents,
} = NumberHelpers;

const parseDescription = (data, transaction_type) => {
  return data.length && data[0].description
    ? data[0].description
    : descriptionFromType(transaction_type);
};

const descriptionFromType = (transaction_type) => {
  const desc_array = transaction_type.split("_").map((word) => {
    if (["sms", "mms"].includes(word)) {
      return word.toUpperCase();
    }

    const wordToArray = word.split("");
    wordToArray.splice(0, 1, word[0].toUpperCase());
    return wordToArray.join("");
  });

  return desc_array.join(" ");
};

const formatCashAmount = ({
  cash_amount = 0,
  forceAbsValue = false,
  invertSign = false,
  decimalPlaces,
}) => {
  const isFixedTo2 = decimalPlaces
    ? decimalPlaces
    : Math.floor(convertFromHundredCents(cash_amount)) >= 1
    ? 2
    : null;
  if (forceAbsValue) {
    cash_amount = Math.abs(cash_amount);
  } else if (invertSign) {
    cash_amount *= -1;
  }

  return convertNumberToCurrency(cash_amount, isFixedTo2);
};

const formatCreditAmount = ({
  credit_amount,
  forceAbsValue = false,
  type,
  invertSign = false,
}) => {
  if (forceAbsValue) {
    credit_amount = Math.abs(credit_amount);
  } else if (invertSign) {
    credit_amount *= -1;
  }

  return `${withCommaSeparator(credit_amount)} 
  ${type === "number" ? "number" : ""} 
  ${credit_amount === 1 ? "credit" : "credits"}`;
};

export function createAccountCreditData({
  post_time,
  transaction_type,
  cash_amount,
  credit_amount,
  balances,
  data,
}) {
  const { cash_balance, usage_credits } = balances;

  const description = parseDescription(data, transaction_type);

  const amount = credit_amount
    ? formatCreditAmount({ credit_amount, forceAbsValue: true })
    : formatCashAmount({ cash_amount, decimalPlaces: 4, invertSign: true });

  const balance = credit_amount
    ? formatCreditAmount({ credit_amount: usage_credits })
    : formatCashAmount({ cash_amount: cash_balance, decimalPlaces: 4 });

  return {
    date: format(new Date(post_time), "yyyy-MM-dd"),
    description: description,
    amount: `${amount}`,
    balance,
  };
}

export function createAllTransactionData({
  post_time,
  transaction_type,
  cash_amount,
  credit_amount,
  balances,
  data,
  unit_count,
}) {
  const { cash_balance, usage_credits, number_credits } = balances;

  const description = parseDescription(data, transaction_type);

  const amount = credit_amount
    ? formatCreditAmount({ credit_amount, invertSign: true })
    : cash_amount === 0
    ? "FREE"
    : formatCashAmount({ cash_amount, decimalPlaces: 4, invertSign: true });

  // We want to show the balance of the account against which the txn was
  // charged. For "FREE" txns this can get tricky. If both credit and cash
  // amounts are 0, we show usage credit balance if usage_credits > 0,
  // otherwise show cash balance. This may behave oddly if we ever allow an
  // account to get free numbers.
  let balance;
  if (credit_amount || (!cash_amount && usage_credits)) {
    balance =
      transaction_type.indexOf("number") > -1
        ? formatCreditAmount({ credit_amount: number_credits, type: "number" })
        : formatCreditAmount({ credit_amount: usage_credits });
  } else {
    balance = formatCashAmount({ cash_amount: cash_balance, decimalPlaces: 4 });
  }

  return {
    date: format(new Date(post_time), "yyyy-MM-dd"),
    description: description,
    quantity: unit_count,
    amount: `${amount}`,
    balance,
  };
}

export const excludeTransactionTypes = (wantedTransactionTypes) => {
  return TRANSACTION_TYPES.filter(
    (transaction) => !wantedTransactionTypes.includes(transaction)
  );
};
