/* un-set some global defaults set by global.css */
export const brochureTheme = {
  "@global": {
    "html, body, #root": {
      "font-size": "18px",
      width: "initial",
      height: "initial",
      overflow: "initial",
      //CSSBaseline default is 'antialiased' - overriding here to match Wordpress styles :-(
      "-webkit-font-smoothing": "auto",
    },
    ".clearfix::after": {
      content: '""',
      clear: "both",
      display: "table",
    },
    ".center": {
      textAlign: "center",
    },
    "p > a": {
      fontWeight: "inherit",
    },
  },
};

export default {
  brochureTheme,
};
