/* eslint-disable react/prop-types */
import React from "react";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";

import Subtitle from "components/Layouts/Brochure/Subtitle";

const useStyles = makeStyles(() => ({
  root: {
    padding: "0 3%",
  },
  item: {
    lineHeight: 1.7,
    "& strong": {
      fontWeight: 700,
    },
  },
  itemIconContainer: {
    height: "100px",
    marginBottom: "20px",
    textAlign: "center",
    "& img": {
      maxHeight: "100%",
      maxWidth: "100%",
    },
  },
}));

export default function BenefitsList(props) {
  const classes = useStyles();
  const { header, items, headerType } = props;
  //prevent component rendering before the item list is acquired
  if (!items || !items.length) return null;

  return (
    <div className={classes.root}>
      {header && (
        <div className="center">
          <Subtitle>{header}</Subtitle>
        </div>
      )}

      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        spacing={6}
      >
        {items.map((item, index) => {
          return (
            <Grid item key={index} md={4} sm={12} className={classes.item}>
              {item.fields.image && (
                <div className={classes.itemIconContainer}>
                  <img
                    alt="{item.header}"
                    src={item.fields.image.fields.file.url}
                  />
                </div>
              )}

              {headerType ? (
                <div className="center">
                  <Typography variant={headerType} component="strong">
                    {item.fields.header}
                  </Typography>

                  <Typography variant="body1">
                    {item.fields.shortDescription}
                  </Typography>
                </div>
              ) : (
                <Typography variant="body1">
                  <Typography variant={headerType} component="strong">
                    {item.fields.header}
                  </Typography>{" "}
                  {item.fields.shortDescription}
                </Typography>
              )}
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}
