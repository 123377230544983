/* eslint-disable react/prop-types */
import React from "react";
import { Helmet } from "react-helmet";

const defaults = {
  url: "https://vivialconnect.net",
  title: "Vivial Connect APIs Designed by Developers, For Developers",
  description:
    "Scalable Messaging APIs to power SMS &amp; MMS, toll-free messaging and authentication services for your application. Sign up for your free trial today!",
};

export default function HTMLHead({ page }) {
  if (!page) return;
  const { fields } = page;
  const base_url = process.env.REACT_APP_WEB_URL || defaults.url;
  let cannonical_url = `${base_url}/${fields.slug}`;

  return (
    <Helmet>
      <title>{fields.pageTitle || defaults.title}</title>
      <meta property="og:title" content={fields.pageTitle || defaults.title} />
      <meta name="twitter:title" content={fields.pageTitle || defaults.title} />
      <meta
        name="description"
        content={fields.metaDescription || defaults.description}
      />
      <meta
        property="og:description"
        content={fields.metaDescription || defaults.description}
      />
      <meta
        name="twitter:description"
        content={fields.metaDescription || defaults.description}
      />
      <link rel="canonical" href={cannonical_url} />
      <meta property="og:url" content={cannonical_url} />
    </Helmet>
  );
}
