/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";

import VivialConnectAPI from "api/vivial-connect";
import { useAppContext } from "components/Context";
import withPageContext from "components/Context/WithPageContext";

import GeneralSettings from "./GeneralSettings";
import EditYourProfileName from "./EditYourProfileName";
import ChangeYourPassword from "./ChangeYourPassword";
import UsersBanner from "./UsersBanner";
import UsersTable from "./UsersTable";
import AddUserForm from "./AddUserForm";
import EditUserForm from "./EditUserForm";

export default withPageContext(Account, {
  title: "Account",
  subtitle: "Settings",
});

function Account() {
  const { appState, isAdmin } = useAppContext();
  const [showPane, setShowPane] = useState("");
  const [currentUser, setCurrentUser] = useState({});
  const [selectedUser, setSelectedUser] = useState({});

  const handleShowEditSelfForm = () => {
    setShowPane("showEditSelfForm");
  };

  const handleShowAddUserForm = () => {
    setShowPane("showAddUserForm");
  };

  const handleShowEditUserForm = () => {
    setShowPane("showEditUserForm");
  };

  const handleShowDefault = () => {
    setShowPane("");
  };

  const handleSelectedUser = (selectedUser) => {
    selectedUser.id === currentUser.id
      ? handleShowEditSelfForm()
      : handleShowEditUserForm();
    setSelectedUser(selectedUser);
  };

  useEffect(() => {
    const { users } = VivialConnectAPI.services;

    users.getUser(appState.user_id).then((user) => {
      setCurrentUser(user);
    });
  }, [appState.user_id]);

  const EditSelfForms = ({ userToEdit }) => {
    return (
      <>
        <Grid item xs={6}>
          <EditYourProfileName
            handleShowDefault={handleShowDefault}
            selectedUser={userToEdit}
          />
        </Grid>
        <Grid item xs={6}>
          <ChangeYourPassword
            handleShowDefault={handleShowDefault}
            selectedUser={userToEdit}
          />
        </Grid>
      </>
    );
  };

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <GeneralSettings />
      </Grid>
      {isAdmin() ? (
        <>
          <Grid item xs={12}>
            <UsersBanner
              handleShowAddUserForm={handleShowAddUserForm}
              handleShowDefault={handleShowDefault}
              showManageUserButton={showPane !== ""}
              showAddUserButton={showPane !== "showAddUserForm"}
            />
          </Grid>
          {showPane === "showAddUserForm" && (
            <Grid item xs={12}>
              <AddUserForm handleShowDefault={handleShowDefault} />
            </Grid>
          )}

          {showPane === "showEditUserForm" && (
            <Grid item xs={12}>
              <EditUserForm
                handleShowDefault={handleShowDefault}
                selectedUser={selectedUser}
              />
            </Grid>
          )}

          {showPane === "showEditSelfForm" && (
            <EditSelfForms userToEdit={selectedUser} />
          )}

          {showPane === "" && (
            <Grid item xs={12}>
              <UsersTable
                currentUser={currentUser}
                handleSelectedUser={handleSelectedUser}
              />
            </Grid>
          )}
        </>
      ) : (
        <EditSelfForms userToEdit={currentUser} />
      )}
    </Grid>
  );
}
