/* eslint-disable react/prop-types */
import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  subtitle: {
    display: "inline-block",
    fontStyle: "normal",
    lineHeight: "1.4em",
    fontSize: "2.2rem",
    borderTopStyle: "solid",
    borderTopWidth: "1px",
    padding: "10px 0",
    margin: "30px 0 40px",
  },
  noBorder: {
    borderTopWidth: 0,
  },
}));

export default function Subtitle({ children, className }) {
  const classes = useStyles();
  let rootClass = classes.subtitle;
  if (className) {
    className.split(" ").forEach((name) => {
      rootClass += classes[name] ? ` ${classes[name]}` : ` ${name}`;
    });
  }

  return (
    <Typography variant="h3" className={rootClass} gutterBottom>
      {children}
    </Typography>
  );
}
