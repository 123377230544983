/* eslint-disable react/prop-types */
import React, { useState, useImperativeHandle, useRef } from "react";
import TextField from "@material-ui/core/TextField";

import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";

import { colorPrimaryBlack, colorExtendedGray2 } from "themes/palette";
import { fontPrimaryBody } from "themes/typography";

function StripeInput({ component: Component, inputRef, ...other }) {
  const elementRef = useRef();

  useImperativeHandle(inputRef, () => ({
    focus: () => elementRef.current.focus,
  }));

  return (
    <Component
      onReady={(element) => (elementRef.current = element)}
      {...other}
    />
  );
}

export const StripeTextField = ({
  component: Component,
  label,
  requestError = false,
  setRequestError,
}) => {
  const [errorMessage, setErrorMessage] = useState(null);

  const options = {
    style: {
      base: {
        fontFamily: fontPrimaryBody,
        fontSize: "20px",
        fontWeight: 100,
        color: colorPrimaryBlack,
        "::placeholder": {
          color: colorExtendedGray2,
        },
      },
      invalid: {
        color: "#9e2146",
      },
    },
  };

  function handleElementChange({ error }) {
    if (error) {
      setErrorMessage(error.message);
    } else {
      setErrorMessage(null);
      requestError ? setRequestError(false) : null;
    }
  }

  const hasError = errorMessage !== null || requestError;

  return (
    <TextField
      required
      fullWidth
      label={label}
      variant="outlined"
      error={hasError}
      helperText={hasError ? errorMessage || "Invalid" : ""}
      onChange={handleElementChange}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        inputProps: {
          component: Component,
          options,
        },
        inputComponent: StripeInput,
      }}
    />
  );
};

export const StripeCardNumberElementField = (props) => {
  return (
    <StripeTextField
      component={CardNumberElement}
      label="Credit Card Number"
      {...props}
    />
  );
};

export const StripeCardExpiryElementField = (props) => {
  return (
    <StripeTextField
      component={CardExpiryElement}
      label="Exp. Date"
      {...props}
    />
  );
};

export const StripeCardCvcElementField = (props) => {
  return <StripeTextField component={CardCvcElement} label="CVC" {...props} />;
};

export default StripeTextField;
