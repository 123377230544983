/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";

import { toast } from "react-toastify";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import VivialConnectAPI from "api/vivial-connect";
import { useAppContext } from "components/Context";

import LobbyHeader from "components/Layouts/Lobby/Header";
import LobbyFormWrapper from "components/Layouts/Lobby/FormWrapper";

import { useStyles } from "./styles";

const {
  comparePassword,
  validatePasswordRules,
} = VivialConnectAPI.helpers.validation;

export default function ResetPassword(props) {
  const { appState } = useAppContext();
  let forceReset = false;
  if (props.location && props.location.state) {
    forceReset = props.location.state.forceReset;
  }

  const classes = useStyles();
  const [isLinkInvalid, setIsLinkInvalid] = useState(false);
  const [isResetSuccessful, setIsResetSuccessful] = useState(false);
  const { userId, verficationToken } = useParams();
  const [values, setValues] = useState({
    oldPassword: "",
    newPassword: "",
    retypeNewPassword: "",
    showNewPassword: false,
    showRetypeNewPassword: false,
    showOldPassword: false,
  });

  const [errors, setErrors] = useState({
    newPassword: "",
    retypeNewPassword: "",
    oldPassword: "",
  });

  const validateForm = () => {
    let errorFound = false;

    if (values.newPassword.length < 8 || values.newPassword.length > 256) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        newPassword:
          "New password must be at least 8 and less than 256 characters long.",
      }));
      errorFound = true;
    } else if (!validatePasswordRules(values.newPassword)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        newPassword:
          "New password must contain at least two of the following: one lowercase letter, one uppercase letter, one number and/or one special character.",
      }));
      errorFound = true;
    } else if (!comparePassword(values.newPassword, values.retypeNewPassword)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        newPassword: "New password fields do not match.",
        retypeNewPassword: "New password fields do not match.",
      }));
      errorFound = true;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        newPassword: "",
        retypeNewPassword: "",
      }));
    }

    return errorFound;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (validateForm()) return;

    const { auth } = VivialConnectAPI.services;
    const { newPassword, oldPassword } = values;

    // TODO: User the validator helper implemented by Dayrimell
    if (forceReset) {
      const payload = {
        password: newPassword,
        username: appState.username,
        _password: oldPassword,
      };

      auth
        .authenticate(payload)
        .then((response) => {
          if (response.valid) {
            toast.success("Password reset successful");
            setIsResetSuccessful(true);
          } else {
            toast.error(`${response.message}`);
          }
        })
        .catch((error) => toast.error(`${error}`));
    } else {
      auth
        .passwordReset(userId, verficationToken, newPassword)
        .then((response) => {
          toast.success(`${response}`);
          setIsResetSuccessful(true);
        })
        .catch((error) => toast.error(`${error}`));
    }
  };

  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value ? event.target.value : "",
    });
  };

  const handleClickShowPassword = (name) => {
    const newValues = { ...values };
    newValues[name] = !values[name];
    setValues({ ...newValues });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    const { auth } = VivialConnectAPI.services;

    if (userId && verficationToken) {
      auth.passwordResetValidate(userId, verficationToken).catch(() => {
        setIsLinkInvalid(true);
      });
    }
  }, [userId, verficationToken]);

  const LinkInvalid = () => (
    <Typography variant="body1" className={classes.submitMessage}>
      Sorry, this link is no longer valid
    </Typography>
  );

  const ResetSuccessful = () => (
    <Typography variant="body1" className={classes.submitMessage}>
      Password reset successful. Please <Link href="/login">login</Link>.
    </Typography>
  );

  return (
    <>
      <LobbyHeader
        title={"Reset Password"}
        subtitle={"Please choose a new password."}
      />
      <LobbyFormWrapper onSubmit={handleSubmit}>
        {isLinkInvalid ? (
          <LinkInvalid />
        ) : isResetSuccessful ? (
          <ResetSuccessful />
        ) : (
          <Grid container spacing={3}>
            {forceReset ? (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    variant="outlined"
                    label="Old Password"
                    required
                    autoFocus
                    name="oldPassword"
                    id="old-password"
                    type={values.showOldPassword ? "text" : "password"}
                    value={values.oldPassword}
                    onChange={handleChange("oldPassword")}
                    error={errors.oldPassword !== ""}
                    helperText={errors.oldPassword}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              handleClickShowPassword("showOldPassword")
                            }
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showOldPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  label="New Password"
                  required
                  autoFocus
                  name="newPassword"
                  id="new-password"
                  type={values.showPassword ? "text" : "password"}
                  value={values.newPassword}
                  onChange={handleChange("newPassword")}
                  error={errors.newPassword !== ""}
                  helperText={errors.newPassword}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            handleClickShowPassword("showPassword")
                          }
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  label="Confirm Password"
                  required
                  autoFocus
                  name="retypeNewPassword"
                  id="confirm-new-password"
                  type={values.showNewPassword ? "text" : "password"}
                  value={values.retypeNewPassword}
                  onChange={handleChange("retypeNewPassword")}
                  error={errors.retypeNewPassword !== ""}
                  helperText={errors.retypeNewPassword}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            handleClickShowPassword("showNewPassword")
                          }
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showNewPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.buttonWrapper}>
                <Box mr={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    Submit
                  </Button>
                </Box>
                <Link component={NavLink} to="/login">
                  Return to Login
                </Link>
              </div>
            </Grid>
          </Grid>
        )}
      </LobbyFormWrapper>
    </>
  );
}
