import { makeStyles } from "@material-ui/core/styles";
import {
  colorPrimaryWhite,
  colorExtendedGray4,
  colorExtendedGreen4,
  colorPrimaryBlack,
} from "themes/palette";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  table: {
    minWidth: 400,
    marginBottom: 20,
    marginTop: 20,
    // TODO: decided if we need stronger table borders
    // "& td, & th": {
    //   borderBottom: "1px solid rgba(112,112,112,1)",
    // },
  },
  thead: {
    backgroundColor: "transparent",
  },
  note: {
    fontSize: "0.8em",
    color: colorExtendedGray4,
    "& > a": { fontSize: "1em" },
  },
  yourPlanButtonWrapper: {
    "& button": {
      marginRight: theme.spacing(2),
    },
  },
  upgradeButton: {
    "& :hover": {
      color: colorPrimaryWhite,
    },
  },
  dialog: {
    "& > div > div": {
      padding: 30,
    },
  },
  radioButtonOption: {
    "& > span": {
      fontWeight: "bolder",
    },
  },
  radioNote: {
    paddingLeft: 30,
  },
  textButton: {
    "& :hover": { color: colorExtendedGreen4 },
  },
  cancelPlanDialogButton: {
    "& :hover": {
      color: colorPrimaryBlack,
    },
  },
  addFundsInput: {
    paddingRight: theme.spacing(1),
  },
  spinner: {
    position: "absolute",
  },
}));

export default useStyles;
