import React from "react";
import { Card } from "components/Layouts/Dashboard/Card";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import { usePageContext } from "components/Context";
import { useStyles } from "./styles";

const SwaggerDoc = () => {
  const specURL = `${process.env.REACT_APP_WEB_URL}/api_specification.yml`;
  const { setPageState } = usePageContext();
  const classes = useStyles();

  // OAuth config - uncomment when we decided to enable active usage of swagger docs
  // const clientId = process.env.REACT_APP_CLIENT_ID;
  // const secret = process.env.REACT_APP_CLIENT_SECRET;
  // const oauthConfig = {
  //   clientId: clientId,
  //   clientSecret: secret,
  //   scopeSeparator: " ",
  //   scopes: "basic messages numbers connectors",
  // };

  React.useEffect(() => {
    setPageState({
      title: "Documentation",
      subtitle: "API Reference",
    });
  }, [setPageState]);

  return (
    <Card>
      <div className={classes.content}>
        <SwaggerUI
          url={specURL}
          supportedSubmitMethods={[]} // Disable "Try it out" feature for all endpoints
          deepLinking={true}

          // OAuth init - uncomment when we decided to enable active usage of swagger docs
          // onComplete={(system) => {
          //   system.initOAuth(oauthConfig);
          // }}
        />
      </div>
    </Card>
  );
};

export default SwaggerDoc;
