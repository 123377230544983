import createTheme from "@material-ui/core/styles/createTheme";
import {
  alternativePalette,
  darkPalette,
  lightPalette,
  brochurePalette,
} from "./palette";
import { typography, brochureTypography } from "./typography";
import overrides from "./overrides";
import breakpoints from "./breakpoints";

export const lightTheme = createTheme({
  palette: lightPalette,
  typography,
  overrides: overrides.lightTheme,
  breakpoints,
});

export const darkTheme = createTheme({
  palette: darkPalette,
  typography,
  overrides: overrides.darkTheme,
  breakpoints,
  mixins: {
    toolbar: {
      minHeight: 56,
      [`@media (min-width:${breakpoints.values.xs}px) and (orientation: landscape)`]:
        {
          minHeight: 48,
        },
      [`@media (min-width:${breakpoints.values.sm}px)`]: {
        minHeight: 98,
      },
    },
  },
});

export const alternativeTheme = createTheme({
  palette: alternativePalette,
  typography,
  overrides: overrides.lightTheme,
  breakpoints,
});

export const brochureTheme = createTheme({
  palette: brochurePalette,
  typography: brochureTypography,
  overrides: overrides.brochureTheme,
  mixins: {
    toolbar: {
      minHeight: "90px",
    },
  },
});

export default lightTheme;
