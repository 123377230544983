import React from "react";
import ChromeReaderModeIcon from "@material-ui/icons/ChromeReaderMode";
import MoreIcon from "@material-ui/icons/More";
import DescriptionIcon from "@material-ui/icons/Description";

export const menuIcons = {
  chromeReader: <ChromeReaderModeIcon />,
  description: <DescriptionIcon />,
  more: <MoreIcon />,
};
