// mfa/endpoints.js
import { factory } from "vivialconnect-js-core";

//NOTE: do not use spread syntax here.
//helper == {init, helper.BASE_URL}
const helper = factory.initEndpoints();

export default {
  init: helper.init,
  phoneNumber: (id) => `${helper.BASE_URL}/validate/phone_number.json`,
};
