// contacts/endpoints.js
import { factory } from "vivialconnect-js-core";

//NOTE: do not use spread syntax here.
//helper == {init, helper.BASE_URL}
const helper = factory.initEndpoints();

export default {
  init: helper.init,
  numbers: (id) => `${helper.BASE_URL}/accounts/${id}/numbers.json`,
  numbersLocal: (id) => `${helper.BASE_URL}/accounts/${id}/numbers/local.json`,
  numbersTollfree: (id) =>
    `${helper.BASE_URL}/accounts/${id}/numbers/tollfree.json`,
  numbersCount: (id) => `${helper.BASE_URL}/accounts/${id}/numbers/count.json`,
  numbersLocalCount: (id) =>
    `${helper.BASE_URL}/accounts/${id}/numbers/local/count.json`,
  numbersTollfreeCount: (id) =>
    `${helper.BASE_URL}/accounts/${id}/numbers/tollfree/count.json`,
  number: (id, numberId) =>
    `${helper.BASE_URL}/accounts/${id}/numbers/${numberId}.json`,
  numberLocal: (id, numberId) =>
    `${helper.BASE_URL}/accounts/${id}/numbers/local/${numberId}.json`,
  numberTollfree: (id, numberId) =>
    `${helper.BASE_URL}/accounts/${id}/numbers/tollfree/${numberId}.json`,
  numbersAvailableCountryCodeNumberType: (id, countryCode, numberType) =>
    `${helper.BASE_URL}/accounts/${id}/numbers/available/${countryCode}/${numberType}.json`,
  numbersAvailableCountryCode: (id, countryCode) =>
    `${helper.BASE_URL}/accounts/${id}/numbers/available/${countryCode}.json`,
  numbersLookup: (id) =>
    `${helper.BASE_URL}/accounts/${id}/numbers/lookup.json`,
  numberVoiceToggle: (id, numberId) =>
    `${helper.BASE_URL}/accounts/${id}/numbers/${numberId}/voice/toggle.json`,
  numbersTags: (id) => `${helper.BASE_URL}/accounts/${id}/numbers/tags.json`,
  numberTags: (id, numberId) =>
    `${helper.BASE_URL}/accounts/${id}/numbers/${numberId}/tags.json`,
};
