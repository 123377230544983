import { colorPrimaryWhite, colorExtendedGray1 } from "../palette";

export const darkTheme = {
  root: {
    backgroundColor: colorPrimaryWhite,
  },
};

export const lightTheme = {
  root: {
    backgroundColor: colorExtendedGray1,
  },
};

export default {
  darkTheme,
  lightTheme,
};
