/* eslint-disable react/prop-types */
import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  Card as DashboardCard,
  CardHeader as DashboardCardHeader,
  CardContent as DashboardCardContent,
} from "components/Layouts/Dashboard/Card";
import ConfirmModal from "@moosetalk-frontend/global/components/ConfirmModal";

import { DefaultPaymentMethod } from "./index";
import useStyles from "./styles";

import VivialConnectAPI from "api/vivial-connect";
const { withCommaSeparator } = VivialConnectAPI.helpers.numbers;

const AccountStatusOnDemand = ({
  status,
  defaultPaymentMethod,
  handleAddFunds,
  loading,
}) => {
  const classes = useStyles();
  const [funds, setFunds] = useState(20);
  const { cash_balance: cashBalance, usage_credits: usageCredits } = status;

  const handleOnChange = (event) => {
    const { value } = event.target;
    setFunds(value ? value : 20);
  };

  return (
    <DashboardCard>
      <DashboardCardHeader title={"Account Status"} />
      <DashboardCardContent alignItems="center" alignContent="flex-start">
        <Grid item xs={7}>
          <Typography variant="body2">Cash Balance:</Typography>
        </Grid>
        <Grid item xs={5}>
          <p>{`$${cashBalance ? (cashBalance / 10000).toFixed(2) : "0.00"}`}</p>
        </Grid>
        <Grid item xs={7}>
          <Typography variant="body2">Add Funds:</Typography>
        </Grid>
        <Grid item xs={5}>
          <TextField
            type="number"
            disabled={!defaultPaymentMethod}
            variant="outlined"
            value={funds ? funds : 20}
            onChange={handleOnChange}
            inputProps={{ min: 20, step: 1, className: classes.addFundsInput }}
            InputProps={{
              endAdornment: (
                <ConfirmModal
                  title={`ADD FUNDS`}
                  warningMessage={
                    <>
                      <span>Your credit card will be charged ${funds}.</span>
                    </>
                  }
                  clickableComponent={
                    <Button
                      disabled={!defaultPaymentMethod || loading}
                      color="primary"
                      variant="contained"
                    >
                      Add
                      {loading && (
                        <CircularProgress
                          size={24}
                          color="primary"
                          disableShrink
                          className={classes.spinner}
                        />
                      )}
                    </Button>
                  }
                  onSubmit={() => handleAddFunds(funds)}
                />
              ),
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={7}>
          <Typography variant="body2">Payment Method:</Typography>
        </Grid>
        <Grid item xs={5}>
          <DefaultPaymentMethod hasDefault={defaultPaymentMethod} />
        </Grid>
        {usageCredits ? (
          <>
            <Grid item xs={7}>
              <Typography variant="body2">
                Free Trial Credits Remaining:
              </Typography>
            </Grid>
            <Grid item xs={5}>
              {withCommaSeparator(usageCredits)}
            </Grid>
          </>
        ) : null}
      </DashboardCardContent>
    </DashboardCard>
  );
};

export default AccountStatusOnDemand;
