import React from "react";

import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";

import { GetContentfulPage } from "api/contentful/helper";

import HTMLHead from "components/HTMLHead";

import Hero from "components/Layouts/Brochure/Hero";
import Section from "components/Layouts/Brochure/Section";
import RichText from "components/Layouts/Brochure/RichText";

import ContactForm from "./ContactForm";

const useStyles = makeStyles((theme) => ({
  sidebar: {
    "& h2, & h3, & h4, & h5, & h6": {
      fontFamily: theme.typography.fontTitle,
      fontWeight: 200,
      marginBottom: theme.spacing(1),
    },
  },
}));
export default function Contact() {
  const classes = useStyles();
  const [page, setPage] = React.useState(null);
  const [heroContent, setHeroContent] = React.useState({});

  //Get page from contentful on load
  React.useEffect(() => {
    GetContentfulPage("contact", setPage);
  }, []);

  //Extract content from contentful content model
  React.useEffect(() => {
    if (!page) return;
    const { fields } = page;

    setHeroContent({
      imageURL: fields.heroImg.fields.file.url,
      header1: fields.heroHdr1,
      header2: fields.heroHdr2,
    });
  }, [page]);

  if (!page) return null;

  return (
    <>
      <HTMLHead page={page} />
      <Hero {...heroContent} />

      <Section>
        <Grid container direction="row" justifyContent="flex-start" spacing={10}>
          <Grid item sm={8}>
            <ContactForm />
          </Grid>

          <Divider orientation="vertical" flexItem />

          <Grid item sm={3} className={classes.sidebar}>
            <RichText>{page.fields.content1}</RichText>
          </Grid>
        </Grid>
      </Section>
    </>
  );
}
