// messages/endpoints.js
import { factory } from "vivialconnect-js-core";

//NOTE: do not use spread syntax here.
//helper == {init, helper.BASE_URL}
const helper = factory.initEndpoints();

export default {
  init: helper.init,
  register: () => `${helper.BASE_URL}/registration/register.json`,
  confirm: (userId, verificationToken) =>
    `${helper.BASE_URL}/registration/confirm/${userId}/${verificationToken}`,
  resendEmail: () => `${helper.BASE_URL}/registration/resend_email.json`,
  invitationConfirm: (userId, verificationToken) =>
    `${helper.BASE_URL}/invitation/confirm/${userId}/${verificationToken}`,
  invitationResendEmail: () => `${helper.BASE_URL}/invitation/resend_email.json`,
};
