import { makeStyles } from "@material-ui/core/styles";
import {
  colorExtendedGreen4,
  colorPrimaryBlack,
  colorExtendedGray1,
} from "../../themes/palette";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  list: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 0,
    borderBottom: "1px solid lightgray",
  },
  listItem: {
    "&:hover": {
      backgroundColor: colorExtendedGray1,
      cursor: "pointer",
    },
  },
  noHover: {
    "pointer-events": "none",
  },
  itemPrimaryText: {
    textTransform: "capitalize",
    fontSize: "0.875rem",
    whiteSpace: "nowrap",
  },
  firstItemSecondaryText: {
    color: colorExtendedGreen4,
    fontSize: "1.25rem",
  },
  itemSecondaryText: {
    color: colorPrimaryBlack,
    fontSize: "1rem",
  },
  avatar: {
    color: colorExtendedGreen4,
  },
}));
