/* eslint-disable react/prop-types */
//Context Cache
//Store user & account data in React contexts
//Exports:
// * context provider (for App.js)
// * context consumers (for other components)

// We maintain a copy of the contexts in session storage. This is necessary
// to repopulate the contexts in the event that the user manually reloads the
// site. Some data (username, roles) is only provided at login. If we don't
// store persistently it will be lost on browser reload. The session store is
// also a convenient way to expose stored data to the JS API.

//Inspired by https://react.christmas/2019/7
//https://web.archive.org/web/20200707034609if_/https://react.christmas/2019/7

import React, {
  createContext,
  useContext,
  useReducer,
  useEffect,
  useState,
} from "react";
import VivialConnectAPI from "api/vivial-connect";

const { token, appSessionStore } = VivialConnectAPI.helpers;
const { accounts } = VivialConnectAPI.services;
const ADMIN_ROLES = ["AccountAdministrator"];

//appContext holds cached user & account data
const appContext = createContext();
const appContextTemplate = {
  id: null,
  company_name: "",
  plan_type: "",
  cash_balance: 0,
  freetrial: true,
  last_allocation_date: null,
  last_renewal_date: null,
  next_allocation_date: null,
  next_renewal_date: null,
  number_credits: 0,
  numbers_owned: 0,
  phone_verified: false,
  plan_dates: [],
  plan_description: "",
  plan_max_credits: 0,
  plan_max_numbers: 0,
  prices: {},
  referral_codes: [],
  subscription_balance: 0,
  usage_credits: 0,
  user_id: null,
  username: "",
  roles: [],
  setup_required: false,
};

//simple reducer updates any state values and returns new state object
//ignores keys that do not exist in the old state
const reducer = (oldstate, newstate) => {
  let state = { ...oldstate };
  for (const key in newstate) {
    if (key in state) {
      state[key] && typeof state[key] === "object" && !Array.isArray(state[key])
        ? (state[key] = { ...newstate[key] })
        : (state[key] = newstate[key]);
    }
  }
  return state;
};

//pageContext facilitates data sharing between components
const pageContext = createContext();
const pageContextTemplate = { title: "", subtitle: "" };

const ContextProvider = ({ children }) => {
  //Initialize caches with backup data from session storage
  const initialAppStateValue = {
    ...appContextTemplate,
    ...(appSessionStore.get() || {}),
  };

  /*****
   * Context API
   *****/

  //appState stores all data received from endpoints such as:
  //login, verify phone, account status
  const [appState, setAppState] = useReducer(reducer, initialAppStateValue);

  //pageContext is not backedup to session storage
  //unlike appContext, pageContext can store arbitrary data:
  const [pageState, setPageState] = useState(pageContextTemplate);

  //refreshContexts
  //GET data from API for appState
  const refreshContexts = async () => {
    let account = null;

    try {
      account = await accounts.getAccountStatus();
    } catch (error) {
      return null; //squash API errors on status check
    }

    if (account) {
      setAppState(account);
    }

    //Return status payload to grant callers synchronous access to data
    //without waiting for context update & rerender cycle.
    return account;
  };

  //clearContexts - initializes caches
  const clearContexts = () => {
    //Note: this doesn't remove the session storage backup since
    //the useEffect block below will simply recreate it again.
    setAppState(appContextTemplate);
  };

  //isAdmin
  const isAdmin = () => {
    const userAdminRoles = ADMIN_ROLES.filter((role) =>
      appState.roles.includes(role)
    );
    if (userAdminRoles.length) return true;
  };

  const appContextAPI = {
    appState,
    setAppState,
    refreshContexts,
    clearContexts,
    isAdmin,
  };

  const pageContextAPI = {
    pageState,
    setPageState,
  };

  /******
   * Side Effects
   ******/

  //Update session storage every time caches change.
  useEffect(() => {
    appSessionStore.set(appState);
  }, [appState]);

  //Manual page reload use-case handler:
  useEffect(() => {
    //on initializtion only...
    if (
      //if user is logged in...
      token.get()
    ) {
      //pull fresh data from the API.
      refreshContexts();
    }
  }, []);

  return (
    <appContext.Provider value={appContextAPI}>
      <pageContext.Provider value={pageContextAPI}>
        {children}
      </pageContext.Provider>
    </appContext.Provider>
  );
};

//useAppContext & usePageContext
//Convenience functions. Users don't have to import useContext.
const useAppContext = () => useContext(appContext);
const usePageContext = () => useContext(pageContext);

export default ContextProvider;
export { appContext, pageContext, useAppContext, usePageContext, ADMIN_ROLES };
