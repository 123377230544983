/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  Card as DashboardCard,
  CardHeader as DashboardCardHeader,
  CardContent as DashboardCardContent,
} from "components/Layouts/Dashboard/Card";

import useStyles from "./styles";
import VivialConnectAPI from "api/vivial-connect";

const { customHooks } = VivialConnectAPI.helpers;

export default function EditYourProfileName({
  handleShowDefault,
  selectedUser,
}) {
  const isMounted = customHooks.useMountedRef();
  const classes = useStyles();
  const [userProfileInfo, setUserProfileInfo] = useState({
    id: 0,
    first_name: "",
    last_name: "",
    email: "",
  });
  const [loading, setLoading] = useState(false);

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setUserProfileInfo({ ...userProfileInfo, [name]: value });
  };

  const handleOnClick = () => {
    const { users } = VivialConnectAPI.services;

    const payload = {
      user: {
        ...userProfileInfo,
      },
    };

    setLoading(true);
    users
      .putUserProfile(userProfileInfo.id, payload)
      .then(() => {
        toast.success("Profile updated");
        if (!isMounted.current) return;
        handleShowDefault();
      })
      .catch((error) => toast.error(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    const values = {
      id: selectedUser.id,
      first_name: selectedUser.first_name,
      last_name: selectedUser.last_name,
      email: selectedUser.email,
    };

    setUserProfileInfo(values);
  }, [selectedUser]);

  const { first_name, last_name } = userProfileInfo;

  return (
    <DashboardCard>
      <DashboardCardHeader title={"Edit Your Profile Name"} />
      <form
        onSubmit={(event) => {
          event.preventDefault();
          handleOnClick();
        }}
      >
        <DashboardCardContent>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                required
                variant="outlined"
                label="First Name"
                name="first_name"
                value={first_name ? first_name : ""}
                onChange={handleOnChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                required
                variant="outlined"
                label="Last Name"
                name="last_name"
                value={last_name ? last_name : ""}
                onChange={handleOnChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.editYourProfileButton}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={loading}
              >
                Save Changes
                {loading && (
                  <CircularProgress
                    size={24}
                    color="primary"
                    disableShrink
                    className={classes.spinner}
                  />
                )}
              </Button>
            </div>
          </Grid>
        </DashboardCardContent>
      </form>
    </DashboardCard>
  );
}
