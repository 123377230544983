import accounts from "./accounts/index.js";
import billing from "./billing/index.js";
import campaigns from "./campaigns/index.js";
import connectors from "./connectors/index.js";
import contacts from "./contacts/index.js";
import logs from "./logs/index.js";
import lookups from "./lookups/index.js";
import mailings from "./mailings/index.js";
import messages from "./messages/index.js";
import mfa from "./mfa/index.js";
import numbers from "./numbers/index.js";
import registration from "./registration/index.js";
import referrals from "./referrals/index.js";
import reports from "./reports/index.js";
import oauth from "./oauth/index.js";
import roles from "./roles/index.js";
import users from "./users/index.js";
import validate from "./validate/index.js";

export default {
  accounts,
  billing,
  campaigns,
  connectors,
  contacts,
  logs,
  lookups,
  mailings,
  messages,
  mfa,
  numbers,
  registration,
  referrals,
  reports,
  oauth,
  roles,
  users,
  validate,
};
