/* eslint-disable react/prop-types */
import React from "react";

import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";

import { colorExtendedGreen3 } from "themes/palette";

const useStyles = makeStyles((theme) => ({
  introHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: theme.spacing(4),

    "& h5": {
      color: colorExtendedGreen3,
    },
    "& img": {
      position: "relative",
      margin: "-6px 5px 0 0",
    },
  },
}));

export default function IntroItem(props) {
  const classes = useStyles();
  const { imageURL, header, shortDescription, description } = props;

  return (
    <div className={classes.introRoot}>
      <div className={classes.introHeader}>
        <img alt={header} src={imageURL} />

        <Typography variant="h5" component="h5">
          {header}
        </Typography>
      </div>

      <Typography variant="body1" paragraph={true}>
        <b>{shortDescription}</b>
        <br />
        {description}
      </Typography>
    </div>
  );
}
