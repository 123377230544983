import { makeStyles } from "@material-ui/core/styles";
import RegistrationPage from "assets/img/RegistrationPage.png";

export const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    overflow: "auto",
    alignSelf: "center",
    [theme.breakpoints.up("md")]: {
      backgroundImage: `url(${RegistrationPage})`,
      backgroundSize: "cover",
      padding: theme.spacing(4),
    },
  },
  verticalCenter: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      justifyContent: "center",
    },
  },
  contentWrapper: {
    width: "100%",
    margin: "0 auto",
  },
  narrow: {
    maxWidth: "874px",
  },
  wide: {
    maxWidth: "1402px",
  },
}));
