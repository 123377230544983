/* eslint-disable react/prop-types */
import React from "react";
import Button from "@material-ui/core/Button";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

export default function BrochureButton(props) {
  const { children, ...rest } = props;

  return (
    <Button
      color="primary"
      variant="outlined"
      endIcon={<ArrowForwardIosIcon />}
      {...rest}
    >
      <span className="content">{children}</span>
    </Button>
  );
}
