/* eslint-disable react/prop-types */
import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import {
  Card as DashboardCard,
  CardHeader as DashboardCardHeader,
  CardContent as DashboardCardContent,
} from "components/Layouts/Dashboard/Card";

const FreeTrialYourPlan = ({ status }) => {
  const { plan_description: planDescription } = status;

  return (
    <DashboardCard>
      <DashboardCardHeader title={"Your Plan"} />
      <DashboardCardContent>
        <Grid item xs={3}>
          <Typography variant="body2">Type:</Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography variant="h6">{planDescription}</Typography>
          <Typography variant="body1">
            Your three month trial comes with 25 credits and one free number.
            Free Trial accounts can only exchange messages with their verified
            mobile number.
          </Typography>
        </Grid>
      </DashboardCardContent>
    </DashboardCard>
  );
};

export default FreeTrialYourPlan;
