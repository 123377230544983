import React from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import useStyles from "./styles";

export default function ConfirmModal({
  title,
  warningMessage,
  clickableComponent,
  onSubmit,
  disabled = false,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    if (!disabled) setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOnSubmit = async (event) => {
    event.preventDefault();

    if (onSubmit) {
      onSubmit();
      handleClose();
    } else {
      toast.error("Unable to execute your action.");
    }
  };

  return (
    <>
      <span onClick={handleClickOpen}>{clickableComponent}</span>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        className={classes.dialog}
      >
        <DialogTitle className={classes.dialogTitle} id="form-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{warningMessage}</DialogContentText>
        </DialogContent>
        <br />
        <br />
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="contained"
            className={classes.cancelPlanDialogButton}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            onClick={handleOnSubmit}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

ConfirmModal.propTypes = {
  title: PropTypes.string.isRequired,
  clickableComponent: PropTypes.element.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
