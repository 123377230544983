import {
  colorPrimaryWhite,
  colorPrimaryBlack,
  colorExtendedGray1,
  colorExtendedGray4,
  colorExtendedGreen4,
} from "../palette";

export const darkTheme = {
  root: {
    color: colorPrimaryWhite,
  },
  colorDefault: {
    color: colorPrimaryBlack,
    backgroundColor: colorExtendedGreen4,
  },
};

export const lightTheme = {
  root: {
    "& > button > span > svg": {
      color: colorExtendedGray4,
    },
    "&:hover > button > span > svg": {
      color: colorPrimaryWhite,
    },
    "& .active > button > span > svg": {
      color: colorPrimaryWhite,
    },
  },
  colorDefault: {
    color: colorPrimaryWhite,
    backgroundColor: colorExtendedGray1,
    "&:hover": {
      backgroundColor: colorExtendedGreen4,
    },
    "& .active": {
      backgroundColor: colorExtendedGreen4,
    },
  },
};

export default {
  darkTheme,
  lightTheme,
};
