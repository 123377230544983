import endpoints from "./endpoints";
import { factory } from "vivialconnect-js-core";

//NOTE: do not use spread syntax here.
//serviceHelpers == {init, http, appSessionStore}
const serviceHelpers = factory.initService(endpoints);

const getConnectors = async (params, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.get(
      endpoints.connectors(id ? id : appSessionStore.getAccountId()),
      { params }
    );
    return data.connectors;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getConnectorsCount = async (params, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.get(
      endpoints.connectorsCount(id ? id : appSessionStore.getAccountId()),
      { params }
    );
    return data.count;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const postConnectors = async (payload, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.post(
      endpoints.connectors(id ? id : appSessionStore.getAccountId()),
      payload
    );
    return data;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getConnector = async (connectorId, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.get(
      endpoints.connector(id ? id : appSessionStore.getAccountId(), connectorId)
    );
    return data.connector;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const putConnector = async (connectorId, payload, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.put(
      endpoints.connector(
        id ? id : appSessionStore.getAccountId(),
        connectorId
      ),
      payload
    );
    return data.connector;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const deleteConnector = async (connectorId, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    await http.delete(
      endpoints.connector(id ? id : appSessionStore.getAccountId(), connectorId)
    );
    return true;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getConnectorCallbacks = async (connectorId, params, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.get(
      endpoints.connectorsCallbacks(
        id ? id : appSessionStore.getAccountId(),
        connectorId
      ),
      { params }
    );
    return data.connector;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const postConnectorCallbacks = async (connectorId, payload, params, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.post(
      endpoints.connectorsCallbacks(
        id ? id : appSessionStore.getAccountId(),
        connectorId
      ),
      payload,
      { params }
    );
    return data.connector;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const putConnectorCallbacks = async (connectorId, payload, params, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.put(
      endpoints.connectorsCallbacks(
        id ? id : appSessionStore.getAccountId(),
        connectorId
      ),
      payload,
      { params }
    );
    return data.connector;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const deleteConnectorCallbacks = async (connectorId, payload, params, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.delete(
      endpoints.connectorsCallbacks(
        id ? id : appSessionStore.getAccountId(),
        connectorId
      ),
      payload,
      { params }
    );
    return data.connector;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getConnectorPhoneNumbers = async (connectorId, params, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.get(
      endpoints.connectorsPhoneNumbers(
        id ? id : appSessionStore.getAccountId(),
        connectorId
      ),
      { params }
    );
    return data.connector;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getConnectorPhoneNumbersCount = async (connectorId, params, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.get(
      endpoints.connectorsPhoneNumbersCount(
        id ? id : appSessionStore.getAccountId(),
        connectorId
      ),
      { params }
    );
    return data.count;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const postConnectorPhoneNumbers = async (connectorId, payload, params, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.post(
      endpoints.connectorsPhoneNumbers(
        id ? id : appSessionStore.getAccountId(),
        connectorId
      ),
      payload,
      { params }
    );
    return data.connector;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const putConnectorPhoneNumbers = async (connectorId, payload, params, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.put(
      endpoints.connectorsPhoneNumbers(
        id ? id : appSessionStore.getAccountId(),
        connectorId
      ),
      payload,
      { params }
    );
    return data.connector;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const deleteConnectorPhoneNumbers = async (
  connectorId,
  payload,
  params,
  id
) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.delete(
      endpoints.connectorsPhoneNumbers(
        id ? id : appSessionStore.getAccountId(),
        connectorId
      ),
      { data: payload },
      { params }
    );
    return data.connector;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

export default {
  init: serviceHelpers.init,
  deleteConnector,
  deleteConnectorCallbacks,
  deleteConnectorPhoneNumbers,
  getConnector,
  getConnectorCallbacks,
  getConnectorPhoneNumbers,
  getConnectorPhoneNumbersCount,
  getConnectors,
  getConnectorsCount,
  postConnectorCallbacks,
  postConnectorPhoneNumbers,
  postConnectors,
  putConnector,
  putConnectorCallbacks,
  putConnectorPhoneNumbers,
};
