import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import {
  StripeCardNumberElementField,
  StripeCardCvcElementField,
  StripeCardExpiryElementField,
} from "components/StripeInputFields";
import {
  useStripe,
  useElements,
  CardNumberElement,
} from "@stripe/react-stripe-js";

import VivialConnectAPI from "api/vivial-connect";
import BillingAddressForm from "./BillingAddressForm";
import useStyles from "./styles";
import { validateForm, requiredFields } from "./shared";

export default function AddCreditCardForm({
  handleGetPaymentSources,
  setOpen,
  isMounted,
}) {
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();

  const [billingContact, setBillingContact] = useState(requiredFields);
  const [inputErrors, setInputErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const { contacts } = VivialConnectAPI.services;

  const handleSaveCreditCard = (card_token) => {
    const { accounts } = VivialConnectAPI.services;
    const payload = {
      payment_source: {
        card_token,
      },
    };
    let hasError = false;
    setLoading(true);
    accounts
      .postAccountPaymentSources(payload)
      .then((paymentSource) => {
        toast.success("Your card was added.");
        if (paymentSource) {
          handleGetPaymentSources();

          if (Object.prototype.hasOwnProperty.call(billingContact, "id")) {
            handleUpdateBillingContact();
          } else {
            handleCreateBillingContact();
          }

          if (isMounted.current) setOpen("payments");
        }
      })
      .catch((error) => {
        toast.error(error);
        setRequestError(true);
        hasError = true;
      })
      .finally(() => {
        if (!isMounted.current) return;
        setLoading(false);
        if (!hasError) {
          setOpen("payments");
        }
      });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { first_name, last_name, address1 } = billingContact;

    if (!stripe || !elements) {
      toast.error("A problem occurred with the form.");
      return null;
    }

    //Validate non-stripe inputs
    let { hasError, errorMessages } = validateForm(
      inputErrors,
      setInputErrors,
      billingContact
    );

    // Attempt to create token even if previous validation fails
    // This sets error states for stripe elements
    setLoading(true);
    const { error, token } = await stripe
      .createToken(elements.getElement(CardNumberElement), {
        name: `${first_name} ${last_name}`,
        address_line1: address1,
      })
      .finally(() => {
        if (!isMounted.current) return;
        setLoading(false);
      });

    // Add stripe errors to error array
    if (error) {
      hasError = true;
      errorMessages.push(error.message);
    }

    if (hasError) {
      errorMessages.length
        ? errorMessages.forEach((msg) => {
            toast.error(msg);
          })
        : toast.error("Please fill in all required fields.");
    } else {
      handleSaveCreditCard(token.id);
    }
  };

  const handleUpdateBillingContact = async () => {
    const {
      id: contactId,
      address1,
      postal_code,
      state,
      city,
      country,
      first_name,
      last_name,
    } = billingContact;

    const payload = {
      contact: {
        id: contactId,
        address1,
        postal_code,
        state,
        city,
        country,
        first_name,
        last_name,
      },
    };

    contacts
      .putContact(contactId, payload)
      .then(() => {
        contacts
          .getContacts()
          .then((contacts) => {
            if (!isMounted.current) return;
            const billingContact = contacts.filter(
              (contact) => contact.contact_type === "billing"
            )[0];

            if (billingContact) setBillingContact(billingContact);
          })
          .catch((error) => toast.error(error));
      })
      .catch((error) => toast.error(error));
  };

  const handleCreateBillingContact = () => {
    const payload = {
      contanct: billingContact,
    };

    contacts
      .postContacts(payload)
      .then(() => {
        contacts
          .getContacts()
          .then((contacts) => {
            if (!isMounted.current) return;
            const billingContact = contacts.filter(
              (contact) => contact.contact_type === "billing"
            )[0];

            if (billingContact) setBillingContact(billingContact);
          })
          .catch((error) => toast.error(error));
      })
      .catch((error) => toast.error(error));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "country") billingContact["state"] = "";
    billingContact[name] = value;
    setBillingContact({ ...billingContact });

    if (name in inputErrors) inputErrors[name] = false;
  };

  useEffect(() => {
    const { contacts } = VivialConnectAPI.services;

    contacts
      .getContacts()
      .then((contacts) => {
        if (!isMounted.current) return;
        const billingContact = contacts.filter(
          (contact) => contact.contact_type === "billing"
        )[0];

        if (billingContact) setBillingContact(billingContact);
      })
      .catch((error) => toast.error(error));
  }, [isMounted]);

  return (
    <BillingAddressForm
      title="Add Credit Card"
      billingContact={billingContact}
      handleOnChangeBillingContact={handleInputChange}
      onSubmit={handleSubmit}
      loading={loading}
      setOpen={setOpen}
    >
      <Grid item xs={12}>
        <Typography variant="body1" className={classes.addCreditCardLabel}>
          <span className="body-title">Card Information</span>
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <StripeCardNumberElementField
                requestError={requestError}
                setRequestError={setRequestError}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <StripeCardExpiryElementField
                requestError={requestError}
                setRequestError={setRequestError}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <StripeCardCvcElementField
                requestError={requestError}
                setRequestError={setRequestError}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </BillingAddressForm>
  );
}

AddCreditCardForm.propTypes = {
  setOpen: PropTypes.func,
  handleGetPaymentSources: PropTypes.func,
  isMounted: PropTypes.bool,
};
