/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { toast } from "react-toastify";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";

import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import FileCopyIcon from "@material-ui/icons/FileCopy";

import { format, parseISO } from "date-fns";

import { useAppContext } from "components/Context";
import {
  Card as DashboardCard,
  CardContent as DashboardCardContent,
} from "components/Layouts/Dashboard/Card";
import ConfirmPassword from "components/ConfirmPassword";
import TruncateText from "components/TruncateText";

import useStyles from "./styles";
import VivialConnectAPI from "api/vivial-connect";

const EditableCell = ({
  credential,
  name,
  editName,
  onCancel,
  handleOnChange,
  onSave,
  selectedCredential,
  onEdit,
}) => {
  const classes = useStyles();

  return (
    <TableCell component="th" scope="row">
      <Grid container justify="center" alignItems="center">
        {editName && credential.id === selectedCredential ? (
          <>
            <Grid item xs={7} className={classes.keyField}>
              <TextField
                fullWidth
                variant="outlined"
                label="Key Name"
                name={`key`}
                value={name}
                onChange={handleOnChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={5}>
              <Button variant="contained" size="small" onClick={onCancel}>
                Cancel
              </Button>
              &nbsp;&nbsp;
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                  onSave(credential);
                  onEdit();
                }}
              >
                Save
              </Button>
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <Typography variant="h5" className={classes.keyLabel}>
              {credential.name}
            </Typography>
          </Grid>
        )}
      </Grid>
    </TableCell>
  );
};

function APIKeysTable({
  credentials,
  setCredentials,
  width,
  name,
  setName,
  handleOnChange,
  onSave,
}) {
  const { appState } = useAppContext();
  const classes = useStyles();
  const [copyRow, setCopyRow] = useState("");
  const [selectedCredential, setSelectedCredential] = useState();
  const [editName, setEditName] = useState(false);

  const onEdit = (credential) => {
    if (credential) {
      setEditName(true);
      setName(credential.name);
      setSelectedCredential(credential.id);
    } else {
      setEditName(false);
      setName();
      setSelectedCredential();
    }
  };

  const onCancel = () => {
    setName();
    setEditName();
    setSelectedCredential();
  };

  const handleOnDelete = async (credential) => {
    const { users } = VivialConnectAPI.services;

    const isDeleted = await users.deleteUserCredential(
      credential.user_id,
      credential.id
    );

    if (isDeleted) {
      toast.success(`API Key ${credential.id} successfully deleted`);
      users
        .getUserCredentials(appState.user_id)
        .then((newCredentials) => setCredentials(newCredentials));
    } else {
      toast.error("Unable to delete the credential");
    }
  };

  function createUserData(name, apiKey, dateCreated, credentialInfo) {
    return {
      name,
      apiKey,
      dateCreated,
      credentialInfo,
      copyButtonText: "copy",
    };
  }

  const convertDataToRows = (credential) => {
    const { api_key, name, date_created } = credential;

    return createUserData(
      name,
      api_key,
      format(parseISO(date_created), "yyyy-MM-dd"),
      credential
    );
  };

  const rowsUsersTable = credentials.map((credential) =>
    convertDataToRows(credential)
  );

  const copyToClipboard = (event, apiKey) => {
    setCopyRow(apiKey);
    navigator.clipboard.writeText(apiKey);
  };

  return (
    <DashboardCard>
      <DashboardCardContent>
        <TableContainer>
          <Table
            stickyHeader
            className={"classes.table"}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography
                    variant="h6"
                    className={classes.tableColumnHeader}
                  >
                    Key Name
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="h6"
                    className={classes.tableColumnHeader}
                  >
                    Key
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="h6"
                    className={classes.tableColumnHeader}
                  >
                    Date Created
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    variant="h6"
                    className={classes.tableColumnHeader}
                  >
                    Actions
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowsUsersTable.map((row, indx) => {
                let apiKeyCell = row.apiKey;

                if (isWidthDown("xs", width)) {
                  apiKeyCell = <TruncateText text={apiKeyCell} length={3} />;
                } else if (isWidthDown("sm", width)) {
                  apiKeyCell = <TruncateText text={apiKeyCell} length={6} />;
                } else if (isWidthDown("md", width)) {
                  apiKeyCell = <TruncateText text={apiKeyCell} length={10} />;
                }
                return (
                  <TableRow hover key={indx} className={classes.rowWrapper}>
                    <EditableCell
                      credential={row.credentialInfo}
                      setCredentials={setCredentials}
                      editName={editName}
                      onCancel={onCancel}
                      onEdit={onEdit}
                      name={name}
                      setName={setName}
                      handleOnChange={handleOnChange}
                      onSave={onSave}
                      selectedCredential={selectedCredential}
                    />
                    <TableCell>
                      {apiKeyCell}
                      <Tooltip
                        title={copyRow === row.apiKey ? "Copied!" : "Copy"}
                      >
                        <IconButton
                          className={classes.copyButton}
                          onClick={(e) => copyToClipboard(e, row.apiKey)}
                        >
                          <FileCopyIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>

                    <TableCell>{row.dateCreated}</TableCell>
                    <TableCell align="center">
                      <Grid container>
                        <Grid item xs={6}>
                          <Tooltip title={"Edit"}>
                            <IconButton
                              onClick={() => onEdit(row.credentialInfo)}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={6}>
                          <ConfirmPassword
                            title={`DELETE API KEY`}
                            warningMessage={
                              <>
                                <span className={classes.warningText}>
                                  Deletion of an API Key can cause link failures
                                  for connected programs. Please re-enter your
                                  password to acknowledge and proceed.
                                </span>
                                <br />
                                <br />
                                <span className={classes.keyTitle}>Key</span>
                                <br />
                                <span>{row.apiKey}</span>
                              </>
                            }
                            clickableComponent={
                              <Tooltip title={"Delete"}>
                                <IconButton>
                                  <DeleteIcon
                                    className={classes.tableDeleteButton}
                                  />
                                </IconButton>
                              </Tooltip>
                            }
                            onSubmit={() => handleOnDelete(row.credentialInfo)}
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DashboardCardContent>
    </DashboardCard>
  );
}

export default withWidth()(APIKeysTable);
