/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  startOfDay,
  endOfToday,
  endOfDay,
  subMonths,
  differenceInDays,
  addMonths,
  format,
} from "date-fns";

import {
  Card as DashboardCard,
  CardHeader as DashboardCardHeader,
  CardContent as DashboardCardContent,
} from "components/Layouts/Dashboard/Card";

import useStyles from "./styles";
import { toast } from "react-toastify";

import { FILTER_GROUPS } from "./constants";
import { createAllTransactionData } from "./helpers";

const minDate = new Date("1900", "01", "01");
const maxDate = new Date("2100", "01", "01");

export default function TransactionsPane({
  title,
  getAccountTransactions,
  getAccountTransactionsCount,
  helperService,
  useLocalTime = false,
  createTransactionData = createAllTransactionData,
  showQuantityColumnn = true,
}) {
  if (helperService === undefined)
    throw "No Helper Service defined for TransactionsPane";

  if (
    getAccountTransactions === undefined ||
    getAccountTransactionsCount === undefined
  )
    throw "Transactions getter function not defined";

  const { isInvalidDate } = helperService.validation;
  const { toUTCDateString } = helperService.stringUtils;
  const { useMountedRef } = helperService.customHooks;

  const isMounted = useMountedRef();
  const classes = useStyles();
  const [selectedTransactionOption, setSelectedTransactionOption] = useState(
    "all"
  );
  const [selectedTransactionFilter, setSelectedTransactionFilter] = useState(
    FILTER_GROUPS.all
  );
  const [startDate, setStartDate] = useState(
    startOfDay(subMonths(Date.now(), 1))
  );
  const [endDate, setEndDate] = useState(endOfToday());
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [transactions, setTransactions] = useState([]);
  const [transactionsCount, setTransactionsCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    startDate: "",
    endDate: "",
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleFilterChange = (event) => {
    setSelectedTransactionOption(event.target.value);
    setSelectedTransactionFilter(FILTER_GROUPS[event.target.value]);
  };

  const handleStartDateChange = (date) => {
    if (differenceInDays(endDate, date) < 0)
      setEndDate(addMonths(endOfDay(date), 1));

    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    if (differenceInDays(startDate, date) > 0)
      setStartDate(subMonths(startOfDay(date), 1));

    setEndDate(date);
  };

  const handleSearch = () => {
    handleGetAccountTransactions();
  };

  const rowsTransactionsTable = transactions.map((transaction) => {
    return createTransactionData(transaction);
  });

  const validateDates = useCallback(() => {
    let error = false;

    if (isInvalidDate(startDate)) {
      error = true;
      setErrors((oldErrors) => ({
        ...oldErrors,
        startDate: "Please set a valid start date.",
      }));
    } else if (startDate < minDate || startDate > maxDate) {
      error = true;
      setErrors((oldErrors) => ({
        ...oldErrors,
        startDate: "Dates must be between 2010 and 2040.",
      }));
    } else {
      setErrors((oldErrors) => ({
        ...oldErrors,
        startDate: "",
      }));
    }
    if (isInvalidDate(endDate)) {
      error = true;

      setErrors((oldErrors) => ({
        ...oldErrors,
        endDate: "Please set a valid end date.",
      }));
    } else if (endDate < minDate || endDate > maxDate) {
      error = true;
      setErrors((oldErrors) => ({
        ...oldErrors,
        endDate: "Dates must be between 2010 and 2040.",
      }));
    } else {
      setErrors((oldErrors) => ({
        ...oldErrors,
        endDate: "",
      }));
    }

    return error;
  }, [endDate, startDate]);

  const handleGetAccountTransactions = useCallback(() => {
    if (validateDates()) return;

    const formattedStateDate = useLocalTime
      ? format(startDate, "yyyy-MM-dd'T'HH:mm:ss'Z'")
      : toUTCDateString(startDate);

    const formattedEndDate = useLocalTime
      ? format(endDate, "yyyy-MM-dd'T'HH:mm:ss'Z'")
      : toUTCDateString(endDate);

    let params = {
      page: page + 1,
      limit: rowsPerPage,
      start_time: formattedStateDate,
      end_time: formattedEndDate,
    };

    if (
      selectedTransactionFilter !== undefined &&
      Array.isArray(selectedTransactionFilter.types)
    ) {
      params["include_types"] = selectedTransactionFilter.types;
    }

    // NOTE: As of 2021-01-07 there exists an similar transactions endpoint
    // in the /admin/ section of the API. This page used to use that endpoint.
    // I don't think there's any advantage to using it - it doesn't handle
    // pagination well - but it's possible we may choose to revert back to that
    // method in the future if we determine there's a need for separate
    // transactions endpoints... or I missed something important. @nherman
    // NOTE: 2021-11-29 moved to a shared component. The above note refers to
    // former caribou version of this page.
    setLoading(true);
    getAccountTransactions(params)
      .then((transactions) => {
        if (!isMounted.current) return;
        setTransactions(transactions);
      })
      .catch((error) => toast.error(error))
      .finally(() => {
        if (!isMounted.current) return;
        setLoading(false);
      });

    getAccountTransactionsCount(params)
      .then((count) => {
        if (!isMounted.current) return;
        setTransactionsCount(count);
      })
      .catch((error) => toast.error(error));
  }, [
    selectedTransactionFilter,
    startDate,
    endDate,
    page,
    rowsPerPage,
    validateDates,
    isMounted,
  ]);

  useEffect(() => {
    handleGetAccountTransactions();
  }, [handleGetAccountTransactions]);

  return (
    <DashboardCard>
      <DashboardCardHeader title={`${title}`} />
      <DashboardCardContent>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                variant="outlined"
                margin="normal"
                id="transactions-filter"
                label="Filters"
                select
                value={selectedTransactionOption}
                onChange={handleFilterChange}
                size="medium"
                fullWidth
              >
                {Object.keys(FILTER_GROUPS).map((key, i) => (
                  <MenuItem value={key} key={i}>
                    {FILTER_GROUPS[key].label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={2}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                inputVariant="outlined"
                format="MM/dd/yyyy"
                margin="normal"
                id={`start-date-${title.replace(/\s+/g, "-").toLowerCase()}`}
                label="Start Date"
                autoOk={true}
                value={startDate}
                onChange={handleStartDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change start date",
                }}
                error={errors.startDate !== ""}
                helperText={errors.startDate}
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                inputVariant="outlined"
                format="MM/dd/yyyy"
                margin="normal"
                id={`end-date-${title.replace(/\s+/g, "-").toLowerCase()}`}
                label="End Date"
                autoOk={true}
                value={endDate}
                onChange={handleEndDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change end date",
                }}
                error={errors.endDate !== ""}
                helperText={errors.endDate}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <Box className={classes.button}>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={handleSearch}
                  disabled={loading}
                >
                  Search
                  {loading && (
                    <CircularProgress
                      size={24}
                      color="primary"
                      disableShrink
                      className={classes.spinner}
                    />
                  )}
                </Button>
              </Box>
              {
                // TODO: enable download feature
                // <div className={classes.buttonsWrapper}>
                //   <Box className={classes.button}>
                //     <Button
                //       color="primary"
                //       variant="outlined"
                //       startIcon={<GetAppOutlinedIcon />}
                //     >
                //       Download
                //     </Button>
                //   </Box>
                // </div>
              }
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
        <TableContainer className={classes.tableContainer}>
          <Table
            stickyHeader
            className={"classes.table"}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="h6" color="primary">
                    Date
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" color="primary">
                    Description
                  </Typography>
                </TableCell>
                {showQuantityColumnn ? (
                  <TableCell>
                    <Typography variant="h6" color="primary">
                      Quantity
                    </Typography>
                  </TableCell>
                ) : null}
                <TableCell>
                  <Typography variant="h6" color="primary">
                    Amount
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" color="primary">
                    Balance
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowsTransactionsTable.map((row, indx) => (
                <TableRow hover key={indx}>
                  <TableCell component="th" scope="row">
                    {row.date}
                  </TableCell>
                  <TableCell>{row.description}</TableCell>
                  {showQuantityColumnn ? (
                    <TableCell>{row.quantity}</TableCell>
                  ) : null}
                  <TableCell>{row.amount}</TableCell>
                  <TableCell>{row.balance}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[20, 50, 100, 150]}
          component="div"
          count={transactionsCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className={classes.tablePagination}
          labelRowsPerPage={"Rows Per Page"}
        />
      </DashboardCardContent>
    </DashboardCard>
  );
}
