/* eslint-disable react/prop-types */
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Typography, Grid } from "@material-ui/core";
import BrochureButton from "../BrochureButton";

import { useStyles } from "./styles";

export default function Hero(props) {
  const classes = useStyles();
  const { imageURL, header1, header2, date, button, fullHeight } = props;

  const heroImageClass = {
    backgroundImage: `url(${imageURL})`,
  };

  let contentWrapperClass = classes.contentWrapper;
  if (fullHeight) {
    contentWrapperClass += ` ${classes.fullHeight}`;
  }

  return (
    <div className={classes.root} style={heroImageClass}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={contentWrapperClass}
      >
        {header1 && (
          <Grid item className={classes.content}>
            <Typography
              className={classes.header1}
              variant="h1"
              color="inherit"
              gutterBottom
            >
              {header1}
            </Typography>
          </Grid>
        )}
        {header2 && (
          <Grid item className={classes.content}>
            <Typography
              className={classes.header2}
              variant="h2"
              color="inherit"
            >
              {header2}
            </Typography>
            {date && (
              <Typography variant="subtitle1" className={classes.date}>
                {date}
              </Typography>
            )}
          </Grid>
        )}
        <Grid item className={classes.content}>
          {button && (
            <RouterLink to={button.fields.link}>
              <BrochureButton color="secondary">
                {button.fields.text}
              </BrochureButton>
            </RouterLink>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
