/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import CircularProgress from "@material-ui/core/CircularProgress";

import StarIcon from "@material-ui/icons/Star";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/Create";

import { PaymentIcon } from "components/VivialIcons";
import ConfirmModal from "@moosetalk-frontend/global/components/ConfirmModal";
import {
  Card as DashboardCard,
  CardHeader as DashboardCardHeader,
  CardContent as DashboardCardContent,
} from "components/Layouts/Dashboard/Card";

import CreditCard from "./CreditCard";

import VivialConnectAPI from "api/vivial-connect";

import {
  colorExtendedGray1,
  colorExtendedGray2,
  colorExtendedGreen4,
} from "themes/palette";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  list: {
    borderRadius: 26,
    marginLeft: 14,
    padding: "2px 0",
    backgroundColor: colorExtendedGray2,
  },
  avatar: {
    backgroundColor: colorExtendedGray2,
  },
  noHover: {
    pointerEvents: "none",
  },
  listItem: {
    padding: "12px 7px",
  },
  listItemAvatar: {
    minWidth: 0,
  },
  deleteAction: {
    backgroundColor: red[500],
    color: theme.palette.common.white,
    "& span >button > span >svg": {
      color: theme.palette.common.white,
    },
  },
  addCard: {
    height: "100%",
    width: "100%",
    maxWidth: 370,
    minHeight: 217.33,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    border: `5px dashed ${colorExtendedGray1}`,
    borderRadius: 22,
  },
  defaultCard: {
    backgroundColor: colorExtendedGreen4,
    "& >button > span >svg": {
      color: theme.palette.common.white,
    },
  },
  iconAccent: { color: theme.palette.common.white },
}));

const CreditCardActions = (props) => {
  const classes = useStyles();
  const {
    isDefault,
    paymentSource,
    handleEditCard,
    handleDeleteCard,
    handleSetDefaultCard,
    loading,
    cardSelected,
  } = props;

  const handleAvatarClass = () => {
    if (isDefault) {
      return classes.defaultCard;
    } else {
      if (cardSelected) {
        return `${classes.avatar} ${classes.noHover}`;
      } else {
        return classes.avatar;
      }
    }
  };

  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <ListItemAvatar className={classes.listItemAvatar}>
          <Avatar className={handleAvatarClass()}>
            <IconButton
              disabled={isDefault}
              title={isDefault ? "Default" : "Make Default"}
              color="default"
              onClick={() => handleSetDefaultCard(paymentSource)}
            >
              {cardSelected === paymentSource.id && loading.default ? (
                <CircularProgress size={24} color="primary" disableShrink />
              ) : (
                <StarIcon />
              )}
            </IconButton>
          </Avatar>
        </ListItemAvatar>
      </ListItem>
      <ListItem className={classes.listItem}>
        <ListItemAvatar className={classes.listItemAvatar}>
          <Avatar className={classes.avatar}>
            <IconButton
              title="Edit Payment"
              onClick={() => handleEditCard(paymentSource)}
            >
              <CreateIcon />
            </IconButton>
          </Avatar>
        </ListItemAvatar>
      </ListItem>
      <ListItem className={classes.listItem}>
        <ListItemAvatar className={classes.listItemAvatar}>
          <Avatar className={classes.deleteAction}>
            <ConfirmModal
              title={`Delete Credit Card`}
              warningMessage="Please confirm that you want to delete this credit card."
              clickableComponent={
                <IconButton title="Delete Payment">
                  {cardSelected === paymentSource.id && loading.delete ? (
                    <CircularProgress
                      size={24}
                      disableShrink
                      className={classes.iconAccent}
                    />
                  ) : (
                    <DeleteIcon />
                  )}
                </IconButton>
              }
              onSubmit={() => handleDeleteCard(paymentSource)}
            />
          </Avatar>
        </ListItemAvatar>
      </ListItem>
    </List>
  );
};

const renderCreditCard = (
  index,
  classes,
  paymentSource,
  handleEditCard,
  handleDeleteCard,
  handleSetDefaultCard,
  loading,
  cardSelected
) => {
  const { card_brand, card_expiration, card_owner_name, is_default, last4 } =
    paymentSource;

  return (
    <Grid item xs={12} sm={12} md={12} lg={6} xl={4} key={index}>
      <div className={classes.root}>
        <CreditCard
          brand={card_brand}
          expiration={card_expiration}
          owner={card_owner_name}
          digits={last4}
        />
        <CreditCardActions
          isDefault={is_default}
          paymentSource={paymentSource}
          handleEditCard={handleEditCard}
          handleDeleteCard={handleDeleteCard}
          handleSetDefaultCard={handleSetDefaultCard}
          loading={loading}
          cardSelected={cardSelected}
        />
      </div>
    </Grid>
  );
};

export default function Payments({
  payments: paymentSources,
  setOpen,
  setSelectedCreditCard,
  handleGetPaymentSources,
  handleDisplayDefaultCard,
  isMounted,
}) {
  const classes = useStyles();
  const { accounts } = VivialConnectAPI.services;
  const [loading, setLoading] = useState({
    default: false,
    delete: false,
  });
  const [cardSelected, setCardSelected] = useState("");

  const handleAddCard = () => {
    setSelectedCreditCard({});
    setOpen("addCreditCard");
  };

  const handleEditCard = (paymentSource) => {
    setSelectedCreditCard(paymentSource);
    setOpen("updateCreditCard");
  };

  const handleDeleteCard = (paymentSource) => {
    const { id: cardId } = paymentSource;
    setCardSelected(cardId);
    setLoading({ ...loading, delete: true });
    accounts
      .deleteAccountPaymentSource(cardId)
      .then(() => {
        toast.success("Your card was deleted.");
        handleGetPaymentSources();
      })
      .catch((error) => {
        toast.error(error);
      })
      .finally(() => {
        if (!isMounted.current) return;
        setLoading({ ...loading, delete: false });
        setCardSelected("");
      });
  };

  const handleSetDefaultCard = (paymentSource) => {
    const { id: cardId } = paymentSource;
    setCardSelected(cardId);

    const payload = {
      payment_source: {
        is_default: true,
      },
    };

    setLoading({ ...loading, default: true });
    accounts
      .putAccountPaymentSource(cardId, payload)
      .then(() => {
        toast.success("Your default card was updated.");
        handleDisplayDefaultCard(cardId);
      })
      .catch((error) => toast.error(error))
      .finally(() => {
        if (!isMounted.current) return;
        setLoading({ ...loading, default: false });
        setCardSelected("");
      });
  };

  return (
    <DashboardCard>
      <DashboardCardHeader title={"Credit Cards"} />
      <DashboardCardContent>
        <Grid container spacing={5}>
          <Grid item xs={12} md={12} lg={12}>
            <Typography variant="body1" className={classes.typography}>
              Manage your saved credit cards below. The card selected as default
              will be used to process automatic payments and subscription
              renewals.
            </Typography>
          </Grid>
          {paymentSources.map((paymentSource, index) =>
            renderCreditCard(
              paymentSource.is_default ? paymentSource.id : index,
              classes,
              paymentSource,
              handleEditCard,
              handleDeleteCard,
              handleSetDefaultCard,
              loading,
              cardSelected
            )
          )}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={4}
            className={classes.root}
          >
            <div className={classes.addCard}>
              <Button
                color="primary"
                variant="contained"
                startIcon={<PaymentIcon className={classes.iconAccent} />}
                onClick={handleAddCard}
              >
                Add Card
              </Button>
            </div>
          </Grid>
        </Grid>
      </DashboardCardContent>
    </DashboardCard>
  );
}
