import endpoints from "./endpoints";
import { factory } from "vivialconnect-js-core";

//NOTE: do not use spread syntax here.
//serviceHelpers == {init, http, appSessionStore}
const serviceHelpers = factory.initService(endpoints);

const getPublicRoles = async () => {
  const { http } = serviceHelpers;
  try {
    const { data } = await http.get(endpoints.roles());
    return data.roles;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

export default {
  init: serviceHelpers.init,
  getPublicRoles,
};
