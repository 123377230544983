/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";

import withPageContext from "components/Context/WithPageContext";

import OnDemandAccountStatus from "./OnDemandAccountStatus";
import OnDemandAutomaticBilling from "./OnDemandAutomaticBilling";
import OnDemandYourPlan from "./OnDemandYourPlan";
import OnDemandYourPrices from "./OnDemandYourPrices";
import SubscriptionAccountStatus from "./SubscriptionAccountStatus";
import SubscriptionYourOveragePrices from "./SubscriptionYourOveragePrices";
import SubscriptionYourPlan from "./SubscriptionYourPlan";
import FreeTrialAccountStatus from "./FreeTrialAccountStatus";
import FreeTrialYourPlan from "./FreeTrialYourPlan";
import HowYourCreditsAreSpent from "./HowYourCreditsAreSpent";

import useStyles from "./styles";
import VivialConnectAPI from "api/vivial-connect";
import { toast } from "react-toastify";

export const DefaultPaymentMethod = ({ hasDefault }) => {
  return (
    <>
      {hasDefault ? (
        <Typography variant="body1">
          Credit card ending {hasDefault}.{" "}
          <Link to="/dashboard/billing/payment" component={NavLink}>
            Edit
          </Link>
        </Typography>
      ) : (
        <Typography variant="body1">
          No payment method found. <br />
          <Link to="/dashboard/billing/payment" component={NavLink}>
            Go to payment methods
          </Link>
        </Typography>
      )}
    </>
  );
};

export default withPageContext(Billing, {
  title: "Billing",
  subtitle: "billing status",
});

function Billing() {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({});
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(null);
  const [addFund, setAddFunds] = useState({});
  const [billingOptions, setBillingOptions] = useState({});
  const classes = useStyles();

  const handleAddFunds = (amount) => {
    const { accounts } = VivialConnectAPI.services;

    const payload = {
      amount: parseInt(amount),
      payment_type: "MANUAL_PAYMENT",
    };

    setLoading(true);
    accounts
      .postAccountCharge(payload)
      .then((payment) => {
        setAddFunds(payment);
        toast.success("Your payment has been received");
      })
      .catch((error) => toast.error(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    const { accounts } = VivialConnectAPI.services;

    accounts
      .getAccountStatus()
      .then((status) => setStatus(status))
      .catch((error) => toast.error(error));

    accounts
      .getAccountPaymentSources()
      .then((paymentSources) => {
        const defaultPaymentMethod = paymentSources.filter(
          (source) => source.is_default === true
        );

        if (defaultPaymentMethod.length)
          setDefaultPaymentMethod(defaultPaymentMethod[0].last4);
      })
      .catch(() => {
        // Free Trial plans have no payment method so this call will always throw an error.
        // For now we will handle this case by simply ignoring errors.
        // toast.error(error)
      });
  }, [addFund]);

  useEffect(() => {
    const { billing } = VivialConnectAPI.services;

    billing
      .getBillingOptions()
      .then((billingOptions) => setBillingOptions(billingOptions))
      .catch((error) => toast.error(error));
  }, []);

  if (!Object.keys(status).length) return null;
  const { plan_type } = status;

  return (
    <div className={classes.root}>
      <Grid container spacing={5}>
        <Grid item xs={12} md={12} lg={6}>
          {plan_type === "ondemand" ? (
            <OnDemandAccountStatus
              status={status}
              defaultPaymentMethod={defaultPaymentMethod}
              handleAddFunds={handleAddFunds}
              loading={loading}
            />
          ) : null}
          {plan_type === "subscription" ? (
            <SubscriptionAccountStatus
              status={status}
              defaultPaymentMethod={defaultPaymentMethod}
              handleAddFunds={handleAddFunds}
              billingOptions={billingOptions}
              loading={loading}
            />
          ) : null}
          {plan_type === "freetrial" ? (
            <FreeTrialAccountStatus status={status} />
          ) : null}
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          {plan_type === "ondemand" ? <OnDemandYourPlan /> : null}
          {plan_type === "subscription" ? (
            <SubscriptionYourPlan
              status={status}
              billingOptions={billingOptions}
              setBillingOptions={setBillingOptions}
            />
          ) : null}
          {plan_type === "freetrial" ? (
            <FreeTrialYourPlan status={status} />
          ) : null}
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={12} md={12} lg={6}>
          {plan_type === "ondemand" ? (
            <OnDemandAutomaticBilling
              status={status}
              defaultPaymentMethod={defaultPaymentMethod}
            />
          ) : null}
          {plan_type === "freetrial" || plan_type === "subscription" ? (
            <HowYourCreditsAreSpent />
          ) : null}
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          {plan_type === "ondemand" ? (
            <OnDemandYourPrices status={status} />
          ) : null}
          {plan_type === "subscription" ? (
            <SubscriptionYourOveragePrices status={status} />
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
}
