/* eslint-disable react/prop-types */
import React from "react";
import ListItem from "@material-ui/core/ListItem";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import {
  TypeBuyNumbersIcon,
  PhoneSettingsIcon,
  PhoneIcon,
} from "components/VivialIcons";
import MainItem from "../../components/ListView/MainItem";
import VivialConnectAPI from "api/vivial-connect";

const { convertNumberToCurrency } = VivialConnectAPI.helpers.numbers;

export const BannerText = ({ plan_type, included, owned }) => {
  if (plan_type === "freetrial") {
    return (
      <Typography paragraph={true}>
        {`Your Free Trial includes ${included} free number.`}
      </Typography>
    );
  }

  if (plan_type === "subscription") {
    let str = `Your subscription includes ${included} number credit${
      included !== 1 ? "s" : ""
    }.`;

    if (owned > included) {
      str += ` Your ${owned} number${
        owned !== 1 ? "s" : ""
      }  will consume all available credits and incur an overage each month. When your subscription renews we will upgrade your plan to cover these extra numbers.`;
    }

    return <Typography paragraph={true}>{str}</Typography>;
  }

  return "";
};

export const handleNumbers = (phoneNumber) => {
  const {
    name,
    phone_number,
    phone_number_type,
    voice_forwarding_number,
    campaign_id,
  } = phoneNumber;
  return (
    <>
      <MainItem
        primaryText={phone_number}
        secondaryText={name}
        itemType="first"
        key={phone_number}
      />
      <ListItem key={phone_number + "type"}>{phone_number_type}</ListItem>
      <ListItem key={phone_number + "voice"}>
        <CheckCircleIcon
          color={voice_forwarding_number ? "primary" : "disabled"}
        />
      </ListItem>
      <ListItem key={phone_number + "campaign"}>
        {campaign_id ? campaign_id : "-"}
      </ListItem>
    </>
  );
};

export const handleNumbersPurchaseItems = (
  phone,
  index,
  purchaseNumber,
  confirmPurchase,
  handleConfirmToPurchase,
  selectedPhone,
  searchTollFree,
  loading
) => {
  const {
    name,
    phone_number,
    phone_number_type,
    price,
    rate_center,
    region,
    city,
  } = phone;
  const purchasePayload = {
    phone_number: {
      phone_number,
      phone_number_type,
    },
  };
  return (
    <>
      <MainItem
        primaryText={"Phone Number"}
        secondaryText={<nobr>{name}</nobr>}
        avatarIcon={<PhoneIcon />}
        itemType="first"
      />
      {searchTollFree !== "tollfree" ? (
        <MainItem
          primaryText={"Location"}
          secondaryText={`${city}, ${region}`}
          avatarIcon={<LocationOnOutlinedIcon />}
        />
      ) : null}

      <MainItem
        primaryText={"Type"}
        secondaryText={phone_number_type}
        avatarIcon={<TypeBuyNumbersIcon />}
      />
      <MainItem
        primaryText={"Price"}
        secondaryText={convertNumberToCurrency(price, 2, false)}
        avatarIcon={<AttachMoneyIcon />}
      />
      {searchTollFree !== "tollfree" ? (
        <MainItem
          primaryText={"Rate Center"}
          secondaryText={rate_center}
          avatarIcon={<PhoneSettingsIcon />}
        />
      ) : null}

      <ListItem style={{ display: "flex", justifyContent: "center" }}>
        {confirmPurchase & (selectedPhone === phone_number) ? (
          <Button
            variant="contained"
            color="primary"
            size="small"
            disabled={loading}
            onClick={() => purchaseNumber(purchasePayload, index)}
            startIcon={
              loading ? <CircularProgress size={24} color="inherit" /> : null
            }
          >
            Confirm Purchase
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            size="small"
            disabled={loading}
            onClick={() => handleConfirmToPurchase(phone, index)}
          >
            <nobr>Buy number</nobr>
          </Button>
        )}
      </ListItem>
    </>
  );
};
