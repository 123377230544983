// oauth/endpoints.js
import { factory } from "vivialconnect-js-core";

//NOTE: do not use spread syntax here.
//helper == {init, helper.BASE_URL}
const helper = factory.initEndpoints();

export default {
  init: helper.init,
  personalTokens: (accountId, userId) =>
    `${helper.BASE_URL}/accounts/${accountId}/users/${userId}/oauth/personal_tokens.json`,
  personalTokensCount: (accountId, userId) =>
    `${helper.BASE_URL}/accounts/${accountId}/users/${userId}/oauth/personal_tokens/count.json`,
  personalToken: (accountId, userId, tokenId) =>
    `${helper.BASE_URL}/accounts/${accountId}/users/${userId}/oauth/personal_tokens/${tokenId}.json`,
  scopes: () => `${helper.OAUTH_URL}/oauth2/scopes`,
};
