import React from "react";
import { Switch, Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { createBrowserHistory } from "history";

import "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import config from "config/app";

import ContextProvider from "components/Context";
import VCRoutes from "components/Routes";

import logger from "./services/loggerService";

const stripePromise = loadStripe(config.STRIPE_KEY, {
  apiVersion: "2020-03-02",
});

if (process.env.NODE_ENV === "production") {
  logger.init();
}

function App() {
  //Google Analytics
  const history = createBrowserHistory();

  return (
    <Router history={history}>
      <ToastContainer />
      <Elements stripe={stripePromise}>
        <ContextProvider>
          <Switch>{VCRoutes()}</Switch>
        </ContextProvider>
      </Elements>
    </Router>
  );
}

export default App;
