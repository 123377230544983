import { makeStyles } from "@material-ui/core";
import defaultImage from "assets/img/home_main.png";

export const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: 0,
    backgroundImage: `url(${defaultImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  contentWrapper: {
    height: "100%",
    minHeight: "650px",
    textAlign: "center",
    paddingTop: "50px",
  },
  fullHeight: {
    height: "100vh",
  },
  content: {
    textAlign: "center",
    padding: "0 5%",
    [theme.breakpoints.up("lg")]: {
      padding: 0,
      maxWidth: "60%",
    },
  },
  header1: {
    fontWeight: 200,
    color: theme.palette.common.white,
    textTransform: "uppercase",
    fontSize: 22,
    margin: "0 0 45px",
    padding: "14px 5px 0",
    borderTop: "1px solid #fff",
    display: "inline-block",
  },
  header2: {
    fontFamily: theme.typography.fontTitle,
    color: theme.palette.common.white,
    marginBottom: 28,
    fontSize: "2.8em",
    lineHeight: "1.1",
    marginTop: 0,
    fontWeight: 200,
  },
  date: {
    color: theme.palette.common.white,
  },
}));
