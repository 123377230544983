/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { toast } from "react-toastify";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";

import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import { format, parseISO } from "date-fns";

import { useAppContext } from "components/Context";
import {
  Card as DashboardCard,
  CardContent as DashboardCardContent,
} from "components/Layouts/Dashboard/Card";
import ConfirmPassword from "components/ConfirmPassword";

import useStyles from "./styles";
import VivialConnectAPI from "api/vivial-connect";

const EditableCell = ({
  token,
  description,
  editName,
  onCancel,
  handleOnChange,
  onSave,
  selectedToken,
  onEdit,
}) => {
  const classes = useStyles();

  return (
    <TableCell component="th" scope="row">
      <Grid container justify="center" alignItems="center">
        {editName && token.tokenInfo.id === selectedToken ? (
          <>
            <Grid item xs={7} className={classes.keyField}>
              <TextField
                fullWidth
                variant="outlined"
                label="Key Name"
                name={`key`}
                value={description}
                onChange={handleOnChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={5}>
              <Button variant="contained" size="small" onClick={onCancel}>
                Cancel
              </Button>
              &nbsp;&nbsp;
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                  onSave(token.tokenInfo);
                  onEdit();
                }}
              >
                Save
              </Button>
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <Typography variant="h5" className={classes.keyLabel}>
              {token.description}
            </Typography>
          </Grid>
        )}
      </Grid>
    </TableCell>
  );
};

export default function TokensTable({
  tokens,
  setTokens,
  description,
  setDescription,
  handleOnChange,
  onSave,
}) {
  const { appState } = useAppContext();
  const classes = useStyles();
  const [selectedToken, setSelectedToken] = useState();
  const [editName, setEditName] = useState(false);

  const onEdit = (token) => {
    if (token) {
      setEditName(true);
      setDescription(token.description);
      setSelectedToken(token.tokenInfo.id);
    } else {
      setEditName(false);
      setDescription();
      setSelectedToken();
    }
  };

  const onCancel = () => {
    setDescription();
    setEditName();
    setSelectedToken();
  };

  const handleOnDelete = async (token) => {
    const { oauth } = VivialConnectAPI.services;

    oauth
      .deleteToken(token.id, appState.user_id)
      .then(() => {
        toast.success(`Token ${token.id} successfully deleted`);
        oauth
          .getTokens(appState.user_id)
          .then((newTokens) => setTokens(newTokens));
      })
      .catch(() => {
        toast.error("Unable to delete the token");
      });
  };

  function createUserData(description, scope, dateCreated, tokenInfo) {
    return {
      description,
      scope,
      dateCreated,
      tokenInfo,
      copyButtonText: "copy",
    };
  }

  const convertDataToRows = (token) => {
    const { description, scope, date_created } = token;

    return createUserData(
      description,
      scope,
      format(parseISO(date_created), "yyyy-MM-dd"),
      token
    );
  };

  const rowsUsersTable = tokens.map((token) => convertDataToRows(token));

  return (
    <DashboardCard>
      <DashboardCardContent>
        <TableContainer>
          <Table
            stickyHeader
            className={"classes.table"}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography
                    variant="h6"
                    className={classes.tableColumnHeader}
                  >
                    Token Description
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="h6"
                    className={classes.tableColumnHeader}
                  >
                    Date Created
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="h6"
                    className={classes.tableColumnHeader}
                  >
                    Allowed Scopes
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography
                    variant="h6"
                    className={classes.tableColumnHeader}
                  >
                    Actions
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowsUsersTable.map((row, indx) => {
                return (
                  <TableRow hover key={indx} className={classes.rowWrapper}>
                    <EditableCell
                      token={row}
                      editName={editName}
                      onCancel={onCancel}
                      onEdit={onEdit}
                      description={description}
                      handleOnChange={handleOnChange}
                      onSave={onSave}
                      selectedToken={selectedToken}
                    />

                    <TableCell>{row.dateCreated}</TableCell>
                    <TableCell>{row.scope.split(" ").join(", ")}</TableCell>

                    <TableCell align="right">
                      <Grid container>
                        <Grid item xs={6}>
                          <Tooltip title={"Edit"}>
                            <IconButton onClick={() => onEdit(row)}>
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={6}>
                          <ConfirmPassword
                            title={`DELETE ACCESS TOKEN`}
                            warningMessage={
                              <>
                                <span className={classes.warningText}>
                                  Deletion of an Personal Token can cause link
                                  failures for connected programs. Please
                                  re-enter your password to acknowledge and
                                  proceed.
                                </span>
                                <br />
                                <br />
                                <span className={classes.keyTitle}>Token</span>
                                <br />
                                <span>{row.description}</span>
                              </>
                            }
                            clickableComponent={
                              <Tooltip title={"Delete"}>
                                <IconButton>
                                  <DeleteIcon
                                    className={classes.tableDeleteButton}
                                  />
                                </IconButton>
                              </Tooltip>
                            }
                            onSubmit={() => handleOnDelete(row.tokenInfo)}
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DashboardCardContent>
    </DashboardCard>
  );
}
