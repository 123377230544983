import ContentfulClient from ".";
import { contentfulOptions } from "./config.js";

async function getFirstEntry(type, slug, callback) {
  try {
    const { items } = await ContentfulClient.getEntries({
      ...contentfulOptions,
      content_type: type,
      "fields.slug": slug,
    });
    if (typeof callback === "function") {
      callback(items[0]);
    }
    return items[0];
  } catch (error) {
    console.log(error);
  }
}

async function getAllEntries(type, callback) {
  try {
    const { items } = await ContentfulClient.getEntries({
      content_type: type,
    });
    if (typeof callback === "function") {
      callback(items);
    }
    return items;
  } catch (error) {
    console.log(error);
  }
}

export const GetContentfulBlogPosts = async (callback) => {
  return getAllEntries("blog", callback);
};

export const GetContentfulPage = async (slug, callback) => {
  return getFirstEntry("page", slug, callback);
};

export const GetGlossaryTerm = async (slug, callback) => {
  return getFirstEntry("glossaryTerm", slug, callback);
};

export const GetDoc = async (slug, callback) => {
  return getFirstEntry("doc", slug, callback);
};

export const GetDocNav = async (callback) => {
  return getFirstEntry("docNav", "docs-nav", callback);
};

export const GetContentfulBlogPost = async (slug, callback) => {
  return getFirstEntry("blog", slug, callback);
};

export const GetAdditionalContent = (page, id, name, callback) => {
  let content = {};
  page.fields.additionalContent.forEach((entry) => {
    if (entry.sys.contentType.sys.id === id && entry.fields.name === name) {
      content = entry.fields;
    }
  });

  try {
    if (typeof callback === "function") {
      callback(content);
    }
  } catch (error) {
    console.log(error);
  }

  return content;
};

export const getItemContent = (page, item_name, callback) => {
  const item = GetAdditionalContent(page, "item", item_name);
  if (!item) return;

  let content = {
    header: item.header,
    shortDescription: item.shortDescription,
    description: item.longDescription,
  };

  if (item.image) {
    content.imageURL = item.image.fields.file.url;
  }

  if (item.button) {
    content.button = item.button;
  }

  try {
    if (typeof callback === "function") {
      callback(content);
    }
  } catch (error) {
    console.log(error);
  }

  return content;
};
