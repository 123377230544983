/* eslint-disable react/prop-types */
import React from "react";
import Divider from "@material-ui/core/Divider";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";

import { useStyles } from "./styles";
import { ListItemText } from "@material-ui/core";

export default function ListView({
  headers,
  list,
  notFoundMessage = "",
  renderItem,
  onClick = () => {},
  hideHeaderBreakpoints = {},
}) {
  const classes = useStyles();

  if (!(list.length > 0)) return notFoundMessage;

  return (
    <div className={classes.root} spacing={2}>
      <List>
        <Hidden {...hideHeaderBreakpoints}>
          <div className={classes.list}>
            {headers &&
              headers.map((item, index) => {
                return (
                  <ListItem key={index}>
                    <ListItemText
                      primary={
                        <Typography variant="h6" color="primary">
                          {item}
                        </Typography>
                      }
                    ></ListItemText>
                  </ListItem>
                );
              })}
          </div>
          <Divider />
        </Hidden>

        {list.map((item, index) => {
          return (
            <div key={index}>
              <div
                className={`${classes.list} ${classes.listItem}`}
                onClick={() => onClick(item)}
              >
                {renderItem(item, index)}
              </div>
              <Divider />
            </div>
          );
        })}
      </List>
    </div>
  );
}
