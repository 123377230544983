import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import { toast } from "react-toastify";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Link from "@material-ui/core/Link";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import LobbyHeader from "components/Layouts/Lobby/Header";
import LobbyFormWrapper from "components/Layouts/Lobby/FormWrapper";
import VivialConnectAPI from "api/vivial-connect";

import { useStyles } from "./styles";

export default function ForgotPassword() {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(false);

  const handleChange = ({ target }) => {
    const { value } = target;
    setEmail(value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const { auth } = VivialConnectAPI.services;

    auth
      .passwordResetRequest(email)
      .then((response) => setOpen(response))
      .catch((error) => toast.error(`${error}`));
  };

  return (
    <>
      <LobbyHeader
        title={"Forgot Password?"}
        subtitle={"Enter your email address to request password reset link."}
      />
      <LobbyFormWrapper onSubmit={handleSubmit}>
        {open ? (
          <Typography variant="body1" className={classes.formSuccessMessage}>
            Request Received. Please check your email for a password reset link.
          </Typography>
        ) : (
          <>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                margin="normal"
                required
                type="email"
                id="email"
                label="Email Address"
                name="email"
                value={email}
                onChange={handleChange}
                autoFocus
                placeholder="Please enter your@email.com"
                InputLabelProps={{
                  className: classes.placeholder,
                  classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                  },
                  shrink: true,
                }}
                InputProps={{
                  className: classes.multilineColor,
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              />
            </FormControl>
            <div className={classes.buttonWrapper}>
              <Box mr={2}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.submit}
                >
                  Request Reset
                </Button>
              </Box>
              <Link component={NavLink} to="/login" className={classes.link}>
                Return to Login
              </Link>
            </div>
          </>
        )}
      </LobbyFormWrapper>
    </>
  );
}
