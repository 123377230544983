import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { ThemeProvider } from "@material-ui/core/styles";

import { darkTheme } from "themes";
import Social from "./Social";
import FooterNav from "./FooterNav";

const useStyles = makeStyles(() => ({
  root: {
    padding: "30px 45px",
    backgroundColor: darkTheme.palette.background.default,
  },
}));

export default function BrochureFooter() {
  const classes = useStyles();

  return (
    <ThemeProvider theme={darkTheme}>
      <footer className={classes.root}>
        <Social />
        <FooterNav />
      </footer>
    </ThemeProvider>
  );
}
