/* eslint-disable react/prop-types */
import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";

import {
  Card as DashboardCard,
  CardContent as DashboardCardContent,
} from "components/Layouts/Dashboard/Card";

import {
  chartStyles,
  dataHelpers,
  withLogsView,
} from "@moosetalk-frontend/global/components/UsageChart";

import VivialConnectAPI from "api/vivial-connect";
const { logs: publicLogsService } = VivialConnectAPI.services;

// fetch/process data for account charts
const {
  getAccountFilteredChartData,
  processAccountLogDataForChart,
} = dataHelpers;

// withLogsView HOC behaves like a mixin.
// Logs component will throw errors if not wrapped in withLogsView
export default withLogsView(UsageLogs);

function UsageLogs({ logsView }) {
  const classes = chartStyles();

  // initialize data fetch method with the current account id
  // this method gets data for the chart only
  const getDataForAccount = getAccountFilteredChartData(publicLogsService);

  // Control chart vs. list display
  const [showChart, setShowChart] = useState(true);
  const handleViewChange = (view) => {
    view === "chart" ? setShowChart(() => true) : setShowChart(() => false);
  };

  return (
    <>
      <Grid item xs={12}>
        <logsView.activityFilters title="" />
      </Grid>
      <Grid item xs={12}>
        <DashboardCard>
          <DashboardCardContent>
            <Grid item xs={12} className={classes.logsHeader}>
              <ButtonGroup
                variant="text"
                color="primary"
                aria-label="text primary button group"
              >
                <Button onClick={() => handleViewChange("chart")}>Usage</Button>
                <Button onClick={() => handleViewChange("logs")}>Logs</Button>
              </ButtonGroup>
              {showChart ? (
                <logsView.chartLogGroupSelector />
              ) : (
                <logsView.listLogGroupSelector />
              )}
            </Grid>
            <Grid item xs={12}>
              {showChart ? (
                <logsView.usageChart
                  getData={getDataForAccount}
                  transformData={processAccountLogDataForChart}
                />
              ) : (
                <logsView.logsTable
                  publicAPIServices={VivialConnectAPI.services}
                />
              )}
            </Grid>
          </DashboardCardContent>
        </DashboardCard>
      </Grid>
    </>
  );
}
