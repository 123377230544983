import { makeStyles } from "@material-ui/core";
import { colorExtendedGreen4 } from "../../themes/palette";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
  bannerWrapper: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  bannerHeader: {
    color: colorExtendedGreen4,
  },
  bannerTitle: {
    flexGrow: 1,
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    "& button": {
      marginLeft: theme.spacing(2),
    },
  },
  testForm: {
    height: "100%",
  },
  formButtons: {
    display: "flex",
    justifyContent: "space-between",
  },
  saveButtonWrapper: {
    textAlign: "right",
  },
  releaseNumberButton: {
    color: "#f00",
    borderColor: "#f00",
  },
  paginationWrapper: {
    textAlign: "center",
  },
  numberPurchaseItemWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  errorLink: {
    color: theme.palette.common.white,
    textDecoration: "underline",
  },
  dialog: {
    "& > div > div": {
      padding: 30,
    },
  },
  dialogTitle: {
    textTransform: "uppercase",
  },
}));
