/* eslint-disable react/prop-types */
import React from "react";

import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";

import { fontPrimaryTitleSubheading } from "themes/typography";

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    textAlign: "center",
    paddingTop: "10px",
    paddingBottom: "30px",
    maxWidth: "400px",
    height: "100%",
  },
  header: {
    flex: "3 0 0",
    marginBottom: theme.spacing(4),
    "& h5": {
      textTransform: "uppercase",
      marginBottom: theme.spacing(1),
    },
    "& h6": {
      textTransform: "capitalize",
      fontSize: "0.5555rem",
      fontWeight: 700,
    },
  },
  primaryWrapper: {
    flex: "6 0 auto",
    minHeight: "140px",
    fontFamily: fontPrimaryTitleSubheading,
    fontWeight: 300,
    lineHeight: 1.7,
    "& h5": {
      fontFamily: fontPrimaryTitleSubheading,
      lineHeight: 1.7,
      fontWeight: 700,
    },
  },
  secondaryWrapper: {
    flex: "9 0 auto",
    fontFamily: fontPrimaryTitleSubheading,
    fontSize: "0.7222rem",
    lineHeight: 2.5,
    padding: "10px 0",
  },
  flexOne: {
    flex: "1 0 0",
  },
}));

export default function PlanCard(props) {
  const classes = useStyles();
  const { header, subheader, primary, secondary } = props;

  return (
    <Paper className={classes.cardRoot} elevation={2}>
      <div className={classes.header}>
        <Typography variant="h5" component="h5">
          {header}
        </Typography>
        <Typography variant="h6" component="h6">
          {subheader}
        </Typography>
      </div>

      <div className={classes.primaryWrapper}>{primary}</div>
      <Divider variant="middle" />
      <div className={classes.secondaryWrapper}>{secondary}</div>

    </Paper>
  );
}
