import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    padding: theme.spacing(8),
  },
  typography: {
    marginTop: "2rem",
  },
  register: {
    marginLeft: 15,
  },
  buttonWrapper: {
    display: "flex",
    alignItems: "center",
    fontSize: "1.125rem",
    marginTop: theme.spacing(2),
  },
  version: {
    color: theme.palette.common.white,
  },
}));
