/* eslint-disable react/prop-types */
import React from "react";
import { Link as RouterLink } from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";

import Subtitle from "components/Layouts/Brochure/Subtitle";
import BrochureButton from "components/Layouts/Brochure/BrochureButton";

const useStyles = makeStyles(() => ({
  homeItemImg: {
    width: "100%",
  },
  homeItemContent: {
    "& p": {
      marginBottom: "30px",
    },
  },
}));

export default function HomeItem(props) {
  const classes = useStyles();
  const { imageURL, header, description, button } = props;
  const direction = props.direction || "row";

  //prevent component rendering before the imageURL is acquired
  if (!imageURL) return null;

  return (
    <Grid
      container
      direction={direction}
      justifyContent="center"
      alignItems="flex-start"
      spacing={5}
    >
      <Grid item xs={12} md={6}>
        <img alt="{header}" src={imageURL} className={classes.homeItemImg} />
      </Grid>

      <Grid item xs={12} md={6} className={classes.homeItemContent}>
        <Subtitle>{header}</Subtitle>

        <Typography className={classes.homeItemBody}>{description}</Typography>

        {button && (
          <RouterLink to={button.fields.link}>
            <BrochureButton size="small">{button.fields.text}</BrochureButton>
          </RouterLink>
        )}
      </Grid>
    </Grid>
  );
}
