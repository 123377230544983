import React, { useState } from "react";
import { toast } from "react-toastify";
import { Redirect, NavLink } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TablePagination from "@material-ui/core/TablePagination";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";

import VivialConnectAPI from "api/vivial-connect";
import withPageContext from "components/Context/WithPageContext";
import {
  Card as DashboardCard,
  CardContent as DashboardCardContent,
} from "components/Layouts/Dashboard/Card";
import ListView from "components/ListView";
import MainItem from "components/ListView/MainItem";
import { AccountTreeIcon } from "components/VivialIcons";

import { PageHeader } from "./shared.js";
import { useStyles } from "./styles";

const { connectors } = VivialConnectAPI.services;
const { customHooks } = VivialConnectAPI.helpers;

export default withPageContext(Connectors, {
  title: "Connectors",
  subtitle: "manage connectors",
});

function Connectors() {
  const isMounted = customHooks.useMountedRef();
  const classes = useStyles();
  const headers = [
    "Name",
    "Qty. Numbers",
    "Status Callback URL",
    "Incoming Message Callback URL",
  ];
  const [connectorList, setConnectorList] = useState([]);
  const [selectedConnector, setSelectedConnector] = useState({});
  const [count, setCount] = useState(null);
  const [page, setPage] = useState(0);
  const [redirectToEdit, setRedirectToEdit] = useState(false);
  const limit = 10;

  React.useEffect(() => {
    const params = {
      page: 1,
      limit: limit,
    };
    connectors
      .getConnectors(params)
      .then((connectors) => {
        if (!isMounted.current) return;
        setConnectorList(connectors);
        setPage(0);
      })
      .catch((error) => toast.error(error));
    connectors
      .getConnectorsCount()
      .then((count) => {
        if (!isMounted.current) return;
        setCount(count);
      })
      .catch((error) => toast.error(error));
  }, [isMounted]);

  const handlePageChange = async (event, newPage) => {
    const params = {
      page: newPage + 1, //API pagination is 1-based. MD pagination is 0-based :(
      limit: limit,
    };
    connectors
      .getConnectors(params)
      .then((connectors) => {
        setConnectorList(connectors);
        setPage(newPage);
      })
      .catch((error) => toast.error(error));
  };

  const handleSelectConnector = (connector) => {
    setSelectedConnector(connector);
    setRedirectToEdit(true);
  };

  if (redirectToEdit)
    return (
      <Redirect to={`/dashboard/connectors/edit/${selectedConnector.id}`} />
    );

  return (
    <div className={classes.root} spacing={2}>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <PageHeader>
            <Button
              color="primary"
              variant="outlined"
              startIcon={<AccountTreeIcon />}
              component={NavLink}
              to={"/dashboard/connectors/create"}
            >
              Create Connector
            </Button>
          </PageHeader>
        </Grid>
        <IndexView />
      </Grid>
    </div>
  );

  function IndexView() {
    const ShowError = () => (
      <Typography variant="subtitle1">
        You don&apos;t have any connectors yet.
      </Typography>
    );

    const ShowList = () => (
      <DashboardCard>
        <DashboardCardContent>
          <ListView
            headers={headers}
            list={connectorList}
            notFoundMessage="No connectors found."
            columns={2}
            renderItem={renderItem}
            onClick={handleSelectConnector}
          />
          {count !== null && count > limit && (
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={count}
              rowsPerPage={limit}
              page={page}
              onPageChange={handlePageChange}
            />
          )}
        </DashboardCardContent>
      </DashboardCard>
    );

    return (
      <Grid item xs={12}>
        {count > 0 && <ShowList />}
        {count === 0 && <ShowError />}
      </Grid>
    );
  }

  function renderItem(connector) {
    const { name, id, phone_numbers, callbacks } = connector;

    const keys = {
      status: "statusCallback" + id,
      incoming: "incomingCallback" + id,
    };
    const callbacksRender = [
      <ListItem key={keys.status}>{""}</ListItem>,
      <ListItem key={keys.incoming}>{""}</ListItem>,
    ];

    if (Array.isArray(callbacks)) {
      callbacks.forEach((item) => {
        if (item.event_type === "status") {
          callbacksRender[0] = (
            <ListItem key={keys.status}>{item.url}</ListItem>
          );
        } else if (item.event_type === "incoming") {
          callbacksRender[1] = (
            <ListItem key={keys.incoming}>{item.url}</ListItem>
          );
        }
      });
    }

    return (
      <>
        <MainItem
          primaryText={`ID: ${id}`}
          secondaryText={name}
          itemType="first"
        />
        <ListItem>{phone_numbers.length}</ListItem>
        {callbacksRender.map((item) => {
          return item;
        })}
      </>
    );
  }
}
