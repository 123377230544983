import endpoints from "./endpoints.js";
import { factory } from "vivialconnect-js-core";

//NOTE: do not use spread syntax here.
//serviceHelpers == {init, http, appSessionStore}
const serviceHelpers = factory.initService(endpoints);

const getUsers = async (params, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.get(
      endpoints.users(id ? id : appSessionStore.getAccountId()),
      { params }
    );
    return data.users;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getUsersCount = async (params, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.get(
      endpoints.usersCount(id ? id : appSessionStore.getAccountId()),
      { params }
    );
    return data.count;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getUser = async (userId, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.get(
      endpoints.user(id ? id : appSessionStore.getAccountId(), userId)
    );
    return data.user;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const putUser = async (userId, payload, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.put(
      endpoints.user(id ? id : appSessionStore.getAccountId(), userId),
      payload
    );
    return data.user;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const deleteUser = async (userId, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    await http.delete(
      endpoints.user(id ? id : appSessionStore.getAccountId(), userId)
    );
    return true;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const postUserRegister = async (payload, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    await http.post(
      endpoints.userRegister(id ? id : appSessionStore.getAccountId()),
      payload
    );
    return true;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getUserRoles = async (userId, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.get(
      endpoints.userProfileRoles(
        id ? id : appSessionStore.getAccountId(),
        userId
      )
    );
    return data.user.roles;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getUserCredentials = async (userId, params, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.get(
      endpoints.userProfileCredentials(
        id ? id : appSessionStore.getAccountId(),
        userId
      ),
      { params }
    );
    return data.user.credentials;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getUserCredentialsCount = async (userId, params, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.get(
      endpoints.userProfileCredentialsCount(
        id ? id : appSessionStore.getAccountId(),
        userId
      ),
      { params }
    );
    return data.count;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const postUserCredentials = async (userId, payload, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.post(
      endpoints.userProfileCredentials(
        id ? id : appSessionStore.getAccountId(),
        userId
      ),
      payload
    );
    return data.user.credential;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getUserCredential = async (userId, credentialId, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.get(
      endpoints.userProfileCredential(
        id ? id : appSessionStore.getAccountId(),
        userId,
        credentialId
      )
    );
    return data.user.credential;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const putUserCredential = async (userId, credentialId, payload, params, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.put(
      endpoints.userProfileCredential(
        id ? id : appSessionStore.getAccountId(),
        userId,
        credentialId
      ),
      payload,
      { params }
    );
    return data.user.credential;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const deleteUserCredential = async (userId, credentialId, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    await http.delete(
      endpoints.userProfileCredential(
        id ? id : appSessionStore.getAccountId(),
        userId,
        credentialId
      )
    );
    return true;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const putUserProfile = async (userId, payload, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    await http.put(
      endpoints.userProfileBasic(
        id ? id : appSessionStore.getAccountId(),
        userId
      ),
      payload
    );
    return true;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const putUserProfilePassword = async (userId, payload, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    await http.put(
      endpoints.userProfilePassword(
        id ? id : appSessionStore.getAccountId(),
        userId
      ),
      payload
    );
    return true;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const postUserPasswordVerify = async (userId, payload, params, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.post(
      endpoints.userProfilePasswordVerify(
        id ? id : appSessionStore.getAccountId(),
        userId
      ),
      payload,
      { params }
    );
    return data.valid;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const putUserPasswordVerify = async (userId, payload, params, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.put(
      endpoints.userProfilePasswordVerify(
        id ? id : appSessionStore.getAccountId(),
        userId
      ),
      payload,
      { params }
    );
    return data.valid;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

export default {
  init: serviceHelpers.init,
  getUsers,
  getUsersCount,
  getUser,
  putUser,
  deleteUser,
  postUserRegister,
  getUserRoles,
  getUserCredentials,
  getUserCredentialsCount,
  postUserCredentials,
  getUserCredential,
  putUserCredential,
  deleteUserCredential,
  putUserProfile,
  putUserProfilePassword,
  postUserPasswordVerify,
  putUserPasswordVerify,
};
