/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { toast } from "react-toastify";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import { useAppContext } from "components/Context";
import {
  Card as DashboardCard,
  CardHeader as DashboardCardHeader,
  CardContent as DashboardCardContent,
} from "components/Layouts/Dashboard/Card";

import useStyles from "./styles";
import VivialConnectAPI from "api/vivial-connect";

const { customHooks } = VivialConnectAPI.helpers;

export default function ChangeYourPassword({ handleShowDefault }) {
  const isMounted = customHooks.useMountedRef();
  const { appState } = useAppContext();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    password: "",
    newPassword: "",
    retypeNewPassword: "",
    showPassword: false,
    showNewPassword: false,
    showRetypeNewPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value ? event.target.value : "",
    });
  };

  const handleClickShowPassword = (name) => {
    const newValues = { ...values };
    newValues[name] = !values[name];
    setValues({ ...newValues });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleOnClick = () => {
    const { password, newPassword, retypeNewPassword } = values;
    const newPasswordMatch = newPassword === retypeNewPassword;

    if (newPasswordMatch && password && newPassword) {
      const { users } = VivialConnectAPI.services;

      const payload = {
        user: {
          _password: password,
          password: newPassword,
        },
      };

      setLoading(true);
      users
        .putUserProfilePassword(appState.user_id, payload)
        .then((updated) => {
          if (updated) {
            toast.success("Password updated");
            if (isMounted.current) handleShowDefault();
          } else {
            toast.error(
              "Unable to update your password. Please confirm password constraints and try again"
            );
          }
        })
        .catch((error) => toast.error(error))
        .finally(() => {
          if (!isMounted.current) return;
          setLoading(false);
        });
    } else {
      toast.error("New Password and Retype New Password miss match.");
    }
  };

  return (
    <DashboardCard>
      <DashboardCardHeader title={"Change Your Password"} />
      <DashboardCardContent>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              required
              variant="outlined"
              label="Current Password"
              name="new_password"
              id="outlined-adornment-password"
              type={values.showPassword ? "text" : "password"}
              value={values.password}
              onChange={handleChange("password")}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword("showPassword")}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              required
              variant="outlined"
              label="New Password"
              name="newPassword"
              id="outlined-adornment-new-password"
              type={values.showNewPassword ? "text" : "password"}
              value={values.newPassword}
              onChange={handleChange("newPassword")}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword("showNewPassword")}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showNewPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              required
              variant="outlined"
              label="Retype New Password"
              name="retypeNewPassword"
              id="outlined-adornment-retype-new-password"
              type={values.showRetypeNewPassword ? "text" : "password"}
              value={values.retypeNewPassword}
              onChange={handleChange("retypeNewPassword")}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        handleClickShowPassword("showRetypeNewPassword")
                      }
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showRetypeNewPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.editYourProfileButton}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              onClick={handleOnClick}
              disabled={loading}
            >
              Update Password
              {loading && (
                <CircularProgress
                  size={24}
                  color="primary"
                  disableShrink
                  className={classes.spinner}
                />
              )}
            </Button>
          </div>
        </Grid>
      </DashboardCardContent>
    </DashboardCard>
  );
}
