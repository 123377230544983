/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { usePageContext } from "components/Context";

import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import MenuIcon from "@material-ui/icons/Menu";
import { PowerSettingsIcon, DocumentsIcon } from "components/VivialIcons";

import { useStyles } from "./styles";

export default function MainAppBar(props) {
  const { pageState } = usePageContext();
  const classes = useStyles();
  const history = useHistory();
  const [anchorElDocs, setAnchorElDocs] = useState(null);
  const { handleDrawerToggle } = props;

  const handleDocsMenu = (event) => {
    setAnchorElDocs(event.currentTarget);
  };
  const handleDocsClose = (url) => {
    return () => {
      setAnchorElDocs(null);
      window.open(url, "docs");
    };
  };

  const handleClose = () => {
    setAnchorElDocs(null);
  };

  const handleLogout = () => {
    history.push("/logout");
  };

  return (
    <AppBar position="absolute" className={classes.appBar} color="inherit">
      <Toolbar className={classes.toolbar}>
        <Hidden mdUp>
          <IconButton
            aria-label="show menu"
            onClick={handleDrawerToggle}
            className={classes.drawerToggle}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>

        <div className={classes.title}>
          <Typography
            component="h1"
            variant="h4"
            noWrap
            className={classes.typography}
          >
            {pageState.title || ""}
          </Typography>
          <Hidden smDown>
            {pageState.subtitle ? (
              <Box className={classes.box}>
                <Typography
                  component="h2"
                  variant="h6"
                  noWrap
                  className={`${classes.typography} ${classes.subTitle}`}
                >
                  {pageState.subtitle}
                </Typography>
              </Box>
            ) : null}
          </Hidden>
        </div>

        <div className={classes.menuItems}>
          <Hidden smDown>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleDocsMenu}
            >
              <DocumentsIcon className={classes.menuIconDocs} />
              <Typography
                type="body1"
                style={{
                  marginLeft: 4,
                }}
              >
                Docs
              </Typography>
              <ArrowDropDownIcon className={classes.menuIconArrow} />
            </IconButton>
            <Menu
              id="menu-docs"
              anchorEl={anchorElDocs}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={Boolean(anchorElDocs)}
              onClose={handleClose}
              PaperProps={{
                style: {
                  minWidth: 120,
                },
              }}
            >
              <MenuItem onClick={handleDocsClose("/docs")}>
                All Documentation
              </MenuItem>
              <MenuItem onClick={handleDocsClose("/docs/api")}>
                API Specification
              </MenuItem>
              <MenuItem onClick={handleDocsClose("/docs/faq")}>FAQ</MenuItem>
              <MenuItem
                onClick={handleDocsClose("https://vivialconnect.github.io")}
              >
                Client SDKs
              </MenuItem>
            </Menu>
          </Hidden>
          <IconButton
            aria-label="Logout session"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            title="Logout"
            onClick={handleLogout}
          >
            <PowerSettingsIcon className={classes.menuIconPower} />
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
}
