/* dataHelpers.js
 * methods for getting data from various endpoints and processing it for dislay
 * in either ApexCharts or Log list format
 */
import { format, eachDayOfInterval, parse } from "date-fns";
import { logTypes, logGroups, chartDateFormats } from "./constants";
import { toast } from "react-toastify";

/* getSystemFilteredChartData
 * generates getter function for system-wide usage data.
 */
export const getSystemFilteredChartData = (adminLogsService) =>
  chartDataGetterFactory({
    getter: adminLogsService.getLogsAggregate,
    date_format: "yyyy-MM-dd'T'HH:mm:ss", //different endpoints use different date formats :-(
  });

/* getAccountFilteredChartData
 * generates getter function for single account usage data via the public API.
 * must be initialized like: const getter = getAccountFilteredChartData(publicLogsService, [id]);
 */
export const getAccountFilteredChartData = (publicLogsService, accountId) => {
  const getLogsAggregateForAccount = (params) => {
    //call public API
    return publicLogsService.getLogsAggregate(params, accountId);
  };

  return chartDataGetterFactory({
    getter: getLogsAggregateForAccount,
  });
};

/* processLogDataForChart */
// Data processor helpers accept a raw Response from the API and transform
// it so it can be consumed by Apex-Charts.

// As of 2020-12 the only important difference between the public and system
// endpoints is the name of data array (items vs. log_items. why?!). They may
// diverge more in the future so I suggest we retain separate processors.
export const processSystemLogDataForChart = (
  data,
  filters,
  selectedLogGroup
) => {
  const items = data && data.items ? data.items : [];
  return processLogDataForChart(items, filters, selectedLogGroup);
};

export const processAccountLogDataForChart = (
  data,
  filters,
  selectedLogGroup
) => {
  const log_items = data && data.log_items ? data.log_items : [];
  return processLogDataForChart(log_items, filters, selectedLogGroup);
};

export const formatResultDateForLogs = (datetime) => {
  //Input & output datetime is in utc time
  if (!datetime) return "";
  return format(
    parse((datetime / 1000).toString(), "yyyyMMddHHmmssSSS", new Date()),
    "yyyy-MM-dd HH:mm:ss"
  );
};

export default {
  getSystemFilteredChartData,
  getAccountFilteredChartData,
  processSystemLogDataForChart,
  processAccountLogDataForChart,
  formatResultDateForLogs,
};

/* chartDataGetterFactory
 * generates a getter function that can be passed to usageChart.
 */
function chartDataGetterFactory({
  getter,
  date_format = "yyyyMMdd'T'HHmmss'Z'", //defaults to public API format
}) {
  return async (filters) => {
    const payload = {
      start_time: format(filters.startDate, date_format),
      end_time: format(filters.endDate, date_format),
      limit: 5000,
      aggregator_type: filters.aggregatorType,
    };

    const filteredChartData = await getter(payload)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        toast.error(error.toString());
        return false;
      });

    return filteredChartData;
  };
}

//*****************************************************************************
//  * processLogDataForChart
//
//  log_items contains ALL aggregated log data for ALL log types.
//  trim unnecessary logs and convert data to required format for the chart lib

//  There  are two possible input formats, but they are similar enough that
//  processLogDataForChart for chart can handle either. This may change in
//  the future if account and system logs endpoints diverge for some reason.

//   input format for Account logs:
//   (list of objects each representing a day/month for a type)
//     [
//       {
//         account_id: "4"
//         account_id_log_type: "4-sms.delivered"
//         aggregate_key: "days"
//         log_count: 8
//         log_timestamp: 20200821
//         log_type: "sms.delivered"
//       },
//       ...
//     ]

//   input format for System logs:
//   (list of objects each representing a day/month for a type)
//      {
//        "aggregate_key": "hours",
//        "log_count": 3868,
//        "log_timestamp": 20200701,
//        "log_type": "sms.received"
//      },

//   output format for chart:
//   (list of objects each type that includes log totals for each day/month in interval)
//   [
//     {
//       id: "messages.sent"   <-- not required for chart, but helpful to have
//       name: "Messages Sent",
//       data: [
//         [1597723200000, 0],
//         [1597809600000, 0],
//         [1597896000000, 0],
//         [1597982400000, 16],
//         [1598068800000, 0],
//         [1598155200000, 0],
//       ]
//     },
//     ...
//   ]
//*****************************************************************************
function processLogDataForChart(
  log_items = [],
  filters = {},
  selectedLogGroup = "all"
) {
  // 1. Set up our interval - a list of days or months to be displayed
  let interval = [];
  if (filters.aggregatorType === "days") {
    interval = eachDayOfInterval({
      start: filters.startDate,
      end: filters.endDate,
    });
  } else {
    interval = monthsRange(
      filters.startDate,
      filters.endDate,
      chartDateFormats["months"]
    );
  }

  // 2. create an intermediate hash data structure to:
  //   - convert timestamp format
  //   - group data by log type
  //   - insert entries where no data is returned (i.e. days with 0 logs)

  //   {
  //     messages.queued: {
  //       1597982400000: 16,
  //       1597636800000: 5,
  //       1597723200000: 0,
  //       1597809600000: 0,
  //       1597896000000: 2,
  //       1597982400000: 16,
  //       1598068800000: 0,
  //       1598155200000: 0,
  //     ],
  //     messages.received: [
  //       1597982400000: 10,
  //       ...
  //     ],
  //   }

  // Initialize hash with:
  //    * all log types being displayed on this render
  //    * all interval periods being displayed on this render
  // This ensures that even types with no data will be represented

  let tmpLogHash = {};
  let tmpDateHash = {};

  interval.forEach((date) => {
    tmpDateHash[Date.parse(date)] = 0;
  });
  logGroups[selectedLogGroup].types.forEach((type) => {
    tmpLogHash[type] = { ...tmpDateHash };
  });

  // Insert log count data into the initialized hash
  log_items.forEach((logItem) => {
    const type = logItem.log_type;

    //skip log types that aren't being display on this render
    if (!tmpLogHash[type]) return;

    //Convert log timestamp to JS date object
    const logDate = parse(
      logItem.log_timestamp,
      chartDateFormats[filters.aggregatorType],
      new Date()
    );
    //Convert date object to epoch-based time string
    const logTimestamp = Date.parse(logDate);

    //insert entry for log type/date in output format
    tmpLogHash[type][logTimestamp] = logItem.log_count;
  });

  //convert intermediate hash structure into array-based output format
  const output = [];
  for (let logType in tmpLogHash) {
    const series_label = logTypes[logType];
    const series = tmpLogHash[logType];

    //convert data series to array format
    let data = [];
    for (let period in series) {
      const count = series[period];
      //parseInt to prevent period from converting to string. Because javascript :/
      data.push([parseInt(period), count]);
    }

    //Create final entry for this series
    output.push({
      id: logType,
      name: series_label,
      data: data,
    });
  }

  return output;
}

function monthsRange(startDate, endDate, format) {
  const start = startDate.getMonth();
  const end = endDate.getMonth();
  const startYear = parseInt(startDate.getFullYear());
  const endYear = parseInt(endDate.getFullYear());
  let dates = [];

  for (let i = startYear; i <= endYear; i++) {
    const endMonth = i !== endYear ? 11 : parseInt(end);
    const startMonth = i === startYear ? parseInt(start) : 0;

    for (let j = startMonth; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
      const month = j + 1;
      const displayMonth = month < 10 ? "0" + month : String(month);
      const date = i + displayMonth;

      dates.push(parse(date, format, new Date()));
    }
  }
  return dates;
}
