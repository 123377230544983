import endpoints from "./endpoints.js";
import { factory } from "vivialconnect-js-core";

//NOTE: do not use spread syntax here.
//serviceHelpers == {init, http, appSessionStore}
const serviceHelpers = factory.initService(endpoints);

const getContacts = async (params, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.get(
      endpoints.contacts(id ? id : appSessionStore.getAccountId(), { params })
    );
    return data.contacts;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const postContacts = async (payload, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.post(
      endpoints.contacts(id ? id : appSessionStore.getAccountId()),
      payload
    );

    return data.contact;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getContact = async (contactId, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.get(
      endpoints.contact(id ? id : appSessionStore.getAccountId(), contactId)
    );
    return data.contact;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const putContact = async (contactId, payload, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.put(
      endpoints.contact(id ? id : appSessionStore.getAccountId(), contactId),
      payload
    );
    return data.contact;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const deleteContact = async (contactId, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.delete(
      endpoints.contact(id ? id : appSessionStore.getAccountId(), contactId)
    );
    return data.contact;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getContactsCount = async (id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.get(
      endpoints.contactsCount(id ? id : appSessionStore.getAccountId())
    );
    return data;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

export default {
  init: serviceHelpers.init,
  getContacts,
  postContacts,
  getContact,
  putContact,
  deleteContact,
  getContactsCount,
};
