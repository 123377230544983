/* eslint-disable react/prop-types */
import React from "react";
import Box from "@material-ui/core/Box";

import { useStyles } from "./styles";

export default function Section(props) {
  const classes = useStyles();
  const { children, className } = props;
  let rootClass = classes.root;
  rootClass += classes[props.bg] ? ` ${classes[props.bg]}` : "";
  if (className) {
    className.split(" ").forEach((name) => {
      rootClass += classes[name] ? ` ${classes[name]}` : ` ${name}`;
    });
  }

  return (
    <Box component="section" className={rootClass}>
      {children}
    </Box>
  );
}
