/* eslint-disable react/prop-types */
import React from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";

export default function SearchField({
  label,
  placeholder = "(xxx) xxx-xxxx",
  searchValue,
  onSearch,
  fullWidth = false,
  error,
  ...rest
}) {
  return (
    <TextField
      id="standard-search"
      label={label}
      type="search"
      inputProps={{ ref: searchValue }}
      onChange={(event) => {
        event.preventDefault();
        if (event.target.value === "") {
          onSearch();
        }
      }}
      onKeyPress={(event) => {
        if (event.key === "Enter") {
          event.preventDefault();
          onSearch();
        }
      }}
      style={{ margin: 8 }}
      placeholder={placeholder}
      margin="normal"
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <IconButton aria-label="search" edge="end" onClick={onSearch}>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      variant="outlined"
      fullWidth={fullWidth}
      error={error ? error !== "" : false}
      helperText={error}
      {...rest}
    />
  );
}
