import React from "react";
import {
  // BuildIcon,
  HomeIcon,
  PaymentIcon,
  PhoneIcon,
  AccountTreeIcon,
  // UpgradeIcon,
} from "components/VivialIcons";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import ChatOutlinedIcon from "@material-ui/icons/ChatOutlined";
//import DescriptionIcon from "@material-ui/icons/Description";

export const menuItems = [
  {
    name: "Company Name",
    route: "/dashboard/account",
    children: [
      {
        optionName: "Account Settings",
        route: "/dashboard/account",
      },
      {
        optionName: "Access Tokens",
        route: "/dashboard/account/tokens",
      },
      {
        optionName: "API Keys",
        route: "/dashboard/account/keys",
      },
    ],
    id: "company",
    icon: <RadioButtonUncheckedIcon />,
  },
  {
    name: "Dashboard",
    route: "/dashboard",
    icon: <HomeIcon />,
    iconClassName: "dashboardIcon",
  },
  {
    name: "Billing",
    route: "/dashboard/billing",
    children: [
      {
        optionName: "Billing Status",
        route: "/dashboard/billing",
      },
      {
        optionName: "Billing History",
        route: "/dashboard/billing/history",
        freetrial: false,
      },
      {
        optionName: "Payment Methods",
        route: "/dashboard/billing/payment",
        freetrial: false,
      },
    ],
    id: "billing",
    icon: <PaymentIcon />,
  },
  {
    name: "Numbers",
    route: "/dashboard/numbers",
    children: [
      {
        optionName: "Manage Numbers",
        route: "/dashboard/numbers",
        alternativeRoute: "/dashboard/numbers/edit/:id",
      },
      {
        optionName: "Buy Numbers",
        route: "/dashboard/numbers/purchase",
      },
    ],
    id: "numbers",
    icon: <PhoneIcon />,
  },
  {
    name: "Connectors",
    route: "/dashboard/connectors",
    children: [
      {
        optionName: "Manage Connectors",
        route: "/dashboard/connectors",
        alternativeRoute: "/dashboard/connectors/edit/:id",
      },
      {
        optionName: "Create Connectors",
        route: "/dashboard/connectors/create",
      },
    ],
    id: "connectors",
    icon: <AccountTreeIcon />,
  },
  {
    name: "10DLC",
    route: "/dashboard/campaigns",
    children: [
      {
        optionName: "Brands",
        route: "/dashboard/campaigns/brands",
      },
      {
        optionName: "Campaigns",
        route: "/dashboard/campaigns",
      },
    ],
    id: "10dlc",
    icon: <ChatOutlinedIcon />,
    freetrial: false,
  },
  // {
  //   name: "Reports",
  //   route: "/dashboard/reports",
  //   id: "reports",
  //   icon: <BuildIcon />,
  // },
  // {
  //   name: "Upgrade",
  //   route: "/dashboard/upgrade",
  //   icon: <UpgradeIcon />,
  //   freetrial: true,
  // },
];
