import React from "react";
import { NavLink } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";

import VivialConnectAPI from "api/vivial-connect";

import { navItems } from "../AppBar/constants";

const useStyles = makeStyles((theme) => ({
  footerNavContainer: {
    backgroundColor: theme.palette.background.default,
    paddingTop: "30px",
    textAlign: "center",
    fontWeight: 300,
  },
  footerMenu: {
    marginBottom: "5px",
  },
  menuItem: {
    alignItems: "center",
    "& a": {
      display: "inline-block",
      padding: "3px 10px",
      fontFamily: theme.typography.fontTitle,
      fontSize: "13px",
      color: theme.palette.text.primary,
      textDecoration: "none",
    },
    "& a:hover": {
      textDecoration: "underline",
    },
  },
  copyright: {
    fontFamily: theme.typography.fontTitle,
    fontSize: "13px",
    textAlign: "center",
    color: theme.palette.text.primary,
    paddingTop: "15px",
  },
}));

export default function FooterNav() {
  const classes = useStyles();
  const isLoggedOut = !VivialConnectAPI.helpers.token.get();

  function displayNavItems(items) {
    return items.map((item, index) => {
      if (isLoggedOut && item.isProtected === true) return null;
      if (!isLoggedOut && item.isProtected === false) return null;

      if (item.children) {
        return displayNavItems(item.children);
      } else {
        return (
          <Grid item className={classes.menuItem} key={index}>
            <NavLink to={item.route}>{item.name}</NavLink>
          </Grid>
        );
      }
    });
  }

  return (
    <Typography component="div" className={classes.footerNavContainer}>
      <Grid
        container
        item
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        className={classes.footerMenu}
      >
        {displayNavItems(navItems)}
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        className={classes.footerMenu}
      >
        <Grid item className={classes.menuItem}>
          <NavLink to="/legal">Terms &amp; Conditions</NavLink>
        </Grid>
        <Grid item className={classes.menuItem}>
          <NavLink to="/privacy-policy">Privacy Policy</NavLink>
        </Grid>
      </Grid>

      <div className={classes.copyright}>
        {"© Copyright "}
        {new Date().getFullYear()}
        {" Vivial Inc. All Rights Reserved."}
      </div>
    </Typography>
  );
}
